.corousal-card {
    background-color: #f9faf9;
    padding: 20px 14px;
    border-radius: 16px;
    margin-bottom: 14px;
}

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

.d-flex.jc-space-between {
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.details-section-wrap .details-section .section-heading-wrap .heading {
    font-size: 16px;
    line-height: 24px;
    color: #121212;
    font-weight: 700;
}

.watemplatebuilder-container .createtemplate-panel .box-setting .template-items .details-section-wrap .red-heading {
    color: #6c6c6c;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
    pointer-events: none;
}

.details-section-wrap .details-section .section-body-wrap {
    margin-top: 14px;
}

.corousal-card-pagination-bar-container {
    margin-top: 14px;
}

.corousal-card-pagination-bar-container .pagination-bar-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 5px;
    background: #fff;
    border-radius: 12px;
    border: 1px solid #c5c5c5;
    transition: width 2s;
}

.corousal-card-pagination-bar-container .pagination-bar-list li.active {
    background-color: #d91b2a;
    color: #fff;
    border-radius: 12px;
}

.corousal-card-pagination-bar-container .pagination-bar-list li.add-list {
    width: 70px;
    margin-left: 5px;
}

.corousal-card-pagination-bar-container .pagination-bar-list li {
    height: 35px;
    width: 55px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    cursor: pointer;
    font-weight: 700;
    font-size: 13px;
    line-height: 22px;
}

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

.d-flex.jc-space-between {
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.details-section-wrap .details-section .section-body-wrap {
    margin-top: 14px;
}
* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

.width100 {
    width: 100%;
}

.floating-input-wrap .input-control:valid {
    border-color: #c5c5c5;
}

watemplatebuilder-container .createtemplate-panel .box-setting .template-items .details-section-wrap #message, .watemplatebuilder-container .createtemplate-panel .box-setting .template-items .template-content #message {
    font-weight: 200;
}

.floating-input-wrap.twoboxarea .textarea {
    height: 180px;
    padding-bottom: 48px;
    font-size: 14px;
}

.floating-input-wrap .input-control:valid {
    border-color: #c5c5c5;
}

.floating-input-wrap .input-control {
    height: 46px;
}

.floating-input-wrap .input-control {
    background-color: transparent;
    font-size: 16px;
    color: #121212;
    padding: 10px 16px;
    width: 100%;
    outline: 0;
    border-radius: 12px;
    border: 1px solid #c5c5c5;
    position: relative;
    z-index: 1;
    font-size: 14px;
    transition: all .3s ease-in-out;
    height: 46px;
}

input, button, select, optgroup, textarea {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}

textarea {
    resize: none;
}

a, area, button, input, label, select, summary, textarea, [tabindex] {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

textarea {
    margin: 0;
    overflow: auto;
    resize: vertical;
}

textarea {
    overflow: auto;
    resize: vertical;
}

input, button, select, optgroup, textarea {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}

a, area, button, [role='button'], input:not([type='range']), label, select, summary, textarea {
    touch-action: manipulation;
}

input[type='text'], input[type='password'], input[type='number'], textarea {
    -webkit-appearance: none;
}

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

* {
    box-sizing: border-box;
}

*, ::before, ::after {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: border-box;
}
user agent stylesheet
textarea {
    font-style: normal; /* Added a default value */
    font-variant-ligatures: normal; /* Added a default value */
    font-variant-caps: normal; /* Added a default value */
    font-variant-numeric: normal; /* Added a default value */
    font-variant-east-asian: normal; /* Added a default value */
    font-variant-alternates: normal; /* Added a default value */
    font-variant-position: normal; /* Added a default value */
    font-weight: normal; /* Added a default value */
    font-stretch: normal; /* Added a default value */
    font-size: medium; /* Added a default value */
    font-family: monospace; /* Already correctly set */
    font-optical-sizing: auto; /* Added a default value */
    font-size-adjust: none; /* Added a default value */
    font-kerning: auto; /* Added a default value */
    font-feature-settings: normal; /* Added a default value */
    font-variation-settings: normal; /* Added a default value */
    text-rendering: auto; /* Already correctly set */
    color: fieldtext; /* Fieldtext is not a valid value; use 'black' or a valid color */
    letter-spacing: normal; /* Already correctly set */
    word-spacing: normal; /* Already correctly set */
    line-height: normal; /* Already correctly set */
    text-transform: none; /* Already correctly set */
    text-indent: 0px; /* Already correctly set */
    text-shadow: none; /* Already correctly set */
    display: inline-block; /* Already correctly set */
    text-align: start; /* Already correctly set */
    appearance: auto; /* Already correctly set */
    -webkit-rtl-ordering: logical; /* Already correctly set */
    resize: auto; /* Changed from -internal-textarea-auto to auto */
    cursor: text; /* Already correctly set */
    overflow-wrap: break-word; /* Already correctly set */
    background-color: white; /* Changed from field to white (or another valid color) */
    column-count: auto !important; /* Changed from initial to auto */
    margin: 0; /* Changed from 0em to 0 */
    border-width: 1px; /* Already correctly set */
    border-style: solid; /* Already correctly set */
    border-color: rgb(118, 118, 118); /* Simplified border-color, use a single color */
    border-image: none; /* Changed from initial to none */
    padding: 2px; /* Already correctly set */
    white-space: pre-wrap; /* Already correctly set */
}


.watemplatebuilder-container .createtemplate-panel {
    font-weight: 400;
    background-color: #f8f8f8;
}
style attribute {
    font-family: Nunito, sans-serif;
    line-height: 1.3;
}

body {
    font-family: "Nunito", "sans-serif";
    font-weight: 100;
    line-height: 1.3;
    margin: 0;
}

body {
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #222;
}

body {
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #222;
}

body {
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #222;
}

body {
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #222;
}

body {
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #222;
}

body {
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #222;
}

body {
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #222;
}

body {
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #222;
}
style attribute {
    --menubar-background: #1D222B;
    --submenubar-background: #29313D;
    --selected-icon-background: #52627a;
    --selected-icon-border: #D73737;
    --selected-secondary-icon-border: transparent;
    --menubar-font-color: #D4D5D6;
    --menubar-font-color-selected: #F6F6F6;
    --menubar-font-weight: 400;
    --menubar-font-weight-selected: 700;
    --menubar-background-selected: #52627A;
    --menubar-separator-color: #394556;
    --menubar-chevron-background: #52627A;
}

:root {
    --button-color: #d91b2a;
    --tab-color: #d91b2a;
    --menubar-background: #e7e7e7;
    --submenubar-background: #fff;
    --selected-icon-background: #fff;
    --selected-icon-border: #fff;
    --selected-secondary-icon-border: #d91b2a;
    --menubar-font-color: #a0a0a0;
    --menubar-font-color-selected: #121212;
    --menubar-font-weight: 700;
    --menubar-font-weight-selected: 700;
    --menubar-background-selected: linear-gradient(90deg, rgba(224, 34, 45, 0.07) 0%, rgba(224, 34, 45, 0.0133) 100%);
    --menubar-separator-color: #e7e7e7;
    --menubar-chevron-background: #e7e7e7;
}

:root {
    --button-color: #d91b2a;
    --tab-color: #d91b2a;
    --menubar-background: #e7e7e7;
    --submenubar-background: #fff;
    --selected-icon-background: #fff;
    --selected-icon-border: #fff;
    --selected-secondary-icon-border: #d91b2a;
    --menubar-font-color: #a0a0a0;
    --menubar-font-color-selected: #121212;
    --menubar-font-weight: 700;
    --menubar-font-weight-selected: 700;
    --menubar-background-selected: linear-gradient(90deg, rgba(224, 34, 45, 0.07) 0%, rgba(224, 34, 45, 0.0133) 100%);
    --menubar-separator-color: #e7e7e7;
    --menubar-chevron-background: #e7e7e7;
}

:root {
    --button-color: #d91b2a;
    --tab-color: #d91b2a;
    --menubar-background: #e7e7e7;
    --submenubar-background: #fff;
    --selected-icon-background: #fff;
    --selected-icon-border: #fff;
    --selected-secondary-icon-border: #d91b2a;
    --menubar-font-color: #a0a0a0;
    --menubar-font-color-selected: #121212;
    --menubar-font-weight: 700;
    --menubar-font-weight-selected: 700;
    --menubar-background-selected: linear-gradient(90deg, rgba(224, 34, 45, 0.07) 0%, rgba(224, 34, 45, 0.0133) 100%);
    --menubar-separator-color: #e7e7e7;
    --menubar-chevron-background: #e7e7e7;
}

:root {
    --button-color: #d91b2a;
    --tab-color: #d91b2a;
    --menubar-background: #e7e7e7;
    --submenubar-background: #fff;
    --selected-icon-background: #fff;
    --selected-icon-border: #fff;
    --selected-secondary-icon-border: #d91b2a;
    --menubar-font-color: #a0a0a0;
    --menubar-font-color-selected: #121212;
    --menubar-font-weight: 700;
    --menubar-font-weight-selected: 700;
    --menubar-background-selected: linear-gradient(90deg, rgba(224, 34, 45, 0.07) 0%, rgba(224, 34, 45, 0.0133) 100%);
    --menubar-separator-color: #e7e7e7;
    --menubar-chevron-background: #e7e7e7;
}

:root {
    --button-color: #d91b2a;
    --tab-color: #d91b2a;
    --menubar-background: #e7e7e7;
    --submenubar-background: #fff;
    --selected-icon-background: #fff;
    --selected-icon-border: #fff;
    --selected-secondary-icon-border: #d91b2a;
    --menubar-font-color: #a0a0a0;
    --menubar-font-color-selected: #121212;
    --menubar-font-weight: 700;
    --menubar-font-weight-selected: 700;
    --menubar-background-selected: linear-gradient(90deg, rgba(224, 34, 45, 0.07) 0%, rgba(224, 34, 45, 0.0133) 100%);
    --menubar-separator-color: #e7e7e7;
    --menubar-chevron-background: #e7e7e7;
}

:root {
    --button-color: #d91b2a;
    --tab-color: #d91b2a;
    --menubar-background: #e7e7e7;
    --submenubar-background: #fff;
    --selected-icon-background: #fff;
    --selected-icon-border: #fff;
    --selected-secondary-icon-border: #d91b2a;
    --menubar-font-color: #a0a0a0;
    --menubar-font-color-selected: #121212;
    --menubar-font-weight: 700;
    --menubar-font-weight-selected: 700;
    --menubar-background-selected: linear-gradient(90deg, rgba(224, 34, 45, 0.07) 0%, rgba(224, 34, 45, 0.0133) 100%);
    --menubar-separator-color: #e7e7e7;
    --menubar-chevron-background: #e7e7e7;
}

:root {
    --button-color: #d91b2a;
    --tab-color: #d91b2a;
    --menubar-background: #e7e7e7;
    --submenubar-background: #fff;
    --selected-icon-background: #fff;
    --selected-icon-border: #fff;
    --selected-secondary-icon-border: #d91b2a;
    --menubar-font-color: #a0a0a0;
    --menubar-font-color-selected: #121212;
    --menubar-font-weight: 700;
    --menubar-font-weight-selected: 700;
    --menubar-background-selected: linear-gradient(90deg, rgba(224, 34, 45, 0.07) 0%, rgba(224, 34, 45, 0.0133) 100%);
    --menubar-separator-color: #e7e7e7;
    --menubar-chevron-background: #e7e7e7;
}

:root {
    --button-color: #d91b2a;
    --tab-color: #d91b2a;
    --menubar-background: #e7e7e7;
    --submenubar-background: #fff;
    --selected-icon-background: #fff;
    --selected-icon-border: #fff;
    --selected-secondary-icon-border: #d91b2a;
    --menubar-font-color: #a0a0a0;
    --menubar-font-color-selected: #121212;
    --menubar-font-weight: 700;
    --menubar-font-weight-selected: 700;
    --menubar-background-selected: linear-gradient(90deg, rgba(224, 34, 45, 0.07) 0%, rgba(224, 34, 45, 0.0133) 100%);
    --menubar-separator-color: #e7e7e7;
    --menubar-chevron-background: #e7e7e7;
}

html {
    cursor: default;
    line-height: 1.5;
    -moz-tab-size: 4;
    tab-size: 4;
    -webkit-tap-highlight-color: transparent /* 4 */;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    word-break: break-word;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::before, ::after {
    text-decoration: inherit;
    vertical-align: inherit;
}

*, ::before, ::after {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: border-box;
}

::before, ::after {
    text-decoration: inherit;
    vertical-align: inherit;
}

*, ::before, ::after {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: border-box;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-corner {
    background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .4);
    box-shadow: 0 0 1px rgba(255, 255, 255, .4);
}

/* ::selection {
    background: Highlight;
    color: HighlightText;
}

::selection {
    background: Highlight;
    color: HighlightText;
}

::selection {
    background: Highlight;
    color: HighlightText;
}

::selection {
    background: Highlight;
    color: HighlightText;
}

::selection {
    background: Highlight;
    color: HighlightText;
}

::selection {
    background: Highlight;
    color: HighlightText;
}

::selection {
    background: Highlight;
    color: HighlightText;
}

::selection {
    background: Highlight;
    color: HighlightText;
} */

.selected-card {
    background-color: #007bff; /* Example color for selected card */
    color: white;
}

.active-dropzone {
    background-color: #eb1b1b; /* Selected card color */
    color: white;
    border: none;
    border-radius: 5px;
    width: 70px;
    font-size: 15px;
    padding: 1px; /* Added padding for better spacing */
    display: flex; /* Use flexbox for alignment */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transition */
}

.active-dropzone:hover {
    background-color: #c71a1a; /* Slightly darker on hover */
    transform: scale(1.05); /* Slight scale effect on hover */
    cursor: pointer;
}

.choose-list {
    background-color: #f8f9fa; /* Light background for unselected cards */
    color: #212529; /* Default text color */
}

.choose-list:hover {
    background-color: #e2e6ea; /* Light gray on hover */
    transform: scale(1.05); /* Scale effect on hover */
    cursor: pointer;
}

.whatsappCardList {
    display: flex; /* Use flexbox to align items in a row */
    list-style-type: none; /* Remove default list styling */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
}

.whatsappCardList li {
    margin-right: 10px; /* Space between items */
    cursor: pointer; /* Change cursor on hover */
}

