.table-container-agent {
	width: 100%;
	overflow-x: auto; /* Enable horizontal scrolling for small screens */
  }
  
  .table-agent {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
  }
  
  .table-agent th,
  .table-agent td {
	padding: 8px;
	text-align: left;
	border-bottom: 1px solid #ddd;
  }
  
  .table-agent th {
	background-color: #f2f2f2;
  }
  
  .table-agent tbody tr:nth-child(even) {
	background-color: #f2f2f2;
  }
  
  .table-agent tbody tr:hover {
	background-color: #ddd;
  }

  .form-container-agent {
	max-width: 400px;
	margin: 0 auto;
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
  }
  
  .form-group-agent {
	margin-bottom: 20px;
  }
  
  .agent-label {
	display: block;
	margin-bottom: 5px;
  }
  
  /* input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="password"] {
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
  } */
  
  .agent-button {
	display: block;
	width: 100%;
	padding: 10px;
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
  }
  
  .agent-button:hover {
	background-color: #0056b3;
  }
  
  .agent-button:active {
	background-color: #004380;
  }

   
  .popup-agent {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	z-index: 9999;
	justify-content: center;
	align-items: center;
  }
  
  .assign-popup-content-agent {
	background-color: white;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
	max-width:100%;
	/* width: 35%; */
  }
.popupPointer svg {
	font-size: 20px;
	cursor: pointer;
	float:right;
}
  @media (max-width: 991px) {
    .navbar-collapse {
		margin-top: 0px !important;
	}
	.navar-collapse li a{
		margin-top: 15px;
	}
	.dropdown-toggle:after{
		display:none !important;
	}
	.popupHeader {
		margin-top: "-10px"; 
		margin-left: "50vh";
	}
	.content {
        padding: 60px 8px 21px !important;
    }
	.dashboard-header li {
		padding-top: 20px !important;
		padding-bottom: 10px !important;
	}
}
@media (max-width: 767px) {
	.navbar-align > .nav-item > .dropdown-toggle:after{
		display:none !important;
	}

	.popupHeader {
		margin-top: "0px"; 
		margin-left: "15vh";
	}
	.overflowblocks {
		overflow-y: scroll;
		max-height: 260px;
		padding: 0px 20px;
	}
	.popupPointer {
		margin-left: 30px;
	}
}

.alert {
	padding: 15px;
	margin-bottom: 20px;
	border: 1px solid transparent;
	border-radius: 4px;
  }
  
  .alert p {
	margin: 0;
  }
  
  .close-btn-alert {
	cursor: pointer;
	float: right;
	font-weight: bold;
	font-size: 22px;
	line-height: 20px;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.2;
  }
  
  .close-btn-alert:hover {
	color: #000;
	text-decoration: none;
	opacity: 0.5;
  }
  
  .success-alert {
	background-color: #dff0d8;
	border-color: #d6e9c6;
	color: #3c763d;
  }
  
  .error-alert {
	background-color: #f2dede;
	border-color: #ebccd1;
	color: #a94442;
  }
  
  .info-alert {
	background-color: #d9edf7;
	border-color: #bce8f1;
	color: #31708f;
  }
  
  .warning-alert {
	background-color: #fcf8e3;
	border-color: #faebcc;
	color: #8a6d3b;
  }