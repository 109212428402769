@charset 'UTF-8';

*,
*::before,
*::after {
  box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

:root {
  --yellow: #fdc236;
  --orange: #ff8434;
  --voilet: #3c3664;
  --darkblack: #3f3f3f;
  --lightblack: #675f80;
  --lightvoilet: #5956d6;
}

@font-face {
  font-family: "poppins";
  src: url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
}

html {
  font-family: "Montserrat", sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #3c3664;
}

body {
  font-family: "poppins", sans-serif !important;
}

p {
  font-size: 15px;
  font-weight: 400;
}

.last-footer a {
  color: #e1dcfd !important;
  font-size: 14px !important;
}

.last-footer a:hover {
  color: var(--orange) !important;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  font-family: "poppins";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  margin: 0;
  text-align: left;
  color: #6c757d;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  overflow: visible;

  box-sizing: content-box;
  height: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.66667rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  cursor: help;
  text-decoration: underline;
  text-decoration: underline dotted;
  border-bottom: 0;
  -webkit-text-decoration: underline dotted;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

.user-profile {
  display: block;
  background: #dbeeff;
  text-align: -webkit-center;
  padding: 5px;
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 700;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;

  position: relative;

  vertical-align: baseline;
}

.site-button {
  background: #4ec9b0 !important;
  padding: 0px 20px;
  border-radius: 4px;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  text-decoration: none;
  color: var(--lightvoilet);
  background-color: transparent;
}

a:hover {
  text-decoration: none;
  color: var(--lightvoilet);
}

a:not([href]) {
  text-decoration: none;

  color: inherit;
}

a:not([href]):hover {
  text-decoration: none;
  color: inherit;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}

pre {
  overflow: auto;

  margin-top: 0;
  margin-bottom: 1rem;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;

  border-style: none;
}

svg {
  overflow: hidden;

  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
  caption-side: bottom;
  text-align: left;
  color: #718096;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;

  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

div#carouselExampleControls i {
  color: #008aff;
  font-size: 56px;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;

  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;

  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;

  resize: vertical;
}

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;

  border: 0;
}

div#carouselExampleControls a {
  color: #008aff;
  font-size: 39px;
}

legend {
  font-size: 1.5rem;
  line-height: inherit;

  display: block;

  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5rem;
  padding: 0;

  white-space: normal;

  color: inherit;
}

@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw);
  }
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;

  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;

  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;

  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  font-weight: 600;
  line-height: 1.65;
  margin-bottom: 0.66667rem;
  color: var(--darkblack);
}

h1,
.h1 {
  font-size: 2.5rem;
}

@media (max-width: 1200px) {
  h1,
  .h1 {
    font-size: calc(1.375rem + 1.5vw);
  }
}

h2,
.h2 {
  font-size: 2rem;
}

@media (max-width: 1200px) {
  h2,
  .h2 {
    font-size: calc(1.325rem + 0.9vw);
  }
}

h3,
.h3 {
  font-size: 1.75rem;
}

@media (max-width: 1200px) {
  h3,
  .h3 {
    font-size: calc(1.3rem + 0.6vw);
  }
}

h4,
.h4 {
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  h4,
  .h4 {
    font-size: calc(1.275rem + 0.3vw);
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

nav.navbar.navbar-expand-lg.navbar-light.bg-white {
  padding: 16px 0px;
}

code {
  background-color: #3f3966;
  display: block;
  color: #fff !important;
  padding: 10px;
  margin: 12px 0px;
  border-radius: 5px;
}

.faq-blog h1 {
  font-size: 24px !important;
  font-weight: 600 !important;
}

.bg-gray-roted:after {
  content: "";
  background: #e9e1e14f;
  transform: rotate(4deg);
  position: absolute;
  width: 110%;
  height: 500px;
  top: 0px;
  left: -28px;
  z-index: -1;
}

.popup-box input,
.popup-box select {
  background-color: #fff !important;
}

.cross_button {
  cursor: pointer;
  position: absolute;
  right: 25px;
}

.popup-size {
  max-width: 700px;
  position: relative;
  background: #fff;
  padding: 18px;
  border-radius: 11px;
}

ul.socialIcons {
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: left;
  margin-left: -5px;
}

.socialIcons li {
  background-color: #282445;
  list-style: none;
  display: inline-block;
  margin: 4px;
  border-radius: 2em;
  overflow: hidden;
  padding: 10px;
}

.socialIcons li svg {
  margin-right: 14px;
  margin-top: -4px;
}

.socialIcons li a {
  display: block;
  min-width: 1.28571429em;
  max-width: 1.28571429em;
  height: 19px;
  white-space: nowrap;
  line-height: 1.5em;
  /*it's working only when you write text with icon*/
  transition: 0.5s;
  text-decoration: none;
  color: #fff;
}

.socialIcons li i {
  margin-right: 0.5em;
}

.socialIcons li:hover a {
  max-width: 200px;
  padding-right: 1em;
}

.socialIcons li:hover {
  background-color: var(--orange);
}

.popup-box {
  z-index: 9999999999;
  position: fixed;
  box-shadow: 0px 0px 51px 18px #cecece40;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 52%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark-blur-btn {
  background: #3f3966 !important;
  color: #fff !important;
}

.outline-btn {
  border: 1px solid #3f3966 !important;
}

.bg-gray-roted {
  position: relative;
  z-index: 9;
}

.translational-sms img {
  width: 40px;
  height: 40px;
}

.faq-blog small {
  background: #f5f5f4;
  display: block;
  padding: 8px;
  margin-bottom: 8px;
}

.filter-option select {
  color: #000;
}

.sms-count {
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
}

.startupsignup .form-control {
  border: 1px solid #c7c6c6 !important;
  border-radius: 5px !important;
  padding: 0px 0px 0px 10px !important;
}

.dlt-registration strong,
.dlt-registration ul li {
  color: #152c5b !important;
}

.border-signup {
  text-align: center;
}

.row-content-text {
  font-size: 14px !important;
  color: #152c5b !important;
  line-height: 25px;
  font-weight: 500 !important;
}

.navbar-collapse {
  align-items: center;
  width: 48%;
}

footer .heading {
  background: none;
  padding-left: 0px !important;
}

.Forgot a {
  color: gray;
}

.deals-and-offers {
  text-align: center;
  padding: 10px;
  background: rgb(95, 90, 127);
}

span.or-center {
  position: absolute;
  top: -14px;
  left: 5%;
  background: #fff;
  padding: 0px 20px;
}

.loginbg .form-control-label {
  color: #000;
  font-weight: 600 !important;
}

#singupData select {
  background: #f9fafb !important;
  border: none !important;
}

#singupData .home-button {
  margin: 0px 0px;
}

.blank {
  color: #000;
}

.loginbg .react-tel-input input[type="tel"] {
  border-bottom: 1px solid #3f396663 !important;
}

.loginbg .react-tel-input {
  width: auto !important;
}

.deals-and-offers p {
  padding: 0px;
  margin: 0px;
  color: #fff;
  font-size: 0.875rem;
}

.deals-and-offers a {
  color: #e1dcfd;
}

.startup-plan {
  list-style: none;
  padding: 0px;
  margin-bottom: 5px;
}

.deals-and-offers .btnlink:hover {
  color: var(--orange);
}

.startup-plan {
  position: relative;
}

.startup-plan li {
  font-weight: 500;
  color: var(--voilet);
  line-height: 2;
  margin-left: 5px;
  font-size: 15px;
  font-family: "poppins";
  list-style: disc;
}

.Forgot p {
  position: relative;
}

.hero h1 {
  font-size: 40px;
  color: var(--darkblack);
  font-family: "poppins";
  font-weight: 700;
}

.hero h5 {
  font-size: 18px;
  color: var(--darkblack);
  font-family: "poppins";
  font-weight: 600;
}

.code-text {
  color: #fff;
}

.free-border {
  padding: 50px 0px;
  border-top: 2px solid #c0c1c3;
  border-bottom: 2px solid #c0c1c3;
}

.free-border h3 {
  font-size: 22px !important;
  color: #152c65;
}

.free-border .border-right-bar {
  border-right: 1px solid #152c5b;
}

.free-image-option {
  width: 100%;
  max-width: 100%;
  text-align: center;
  display: block;
}

.bgnone {
  background: transparent;
}

.sample-code-api {
  list-style: none;
  color: #000;
  padding: 0px;
  position: sticky;
  top: 10px;
}

.sample-code-api .sublink {
  margin-left: 10px;
}

div#accordionExample li {
  font-weight: 600;
  color: #3f3966;
  font-size: 14px;
  display: block !important;
}

.sublink li:before {
  content: "→";
  display: inline-block;
  position: relative;
  transition: all 0.1s ease;
  transform: translateX(0);
}

.sublink a {
  color: #a8a7ab;
}

blockquote {
  margin-top: 20px;
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.4;
  position: relative;
  margin: 0;
  color: #152c5b;
  padding: 20px;
}

.blog-content {
  font-size: 16px !important;
  margin-top: 20px;
}

p.blog-content img {
  width: 100%;
  height: auto;
}

.blog-cover {
  background-image: url(../../components/Dashboard/assets/img/blog-bg-2.svg);
  background-image: url(/static/media/blog-bg-2.2aefbe54.svg);
  background-repeat: no-repeat;
  background-position: top -100px center;
  background-size: contain;
  padding-top: 20px;
  position: relative;
}

.blog-tag {
  position: absolute;
  top: 10px;
  left: 10px;
}

.translation-sms-box {
  border: 1px solid #d3c5c5;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  margin-bottom: 5px;
}

blockquote:before,
blockquote:after {
  position: absolute;
  color: #fdc236;
  font-size: 8rem;
  width: 4rem;
  height: 4rem;
}

blockquote:before {
  content: "“";
  left: -5rem;
  top: -2rem;
}

blockquote:after {
  content: "”";
  right: -5rem;
  bottom: 1rem;
}

.whatsapp-pricing-box {
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(90, 122, 190, 0.12) !important;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 3px 0 0 rgba(90, 122, 190, 0.12);
  box-shadow: 0 3px 0 0 rgba(90, 122, 190, 0.12);
  background-color: #fff;
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  -o-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  height: 100%;
}

.whatsapp-pricing-box p {
  font-size: 16px !important;
}

.whatsapp-pricing-box h2 {
  color: var(--voilet);
  font-weight: 600;
  font-size: 24px;
  background: var(--light-dark);
  padding: 12px 10px;
}

.whatsapp-pricing2 h2 {
  background: var(--orange);
  color: #fff;
}

.whatsapp-pricing2 {
  border: 3px var(--orange) solid !important;
  background: #fff8f4;
}

.green-color {
  color: #02877a;
}

.green_pricetxt {
  font-size: 24px;
  color: #02877a;
  font-weight: 700;
}

.smaplecode {
  margin-bottom: 10px;
}

ul.price-link {
  list-style: none;
  border-bottom: 1px solid #e2e8f0;
  padding: 0px;
}

.price-link .active {
  border-bottom: 2px solid var(--orange);
}

.price-link li {
  display: inline-block;
  padding: 0px 40px;
  margin-bottom: -2px;
}

.price-link li a {
  color: var(--orange);
  font-weight: bold;
  font-size: 18px;
}

.loginbg .input-group-prepend {
  border-bottom: 1px solid #3f396663 !important;
}

.li-active {
  color: #03a84e !important;
}

.subNavLink {
  padding-left: 0px;
  background: #fbfbfb;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.sample-code-api li {
  line-height: 2;
  cursor: pointer;
}

.sample-code-api li a,
.sample-code-api li {
  color: gray !important;
  font-size: 14px;
}

.sample-active {
  color: #03a84e !important;
  font-weight: 600;
}

.bgfreebk {
  background: var(--voilet);
  border-top-left-radius: 400px;
  border-bottom-left-radius: 400px;
}

ul.freeplan li {
  font-size: 14px;
  color: var(--voilet);
  line-height: 2;
  text-align: left;
}

.freeplan {
  list-style: disc;
  padding-left: 20px;
}

.email-unsubscribe .form-check-label {
  font-size: 13px;
}

.hero p {
  font-size: 18px;
  font-family: "poppins";
  font-weight: 400;
  color: var(--lightblack);
  margin-top: 20px;
}

.sms-api p {
  font-size: 14px;
  color: #000;
  font-weight: 400;
}

.sms-api {
  margin-bottom: 33px;
}

.login-right h3 {
  font-size: 30px !important;
  margin-top: 60px;
  font-weight: 900;
  color: #3f3966;
}

.login-right p {
  color: #3f3966;
  padding: 0px;
  font-size: 18px !important;
}

.small {
  font-size: 14px !important;
}

.login-new-box {
  background: #fff;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.kep-login-facebook {
  font-weight: normal !important;
  font-size: 13px !important;
}

.login-with {
  background: #fff;
  width: fit-content;
  left: 41%;
  top: -10px;
  padding: 0px 10px;
}

.login-new-box .form-control-label {
  display: none !important;
}

.login-page-info img {
  height: 40px;
  margin-bottom: 10px;
}

.doc-active-link {
  background: #e3deff;
}

.login-page-info p {
  color: #cdc9c9 !important;
}

.arrow-left::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid #5f5a7f;
  position: absolute;
  top: 50%;
  left: -15px;
}

.css-g1d714-ValueContainer {
  font-size: 13px;
}

.logininput {
  border-color: #cccccc !important;
  background: none !important;
  padding-left: 10px !important;
  font-size: 13px;
  background-color: #fff !important;
}

.loginbg {
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  background-size: 591px;
}

button.btn.btn-block.btn-sm.btn-success {
  height: 46px;
}

.sms-api p {
  font-size: 15px;
  color: #000;
}

.sms-api h2 {
  font-size: 30px;
}

.hero button {
  background: var(--voilet);
  font-size: 14px;
  padding: 10px 28px;
  border-radius: 4px;
  font-weight: 400;
  text-align: center;
  color: #fff;
}

.hero button:hover {
  background: var(--orange);
  color: #fff !important;
}

.button-custome {
  background: var(--voilet);
  color: #fff;
  padding: 5px 16px;
  border-radius: 6px;
  border-style: hidden;
}

.button-custome:hover {
  background: var(--orange);
}

.button-link {
  background: var(--voilet);
  color: #fff;
  border: none;
  border: 2px solid var(--voilet);
  border-radius: 6px;
  font-size: 15px;
  font-family: "poppins";
  padding: 10px 10px;
  min-width: 200px;
}

.cards1 {
  min-height: 320px;
}

.button-link:hover {
  background: var(--orange);
  border: 2px solid var(--orange);
}

.button_linkborder {
  background: transparent;
  color: var(--voilet);
  border: none;
  border: 2px solid var(--voilet);
  border-radius: 6px;
  font-size: 15px;
  font-family: "poppins";
  padding: 10px 10px;
}

.button_linkborder:hover {
  background: var(--orange);
  color: #fff;
  border: 2px solid var(--orange);
}

p.lead {
  color: var(--lightblack);
}

.aligned-row {
  display: flex;
  flex-flow: row wrap;
}

.code-text {
  color: #fff !important;
  font-family: none;
}

.hero-img {
  padding: 275px;
  background-position: 100px -50px;
  background-size: contain;
  background-repeat: no-repeat;
}

h5.h4.lh-130.mb-3 {
  font-size: 18px;
  height: 50px;
  vertical-align: middle;
  width: 100%;
  display: table-cell;
}

.tableblock {
  display: table;
  width: 100%;
  text-align: center;
}

a.readmore {
  color: var(--lightvoilet);
  font-weight: 600;
  font-size: 16px;
}

.border-right-bottom {
  border-right: 4px solid #ffdf73;
  min-height: 200px;
}

.border-right-text {
  border-left: 4px solid #ffdf73;
}

.sms-searvies-page img {
  width: 50px;
}

button.copy-clip {
  border: 0px;
  float: right;
  position: absolute;
  right: 29px;
  border-radius: 5px;
}

#myTabContent .form-control {
  color: #000;
}

.sms-searvies-page p:before {
  content: "";
  width: 41px;
  height: 2px;
  background-color: #3d3765;
  position: absolute;
  margin-top: -14px;
}

.rightBr {
  top: -11px;
  right: 0px;
  left: -57px;
  background: #f9fafb;
  width: 109%;
  position: absolute;
  height: 94%;
  transform: translateY(11%) rotate(5deg);
}

.rightBrSMS {
  top: -40px;
  right: 0px;
  left: -57px;
  background: #f9fafa;
  width: 107%;
  position: absolute;
  height: 66%;
  transform: translateY(11%) rotate(5deg);
}

.mobile-img {
  display: none;
  width: 100%;
}

.form-control:focus {
  box-shadow: none !important;
}

.summary-data {
  text-transform: capitalize;
}

.navbar {
  box-shadow: none !important;
}

.header-email {
  color: #3f3966;
  font-weight: 700;
}

.paivariable {
  color: #91ff00;
}

.api-doc {
  border-radius: 5px;
  color: #000;
}

.api-doc .card {
  height: 400px;
}

.api-doc .card p {
  height: 220px;
}

.api-doc .card img {
  height: 30px;
  margin-bottom: 10px;
}

.api-doc p,
.api-doc ul li {
  font-size: 14px;
  color: #4a4a4a;
}

.border_bar {
  width: 100px;
  height: 10px;
  margin: 0px auto;
  text-align: center;
  background: linear-gradient(
    to right,
    #ffdf73,
    #ffdf73 33.33%,
    #ff8434 33.33%,
    #ff8434 66.66%,
    #3d3765 66.66%
  );
}

.serviceBg {
  background: rgb(95, 90, 127);
  border-radius: 10px;
}

.home-button {
  padding: 8px 20px;
  color: #3a3462;
  border: 1px solid #3a3462;
  border-radius: 6px;
  font-size: 14px;
  font-family: "poppins";
  font-weight: 400;
  margin: 0px 5px;
}

.api-Docs {
  list-style: none;
  padding: 0px;
  line-height: 2;
}

.home-button a:hover {
  text-decoration: none;
}

.dropbtn {
  color: white;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 250px;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 5px 16px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}

.dropdown-content a:hover {
  background-color: #fbfbfb;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.btn-signup {
  background-color: #3a3462;
  color: #fff;
}

.btn-signup:hover {
  background-color: var(--orange) !important;
  border-color: var(--orange);
}

.isActive {
  font-weight: 700 !important;
  color: #3a3462 !important;
}

.swiper-button-next:after {
  background: #3d3765 !important;
  padding: 10px;
  position: absolute;
  left: 14px !important;
  font-size: 16px !important;
  top: -25px;
  color: #fff;
}

.swiper-button-prev:after {
  background: #3d3765 !important;
  padding: 10px;
  position: absolute;
  right: 14px;
  font-size: 16px !important;
  top: -25px;
  color: #fff;
}

.btn-signup:hover {
  background-color: #3a3462;
  color: #fff;
}

.blog-card {
  padding: 10px;
  height: 300px;
  overflow: hidden;
}

.blog-card p {
  height: 77px;
  margin-top: 25px;
}

.click-to-call {
  position: fixed;
  width: 150px;
  display: none;
  height: 150px;
  cursor: pointer;
  z-index: 200000;
  transition: visibility 0.5s;
  left: -55px;
  bottom: 5px;
}

#singupData .form-control {
  border: 1px solid #9a9a9a;
  border-radius: 5px;
}

.circle {
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  position: absolute;
  border-radius: 100%;
  border: 2px solid rgba(245, 128, 54, 0.4);
  opacity: 0.1;
  animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  transition: all 0.5s;
  transform-origin: 50% 50%;
}

.click-to-call.click-to-call-green .circle {
  border-color: #ff8434;
  opacity: 0.5;
}

.circle-fill {
  width: 100px;
  height: 100px;
  top: 50px;
  left: 50px;
  position: absolute;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: 0.1;
  animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  transition: all 0.5s;
  transform-origin: 50% 50%;
  background: yellow;
}

.img-circle {
  width: 60px;
  height: 60px;
  top: 70px;
  left: 70px;
  position: absolute;
  background: rgba(30, 30, 30, 0.1)
    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABNmlDQ1BQaG90b3Nob3AgSUNDIHByb2ZpbGUAAHjarY6xSsNQFEDPi6LiUCsEcXB4kygotupgxqQtRRCs1SHJ1qShSmkSXl7VfoSjWwcXd7/AyVFwUPwC/0Bx6uAQIYODCJ7p3MPlcsGo2HWnYZRhEGvVbjrS9Xw5+8QMUwDQCbPUbrUOAOIkjvjB5ysC4HnTrjsN/sZ8mCoNTIDtbpSFICpA/0KnGsQYMIN+qkHcAaY6addAPAClXu4vQCnI/Q0oKdfzQXwAZs/1fDDmADPIfQUwdXSpAWpJOlJnvVMtq5ZlSbubBJE8HmU6GmRyPw4TlSaqo6MukP8HwGK+2G46cq1qWXvr/DOu58vc3o8QgFh6LFpBOFTn3yqMnd/n4sZ4GQ5vYXpStN0ruNmAheuirVahvAX34y/Axk/96FpPYgAAACBjSFJNAAB6JQAAgIMAAPn/AACA6AAAUggAARVYAAA6lwAAF2/XWh+QAAAB/ElEQVR42uya7W3CMBCG31QM4A1aNggTlG6QbpBMkHYC1AloJ4BOABuEDcgGtBOETnD9c1ERCH/lwxeaV8oPFGP86Hy+DxMREW5Bd7gRjSDSNGn4/RiAOvm8C0ZCRD5PSkQVXSr1nK/xE3mcWimA1ZV3JYBZCIO4giQANoYxMwYS6+xKY4lT5dJPreWZY+uspqSCKPYN27GJVBDXheVSQe494ksiEWTuMXcu1dld9SARxDX1OAJ4lgjy4zDnFsC076A4adEiRwAZg4hOUSpNoCsBPDGM+HqkNGynYBCuILuWj+dgWysGsNe8nwL4GsrW0m2fxZBq9rW0rNcX5MOQ9eZD8JFahcG5g/iKT671alGAYQggpYWvpEPYWrU/HDTOfeRIX0q2SL3QN4tGhZJukVobQyXYWw7WtLDKDIuM+ZSzscyCE9PCy5IttCvnZNaeiGLNHKuz8ZVh/MXTVu/1xQKmIqLEAuJ0fNo3iG5B51oSkeKnsBi/4bG9gYB/lCytU5G9DryFW+3Gm+JLwU7ehbJrwTjq4DJU8bHcVbEV9dXXqqP6uqO5e2/QZRYJpqu2IUAA4B3tXvx8hgKp05QZW6dJqrLTNkB6vrRURLRwPHqtYgkC3cLWQAcDQGGKH13FER/NATzi786+BPDNjm1dMkfjn2pGkBHkf4D8DgBJDuDHx9BN+gAAAABJRU5ErkJggg==")
    no-repeat center center;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: 0.7;
  animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  transform-origin: 50% 50%;
}

.click-to-call.coccoc-alo-active .img-circle {
  animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out !important;
}

.click-to-call.click-to-call-green:hover .img-circle {
  background-color: #75eb50;
}

.click-to-call.click-to-call-green .img-circle {
  background-color: #03a84e;
}

@keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }

  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }

  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }

  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }

  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }

  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }

  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

.serviceBg h5,
.serviceBg p {
  color: #fff !important;
}

#singupData {
  background: #fff;
  padding: 35px 30px;
  border-radius: 11px;
  box-shadow: 0px 0px 51px 18px #cecece40;
}

/*
div#singupData:after {
  content: "";
  border-left: 1px solid transparent;
  border-right: 68px solid transparent;
  border-top: 52px solid #fff;
  position: absolute;
  bottom: -38px;
  left: 15px;
  box-shadow: -24px 5px 18px -11px #cecece26;
} */

.signup-button {
  background: #3a3462;
  color: #fff;
  padding: 6px 20px;
  font-size: 13px;
}

div#singupData input {
  background: #f9fafb;
  border: 1px solid #8080801f !important;
}

.newsletter {
  background-color: transparent;
  position: relative;
}

.newsletter .content {
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 2;
}

.newsletter .content h2 {
  color: #243c4f;
  margin-bottom: 40px;
}

.newsletter .content .form-control {
  height: 50px;
  border-color: #ffffff;
  border-radius: 0;
}

.newsletter .content.form-control:focus {
  box-shadow: none;
  border: 2px solid #243c4f;
}

.newsletter .content .btn {
  min-height: 50px;
  border-radius: 20;
  background: #3f3966;
  color: #fff;
  font-weight: 600;
}

.lead {
  font-size: 18px;
  font-weight: 400;
  margin-top: 15px;
}

.display-1 {
  font-size: 6rem;
  font-weight: 700;
  line-height: 1.3;
}

@media (max-width: 1200px) {
  .display-1 {
    font-size: calc(1.725rem + 5.7vw);
  }
}

.display-2 {
  font-size: 5rem;
  font-weight: 700;
  line-height: 1.3;
}

@media (max-width: 1200px) {
  .display-2 {
    font-size: calc(1.625rem + 4.5vw);
  }
}

.display-3 {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.3;
}

@media (max-width: 1200px) {
  .display-3 {
    font-size: calc(1.525rem + 3.3vw);
  }
}

.display-4 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.3;
}

@media (max-width: 1200px) {
  .display-4 {
    font-size: calc(1.425rem + 2.1vw);
  }
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;

  border: 0;
  border-top: 1px solid #e2e8f0;
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;

  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;

  list-style: none;
}

.list-inline {
  padding-left: 0;

  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.login-box {
  margin-bottom: 50px;
}

.login-box h6 {
  font-weight: 900;
  font-size: 24px !important;
}

.initialism {
  font-size: 90%;

  text-transform: uppercase;
}

.blockquote {
  font-size: 1.25rem;

  margin-bottom: 1rem;
}

.blockquote-footer {
  font-size: 80%;

  display: block;

  color: #718096;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  max-width: 100%;
  height: auto;
  padding: 0.25rem;

  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(31, 45, 61, 0.075);
}

.figure {
  display: inline-block;
}

.figure-img {
  line-height: 1;

  margin-bottom: 0.5rem;
}

.figure-caption {
  font-size: 90%;

  color: #718096;
}

code {
  font-size: 87.5%;

  word-wrap: break-word;

  color: #f074ad;
}

a > code {
  color: inherit;
}

kbd {
  font-size: 87.5%;

  padding: 0.2rem 0.4rem;

  color: #fff;
  border-radius: 0.2rem;
  background-color: #273444;
  box-shadow: inset 0 -0.1rem 0 rgba(31, 45, 61, 0.25);
}

kbd kbd {
  font-size: 100%;
  font-weight: 600;

  padding: 0;

  box-shadow: none;
}

pre {
  font-size: 87.5%;

  display: block;

  color: #273444;
}

pre code {
  font-size: inherit;

  word-break: normal;

  color: inherit;
}

.pre-scrollable {
  overflow-y: scroll;

  max-height: 340px;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;

  margin-right: -15px;
  margin-left: -15px;

  flex-wrap: wrap;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;

  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  max-width: 100%;

  flex-basis: 0;
  flex-grow: 1;
}

.row-cols-1 > * {
  max-width: 100%;

  flex: 0 0 100%;
}

.row-cols-2 > * {
  max-width: 50%;

  flex: 0 0 50%;
}

.row-cols-3 > * {
  max-width: 33.33333%;

  flex: 0 0 33.33333%;
}

.row-cols-4 > * {
  max-width: 25%;

  flex: 0 0 25%;
}

.row-cols-5 > * {
  max-width: 20%;

  flex: 0 0 20%;
}

.row-cols-6 > * {
  max-width: 16.66667%;

  flex: 0 0 16.66667%;
}

.col-auto {
  width: auto;
  max-width: 100%;

  flex: 0 0 auto;
}

.col-1 {
  max-width: 8.33333%;

  flex: 0 0 8.33333%;
}

.col-2 {
  max-width: 16.66667%;

  flex: 0 0 16.66667%;
}

.col-3 {
  max-width: 25%;

  flex: 0 0 25%;
}

.col-4 {
  max-width: 33.33333%;

  flex: 0 0 33.33333%;
}

.col-5 {
  max-width: 41.66667%;

  flex: 0 0 41.66667%;
}

.col-6 {
  max-width: 50%;

  flex: 0 0 50%;
}

.col-7 {
  max-width: 58.33333%;

  flex: 0 0 58.33333%;
}

.col-8 {
  max-width: 66.66667%;

  flex: 0 0 66.66667%;
}

.col-9 {
  max-width: 75%;

  flex: 0 0 75%;
}

.col-10 {
  max-width: 83.33333%;

  flex: 0 0 83.33333%;
}

.col-11 {
  max-width: 91.66667%;

  flex: 0 0 91.66667%;
}

.col-12 {
  max-width: 100%;

  flex: 0 0 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    max-width: 100%;

    flex-basis: 0;
    flex-grow: 1;
  }

  .row-cols-sm-1 > * {
    max-width: 100%;

    flex: 0 0 100%;
  }

  .row-cols-sm-2 > * {
    max-width: 50%;

    flex: 0 0 50%;
  }

  .row-cols-sm-3 > * {
    max-width: 33.33333%;

    flex: 0 0 33.33333%;
  }

  .row-cols-sm-4 > * {
    max-width: 25%;

    flex: 0 0 25%;
  }

  .row-cols-sm-5 > * {
    max-width: 20%;

    flex: 0 0 20%;
  }

  .row-cols-sm-6 > * {
    max-width: 16.66667%;

    flex: 0 0 16.66667%;
  }

  .col-sm-auto {
    width: auto;
    max-width: 100%;

    flex: 0 0 auto;
  }

  .col-sm-1 {
    max-width: 8.33333%;

    flex: 0 0 8.33333%;
  }

  .col-sm-2 {
    max-width: 16.66667%;

    flex: 0 0 16.66667%;
  }

  .col-sm-3 {
    max-width: 25%;

    flex: 0 0 25%;
  }

  .col-sm-4 {
    max-width: 33.33333%;

    flex: 0 0 33.33333%;
  }

  .col-sm-5 {
    max-width: 41.66667%;

    flex: 0 0 41.66667%;
  }

  .col-sm-6 {
    max-width: 50%;

    flex: 0 0 50%;
  }

  .col-sm-7 {
    max-width: 58.33333%;

    flex: 0 0 58.33333%;
  }

  .col-sm-8 {
    max-width: 66.66667%;

    flex: 0 0 66.66667%;
  }

  .col-sm-9 {
    max-width: 75%;

    flex: 0 0 75%;
  }

  .col-sm-10 {
    max-width: 83.33333%;

    flex: 0 0 83.33333%;
  }

  .col-sm-11 {
    max-width: 91.66667%;

    flex: 0 0 91.66667%;
  }

  .col-sm-12 {
    max-width: 100%;

    flex: 0 0 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    max-width: 100%;

    flex-basis: 0;
    flex-grow: 1;
  }

  .row-cols-md-1 > * {
    max-width: 100%;

    flex: 0 0 100%;
  }

  .row-cols-md-2 > * {
    max-width: 50%;

    flex: 0 0 50%;
  }

  .row-cols-md-3 > * {
    max-width: 33.33333%;

    flex: 0 0 33.33333%;
  }

  .row-cols-md-4 > * {
    max-width: 25%;

    flex: 0 0 25%;
  }

  .row-cols-md-5 > * {
    max-width: 20%;

    flex: 0 0 20%;
  }

  .row-cols-md-6 > * {
    max-width: 16.66667%;

    flex: 0 0 16.66667%;
  }

  .col-md-auto {
    width: auto;
    max-width: 100%;

    flex: 0 0 auto;
  }

  .col-md-1 {
    max-width: 8.33333%;

    flex: 0 0 8.33333%;
  }

  .col-md-2 {
    max-width: 16.66667%;

    flex: 0 0 16.66667%;
  }

  .col-md-3 {
    max-width: 25%;

    flex: 0 0 25%;
  }

  .col-md-4 {
    max-width: 33.33333%;

    flex: 0 0 33.33333%;
  }

  .col-md-5 {
    max-width: 41.66667%;

    flex: 0 0 41.66667%;
  }

  .col-md-6 {
    max-width: 50%;

    flex: 0 0 50%;
  }

  .col-md-7 {
    max-width: 58.33333%;

    flex: 0 0 58.33333%;
  }

  .col-md-8 {
    max-width: 66.66667%;

    flex: 0 0 66.66667%;
  }

  .col-md-9 {
    max-width: 75%;

    flex: 0 0 75%;
  }

  .col-md-10 {
    max-width: 83.33333%;

    flex: 0 0 83.33333%;
  }

  .col-md-11 {
    max-width: 91.66667%;

    flex: 0 0 91.66667%;
  }

  .col-md-12 {
    max-width: 100%;

    flex: 0 0 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    max-width: 100%;

    flex-basis: 0;
    flex-grow: 1;
  }

  .row-cols-lg-1 > * {
    max-width: 100%;

    flex: 0 0 100%;
  }

  .row-cols-lg-2 > * {
    max-width: 50%;

    flex: 0 0 50%;
  }

  .row-cols-lg-3 > * {
    max-width: 33.33333%;

    flex: 0 0 33.33333%;
  }

  .row-cols-lg-4 > * {
    max-width: 25%;

    flex: 0 0 25%;
  }

  .row-cols-lg-5 > * {
    max-width: 20%;

    flex: 0 0 20%;
  }

  .row-cols-lg-6 > * {
    max-width: 16.66667%;

    flex: 0 0 16.66667%;
  }

  .col-lg-auto {
    width: auto;
    max-width: 100%;

    flex: 0 0 auto;
  }

  .col-lg-1 {
    max-width: 8.33333%;

    flex: 0 0 8.33333%;
  }

  .col-lg-2 {
    max-width: 16.66667%;

    flex: 0 0 16.66667%;
  }

  .col-lg-3 {
    max-width: 25%;

    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.33333%;

    flex: 0 0 33.33333%;
  }

  .col-lg-5 {
    max-width: 41.66667%;

    flex: 0 0 41.66667%;
  }

  .col-lg-6 {
    max-width: 50%;

    flex: 0 0 50%;
  }

  .col-lg-7 {
    max-width: 58.33333%;

    flex: 0 0 58.33333%;
  }

  .col-lg-8 {
    max-width: 66.66667%;

    flex: 0 0 66.66667%;
  }

  .col-lg-9 {
    max-width: 75%;

    flex: 0 0 75%;
  }

  .col-lg-10 {
    max-width: 83.33333%;

    flex: 0 0 83.33333%;
  }

  .col-lg-11 {
    max-width: 91.66667%;

    flex: 0 0 91.66667%;
  }

  .col-lg-12 {
    max-width: 100%;

    flex: 0 0 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    max-width: 100%;

    flex-basis: 0;
    flex-grow: 1;
  }

  .row-cols-xl-1 > * {
    max-width: 100%;

    flex: 0 0 100%;
  }

  .row-cols-xl-2 > * {
    max-width: 50%;

    flex: 0 0 50%;
  }

  .row-cols-xl-3 > * {
    max-width: 33.33333%;

    flex: 0 0 33.33333%;
  }

  .row-cols-xl-4 > * {
    max-width: 25%;

    flex: 0 0 25%;
  }

  .row-cols-xl-5 > * {
    max-width: 20%;

    flex: 0 0 20%;
  }

  .row-cols-xl-6 > * {
    max-width: 16.66667%;

    flex: 0 0 16.66667%;
  }

  .col-xl-auto {
    width: auto;
    max-width: 100%;

    flex: 0 0 auto;
  }

  .col-xl-1 {
    max-width: 8.33333%;

    flex: 0 0 8.33333%;
  }

  .col-xl-2 {
    max-width: 16.66667%;

    flex: 0 0 16.66667%;
  }

  .col-xl-3 {
    max-width: 25%;

    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.33333%;

    flex: 0 0 33.33333%;
  }

  .col-xl-5 {
    max-width: 41.66667%;

    flex: 0 0 41.66667%;
  }

  .col-xl-6 {
    max-width: 50%;

    flex: 0 0 50%;
  }

  .col-xl-7 {
    max-width: 58.33333%;

    flex: 0 0 58.33333%;
  }

  .col-xl-8 {
    max-width: 66.66667%;

    flex: 0 0 66.66667%;
  }

  .col-xl-9 {
    max-width: 75%;

    flex: 0 0 75%;
  }

  .col-xl-10 {
    max-width: 83.33333%;

    flex: 0 0 83.33333%;
  }

  .col-xl-11 {
    max-width: 91.66667%;

    flex: 0 0 91.66667%;
  }

  .col-xl-12 {
    max-width: 100%;

    flex: 0 0 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;

  color: #718096;
  background-color: transparent;
}

.table th,
.table td {
  padding: 1rem;

  vertical-align: top;

  border-top: 1px solid #eaecf3;
}

.table thead th {
  vertical-align: bottom;

  border-bottom: 2px solid #eaecf3;
}

.table tbody + tbody {
  border-top: 2px solid #eaecf3;
}

.table-sm th,
.table-sm td {
  padding: 0.5rem;
}

.table-bordered {
  border: 1px solid #eaecf3;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #eaecf3;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(250, 251, 254, 0.3);
}

.table-hover tbody tr:hover {
  color: #718096;
  background-color: #fafbfe;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8deff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7ac2ff;
}

.table-hover .table-primary:hover {
  background-color: #9fd2ff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fd2ff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f9fafc;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f4f5f9;
}

.table-hover .table-secondary:hover {
  background-color: #e8ecf4;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #e8ecf4;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d1f0e6;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #aae3d1;
}

.table-hover .table-success:hover {
  background-color: #beeadc;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #beeadc;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #ceeaff;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #a4d9ff;
}

.table-hover .table-info:hover {
  background-color: #b5dfff;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #b5dfff;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffedc9;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdd9a;
}

.table-hover .table-warning:hover {
  background-color: #ffe5b0;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe5b0;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fbd0d4;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f8a8b0;
}

.table-hover .table-danger:hover {
  background-color: #f9b9bf;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f9b9bf;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f9fafc;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f4f5f9;
}

.table-hover .table-light:hover {
  background-color: #e8ecf4;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #e8ecf4;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bebdcb;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #86849f;
}

.table-hover .table-dark:hover {
  background-color: #b0afc0;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b0afc0;
}

.table-neutral,
.table-neutral > th,
.table-neutral > td {
  background-color: white;
}

.table-neutral th,
.table-neutral td,
.table-neutral thead th,
.table-neutral tbody + tbody {
  border-color: white;
}

.table-hover .table-neutral:hover {
  background-color: #f2f2f2;
}

.table-hover .table-neutral:hover > td,
.table-hover .table-neutral:hover > th {
  background-color: #f2f2f2;
}

.table-white,
.table-white > th,
.table-white > td {
  background-color: white;
}

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white;
}

.table-hover .table-white:hover {
  background-color: #f2f2f2;
}

.table-hover .table-white:hover > td,
.table-hover .table-white:hover > th {
  background-color: #f2f2f2;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #fafbfe;
}

.table-hover .table-active:hover {
  background-color: #e5eafa;
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #e5eafa;
}

.table .thead-dark th {
  color: #fff;
  border-color: #201b63;
  background-color: #171347;
}

.table .thead-light th {
  color: #4a5568;
  border-color: #eaecf3;
  background-color: transparent;
}

.table-dark {
  color: #fff;
  background-color: #171347;
}

   
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #201b63;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    overflow-x: auto;

    width: 100%;

    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    overflow-x: auto;

    width: 100%;

    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media only screen and (max-width: 600px) {
  .popup-size {
    width: 90%;
    height: 500px;
    overflow: scroll;
  }

  .each-slide > div {
    background-size: contain !important;
    margin-top: -186px;
    margin-bottom: -186px;
  }

  #progressbar li {
    font-size: 9px;
  }

  .row-reverse {
    display: flex;
    flex-direction: column-reverse;
  }

  .api-buttons .btn {
    display: block;
    margin-bottom: 10px;
  }

  .whatsapp-points li:before {
    display: none;
  }

  .blog-cover h1 {
    padding: 0px !important;
    padding-bottom: 20px !important;
    font-size: 23px;
  }

  section.loginbg {
    background-image: none !important;
  }

  .each-slide-mobile > div {
    background-size: contain !important;
    height: 290px;
    background-repeat: no-repeat;
  }

  .mobile-img button {
    display: none !important;
  }

  #singupData .input-group {
    margin-bottom: 10px;
  }

  .mobile-img {
    display: block;
  }

  .hide-in-mobile-img {
    display: none !important;
  }

  .mobileMenu {
    display: none;
  }

  .slice-lg {
    padding-top: 0rem !important;
  }

  .hero {
    margin: 0px auto;
    width: 100%;
    text-align: center;
  }

  .hero h1 {
    display: block;
    text-align: center;
    font-size: 34px;
  }

  .hero-img {
    background-position: -23px 29px !important;
  }

  .buletpoint strong:before {
    content: none !important;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
  }

  .normal-buletpoint li:after {
    content: none !important;
  }

  .dropdown-content {
    position: relative;
  }

  .dashboard-header .dropdown {
    width: auto !important;
  }

  span.sidebar-toggle {
    margin-top: -40px;
  }

  .mobile-signup a {
    padding: 5px 5px;
    margin-left: 5px;
  }

  .border-right-bottom {
    border-right: none;
  }

  .navbar .dropdown {
    display: block;
    width: 100%;
  }

  .border-signup {
    border: none;
  }

  .footer {
    text-align: center;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    overflow-x: auto;

    width: 100%;

    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    overflow-x: auto;

    width: 100%;

    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  overflow-x: auto;

  width: 100%;

  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: block;
  width: 100%;
  height: calc(1.5em + 1.5rem + 2px);
  padding: 0.75rem 1.25rem;

  transition: all 0.2s ease;

  color: #4a5568;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  border: 0;
  background-color: transparent;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #4a5568;
}

.form-control:focus {
  color: #4a5568;
  border-color: rgba(0, 138, 255, 0.5);
  outline: 0;
  background-color: #fff;
  box-shadow: none, 0 0 20px rgba(0, 138, 255, 0.1);
}

.form-control:-ms-input-placeholder {
  opacity: 1;
  color: #a0aec0;
}

.form-control::-ms-input-placeholder {
  opacity: 1;
  color: #a0aec0;
}

.form-control::placeholder {
  opacity: 1;
  color: #a0aec0;
}

.form-control:disabled,
.form-control[readonly] {
  opacity: 1;
  background-color: #eaecf3;
}

select.form-control:focus::-ms-value {
  color: #4a5568;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;

  width: 100%;
}

.col-form-label {
  font-size: inherit;
  line-height: 1.5;

  margin-bottom: 0;
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
}

.col-form-label-lg {
  font-size: 1rem;
  line-height: 1.5;

  padding-top: calc(1rem + 1px);
  padding-bottom: calc(1rem + 1px);
}

.col-form-label-sm {
  font-size: 0.875rem;
  line-height: 1.5;

  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
}

.form-control-plaintext {
  font-size: 1rem;
  line-height: 1.5;

  display: block;

  width: 100%;
  margin-bottom: 0;
  padding: 0.75rem 0;

  color: #718096;
  border: solid transparent;
  border-width: 1px 0;
  background-color: transparent;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  font-size: 0.875rem;
  line-height: 1.5;

  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1.25rem;

  border-radius: 0.2rem;
}

.form-control-lg {
  font-size: 1rem;
  line-height: 1.5;

  height: calc(1.5em + 2rem + 2px);
  padding: 1rem 1.875rem;

  border-radius: 0.5rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;

  margin-top: 0.25rem;
}

.form-row {
  display: flex;

  margin-right: -5px;
  margin-left: -5px;

  flex-wrap: wrap;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;

  display: block;

  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;

  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #718096;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;

  margin-right: 0.75rem;
  padding-left: 0;

  align-items: center;
}

.form-check-inline .form-check-input {
  position: static;

  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  font-size: 80%;

  display: none;

  width: 100%;
  margin-top: 0.25rem;

  color: #5cc9a7;
}

.valid-tooltip {
  font-size: 0.875rem;
  line-height: 1.7;

  position: absolute;
  z-index: 5;
  top: 100%;

  display: none;

  max-width: 100%;
  margin-top: 0.1rem;
  padding: 0.25rem 0.5rem;

  color: #fff;
  border-radius: 0.375rem;
  background-color: rgba(92, 201, 167, 0.9);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #5cc9a7;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #5cc9a7;
  box-shadow: 0 0 0 0 rgba(92, 201, 167, 0.25);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #5cc9a7;
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #5cc9a7;
  box-shadow: 0 0 0 0 rgba(92, 201, 167, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #5cc9a7;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #5cc9a7;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #5cc9a7;
}

.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #82d6bc;
  background-color: #82d6bc;
}

.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(92, 201, 167, 0.25);
}

.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #5cc9a7;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #5cc9a7;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #5cc9a7;
  box-shadow: 0 0 0 0 rgba(92, 201, 167, 0.25);
}

.invalid-feedback {
  font-size: 80%;

  display: none;

  width: 100%;
  margin-top: 0.25rem;

  color: #f25767;
}

.invalid-tooltip {
  font-size: 0.875rem;
  line-height: 1.7;

  position: absolute;
  z-index: 5;
  top: 100%;

  display: none;

  max-width: 100%;
  margin-top: 0.1rem;
  padding: 0.25rem 0.5rem;

  color: #fff;
  border-radius: 0.375rem;
  background-color: rgba(242, 87, 103, 0.9);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #f25767;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #f25767;
  box-shadow: 0 0 0 0 rgba(242, 87, 103, 0.25);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #f25767;
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #f25767;
  box-shadow: 0 0 0 0 rgba(242, 87, 103, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #f25767;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #f25767;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #f25767;
}

.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f68692;
  background-color: #f68692;
}

.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(242, 87, 103, 0.25);
}

.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #f25767;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #f25767;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #f25767;
  box-shadow: 0 0 0 0 rgba(242, 87, 103, 0.25);
}

.form-inline {
  display: flex;

  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;

    margin-bottom: 0;

    align-items: center;
    justify-content: center;
  }

  .form-inline .form-group {
    display: flex;

    margin-bottom: 0;

    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
  }

  .form-inline .form-control {
    display: inline-block;

    width: auto;

    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    display: flex;

    width: auto;
    padding-left: 0;

    align-items: center;
    justify-content: center;
  }

  .form-inline .form-check-input {
    position: relative;

    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;

    flex-shrink: 0;
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;

  display: inline-block;

  padding: 0.75rem 1.75rem;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: center;
  vertical-align: middle;

  color: #718096;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  background-color: transparent;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  text-decoration: none;

  color: #718096;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 rgba(0, 124, 230, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
  box-shadow: none;
}

.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
  box-shadow: none;
}

.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 rgba(0, 124, 230, 0.25);
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.navbar-btn {
  padding: 4px 20px !important;
}

.btn-primary {
  color: #fff;
  border-color: #3f3966;
  background-color: #3f3966;
  box-shadow: none;
}

.btn-primary:hover {
  color: #fff;
  border-color: #006ecc;
  background-color: #0075d9;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none, 0 0 0 rgba(0, 138, 255, 0.35);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  border-color: #008aff;
  background-color: #008aff;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  border-color: #0068bf;
  background-color: #006ecc;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(0, 138, 255, 0.35);
}

.btn-secondary {
  color: #273444;
  border-color: #eaecf3;
  background-color: #eaecf3;
  box-shadow: none;
}

.btn-secondary:hover {
  color: #273444;
  border-color: #cacfe0;
  background-color: #d2d6e5;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none, 0 0 0 rgba(234, 236, 243, 0.35);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #273444;
  border-color: #eaecf3;
  background-color: #eaecf3;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #273444;
  border-color: #c1c7dc;
  background-color: #cacfe0;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(234, 236, 243, 0.35);
}

.btn-success {
  color: #fff;
  border-color: #5cc9a7;
  background-color: #5cc9a7;
  box-shadow: none;
}

.btn-success:hover {
  color: #fff;
  border-color: #3cb690;
  background-color: #3fbf97;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: none, 0 0 0 rgba(92, 201, 167, 0.35);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  border-color: #5cc9a7;
  background-color: #5cc9a7;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  border-color: #39ac88;
  background-color: #3cb690;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(92, 201, 167, 0.35);
}

.btn-info {
  color: #fff;
  border-color: #50b5ff;
  background-color: #50b5ff;
  box-shadow: none;
}

.btn-info:hover {
  color: #fff;
  border-color: #1d9fff;
  background-color: #2aa5ff;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: none, 0 0 0 rgba(80, 181, 255, 0.35);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  border-color: #50b5ff;
  background-color: #50b5ff;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  border-color: #109aff;
  background-color: #1d9fff;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(80, 181, 255, 0.35);
}

.btn-warning {
  color: #fff;
  border-color: #ffbe3d;
  background-color: #ffbe3d;
  box-shadow: none;
}

.btn-warning:hover {
  color: #fff;
  border-color: #ffad0a;
  background-color: #ffb117;
}

.btn-warning:focus,
.btn-warning.focus {
  box-shadow: none, 0 0 0 rgba(255, 190, 61, 0.35);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #fff;
  border-color: #ffbe3d;
  background-color: #ffbe3d;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  border-color: #fca800;
  background-color: #ffad0a;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(255, 190, 61, 0.35);
}

.btn-danger {
  color: #fff;
  border-color: #f25767;
  background-color: #f25767;
  box-shadow: none;
}

.btn-danger:hover {
  color: #fff;
  border-color: #ee283c;
  background-color: #ef3347;
}

.btn-danger:focus,
.btn-danger.focus {
  box-shadow: none, 0 0 0 rgba(242, 87, 103, 0.35);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  border-color: #f25767;
  background-color: #f25767;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  border-color: #ed1c31;
  background-color: #ee283c;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(242, 87, 103, 0.35);
}

.btn-light {
  color: #495057;
  border-color: #c9ccd7;
  background-color: #fff;
  box-shadow: none;
}

.btn-light:hover {
  color: #495057;
  border-color: #c9ccd7;
  background-color: #fff;
}

.btn-light:focus,
.btn-light.focus {
  box-shadow: none, 0 0 0 rgba(234, 236, 243, 0.35);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #273444;
  border-color: #eaecf3;
  background-color: #eaecf3;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #273444;
  border-color: #c1c7dc;
  background-color: #cacfe0;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(234, 236, 243, 0.35);
}

.btn-dark {
  color: #fff;
  border-color: #171347;
  background-color: #171347;
  box-shadow: none;
}

.btn-dark:hover {
  color: #fff;
  border-color: #0a081f;
  background-color: #0d0b29;
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: none, 0 0 0 rgba(23, 19, 71, 0.35);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  border-color: #171347;
  background-color: #171347;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  border-color: #070615;
  background-color: #0a081f;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(23, 19, 71, 0.35);
}

.btn-neutral {
  color: #273444;
  border-color: #fff;
  background-color: #fff;
  box-shadow: none;
}

.btn-neutral:hover {
  color: #273444;
  border-color: #e6e6e6;
  background-color: #ececec;
}

.btn-neutral:focus,
.btn-neutral.focus {
  box-shadow: none, 0 0 0 rgba(255, 255, 255, 0.35);
}

.btn-neutral.disabled,
.btn-neutral:disabled {
  color: #273444;
  border-color: #fff;
  background-color: #fff;
}

.btn-neutral:not(:disabled):not(.disabled):active,
.btn-neutral:not(:disabled):not(.disabled).active,
.show > .btn-neutral.dropdown-toggle {
  color: #273444;
  border-color: #dfdfdf;
  background-color: #e6e6e6;
}

.btn-neutral:not(:disabled):not(.disabled):active:focus,
.btn-neutral:not(:disabled):not(.disabled).active:focus,
.show > .btn-neutral.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(255, 255, 255, 0.35);
}

.btn-white {
  color: #273444;
  border-color: #fff;
  background-color: #fff;
  box-shadow: none;
}

.btn-white:hover {
  color: #273444;
  border-color: #e6e6e6;
  background-color: #ececec;
}

.btn-white:focus,
.btn-white.focus {
  box-shadow: none, 0 0 0 rgba(255, 255, 255, 0.35);
}

.btn-white.disabled,
.btn-white:disabled {
  color: #273444;
  border-color: #fff;
  background-color: #fff;
}

.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active,
.show > .btn-white.dropdown-toggle {
  color: #273444;
  border-color: #dfdfdf;
  background-color: #e6e6e6;
}

.btn-white:not(:disabled):not(.disabled):active:focus,
.btn-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(255, 255, 255, 0.35);
}

.btn-outline-primary {
  color: #008aff;
  border-color: #008aff;
}

.btn-outline-primary:hover {
  color: #fff;
  border-color: #008aff;
  background-color: #008aff;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: none, 0 0 0 rgba(0, 138, 255, 0.35);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #008aff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  border-color: #008aff;
  background-color: #008aff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(0, 138, 255, 0.35);
}

.btn-outline-secondary {
  color: #eaecf3;
  border-color: #eaecf3;
}

.btn-outline-secondary:hover {
  color: #273444;
  border-color: #eaecf3;
  background-color: #eaecf3;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: none, 0 0 0 rgba(234, 236, 243, 0.35);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #eaecf3;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #273444;
  border-color: #eaecf3;
  background-color: #eaecf3;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(234, 236, 243, 0.35);
}

.btn-outline-success {
  color: #5cc9a7;
  border-color: #5cc9a7;
}

.btn-outline-success:hover {
  color: #fff;
  border-color: #5cc9a7;
  background-color: #5cc9a7;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: none, 0 0 0 rgba(92, 201, 167, 0.35);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #5cc9a7;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  border-color: #5cc9a7;
  background-color: #5cc9a7;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(92, 201, 167, 0.35);
}

.btn-outline-info {
  color: #50b5ff;
  border-color: #50b5ff;
}

.btn-outline-info:hover {
  color: #fff;
  border-color: #50b5ff;
  background-color: #50b5ff;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: none, 0 0 0 rgba(80, 181, 255, 0.35);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #50b5ff;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  border-color: #50b5ff;
  background-color: #50b5ff;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(80, 181, 255, 0.35);
}

.btn-outline-warning {
  color: #ffbe3d;
  border-color: #ffbe3d;
}

.btn-outline-warning:hover {
  color: #fff;
  border-color: #ffbe3d;
  background-color: #ffbe3d;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: none, 0 0 0 rgba(255, 190, 61, 0.35);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffbe3d;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  border-color: #ffbe3d;
  background-color: #ffbe3d;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(255, 190, 61, 0.35);
}

.btn-outline-danger {
  color: #f25767;
  border-color: #f25767;
}

.btn-outline-danger:hover {
  color: #fff;
  border-color: #f25767;
  background-color: #f25767;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: none, 0 0 0 rgba(242, 87, 103, 0.35);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #f25767;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  border-color: #f25767;
  background-color: #f25767;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(242, 87, 103, 0.35);
}

.btn-outline-light {
  color: #eaecf3;
  border-color: #eaecf3;
}

.btn-outline-light:hover {
  color: #273444;
  border-color: #eaecf3;
  background-color: #eaecf3;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: none, 0 0 0 rgba(234, 236, 243, 0.35);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #eaecf3;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #273444;
  border-color: #eaecf3;
  background-color: #eaecf3;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(234, 236, 243, 0.35);
}

.btn-outline-dark {
  color: #171347;
  border-color: #171347;
}

.btn-outline-dark:hover {
  color: #fff;
  border-color: #171347;
  background-color: #171347;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: none, 0 0 0 rgba(23, 19, 71, 0.35);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #171347;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  border-color: #171347;
  background-color: #171347;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(23, 19, 71, 0.35);
}

.btn-outline-neutral {
  color: #fff;
  border-color: #fff;
}

.btn-outline-neutral:hover {
  color: #273444;
  border-color: #fff;
  background-color: #fff;
}

.btn-outline-neutral:focus,
.btn-outline-neutral.focus {
  box-shadow: none, 0 0 0 rgba(255, 255, 255, 0.35);
}

.btn-outline-neutral.disabled,
.btn-outline-neutral:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-neutral:not(:disabled):not(.disabled):active,
.btn-outline-neutral:not(:disabled):not(.disabled).active,
.show > .btn-outline-neutral.dropdown-toggle {
  color: #273444;
  border-color: #fff;
  background-color: #fff;
}

.btn-outline-neutral:not(:disabled):not(.disabled):active:focus,
.btn-outline-neutral:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-neutral.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(255, 255, 255, 0.35);
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}

.btn-outline-white:hover {
  color: #273444;
  border-color: #fff;
  background-color: #fff;
}

.btn-outline-white:focus,
.btn-outline-white.focus {
  box-shadow: none, 0 0 0 rgba(255, 255, 255, 0.35);
}

.btn-outline-white.disabled,
.btn-outline-white:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-white:not(:disabled):not(.disabled):active,
.btn-outline-white:not(:disabled):not(.disabled).active,
.show > .btn-outline-white.dropdown-toggle {
  color: #273444;
  border-color: #fff;
  background-color: #fff;
}

.btn-outline-white:not(:disabled):not(.disabled):active:focus,
.btn-outline-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(255, 255, 255, 0.35);
}

.btn-link {
  font-weight: 400;

  text-decoration: none;

  color: #008aff;
}

.btn-link:hover {
  text-decoration: none;

  color: #0061b3;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: none;

  box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
  pointer-events: none;

  color: #718096;
}

.btn-lg,
.btn-group-lg > .btn {
  font-size: 1rem;
  line-height: 1.5;

  padding: 1rem 1.875rem;

  border-radius: 0.5rem;
}

.btn-sm,
.btn-group-sm > .btn {
  font-size: 0.875rem;
  line-height: 1.5;

  padding: 0.5rem 1.25rem;

  border-radius: 0.375rem;
}

.btn-radius-20 {
  color: #fff;
  border-radius: 20px;
}

.btn-block {
  display: block;

  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.2s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;

  overflow: hidden;

  height: 0;

  transition: height 0.2s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  padding: 0px;
  transform: rotate(0deg);
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  font-size: 0.875rem;

  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 0;

  display: none;
  float: left;

  min-width: 12rem;
  margin: 0.125rem 0 0;
  padding: 0.35rem 0;

  list-style: none;

  text-align: left;

  color: #718096;
  border: 1px solid #eaecf3;
  border-radius: 0.5rem;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05);
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;

  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  font-family: "Font Awesome 5 Free";
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  font-variant: normal;

  display: inline-block;

  margin-left: 0.5rem;

  content: "\f106";

  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;

  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  font-family: "Font Awesome 5 Free";
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  font-variant: normal;

  display: inline-block;

  margin-left: 0.5rem;

  content: "\f105";

  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;

  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  font-family: "Font Awesome 5 Free";
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  font-variant: normal;

  display: inline-block;

  margin-left: 0.5rem;

  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 700;

  display: inline-block;

  margin-right: 0.5rem;

  content: "\f104";
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  overflow: hidden;

  height: 0;
  margin: 0.5rem 0;

  border-top: 1px solid #eaecf3;
}

.dropdown-item {
  font-weight: 400;
  display: block;
  clear: both;
  width: 100%;
  padding: 0.25rem 1rem;
  text-align: inherit;
  white-space: nowrap;
  color: #4a5568;
  border: 0;
  background-color: transparent;
}

.dropdown-item:hover,
.dropdown-item:focus {
  text-decoration: none;

  color: #008aff;
  background-color: transparent;
}

.dropdown-item.active,
.dropdown-item:active {
  text-decoration: none;

  color: #008aff;
  background-color: transparent;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  pointer-events: none;

  color: #718096;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  font-size: 0.875rem;

  display: block;

  margin-bottom: 0;
  padding: 0.35rem 1rem;

  white-space: nowrap;

  color: #718096;
}

.dropdown-item-text {
  display: block;

  padding: 0.25rem 1rem;

  color: #4a5568;
}

.btn-group,
.btn-group-vertical {
  position: relative;

  display: inline-flex;

  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;

  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;

  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.40625rem;
  padding-left: 1.40625rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: none;
}

.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;

  clip: rect(0, 0, 0, 0);

  pointer-events: none;
}

.input-group {
  position: relative;

  display: flex;

  width: 100%;

  flex-wrap: wrap;
  align-items: stretch;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;

  min-width: 0;
  margin-bottom: 0;

  flex: 1 1;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;

  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;

  display: flex;

  margin-bottom: 0;
  padding: 0.75rem 1.25rem;

  text-align: center;
  white-space: nowrap;

  color: #a0aec0;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  background-color: #fafbfe;

  align-items: center;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 2rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  font-size: 1rem;
  line-height: 1.5;

  padding: 1rem 1.875rem;

  border-radius: 0.5rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  font-size: 0.875rem;
  line-height: 1.5;

  padding: 0.5rem 1.25rem;

  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2.25rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;

  display: block;

  min-height: 1.7rem;
  padding-left: 1.75rem;
}

.custom-control-inline {
  display: inline-flex;

  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  left: 0;

  width: 1rem;
  height: 1.35rem;

  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007ce6;
  background-color: #007ce6;
  box-shadow: none;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none, 0 0 20px rgba(0, 138, 255, 0.1);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgba(0, 138, 255, 0.5);
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  border-color: #99d0ff;
  background-color: #99d0ff;
  box-shadow: none;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #718096;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #eaecf3;
}

.custom-control-label {
  position: relative;

  margin-bottom: 0;

  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.35rem;
  left: -1.75rem;

  display: block;

  width: 1rem;
  height: 1rem;

  content: "";
  pointer-events: none;

  border: #eaecf3 solid 0;
  background-color: #e2e8f0;
  box-shadow: none;
}

.custom-control-label::after {
  position: absolute;
  top: 0.35rem;
  left: -1.75rem;

  display: block;

  width: 1rem;
  height: 1rem;

  content: "";

  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.375rem;
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 138, 255, 0.5);
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(0, 138, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 138, 255, 0.5);
}

.custom-switch {
  padding-left: 3.75rem;
}

.custom-switch .custom-control-label::before {
  left: -3.75rem;

  width: 3rem;

  pointer-events: all;

  border-radius: 0.5625rem;
}

.custom-switch .custom-control-label::after {
  top: 0.35rem;
  left: -3.75rem;

  width: 1.125rem;
  height: 1.125rem;

  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  border-radius: 0.5625rem;
  background-color: #eaecf3;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(2rem);

  background-color: #e2e8f0;
}

.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 138, 255, 0.5);
}

.custom-select {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;

  display: inline-block;

  width: 100%;
  height: calc(1.5em + 1.5rem + 2px);
  padding: 0.75rem 2.25rem 0.75rem 1.25rem;

  vertical-align: middle;

  color: #4a5568;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: rgba(0, 138, 255, 0.5);
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(31, 45, 61, 0.075),
    0 0 20px rgba(0, 138, 255, 0.1);
}

.custom-select:focus::-ms-value {
  color: #4a5568;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1.25rem;

  background-image: none;
}

.custom-select:disabled {
  color: #718096;
  background-color: #eaecf3;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #4a5568;
}

.custom-select-sm {
  font-size: 0.875rem;

  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
}

.custom-select-lg {
  font-size: 1rem;

  height: calc(1.5em + 2rem + 2px);
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.875rem;
}

.custom-file {
  position: relative;

  display: inline-block;

  width: 100%;
  height: calc(1.5em + 1.5rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;

  width: 100%;
  height: calc(1.5em + 1.5rem + 2px);
  margin: 0;

  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: rgba(0, 138, 255, 0.5);
  box-shadow: 0 0 20px rgba(0, 138, 255, 0.1);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #eaecf3;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  font-weight: 400;
  line-height: 1.5;

  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;

  height: calc(1.5em + 1.5rem + 2px);
  padding: 0.75rem 1.25rem;

  color: #4a5568;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  background-color: #fff;
  box-shadow: none;
}

.custom-file-label::after {
  line-height: 1.5;

  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;

  display: block;

  height: calc(1.5em + 1.5rem);
  padding: 0.75rem 1.25rem;

  content: "Browse";

  color: #4a5568;
  border-left: inherit;
  border-radius: 0 0.375rem 0.375rem 0;
  background-color: #fafbfe;
}

.custom-range {
  width: 100%;
  height: 1rem;
  padding: 0;

  background-color: transparent;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 20px rgba(0, 138, 255, 0.1);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 20px rgba(0, 138, 255, 0.1);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 20px rgba(0, 138, 255, 0.1);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;

  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  border: 0;
  border-radius: 1rem;
  background-color: #007ce6;
  box-shadow: 0 0.1rem 0.25rem rgba(31, 45, 61, 0.1);

  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #99d0ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;

  cursor: pointer;

  color: transparent;
  border-color: transparent;
  border-radius: 1rem;
  background-color: #e2e8f0;
  box-shadow: inset 0 0.25rem 0.25rem rgba(31, 45, 61, 0.1);
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;

  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  border: 0;
  border-radius: 1rem;
  background-color: #007ce6;
  box-shadow: 0 0.1rem 0.25rem rgba(31, 45, 61, 0.1);

  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #99d0ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;

  cursor: pointer;

  color: transparent;
  border-color: transparent;
  border-radius: 1rem;
  background-color: #e2e8f0;
  box-shadow: inset 0 0.25rem 0.25rem rgba(31, 45, 61, 0.1);
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;

  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  border: 0;
  border-radius: 1rem;
  background-color: #007ce6;
  box-shadow: 0 0.1rem 0.25rem rgba(31, 45, 61, 0.1);

  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #99d0ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;

  cursor: pointer;

  color: transparent;
  border-width: 0.5rem;
  border-color: transparent;
  background-color: transparent;
  box-shadow: inset 0 0.25rem 0.25rem rgba(31, 45, 61, 0.1);
}

.custom-range::-ms-fill-lower {
  border-radius: 1rem;
  background-color: #e2e8f0;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;

  border-radius: 1rem;
  background-color: #e2e8f0;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #a0aec0;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #a0aec0;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #a0aec0;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;

  margin-bottom: 0;
  padding-left: 0;

  list-style: none;

  flex-wrap: wrap;
}

.nav-link {
  display: block;

  padding: 0.25rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  cursor: default;
  pointer-events: none;

  color: #718096;
}

.nav-tabs {
  padding: 10px;
  border-bottom: 1px solid #e2e8f0;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent transparent transparent;
}

.nav-tabs .nav-link.disabled {
  color: #718096;
  border-color: transparent;
  background-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #718096;
  border-color: transparent transparent #008aff;
  background-color: transparent;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;

  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.375rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007ce6;
}

.nav-fill .nav-item {
  text-align: center;

  flex: 1 1 auto;
}

.nav-justified .nav-item {
  text-align: center;

  flex-basis: 0;
  flex-grow: 1;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: fixed;
  
  display: flex;

  padding: 0.75rem 1rem;

  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;

  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  font-size: 1.25rem;
  line-height: inherit;

  display: inline-block;

  margin-right: 1rem;
  padding-top: 0.0375rem;
  padding-bottom: 0.0375rem;

  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;

  margin-bottom: 0;
  padding-left: 0;

  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;

  float: none;
}

.navbar-text {
  display: inline-block;

  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  font-size: 1.25rem;
  line-height: 1;

  padding: 0.25rem 0.75rem;

  border: 1px solid transparent;
  border-radius: 0.375rem;
  background-color: transparent;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;

  width: 1.5em;
  height: 1.5em;

  content: "";
  vertical-align: middle;

  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;

    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;

    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }

  .mb-space {
    padding-top: 40px;
    padding-bottom: 50px;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;

    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;

    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;

  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 138, 255, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 138, 255, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: #675f80;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: var(--lightvoilet);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(31, 45, 61, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--lightvoilet);
}

.navbar-light .navbar-toggler {
  color: var(--voilet);
  border-color: transparent;
}

.navbar-light .navbar-text {
  color: rgba(31, 45, 61, 0.6);
}

.navbar-light .navbar-text a {
  color: rgba(0, 138, 255, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 138, 255, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.85);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.85);
  border-color: transparent;
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.85);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border-radius: 0.75rem;
  background-color: #fff;
  background-clip: border-box;
  box-shadow: 5px 5px 36px 0 rgba(67, 94, 224, 0.17);
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.card-body {
  min-height: 1px;

  color: #718096;

  flex: 1 1 auto;
}

.card-title {
  margin-bottom: 1.25rem;
}

.card-subtitle {
  margin-top: -0.625rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.75rem;
}

.card-header {
  margin-bottom: 0;
  padding: 1.25rem 1.75rem;

  border-bottom: 1px solid #eaecf3;
  background-color: transparent;
}

.card-header:first-child {
  border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 1.25rem 1.75rem;

  border-top: 1px solid #eaecf3;
  background-color: transparent;
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.75rem - 1px) calc(0.75rem - 1px);
}

.card-header-tabs {
  margin-right: -0.875rem;
  margin-bottom: -1.25rem;
  margin-left: -0.875rem;

  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.875rem;
  margin-left: -0.875rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;

  flex-shrink: 0;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.75rem - 1px);
  border-bottom-left-radius: calc(0.75rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;

    margin-right: -15px;
    margin-left: -15px;

    flex-flow: row wrap;
  }

  .card-deck .card {
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;

    flex: 1 0;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;

    flex-flow: row wrap;
  }

  .card-group > .card {
    margin-bottom: 0;

    flex: 1 0;
  }

  .card-group > .card + .card {
    margin-left: 0;

    border-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 1.25rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;

    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  margin-bottom: -1px;

  border-radius: 0;
}

.breadcrumb {
  display: flex;

  margin-bottom: 0;
  padding: 0.75rem 1rem;

  list-style: none;

  border-radius: 0.375rem;
  background-color: transparent;

  flex-wrap: wrap;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;

  padding-right: 0.5rem;

  content: "/";

  color: #cbd5e0;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #718096;
}

.pagination {
  display: flex;

  padding-left: 0;

  list-style: none;

  border-radius: 0.375rem;
}

.page-link {
  line-height: 1.25;

  position: relative;

  display: block;

  margin-left: -1px;
  padding: 0.5rem 0.75rem;

  color: #718096;
  border: 1px solid #e2e8f0;
  background-color: #fff;
}

.page-link:hover {
  z-index: 2;

  text-decoration: none;

  color: #718096;
  border-color: #e2e8f0;
  background-color: #e2e8f0;
}

.page-link:focus {
  z-index: 3;

  outline: 0;
  box-shadow: 0 0 0 rgba(0, 124, 230, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;

  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.page-item.active .page-link {
  z-index: 3;

  color: #fff;
  border-color: #007ce6;
  background-color: #007ce6;
}

.page-item.disabled .page-link {
  cursor: auto;
  pointer-events: none;

  color: #718096;
  border-color: #e2e8f0;
  background-color: #fff;
}

.pagination-lg .page-link {
  font-size: 1.25rem;
  line-height: 1.5;

  padding: 0.75rem 1.5rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.pagination-sm .page-link {
  font-size: 0.875rem;
  line-height: 1.5;

  padding: 0.25rem 0.5rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  font-size: 80%;
  font-weight: 700;
  line-height: 1;

  display: inline-block;

  padding: 0.35rem 0.5rem;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;

  border-radius: 0.375rem;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.875em;
  padding-left: 0.875em;

  border-radius: 50rem;
}

.badge-primary {
  color: #fff;
  background-color: #008aff;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #006ecc;
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 138, 255, 0.5);
}

.badge-secondary {
  color: #273444;
  background-color: #eaecf3;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #273444;
  background-color: #cacfe0;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(234, 236, 243, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #5cc9a7;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #3cb690;
}

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(92, 201, 167, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #50b5ff;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #1d9fff;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(80, 181, 255, 0.5);
}

.badge-warning {
  color: #fff;
  background-color: #ffbe3d;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #fff;
  background-color: #ffad0a;
}

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(255, 190, 61, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #f25767;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #ee283c;
}

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(242, 87, 103, 0.5);
}

.badge-light {
  color: #273444;
  background-color: #eaecf3;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #273444;
  background-color: #cacfe0;
}

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(234, 236, 243, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #171347;
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #0a081f;
}

a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(23, 19, 71, 0.5);
}

.badge-neutral {
  color: #273444;
  background-color: #fff;
}

a.badge-neutral:hover,
a.badge-neutral:focus {
  color: #273444;
  background-color: #e6e6e6;
}

a.badge-neutral:focus,
a.badge-neutral.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.badge-white {
  color: #273444;
  background-color: #fff;
}

a.badge-white:hover,
a.badge-white:focus {
  color: #273444;
  background-color: #e6e6e6;
}

a.badge-white:focus,
a.badge-white.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.jumbotron {
  margin-bottom: 2rem;
  padding: 2rem 1rem;

  border-radius: 0.5rem;
  background-color: #fff;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;

  border-radius: 0;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 0.5rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  font-size: 0.75rem;

  display: flex;
  overflow: hidden;

  height: 0.5rem;

  border-radius: 50rem;
  background-color: #eaecf3;
  box-shadow: inset 0 0.1rem 0.1rem rgba(31, 45, 61, 0.1);
}

.progress-bar {
  display: flex;
  overflow: hidden;
  flex-direction: column;

  transition: width 0.6s ease;
  text-align: center;
  white-space: nowrap;

  color: #fff;
  background-color: #008aff;

  justify-content: center;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 0.5rem 0.5rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;

  align-items: flex-start;
}

.media-body {
  flex: 1 1;
}

.list-group {
  display: flex;
  flex-direction: column;

  margin-bottom: 0;
  padding-left: 0;
}

.list-group-item-action {
  width: 100%;

  text-align: inherit;

  color: #4a5568;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;

  text-decoration: none;

  color: #4a5568;
  background-color: #fafbfe;
}

.list-group-item-action:active {
  color: #4a5568;
  background-color: #eaecf3;
}

.list-group-item {
  position: relative;

  display: block;

  padding: 1rem 1.5rem;

  border: 1px solid #eaecf3;
  background-color: #fff;
}

.list-group-item:first-child {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.list-group-item:last-child {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.list-group-item.disabled,
.list-group-item:disabled {
  pointer-events: none;

  color: #718096;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;

  color: #4a5568;
  border-color: #eaecf3;
  background-color: #fafbfe;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;

  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal .list-group-item:first-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: 0.375rem;
}

.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: 0.375rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal .list-group-item + .list-group-item.active {
  margin-left: -1px;

  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0.375rem;
  }

  .list-group-horizontal-sm .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm .list-group-item + .list-group-item.active {
    margin-left: -1px;

    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0.375rem;
  }

  .list-group-horizontal-md .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md .list-group-item + .list-group-item.active {
    margin-left: -1px;

    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0.375rem;
  }

  .list-group-horizontal-lg .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg .list-group-item + .list-group-item.active {
    margin-left: -1px;

    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0.375rem;
  }

  .list-group-horizontal-xl .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl .list-group-item + .list-group-item.active {
    margin-left: -1px;

    border-left-width: 1px;
  }
}

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:first-child {
  border-top-width: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #0f5da2;
  background-color: #b8deff;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #0f5da2;
  background-color: #9fd2ff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  border-color: #0f5da2;
  background-color: #0f5da2;
}

.list-group-item-secondary {
  color: #89909c;
  background-color: #f9fafc;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #89909c;
  background-color: #e8ecf4;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  border-color: #89909c;
  background-color: #89909c;
}

.list-group-item-success {
  color: #3f7e74;
  background-color: #d1f0e6;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #3f7e74;
  background-color: #beeadc;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  border-color: #3f7e74;
  background-color: #3f7e74;
}

.list-group-item-info {
  color: #3874a2;
  background-color: #ceeaff;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #3874a2;
  background-color: #b5dfff;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  border-color: #3874a2;
  background-color: #3874a2;
}

.list-group-item-warning {
  color: #93783d;
  background-color: #ffedc9;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #93783d;
  background-color: #ffe5b0;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  border-color: #93783d;
  background-color: #93783d;
}

.list-group-item-danger {
  color: #8d4353;
  background-color: #fbd0d4;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #8d4353;
  background-color: #f9b9bf;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  border-color: #8d4353;
  background-color: #8d4353;
}

.list-group-item-light {
  color: #89909c;
  background-color: #f9fafc;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #89909c;
  background-color: #e8ecf4;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  border-color: #89909c;
  background-color: #89909c;
}

.list-group-item-dark {
  color: #1b1f42;
  background-color: #bebdcb;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1f42;
  background-color: #b0afc0;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  border-color: #1b1f42;
  background-color: #1b1f42;
}

.list-group-item-neutral {
  color: #939aa2;
  background-color: white;
}

.list-group-item-neutral.list-group-item-action:hover,
.list-group-item-neutral.list-group-item-action:focus {
  color: #939aa2;
  background-color: #f2f2f2;
}

.list-group-item-neutral.list-group-item-action.active {
  color: #fff;
  border-color: #939aa2;
  background-color: #939aa2;
}

.list-group-item-white {
  color: #939aa2;
  background-color: white;
}

.list-group-item-white.list-group-item-action:hover,
.list-group-item-white.list-group-item-action:focus {
  color: #939aa2;
  background-color: #f2f2f2;
}

.list-group-item-white.list-group-item-action.active {
  color: #fff;
  border-color: #939aa2;
  background-color: #939aa2;
}

.close {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1;

  float: right;

  opacity: 0.5;
  color: rgba(255, 255, 255, 0.6);
  text-shadow: none;
}

.close:hover {
  text-decoration: none;

  color: rgba(255, 255, 255, 0.6);
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;

  border: 0;
  background-color: transparent;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  font-size: 0.875rem;

  overflow: hidden;

  max-width: 350px;

  opacity: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  box-shadow: 0 0.25rem 0.75rem rgba(31, 45, 61, 0.1);

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;

  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;

  padding: 0.25rem 0.75rem;

  color: #718096;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;

  align-items: center;
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;

  display: none;
  overflow: hidden;

  width: 100%;
  height: 100%;

  outline: 0;
}

.modal-dialog {
  position: relative;

  width: auto;
  margin: 0.5rem;

  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;

  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  overflow: hidden;

  max-height: calc(100vh - 1rem);
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;

  min-height: calc(100% - 1rem);

  align-items: center;
}

.modal-dialog-centered::before {
  display: block;

  height: calc(100vh - 1rem);

  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;

  height: 100%;

  justify-content: center;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;

  pointer-events: auto;

  border: 1px solid rgba(31, 45, 61, 0.2);
  border-radius: 0.75rem;
  outline: 0;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: 0 0.25rem 0.5rem rgba(31, 45, 61, 0.3);
}

.modal-backdrop {
  position: fixed;
  z-index: 1040;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background-color: #1f2d3d;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.4;
}

.modal-header {
  display: flex;

  padding: 1.25rem;

  border-bottom: 1px solid #eaecf3;
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);

  align-items: flex-start;
  justify-content: space-between;
}

.modal-header .close {
  margin: -1rem -1rem -1rem auto;
  padding: 1.25rem;
}

.modal-title {
  line-height: 1.7;

  margin-bottom: 0;
}

.modal-body {
  position: relative;

  padding: 1.5rem;

  flex: 1 1 auto;
}

.modal-footer {
  display: flex;

  padding: 1.25rem;

  border-top: 1px solid #eaecf3;
  border-bottom-right-radius: calc(0.75rem - 1px);
  border-bottom-left-radius: calc(0.75rem - 1px);

  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;

  overflow: scroll;

  width: 50px;
  height: 50px;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(31, 45, 61, 0.3);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.7;

  position: absolute;
  z-index: 1070;

  display: block;

  margin: 0;

  text-align: left;
  text-align: start;
  white-space: normal;
  text-decoration: none;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  word-wrap: break-word;
  word-break: normal;

  opacity: 0;
  text-shadow: none;

  line-break: auto;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;

  display: block;

  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;

  content: "";

  border-style: solid;
  border-color: transparent;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;

  border-width: 0.4rem 0.4rem 0;
  border-top-color: #1f2d3d;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;

  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;

  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #1f2d3d;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;

  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #1f2d3d;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;

  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;

  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #1f2d3d;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;

  text-align: center;

  color: #fff;
  border-radius: 0.375rem;
  background-color: #1f2d3d;
}

.row-title {
  font-weight: 600;
}

.popover {
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.7;

  position: absolute;
  z-index: 1060;
  top: 0;
  left: 0;

  display: block;

  max-width: 276px;

  text-align: left;
  text-align: start;
  white-space: normal;
  text-decoration: none;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  word-wrap: break-word;
  word-break: normal;

  border: 1px solid rgba(31, 45, 61, 0.05);
  border-radius: 0.5rem;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: 0 0.5rem 2rem 0 rgba(31, 45, 61, 0.2);
  text-shadow: none;

  line-break: auto;
}

.popover .arrow {
  position: absolute;

  display: block;

  width: 1rem;
  height: 0.5rem;
  margin: 0 0.5rem;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;

  display: block;

  content: "";

  border-style: solid;
  border-color: transparent;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;

  border-width: 0.5rem 0.5rem 0;
  border-top-color: transparent;
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;

  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);

  width: 0.5rem;
  height: 1rem;
  margin: 0.5rem 0;
}

.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;

  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: transparent;
}

.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;

  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;

  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: transparent;
}

.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;

  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;

  display: block;

  width: 1rem;
  margin-left: -0.5rem;

  content: "";

  border-bottom: 1px solid #fff;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);

  width: 0.5rem;
  height: 1rem;
  margin: 0.5rem 0;
}

.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;

  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: transparent;
}

.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;

  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  font-size: 1rem;

  margin-bottom: 0;
  padding: 0.75rem 0.75rem;

  color: #152c5b;
  border-bottom: 1px solid #f2f2f2;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
  background-color: #fff;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.75rem 0.75rem;

  color: #718096;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;

  overflow: hidden;

  width: 100%;
}

.carousel-inner::after {
  display: block;
  clear: both;

  content: "";
}

.carousel-item {
  position: relative;

  display: none;
  float: left;

  width: 100%;
  margin-right: -100%;

  transition: transform 0.6s ease-in-out;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  transition-property: opacity;
  transform: none;

  opacity: 0;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;

  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;

  transition: opacity 0s 0.6s;

  opacity: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;

  display: flex;

  width: 15%;

  transition: opacity 0.15s ease;
  text-align: center;

  opacity: 0.5;
  color: #fff;

  align-items: center;
  justify-content: center;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  text-decoration: none;

  opacity: 0.9;
  color: #fff;
  outline: 0;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;

  width: 20px;
  height: 20px;

  background: no-repeat 50% / 100% 100%;
}

.carousel-indicators {
  position: absolute;
  z-index: 15;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;

  margin-right: 15%;
  margin-left: 15%;
  padding-left: 0;

  list-style: none;

  justify-content: center;
}

.carousel-indicators li {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;

  cursor: pointer;
  transition: opacity 0.6s ease;
  text-indent: -999px;

  opacity: 0.5;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  background-color: #fff;
  background-clip: padding-box;

  flex: 0 1 auto;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  z-index: 10;
  right: 15%;
  bottom: 20px;
  left: 15%;

  padding-top: 20px;
  padding-bottom: 20px;

  text-align: center;

  color: #fff;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;

  width: 2rem;
  height: 2rem;

  animation: spinner-border 0.75s linear infinite;
  vertical-align: text-bottom;

  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;

  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;

  width: 2rem;
  height: 2rem;

  animation: spinner-grow 0.75s linear infinite;
  vertical-align: text-bottom;

  opacity: 0;
  border-radius: 50%;
  background-color: currentColor;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.react-slideshow-container .default-nav {
  border-radius: 0px;
}

.each-slide > div {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-primary {
  background-color: #4ec9b0 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #006ecc !important;
}

.bg-secondary {
  background-color: #eaecf3 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #cacfe0 !important;
}

.bg-success {
  background-color: #0075d9 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #3cb690 !important;
}

.bg-info {
  background-color: #50b5ff !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #1d9fff !important;
}

.bg-warning {
  background-color: #ffbe3d !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffad0a !important;
}

.bg-danger {
  background-color: #f25767 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ee283c !important;
}

.bg-light {
  background-color: #eaecf3 !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #cacfe0 !important;
}

.bg-dark {
  background-color: #fafbfe !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #151617 !important;
}

.bg-neutral {
  background-color: #fff !important;
}

a.bg-neutral:hover,
a.bg-neutral:focus,
button.bg-neutral:hover,
button.bg-neutral:focus {
  background-color: #e6e6e6 !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover,
a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e2e8f0 !important;
}

.border-top {
  border-top: 1px solid #e2e8f0 !important;
}

.border-right {
  border-right: 1px solid #e2e8f0 !important;
}

.border-bottom {
  border-bottom: 1px solid #e2e8f0 !important;
}

.border-left {
  border-left: 1px solid #e2e8f0 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #008aff !important;
}

.border-secondary {
  border-color: #eaecf3 !important;
}

.border-success {
  border-color: #5cc9a7 !important;
}

.border-info {
  border-color: #50b5ff !important;
}

.border-warning {
  border-color: #ffbe3d !important;
}

.border-danger {
  border-color: #f25767 !important;
}

.border-light {
  border-color: #eaecf3 !important;
}

.border-dark {
  border-color: #171347 !important;
}

.border-neutral {
  border-color: #fff !important;
}

.border-white {
  border-color: #fff !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.375rem !important;
}

.rounded-top {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.rounded-right {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-left {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;

  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;

  display: block;
  overflow: hidden;

  width: 100%;
  padding: 0;
}

.embed-responsive::before {
  display: block;

  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;

  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  z-index: 1030;
  top: 0;
  right: 0;
  left: 0;
}

.fixed-bottom {
  position: fixed;
  z-index: 1030;
  right: 0;
  bottom: 0;
  left: 0;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1020;
    top: 0;
  }
}

.sr-only {
  position: absolute;

  overflow: hidden;
  clip: rect(0, 0, 0, 0);

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  white-space: nowrap;

  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;

  overflow: visible;
  clip: auto;

  width: auto;
  height: auto;

  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(31, 45, 61, 0.08) !important;
}

.shadow {
  box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-110 {
  width: 110% !important;
}

.w-120 {
  width: 120% !important;
}

.w-130 {
  width: 130% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-10 {
  height: 10% !important;
}

.h-15 {
  height: 15% !important;
}

.h-80 {
  height: 80% !important;
}

.h-90 {
  height: 90% !important;
}

.h-110 {
  height: 110% !important;
}

.h-120 {
  height: 120% !important;
}

.h-130 {
  height: 130% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: "";
  pointer-events: auto;

  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-6 {
  margin: 4.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4.5rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.mt-7,
.my-7 {
  margin-top: 6rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 6rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 6rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 6rem !important;
}

.m-8 {
  margin: 7.5rem !important;
}

.mt-8,
.my-8 {
  margin-top: 7.5rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 7.5rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 7.5rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 7.5rem !important;
}

.m-9 {
  margin: 9rem !important;
}

.mt-9,
.my-9 {
  margin-top: 9rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 9rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 9rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 9rem !important;
}

.m-10 {
  margin: 10.5rem !important;
}

.mt-10,
.my-10 {
  margin-top: 10.5rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 10.5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 10.5rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 10.5rem !important;
}

.m-17 {
  margin: 21rem !important;
}

.mt-17,
.my-17 {
  margin-top: 21rem !important;
}

.mr-17,
.mx-17 {
  margin-right: 21rem !important;
}

.mb-17,
.my-17 {
  margin-bottom: 21rem !important;
}

.ml-17,
.mx-17 {
  margin-left: 21rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-6 {
  padding: 4.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4.5rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.pt-7,
.py-7 {
  padding-top: 6rem !important;
}

.pr-7,
.px-7 {
  padding-right: 6rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 6rem !important;
}

.pl-7,
.px-7 {
  padding-left: 6rem !important;
}

.p-8 {
  padding: 7.5rem !important;
}

.pt-8,
.py-8 {
  padding-top: 7.5rem !important;
}

.pr-8,
.px-8 {
  padding-right: 7.5rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 7.5rem !important;
}

.pl-8,
.px-8 {
  padding-left: 7.5rem !important;
}

.p-9 {
  padding: 9rem !important;
}

.pt-9,
.py-9 {
  padding-top: 9rem !important;
}

.pr-9,
.px-9 {
  padding-right: 9rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 9rem !important;
}

.pl-9,
.px-9 {
  padding-left: 9rem !important;
}

.p-10 {
  padding: 10.5rem !important;
}

.pt-10,
.py-10 {
  padding-top: 10.5rem !important;
}

.pr-10,
.px-10 {
  padding-right: 10.5rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 10.5rem !important;
}

.pl-10,
.px-10 {
  padding-left: 10.5rem !important;
}

.p-17 {
  padding: 21rem !important;
}

.pt-17,
.py-17 {
  padding-top: 21rem !important;
}

.pr-17,
.px-17 {
  padding-right: 21rem !important;
}

.pb-17,
.py-17 {
  padding-bottom: 21rem !important;
}

.pl-17,
.px-17 {
  padding-left: 21rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-n6 {
  margin: -4.5rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -4.5rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -4.5rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -4.5rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -4.5rem !important;
}

.m-n7 {
  margin: -6rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -6rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -6rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -6rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -6rem !important;
}

.m-n8 {
  margin: -7.5rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -7.5rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -7.5rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -7.5rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -7.5rem !important;
}

.m-n9 {
  margin: -9rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -9rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -9rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -9rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -9rem !important;
}

.m-n10 {
  margin: -10.5rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -10.5rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -10.5rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -10.5rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -10.5rem !important;
}

.m-n17 {
  margin: -21rem !important;
}

.mt-n17,
.my-n17 {
  margin-top: -21rem !important;
}

.mr-n17,
.mx-n17 {
  margin-right: -21rem !important;
}

.mb-n17,
.my-n17 {
  margin-bottom: -21rem !important;
}

.ml-n17,
.mx-n17 {
  margin-left: -21rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .m-sm-6 {
    margin: 4.5rem !important;
  }

  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4.5rem !important;
  }

  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4.5rem !important;
  }

  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4.5rem !important;
  }

  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4.5rem !important;
  }

  .m-sm-7 {
    margin: 6rem !important;
  }

  .mt-sm-7,
  .my-sm-7 {
    margin-top: 6rem !important;
  }

  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 6rem !important;
  }

  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 6rem !important;
  }

  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 6rem !important;
  }

  .m-sm-8 {
    margin: 7.5rem !important;
  }

  .mt-sm-8,
  .my-sm-8 {
    margin-top: 7.5rem !important;
  }

  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 7.5rem !important;
  }

  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 7.5rem !important;
  }

  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 7.5rem !important;
  }

  .m-sm-9 {
    margin: 9rem !important;
  }

  .mt-sm-9,
  .my-sm-9 {
    margin-top: 9rem !important;
  }

  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 9rem !important;
  }

  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 9rem !important;
  }

  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 9rem !important;
  }

  .m-sm-10 {
    margin: 10.5rem !important;
  }

  .mt-sm-10,
  .my-sm-10 {
    margin-top: 10.5rem !important;
  }

  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 10.5rem !important;
  }

  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 10.5rem !important;
  }

  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 10.5rem !important;
  }

  .m-sm-17 {
    margin: 21rem !important;
  }

  .mt-sm-17,
  .my-sm-17 {
    margin-top: 21rem !important;
  }

  .mr-sm-17,
  .mx-sm-17 {
    margin-right: 21rem !important;
  }

  .mb-sm-17,
  .my-sm-17 {
    margin-bottom: 21rem !important;
  }

  .ml-sm-17,
  .mx-sm-17 {
    margin-left: 21rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .p-sm-6 {
    padding: 4.5rem !important;
  }

  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4.5rem !important;
  }

  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4.5rem !important;
  }

  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4.5rem !important;
  }

  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4.5rem !important;
  }

  .p-sm-7 {
    padding: 6rem !important;
  }

  .pt-sm-7,
  .py-sm-7 {
    padding-top: 6rem !important;
  }

  .pr-sm-7,
  .px-sm-7 {
    padding-right: 6rem !important;
  }

  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 6rem !important;
  }

  .pl-sm-7,
  .px-sm-7 {
    padding-left: 6rem !important;
  }

  .p-sm-8 {
    padding: 7.5rem !important;
  }

  .pt-sm-8,
  .py-sm-8 {
    padding-top: 7.5rem !important;
  }

  .pr-sm-8,
  .px-sm-8 {
    padding-right: 7.5rem !important;
  }

  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 7.5rem !important;
  }

  .pl-sm-8,
  .px-sm-8 {
    padding-left: 7.5rem !important;
  }

  .p-sm-9 {
    padding: 9rem !important;
  }

  .pt-sm-9,
  .py-sm-9 {
    padding-top: 9rem !important;
  }

  .pr-sm-9,
  .px-sm-9 {
    padding-right: 9rem !important;
  }

  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 9rem !important;
  }

  .pl-sm-9,
  .px-sm-9 {
    padding-left: 9rem !important;
  }

  .p-sm-10 {
    padding: 10.5rem !important;
  }

  .pt-sm-10,
  .py-sm-10 {
    padding-top: 10.5rem !important;
  }

  .pr-sm-10,
  .px-sm-10 {
    padding-right: 10.5rem !important;
  }

  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 10.5rem !important;
  }

  .pl-sm-10,
  .px-sm-10 {
    padding-left: 10.5rem !important;
  }

  .p-sm-17 {
    padding: 21rem !important;
  }

  .pt-sm-17,
  .py-sm-17 {
    padding-top: 21rem !important;
  }

  .pr-sm-17,
  .px-sm-17 {
    padding-right: 21rem !important;
  }

  .pb-sm-17,
  .py-sm-17 {
    padding-bottom: 21rem !important;
  }

  .pl-sm-17,
  .px-sm-17 {
    padding-left: 21rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-n6 {
    margin: -4.5rem !important;
  }

  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -4.5rem !important;
  }

  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -4.5rem !important;
  }

  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -4.5rem !important;
  }

  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -4.5rem !important;
  }

  .m-sm-n7 {
    margin: -6rem !important;
  }

  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -6rem !important;
  }

  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -6rem !important;
  }

  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -6rem !important;
  }

  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -6rem !important;
  }

  .m-sm-n8 {
    margin: -7.5rem !important;
  }

  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -7.5rem !important;
  }

  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -7.5rem !important;
  }

  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -7.5rem !important;
  }

  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -7.5rem !important;
  }

  .m-sm-n9 {
    margin: -9rem !important;
  }

  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -9rem !important;
  }

  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -9rem !important;
  }

  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -9rem !important;
  }

  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -9rem !important;
  }

  .m-sm-n10 {
    margin: -10.5rem !important;
  }

  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -10.5rem !important;
  }

  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -10.5rem !important;
  }

  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -10.5rem !important;
  }

  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -10.5rem !important;
  }

  .m-sm-n17 {
    margin: -21rem !important;
  }

  .mt-sm-n17,
  .my-sm-n17 {
    margin-top: -21rem !important;
  }

  .mr-sm-n17,
  .mx-sm-n17 {
    margin-right: -21rem !important;
  }

  .mb-sm-n17,
  .my-sm-n17 {
    margin-bottom: -21rem !important;
  }

  .ml-sm-n17,
  .mx-sm-n17 {
    margin-left: -21rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .m-md-6 {
    margin: 4.5rem !important;
  }

  .mt-md-6,
  .my-md-6 {
    margin-top: 4.5rem !important;
  }

  .mr-md-6,
  .mx-md-6 {
    margin-right: 4.5rem !important;
  }

  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4.5rem !important;
  }

  .ml-md-6,
  .mx-md-6 {
    margin-left: 4.5rem !important;
  }

  .m-md-7 {
    margin: 6rem !important;
  }

  .mt-md-7,
  .my-md-7 {
    margin-top: 6rem !important;
  }

  .mr-md-7,
  .mx-md-7 {
    margin-right: 6rem !important;
  }

  .mb-md-7,
  .my-md-7 {
    margin-bottom: 6rem !important;
  }

  .ml-md-7,
  .mx-md-7 {
    margin-left: 6rem !important;
  }

  .m-md-8 {
    margin: 7.5rem !important;
  }

  .mt-md-8,
  .my-md-8 {
    margin-top: 7.5rem !important;
  }

  .mr-md-8,
  .mx-md-8 {
    margin-right: 7.5rem !important;
  }

  .mb-md-8,
  .my-md-8 {
    margin-bottom: 7.5rem !important;
  }

  .ml-md-8,
  .mx-md-8 {
    margin-left: 7.5rem !important;
  }

  .m-md-9 {
    margin: 9rem !important;
  }

  .mt-md-9,
  .my-md-9 {
    margin-top: 9rem !important;
  }

  .mr-md-9,
  .mx-md-9 {
    margin-right: 9rem !important;
  }

  .mb-md-9,
  .my-md-9 {
    margin-bottom: 9rem !important;
  }

  .ml-md-9,
  .mx-md-9 {
    margin-left: 9rem !important;
  }

  .m-md-10 {
    margin: 10.5rem !important;
  }

  .mt-md-10,
  .my-md-10 {
    margin-top: 10.5rem !important;
  }

  .mr-md-10,
  .mx-md-10 {
    margin-right: 10.5rem !important;
  }

  .mb-md-10,
  .my-md-10 {
    margin-bottom: 10.5rem !important;
  }

  .ml-md-10,
  .mx-md-10 {
    margin-left: 10.5rem !important;
  }

  .m-md-17 {
    margin: 21rem !important;
  }

  .mt-md-17,
  .my-md-17 {
    margin-top: 21rem !important;
  }

  .mr-md-17,
  .mx-md-17 {
    margin-right: 21rem !important;
  }

  .mb-md-17,
  .my-md-17 {
    margin-bottom: 21rem !important;
  }

  .ml-md-17,
  .mx-md-17 {
    margin-left: 21rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .p-md-6 {
    padding: 4.5rem !important;
  }

  .pt-md-6,
  .py-md-6 {
    padding-top: 4.5rem !important;
  }

  .pr-md-6,
  .px-md-6 {
    padding-right: 4.5rem !important;
  }

  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4.5rem !important;
  }

  .pl-md-6,
  .px-md-6 {
    padding-left: 4.5rem !important;
  }

  .p-md-7 {
    padding: 6rem !important;
  }

  .pt-md-7,
  .py-md-7 {
    padding-top: 6rem !important;
  }

  .pr-md-7,
  .px-md-7 {
    padding-right: 6rem !important;
  }

  .pb-md-7,
  .py-md-7 {
    padding-bottom: 6rem !important;
  }

  .pl-md-7,
  .px-md-7 {
    padding-left: 6rem !important;
  }

  .p-md-8 {
    padding: 7.5rem !important;
  }

  .pt-md-8,
  .py-md-8 {
    padding-top: 7.5rem !important;
  }

  .pr-md-8,
  .px-md-8 {
    padding-right: 7.5rem !important;
  }

  .pb-md-8,
  .py-md-8 {
    padding-bottom: 7.5rem !important;
  }

  .pl-md-8,
  .px-md-8 {
    padding-left: 7.5rem !important;
  }

  .p-md-9 {
    padding: 9rem !important;
  }

  .pt-md-9,
  .py-md-9 {
    padding-top: 9rem !important;
  }

  .pr-md-9,
  .px-md-9 {
    padding-right: 9rem !important;
  }

  .pb-md-9,
  .py-md-9 {
    padding-bottom: 9rem !important;
  }

  .pl-md-9,
  .px-md-9 {
    padding-left: 9rem !important;
  }

  .p-md-10 {
    padding: 10.5rem !important;
  }

  .pt-md-10,
  .py-md-10 {
    padding-top: 10.5rem !important;
  }

  .pr-md-10,
  .px-md-10 {
    padding-right: 10.5rem !important;
  }

  .pb-md-10,
  .py-md-10 {
    padding-bottom: 10.5rem !important;
  }

  .pl-md-10,
  .px-md-10 {
    padding-left: 10.5rem !important;
  }

  .p-md-17 {
    padding: 21rem !important;
  }

  .pt-md-17,
  .py-md-17 {
    padding-top: 21rem !important;
  }

  .pr-md-17,
  .px-md-17 {
    padding-right: 21rem !important;
  }

  .pb-md-17,
  .py-md-17 {
    padding-bottom: 21rem !important;
  }

  .pl-md-17,
  .px-md-17 {
    padding-left: 21rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-n6 {
    margin: -4.5rem !important;
  }

  .mt-md-n6,
  .my-md-n6 {
    margin-top: -4.5rem !important;
  }

  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -4.5rem !important;
  }

  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -4.5rem !important;
  }

  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -4.5rem !important;
  }

  .m-md-n7 {
    margin: -6rem !important;
  }

  .mt-md-n7,
  .my-md-n7 {
    margin-top: -6rem !important;
  }

  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -6rem !important;
  }

  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -6rem !important;
  }

  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -6rem !important;
  }

  .m-md-n8 {
    margin: -7.5rem !important;
  }

  .mt-md-n8,
  .my-md-n8 {
    margin-top: -7.5rem !important;
  }

  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -7.5rem !important;
  }

  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -7.5rem !important;
  }

  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -7.5rem !important;
  }

  .m-md-n9 {
    margin: -9rem !important;
  }

  .mt-md-n9,
  .my-md-n9 {
    margin-top: -9rem !important;
  }

  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -9rem !important;
  }

  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -9rem !important;
  }

  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -9rem !important;
  }

  .m-md-n10 {
    margin: -10.5rem !important;
  }

  .mt-md-n10,
  .my-md-n10 {
    margin-top: -10.5rem !important;
  }

  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -10.5rem !important;
  }

  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -10.5rem !important;
  }

  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -10.5rem !important;
  }

  .m-md-n17 {
    margin: -21rem !important;
  }

  .mt-md-n17,
  .my-md-n17 {
    margin-top: -21rem !important;
  }

  .mr-md-n17,
  .mx-md-n17 {
    margin-right: -21rem !important;
  }

  .mb-md-n17,
  .my-md-n17 {
    margin-bottom: -21rem !important;
  }

  .ml-md-n17,
  .mx-md-n17 {
    margin-left: -21rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .m-lg-6 {
    margin: 4.5rem !important;
  }

  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4.5rem !important;
  }

  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4.5rem !important;
  }

  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4.5rem !important;
  }

  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4.5rem !important;
  }

  .m-lg-7 {
    margin: 6rem !important;
  }

  .mt-lg-7,
  .my-lg-7 {
    margin-top: 6rem !important;
  }

  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 6rem !important;
  }

  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 6rem !important;
  }

  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 6rem !important;
  }

  .m-lg-8 {
    margin: 7.5rem !important;
  }

  .mt-lg-8,
  .my-lg-8 {
    margin-top: 7.5rem !important;
  }

  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 7.5rem !important;
  }

  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 7.5rem !important;
  }

  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 7.5rem !important;
  }

  .m-lg-9 {
    margin: 9rem !important;
  }

  .mt-lg-9,
  .my-lg-9 {
    margin-top: 9rem !important;
  }

  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 9rem !important;
  }

  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 9rem !important;
  }

  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 9rem !important;
  }

  .m-lg-10 {
    margin: 10.5rem !important;
  }

  .mt-lg-10,
  .my-lg-10 {
    margin-top: 10.5rem !important;
  }

  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 10.5rem !important;
  }

  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 10.5rem !important;
  }

  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 10.5rem !important;
  }

  .m-lg-17 {
    margin: 21rem !important;
  }

  .mt-lg-17,
  .my-lg-17 {
    margin-top: 21rem !important;
  }

  .mr-lg-17,
  .mx-lg-17 {
    margin-right: 21rem !important;
  }

  .mb-lg-17,
  .my-lg-17 {
    margin-bottom: 21rem !important;
  }

  .ml-lg-17,
  .mx-lg-17 {
    margin-left: 21rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .p-lg-6 {
    padding: 4.5rem !important;
  }

  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4.5rem !important;
  }

  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4.5rem !important;
  }

  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4.5rem !important;
  }

  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4.5rem !important;
  }

  .p-lg-7 {
    padding: 6rem !important;
  }

  .pt-lg-7,
  .py-lg-7 {
    padding-top: 6rem !important;
  }

  .pr-lg-7,
  .px-lg-7 {
    padding-right: 6rem !important;
  }

  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 6rem !important;
  }

  .pl-lg-7,
  .px-lg-7 {
    padding-left: 6rem !important;
  }

  .p-lg-8 {
    padding: 7.5rem !important;
  }

  .pt-lg-8,
  .py-lg-8 {
    padding-top: 7.5rem !important;
  }

  .pr-lg-8,
  .px-lg-8 {
    padding-right: 7.5rem !important;
  }

  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 7.5rem !important;
  }

  .pl-lg-8,
  .px-lg-8 {
    padding-left: 7.5rem !important;
  }

  .p-lg-9 {
    padding: 9rem !important;
  }

  .pt-lg-9,
  .py-lg-9 {
    padding-top: 9rem !important;
  }

  .pr-lg-9,
  .px-lg-9 {
    padding-right: 9rem !important;
  }

  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 9rem !important;
  }

  .pl-lg-9,
  .px-lg-9 {
    padding-left: 9rem !important;
  }

  .p-lg-10 {
    padding: 10.5rem !important;
  }

  .pt-lg-10,
  .py-lg-10 {
    padding-top: 10.5rem !important;
  }

  .pr-lg-10,
  .px-lg-10 {
    padding-right: 10.5rem !important;
  }

  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 10.5rem !important;
  }

  .pl-lg-10,
  .px-lg-10 {
    padding-left: 10.5rem !important;
  }

  .p-lg-17 {
    padding: 21rem !important;
  }

  .pt-lg-17,
  .py-lg-17 {
    padding-top: 21rem !important;
  }

  .pr-lg-17,
  .px-lg-17 {
    padding-right: 21rem !important;
  }

  .pb-lg-17,
  .py-lg-17 {
    padding-bottom: 21rem !important;
  }

  .pl-lg-17,
  .px-lg-17 {
    padding-left: 21rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-n6 {
    margin: -4.5rem !important;
  }

  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -4.5rem !important;
  }

  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -4.5rem !important;
  }

  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -4.5rem !important;
  }

  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -4.5rem !important;
  }

  .m-lg-n7 {
    margin: -6rem !important;
  }

  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -6rem !important;
  }

  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -6rem !important;
  }

  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -6rem !important;
  }

  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -6rem !important;
  }

  .m-lg-n8 {
    margin: -7.5rem !important;
  }

  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -7.5rem !important;
  }

  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -7.5rem !important;
  }

  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -7.5rem !important;
  }

  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -7.5rem !important;
  }

  .m-lg-n9 {
    margin: -9rem !important;
  }

  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -9rem !important;
  }

  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -9rem !important;
  }

  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -9rem !important;
  }

  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -9rem !important;
  }

  .m-lg-n10 {
    margin: -10.5rem !important;
  }

  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -10.5rem !important;
  }

  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -10.5rem !important;
  }

  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -10.5rem !important;
  }

  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -10.5rem !important;
  }

  .m-lg-n17 {
    margin: -21rem !important;
  }

  .mt-lg-n17,
  .my-lg-n17 {
    margin-top: -21rem !important;
  }

  .mr-lg-n17,
  .mx-lg-n17 {
    margin-right: -21rem !important;
  }

  .mb-lg-n17,
  .my-lg-n17 {
    margin-bottom: -21rem !important;
  }

  .ml-lg-n17,
  .mx-lg-n17 {
    margin-left: -21rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .m-xl-6 {
    margin: 4.5rem !important;
  }

  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4.5rem !important;
  }

  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4.5rem !important;
  }

  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4.5rem !important;
  }

  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4.5rem !important;
  }

  .m-xl-7 {
    margin: 6rem !important;
  }

  .mt-xl-7,
  .my-xl-7 {
    margin-top: 6rem !important;
  }

  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 6rem !important;
  }

  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 6rem !important;
  }

  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 6rem !important;
  }

  .m-xl-8 {
    margin: 7.5rem !important;
  }

  .mt-xl-8,
  .my-xl-8 {
    margin-top: 7.5rem !important;
  }

  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 7.5rem !important;
  }

  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 7.5rem !important;
  }

  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 7.5rem !important;
  }

  .m-xl-9 {
    margin: 9rem !important;
  }

  .mt-xl-9,
  .my-xl-9 {
    margin-top: 9rem !important;
  }

  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 9rem !important;
  }

  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 9rem !important;
  }

  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 9rem !important;
  }

  .m-xl-10 {
    margin: 10.5rem !important;
  }

  .mt-xl-10,
  .my-xl-10 {
    margin-top: 10.5rem !important;
  }

  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 10.5rem !important;
  }

  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 10.5rem !important;
  }

  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 10.5rem !important;
  }

  .m-xl-17 {
    margin: 21rem !important;
  }

  .mt-xl-17,
  .my-xl-17 {
    margin-top: 21rem !important;
  }

  .mr-xl-17,
  .mx-xl-17 {
    margin-right: 21rem !important;
  }

  .mb-xl-17,
  .my-xl-17 {
    margin-bottom: 21rem !important;
  }

  .ml-xl-17,
  .mx-xl-17 {
    margin-left: 21rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .p-xl-6 {
    padding: 4.5rem !important;
  }

  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4.5rem !important;
  }

  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4.5rem !important;
  }

  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4.5rem !important;
  }

  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4.5rem !important;
  }

  .p-xl-7 {
    padding: 6rem !important;
  }

  .pt-xl-7,
  .py-xl-7 {
    padding-top: 6rem !important;
  }

  .pr-xl-7,
  .px-xl-7 {
    padding-right: 6rem !important;
  }

  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 6rem !important;
  }

  .pl-xl-7,
  .px-xl-7 {
    padding-left: 6rem !important;
  }

  .p-xl-8 {
    padding: 7.5rem !important;
  }

  .pt-xl-8,
  .py-xl-8 {
    padding-top: 7.5rem !important;
  }

  .pr-xl-8,
  .px-xl-8 {
    padding-right: 7.5rem !important;
  }

  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 7.5rem !important;
  }

  .pl-xl-8,
  .px-xl-8 {
    padding-left: 7.5rem !important;
  }

  .p-xl-9 {
    padding: 9rem !important;
  }

  .pt-xl-9,
  .py-xl-9 {
    padding-top: 9rem !important;
  }

  .pr-xl-9,
  .px-xl-9 {
    padding-right: 9rem !important;
  }

  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 9rem !important;
  }

  .pl-xl-9,
  .px-xl-9 {
    padding-left: 9rem !important;
  }

  .p-xl-10 {
    padding: 10.5rem !important;
  }

  .pt-xl-10,
  .py-xl-10 {
    padding-top: 10.5rem !important;
  }

  .pr-xl-10,
  .px-xl-10 {
    padding-right: 10.5rem !important;
  }

  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 10.5rem !important;
  }

  .pl-xl-10,
  .px-xl-10 {
    padding-left: 10.5rem !important;
  }

  .p-xl-17 {
    padding: 21rem !important;
  }

  .pt-xl-17,
  .py-xl-17 {
    padding-top: 21rem !important;
  }

  .pr-xl-17,
  .px-xl-17 {
    padding-right: 21rem !important;
  }

  .pb-xl-17,
  .py-xl-17 {
    padding-bottom: 21rem !important;
  }

  .pl-xl-17,
  .px-xl-17 {
    padding-left: 21rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-n6 {
    margin: -4.5rem !important;
  }

  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -4.5rem !important;
  }

  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -4.5rem !important;
  }

  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -4.5rem !important;
  }

  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -4.5rem !important;
  }

  .m-xl-n7 {
    margin: -6rem !important;
  }

  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -6rem !important;
  }

  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -6rem !important;
  }

  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -6rem !important;
  }

  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -6rem !important;
  }

  .m-xl-n8 {
    margin: -7.5rem !important;
  }

  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -7.5rem !important;
  }

  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -7.5rem !important;
  }

  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -7.5rem !important;
  }

  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -7.5rem !important;
  }

  .m-xl-n9 {
    margin: -9rem !important;
  }

  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -9rem !important;
  }

  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -9rem !important;
  }

  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -9rem !important;
  }

  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -9rem !important;
  }

  .m-xl-n10 {
    margin: -10.5rem !important;
  }

  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -10.5rem !important;
  }

  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -10.5rem !important;
  }

  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -10.5rem !important;
  }

  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -10.5rem !important;
  }

  .m-xl-n17 {
    margin: -21rem !important;
  }

  .mt-xl-n17,
  .my-xl-n17 {
    margin-top: -21rem !important;
  }

  .mr-xl-n17,
  .mx-xl-n17 {
    margin-right: -21rem !important;
  }

  .mb-xl-n17,
  .my-xl-n17 {
    margin-bottom: -21rem !important;
  }

  .ml-xl-n17,
  .mx-xl-n17 {
    margin-left: -21rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-bolder {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #008aff !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #0061b3 !important;
}

.text-secondary {
  color: #eaecf3 !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #b9c0d7 !important;
}

.text-success {
  color: #5cc9a7 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #36a381 !important;
}

.text-info {
  color: #50b5ff !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #0495ff !important;
}

.text-warning {
  color: #ffbe3d !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #f09f00 !important;
}

.text-danger {
  color: #f25767 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #ea1228 !important;
}

.text-light {
  color: #eaecf3 !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #b9c0d7 !important;
}

.text-dark {
  color: #171347 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #03030b !important;
}

.text-neutral {
  color: #fff !important;
}

a.text-neutral:hover,
a.text-neutral:focus {
  color: #d9d9d9 !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover,
a.text-white:focus {
  color: #d9d9d9 !important;
}

.text-body {
  color: #718096 !important;
}

.buletpoint {
  padding-left: 4px !important;
}

.buletpoint strong {
  color: var(--voilet);
}

.buletpoint strong:before {
  content: "";
  background: #ff8434;
  height: 10px;
  width: 10px;
  position: absolute;
  left: -4px;
  margin-top: 7px;
  border-radius: 10px;
}

.buletpoint p {
  font-size: 14px;
  color: #615c5c;
}

.border-signup {
  text-align: center;
  margin: 0px auto 15px;
  font-size: 24px;
  font-weight: 600;
}

.twofector img {
  width: 39px;
}

.twofector .item {
  padding: 7px;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin: 0px auto;
  margin-bottom: 28px;
}

.buletpoint strong {
  color: var(--voilet);
  margin-bottom: 7px !important;
  display: block;
}

.help-center {
  width: 100%;
  text-align: center;
  margin: 2em 0px;
}

.normal-buletpoint ul {
  list-style: none;
  padding-left: 5px;
}

.normal-buletpoint li:after {
  content: "";
  background: #ff8434;
  height: 10px;
  width: 10px;
  position: absolute;
  left: 0px;
  margin-top: 7px;
  border-radius: 10px;
}

.normal-buletpoint ul li {
  line-height: 2;
  color: #152c5b !important;
  font-size: 14px;
  line-height: 25px;
  font-weight: 500 !important;
}

.bulk-sms-pricing {
  color: #152c5b !important;
  font-size: 14px;
  line-height: 25px;
  font-weight: 500 !important;
}

.city-name {
  color: #152c5b !important;
  font-size: 14px;
  line-height: 25px;
  font-weight: 500 !important;
  padding: 10px;
}

.whatsapp-points ul {
  list-style: none;
  padding-left: 5px;
}

.whatsapp-points li:before {
  content: "";
  background: var(--light-dark);
  height: 10px;
  width: 10px;
  position: absolute;
  left: 15px;
  margin-top: 7px;
  border-radius: 10px;
}

.whatsapp-points ul li {
  color: var(--lightblack);
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 10px;
  margin-left: 15px;
}

.text-muted {
  color: var(--lightblack);
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
}

.text-black-50 {
  color: rgba(31, 45, 61, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;

  color: transparent;
  border: 0;
  background-color: transparent;
  text-shadow: none;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;

  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #a0aec0;

    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #1f2d3d;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e2e8f0 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #eaecf3;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #eaecf3;
  }
}

.bg-light-primary {
  background-color: #33a1ff !important;
}

a.bg-light-primary:hover,
a.bg-light-primary:focus,
button.bg-light-primary:hover,
button.bg-light-primary:focus {
  background-color: #008aff !important;
}

.bg-dark-primary {
  background-color: #006ecc !important;
}

a.bg-dark-primary:hover,
a.bg-dark-primary:focus,
button.bg-dark-primary:hover,
button.bg-dark-primary:focus {
  background-color: #005399 !important;
}

.bg-soft-primary {
  background-color: #e0f1ff;
}

.bg-gradient-primary {
  background: linear-gradient(50deg, #008aff 0, #003eff 100%) !important;
}

.bg-translucent-primary {
  background-color: rgba(0, 119, 219, 0.6) !important;
}

a.bg-translucent-primary:hover,
a.bg-translucent-primary:focus,
button.bg-translucent-primary:hover,
button.bg-translucent-primary:focus {
  background-color: rgba(0, 105, 194, 0.6) !important;
}

.bg-light-secondary {
  background-color: white !important;
}

a.bg-light-secondary:hover,
a.bg-light-secondary:focus,
button.bg-light-secondary:hover,
button.bg-light-secondary:focus {
  background-color: #e6e6e6 !important;
}

.bg-dark-secondary {
  background-color: #cacfe0 !important;
}

a.bg-dark-secondary:hover,
a.bg-dark-secondary:focus,
button.bg-dark-secondary:hover,
button.bg-dark-secondary:focus {
  background-color: #a9b1ce !important;
}

.bg-soft-secondary {
  background-color: #fcfdfe;
}

.bg-gradient-secondary {
  background: linear-gradient(50deg, #eaecf3 0, #ebeaf3 100%) !important;
}

.bg-translucent-secondary {
  background-color: rgba(211, 215, 230, 0.6) !important;
}

a.bg-translucent-secondary:hover,
a.bg-translucent-secondary:focus,
button.bg-translucent-secondary:hover,
button.bg-translucent-secondary:focus {
  background-color: rgba(195, 201, 221, 0.6) !important;
}

.bg-light-success {
  background-color: #82d6bc !important;
}

a.bg-light-success:hover,
a.bg-light-success:focus,
button.bg-light-success:hover,
button.bg-light-success:focus {
  background-color: #5cc9a7 !important;
}

.bg-dark-success {
  background-color: #3cb690 !important;
}

a.bg-dark-success:hover,
a.bg-dark-success:focus,
button.bg-dark-success:hover,
button.bg-dark-success:focus {
  background-color: #308f72 !important;
}

.bg-soft-success {
  background-color: #ebf9f4;
}

.bg-gradient-success {
  background: linear-gradient(50deg, #5cc9a7 0, #5cc9c8 100%) !important;
}

.bg-translucent-success {
  background-color: rgba(65, 192, 153, 0.6) !important;
}

a.bg-translucent-success:hover,
a.bg-translucent-success:focus,
button.bg-translucent-success:hover,
button.bg-translucent-success:focus {
  background-color: rgba(58, 174, 138, 0.6) !important;
}

.bg-light-info {
  background-color: #83cbff !important;
}

a.bg-light-info:hover,
a.bg-light-info:focus,
button.bg-light-info:hover,
button.bg-light-info:focus {
  background-color: #50b5ff !important;
}

.bg-dark-info {
  background-color: #1d9fff !important;
}

a.bg-dark-info:hover,
a.bg-dark-info:focus,
button.bg-dark-info:hover,
button.bg-dark-info:focus {
  background-color: #0086e9 !important;
}

.bg-soft-info {
  background-color: #eaf6ff;
}

.bg-gradient-info {
  background: linear-gradient(50deg, #50b5ff 0, #5081ff 100%) !important;
}

.bg-translucent-info {
  background-color: rgba(44, 166, 255, 0.6) !important;
}

a.bg-translucent-info:hover,
a.bg-translucent-info:focus,
button.bg-translucent-info:hover,
button.bg-translucent-info:focus {
  background-color: rgba(19, 155, 255, 0.6) !important;
}

.bg-light-warning {
  background-color: #ffcf70 !important;
}

a.bg-light-warning:hover,
a.bg-light-warning:focus,
button.bg-light-warning:hover,
button.bg-light-warning:focus {
  background-color: #ffbe3d !important;
}

.bg-dark-warning {
  background-color: #ffad0a !important;
}

a.bg-dark-warning:hover,
a.bg-dark-warning:focus,
button.bg-dark-warning:hover,
button.bg-dark-warning:focus {
  background-color: #d68e00 !important;
}

.bg-soft-warning {
  background-color: #fff7e8;
}

.bg-gradient-warning {
  background: linear-gradient(50deg, #ffbe3d 0, #fff83d 100%) !important;
}

.bg-translucent-warning {
  background-color: rgba(255, 178, 25, 0.6) !important;
}

a.bg-translucent-warning:hover,
a.bg-translucent-warning:focus,
button.bg-translucent-warning:hover,
button.bg-translucent-warning:focus {
  background-color: rgba(255, 169, 0, 0.6) !important;
}

.bg-light-danger {
  background-color: #f68692 !important;
}

a.bg-light-danger:hover,
a.bg-light-danger:focus,
button.bg-light-danger:hover,
button.bg-light-danger:focus {
  background-color: #f25767 !important;
}

.bg-dark-danger {
  background-color: #ee283c !important;
}

a.bg-dark-danger:hover,
a.bg-dark-danger:focus,
button.bg-dark-danger:hover,
button.bg-dark-danger:focus {
  background-color: #d31024 !important;
}

.bg-soft-danger {
  background-color: #fdebed;
}

.bg-gradient-danger {
  background: linear-gradient(50deg, #f25767 0, #f27657 100%) !important;
}

.bg-translucent-danger {
  background-color: rgba(239, 54, 73, 0.6) !important;
}

a.bg-translucent-danger:hover,
a.bg-translucent-danger:focus,
button.bg-translucent-danger:hover,
button.bg-translucent-danger:focus {
  background-color: rgba(238, 30, 52, 0.6) !important;
}

.bg-light-light {
  background-color: white !important;
}

a.bg-light-light:hover,
a.bg-light-light:focus,
button.bg-light-light:hover,
button.bg-light-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-dark-light {
  background-color: #cacfe0 !important;
}

a.bg-dark-light:hover,
a.bg-dark-light:focus,
button.bg-dark-light:hover,
button.bg-dark-light:focus {
  background-color: #a9b1ce !important;
}

.bg-soft-light {
  background-color: #fcfdfe;
}

.bg-gradient-light {
  background: linear-gradient(50deg, #eaecf3 0, #ebeaf3 100%) !important;
}

.bg-translucent-light {
  background-color: rgba(211, 215, 230, 0.6) !important;
}

a.bg-translucent-light:hover,
a.bg-translucent-light:focus,
button.bg-translucent-light:hover,
button.bg-translucent-light:focus {
  background-color: rgba(195, 201, 221, 0.6) !important;
}

.bg-light-dark {
  background-color: #241e6f !important;
}

a.bg-light-dark:hover,
a.bg-light-dark:focus,
button.bg-light-dark:hover,
button.bg-light-dark:focus {
  background-color: #171347 !important;
}

.bg-dark-dark {
  background-color: #0a081f !important;
}

a.bg-dark-dark:hover,
a.bg-dark-dark:focus,
button.bg-dark-dark:hover,
button.bg-dark-dark:focus {
  background-color: black !important;
}

.bg-soft-dark {
  background-color: #e3e3e9;
}

.bg-gradient-dark {
  background: linear-gradient(50deg, #171347 0, #271347 100%) !important;
}

.bg-translucent-dark {
  background-color: rgba(14, 11, 43, 0.6) !important;
}

a.bg-translucent-dark:hover,
a.bg-translucent-dark:focus,
button.bg-translucent-dark:hover,
button.bg-translucent-dark:focus {
  background-color: rgba(7, 6, 23, 0.6) !important;
}

.bg-light-neutral {
  background-color: white !important;
}

a.bg-light-neutral:hover,
a.bg-light-neutral:focus,
button.bg-light-neutral:hover,
button.bg-light-neutral:focus {
  background-color: #e6e6e6 !important;
}

.bg-dark-neutral {
  background-color: #e6e6e6 !important;
}

a.bg-dark-neutral:hover,
a.bg-dark-neutral:focus,
button.bg-dark-neutral:hover,
button.bg-dark-neutral:focus {
  background-color: #ccc !important;
}

.bg-soft-neutral {
  background-color: white;
}

.bg-gradient-neutral {
  background: linear-gradient(50deg, #fff 0, white 100%) !important;
}

.bg-translucent-neutral {
  background-color: rgba(237, 237, 237, 0.6) !important;
}

a.bg-translucent-neutral:hover,
a.bg-translucent-neutral:focus,
button.bg-translucent-neutral:hover,
button.bg-translucent-neutral:focus {
  background-color: rgba(224, 224, 224, 0.6) !important;
}

.bg-light-white {
  background-color: white !important;
}

a.bg-light-white:hover,
a.bg-light-white:focus,
button.bg-light-white:hover,
button.bg-light-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-dark-white {
  background-color: #e6e6e6 !important;
}

a.bg-dark-white:hover,
a.bg-dark-white:focus,
button.bg-dark-white:hover,
button.bg-dark-white:focus {
  background-color: #ccc !important;
}

.bg-soft-white {
  background-color: white;
}

.bg-gradient-white {
  background: linear-gradient(50deg, #fff 0, white 100%) !important;
}

.bg-translucent-white {
  background-color: rgba(237, 237, 237, 0.6) !important;
}

a.bg-translucent-white:hover,
a.bg-translucent-white:focus,
button.bg-translucent-white:hover,
button.bg-translucent-white:focus {
  background-color: rgba(224, 224, 224, 0.6) !important;
}

.bg-blue {
  background-color: #008aff !important;
}

a.bg-blue:hover,
a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #006ecc !important;
}

.bg-gradient-blue {
  background: linear-gradient(50deg, #008aff 0, #003eff 100%) !important;
}

.bg-indigo {
  background-color: #6e00ff !important;
}

a.bg-indigo:hover,
a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: #5800cc !important;
}

.bg-gradient-indigo {
  background: linear-gradient(50deg, #6e00ff 0, #b0f 100%) !important;
}

.bg-purple {
  background-color: #510fa8 !important;
}

a.bg-purple:hover,
a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #3a0b79 !important;
}

.bg-gradient-purple {
  background: linear-gradient(50deg, #510fa8 0, #7f0fa8 100%) !important;
}

.bg-pink {
  background-color: #f074ad !important;
}

a.bg-pink:hover,
a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #eb4692 !important;
}

.bg-gradient-pink {
  background: linear-gradient(50deg, #f074ad 0, #f07488 100%) !important;
}

.bg-red {
  background-color: #f25767 !important;
}

a.bg-red:hover,
a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #ee283c !important;
}

.bg-gradient-red {
  background: linear-gradient(50deg, #f25767 0, #f27657 100%) !important;
}

.bg-orange {
  background-color: #ffbe3d !important;
}

a.bg-orange:hover,
a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #ffad0a !important;
}

.bg-gradient-orange {
  background: linear-gradient(50deg, #ffbe3d 0, #fff83d 100%) !important;
}

.bg-yellow {
  background-color: #ffc431 !important;
}

a.bg-yellow:hover,
a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #fdb500 !important;
}

.bg-gradient-yellow {
  background: linear-gradient(50deg, #ffc431 0, #fcff31 100%) !important;
}

.bg-green {
  background-color: #5cc9a7 !important;
}

a.bg-green:hover,
a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #3cb690 !important;
}

.bg-gradient-green {
  background: linear-gradient(50deg, #5cc9a7 0, #5cc9c8 100%) !important;
}

.bg-teal {
  background-color: #50b5ff !important;
}

a.bg-teal:hover,
a.bg-teal:focus,
button.bg-teal:hover,
button.bg-teal:focus {
  background-color: #1d9fff !important;
}

.bg-gradient-teal {
  background: linear-gradient(50deg, #50b5ff 0, #5081ff 100%) !important;
}

.bg-cyan {
  background-color: #4bd6e5 !important;
}

a.bg-cyan:hover,
a.bg-cyan:focus,
button.bg-cyan:hover,
button.bg-cyan:focus {
  background-color: #20cbdd !important;
}

.bg-gradient-cyan {
  background: linear-gradient(50deg, #4bd6e5 0, #4ba8e5 100%) !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover,
a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-gradient-white {
  background: linear-gradient(50deg, #fff 0, white 100%) !important;
}

.bg-gray {
  background-color: #718096 !important;
}

a.bg-gray:hover,
a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #5a677a !important;
}

.bg-gradient-gray {
  background: linear-gradient(50deg, #718096 0, #717596 100%) !important;
}

.bg-gray-dark {
  background-color: #2d3748 !important;
}

a.bg-gray-dark:hover,
a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: #191f29 !important;
}

.bg-gradient-gray-dark {
  background: linear-gradient(50deg, #2d3748 0, #2d2f48 100%) !important;
}

.bg-section-primary {
  background-color: #fff !important;
}

.bg-section-secondary {
  background-color: #fff !important;
}

.bg-section-light {
  background-color: #cbd5e0 !important;
}

.bg-section-dark {
  background-color: #5fb2e6 !important;
}

.google {
  background-color: #dd4b39;
  color: white;
}

.fb {
  background-color: #3b5998;
  color: white;
}

.bg-brand-facebook {
  background-color: #3b5999 !important;
}

.kep-login-facebook {
  padding: 3px 20px !important;
  border-radius: 5px !important;
  text-transform: capitalize !important;
}

a.bg-brand-facebook:hover,
a.bg-brand-facebook:focus,
button.bg-brand-facebook:hover,
button.bg-brand-facebook:focus {
  background-color: #2d4474 !important;
}

.bg-brand-twitter {
  background-color: #1da1f2 !important;
}

a.bg-brand-twitter:hover,
a.bg-brand-twitter:focus,
button.bg-brand-twitter:hover,
button.bg-brand-twitter:focus {
  background-color: #0c85d0 !important;
}

.bg-brand-google-plus {
  background-color: #dd4b39 !important;
}

a.bg-brand-google-plus:hover,
a.bg-brand-google-plus:focus,
button.bg-brand-google-plus:hover,
button.bg-brand-google-plus:focus {
  background-color: #c23321 !important;
}

.bg-brand-instagram {
  background-color: #e4405f !important;
}

a.bg-brand-instagram:hover,
a.bg-brand-instagram:focus,
button.bg-brand-instagram:hover,
button.bg-brand-instagram:focus {
  background-color: #d31e40 !important;
}

.bg-brand-pinterest {
  background-color: #bd081c !important;
}

a.bg-brand-pinterest:hover,
a.bg-brand-pinterest:focus,
button.bg-brand-pinterest:hover,
button.bg-brand-pinterest:focus {
  background-color: #8c0615 !important;
}

.bg-brand-youtube {
  background-color: #cd201f !important;
}

a.bg-brand-youtube:hover,
a.bg-brand-youtube:focus,
button.bg-brand-youtube:hover,
button.bg-brand-youtube:focus {
  background-color: #a11918 !important;
}

.bg-brand-slack {
  background-color: #3aaf85 !important;
}

a.bg-brand-slack:hover,
a.bg-brand-slack:focus,
button.bg-brand-slack:hover,
button.bg-brand-slack:focus {
  background-color: #2d8968 !important;
}

.bg-brand-dribbble {
  background-color: #ea4c89 !important;
}

a.bg-brand-dribbble:hover,
a.bg-brand-dribbble:focus,
button.bg-brand-dribbble:hover,
button.bg-brand-dribbble:focus {
  background-color: #e51e6b !important;
}

.bg-brand-github {
  background-color: #222 !important;
}

a.bg-brand-github:hover,
a.bg-brand-github:focus,
button.bg-brand-github:hover,
button.bg-brand-github:focus {
  background-color: #090909 !important;
}

.bg-brand-vimeo {
  background-color: #04a0f0 !important;
}

a.bg-brand-vimeo:hover,
a.bg-brand-vimeo:focus,
button.bg-brand-vimeo:hover,
button.bg-brand-vimeo:focus {
  background-color: #037fbe !important;
}

.bg-gray-100 {
  background-color: #fafbfe !important;
}

a.bg-gray-100:hover,
a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #d0d9f6 !important;
}

.bg-gray-200 {
  background-color: #eaecf3 !important;
}

a.bg-gray-200:hover,
a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #cacfe0 !important;
}

.bg-gray-300 {
  background-color: #e2e8f0 !important;
}

a.bg-gray-300:hover,
a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #c0cddf !important;
}

.bg-gray-400 {
  background-color: #cbd5e0 !important;
}

a.bg-gray-400:hover,
a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #abbbcd !important;
}

.bg-gray-500 {
  background-color: #a0aec0 !important;
}

a.bg-gray-500:hover,
a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #8194ac !important;
}

.bg-gray-600 {
  background-color: #718096 !important;
}

a.bg-gray-600:hover,
a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
  background-color: #5a677a !important;
}

.bg-gray-700 {
  background-color: #4a5568 !important;
}

a.bg-gray-700:hover,
a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: #353d4a !important;
}

.bg-gray-800 {
  background-color: #2d3748 !important;
}

a.bg-gray-800:hover,
a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
  background-color: #191f29 !important;
}

.bg-gray-900 {
  background-color: #273444 !important;
}

a.bg-gray-900:hover,
a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
  background-color: #141b24 !important;
}

.bg-black {
  background-color: #1f2d3d !important;
}

a.bg-black:hover,
a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: #0e141b !important;
}

.fill-primary {
  fill: #008aff !important;
}

.fill-light-primary {
  fill: #33a1ff !important;
}

.fill-dark-primary {
  fill: #006ecc !important;
}

.stroke-primary {
  stroke: #008aff !important;
}

.fill-soft-primary {
  fill: #e0f1ff;
}

.fill-secondary {
  fill: #eaecf3 !important;
}

.fill-light-secondary {
  fill: white !important;
}

.fill-dark-secondary {
  fill: #cacfe0 !important;
}

.stroke-secondary {
  stroke: #eaecf3 !important;
}

.fill-soft-secondary {
  fill: #fcfdfe;
}

.fill-success {
  fill: #5cc9a7 !important;
}

.fill-light-success {
  fill: #82d6bc !important;
}

.fill-dark-success {
  fill: #3cb690 !important;
}

.stroke-success {
  stroke: #5cc9a7 !important;
}

.fill-soft-success {
  fill: #ebf9f4;
}

.fill-info {
  fill: #50b5ff !important;
}

.fill-light-info {
  fill: #83cbff !important;
}

.fill-dark-info {
  fill: #1d9fff !important;
}

.stroke-info {
  stroke: #50b5ff !important;
}

.fill-soft-info {
  fill: #eaf6ff;
}

.fill-warning {
  fill: #ffbe3d !important;
}

.fill-light-warning {
  fill: #ffcf70 !important;
}

.fill-dark-warning {
  fill: #ffad0a !important;
}

.stroke-warning {
  stroke: #ffbe3d !important;
}

.fill-soft-warning {
  fill: #fff7e8;
}

.fill-danger {
  fill: #f25767 !important;
}

.fill-light-danger {
  fill: #f68692 !important;
}

.fill-dark-danger {
  fill: #ee283c !important;
}

.stroke-danger {
  stroke: #f25767 !important;
}

.fill-soft-danger {
  fill: #fdebed;
}

.fill-light {
  fill: #eaecf3 !important;
}

.fill-light-light {
  fill: white !important;
}

.fill-dark-light {
  fill: #cacfe0 !important;
}

.stroke-light {
  stroke: #eaecf3 !important;
}

.fill-soft-light {
  fill: #fcfdfe;
}

.fill-dark {
  fill: #171347 !important;
}

.fill-light-dark {
  fill: #241e6f !important;
}

.fill-dark-dark {
  fill: #0a081f !important;
}

.stroke-dark {
  stroke: #171347 !important;
}

.fill-soft-dark {
  fill: #e3e3e9;
}

.fill-neutral {
  fill: #fff !important;
}

.fill-light-neutral {
  fill: white !important;
}

.fill-dark-neutral {
  fill: #e6e6e6 !important;
}

.stroke-neutral {
  stroke: #fff !important;
}

.fill-soft-neutral {
  fill: white;
}

.fill-white {
  fill: #fff !important;
}

.fill-light-white {
  fill: white !important;
}

.fill-dark-white {
  fill: #e6e6e6 !important;
}

.stroke-white {
  stroke: #fff !important;
}

.fill-soft-white {
  fill: white;
}

.fill-blue {
  fill: #008aff !important;
}

.stroke-blue {
  stroke: #008aff !important;
}

.fill-indigo {
  fill: #6e00ff !important;
}

.stroke-indigo {
  stroke: #6e00ff !important;
}

.fill-purple {
  fill: #510fa8 !important;
}

.stroke-purple {
  stroke: #510fa8 !important;
}

.fill-pink {
  fill: #f074ad !important;
}

.stroke-pink {
  stroke: #f074ad !important;
}

.fill-red {
  fill: #f25767 !important;
}

.stroke-red {
  stroke: #f25767 !important;
}

.fill-orange {
  fill: #ffbe3d !important;
}

.stroke-orange {
  stroke: #ffbe3d !important;
}

.fill-yellow {
  fill: #ffc431 !important;
}

.stroke-yellow {
  stroke: #ffc431 !important;
}

.fill-green {
  fill: #5cc9a7 !important;
}

.stroke-green {
  stroke: #5cc9a7 !important;
}

.fill-teal {
  fill: #50b5ff !important;
}

.stroke-teal {
  stroke: #50b5ff !important;
}

.fill-cyan {
  fill: #4bd6e5 !important;
}

.stroke-cyan {
  stroke: #4bd6e5 !important;
}

.fill-white {
  fill: #fff !important;
}

.stroke-white {
  stroke: #fff !important;
}

.fill-gray {
  fill: #718096 !important;
}

.stroke-gray {
  stroke: #718096 !important;
}

.fill-gray-dark {
  fill: #2d3748 !important;
}

.stroke-gray-dark {
  stroke: #2d3748 !important;
}

.fill-primary-100 {
  fill: white !important;
}

.fill-primary-200 {
  fill: #cce8ff !important;
}

.fill-primary-300 {
  fill: #99d0ff !important;
}

.fill-primary-400 {
  fill: #66b9ff !important;
}

.fill-primary-500 {
  fill: #33a1ff !important;
}

.fill-primary-600 {
  fill: #1a96ff !important;
}

.stroke-primary-100 {
  stroke: white !important;
}

.stroke-primary-200 {
  stroke: #cce8ff !important;
}

.stroke-primary-300 {
  stroke: #99d0ff !important;
}

.stroke-primary-400 {
  stroke: #66b9ff !important;
}

.stroke-primary-500 {
  stroke: #33a1ff !important;
}

.stroke-primary-600 {
  stroke: #1a96ff !important;
}

.fill-section-primary {
  fill: #fff !important;
}

.stroke-section-primary {
  stroke: #fff !important;
}

.fill-section-secondary {
  fill: #fafbfe !important;
}

.stroke-section-secondary {
  stroke: #fafbfe !important;
}

.fill-section-light {
  fill: #cbd5e0 !important;
}

.stroke-section-light {
  stroke: #cbd5e0 !important;
}

.fill-section-dark {
  fill: #0e0b2b !important;
}

.stroke-section-dark {
  stroke: #0e0b2b !important;
}

.bg-section-fade {
  background: linear-gradient(
    177.86deg,
    #fafbfe 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.bg-cover {
  position: relative;

  background-repeat: no-repeat;
}

.bg-absolute-cover {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;

  overflow: hidden;

  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
}

.bg-absolute-cover svg {
  height: 1000px;

  pointer-events: none;
}

/** Start of home banner image **/

@media (max-width: 1199.98px) {
  .bg-lg-cover {
    background-image: none !important;
  }
}

@media (max-width: 991.98px) {
  .bg-md-cover {
    background-image: none !important;
  }
}

.bg-size--cover {
  background: no-repeat center center/cover;
}

.bg-size--contain {
  background-size: contain;
}

.bg-text {
  font-size: 40rem;
  font-weight: 700;

  position: absolute;
  top: 50%;
  left: 0;

  transform: translate(0%, -50%);
  white-space: nowrap;
  text-transform: uppercase;

  color: rgba(255, 255, 255, 0.2);
}

.bg-inner--text {
  line-height: 1em;

  display: block;
  display: inline-block;

  width: auto;
  max-width: none;
  height: 1em;

  transition: 2s 0s;
  transform: none;
  animation: text_slide 40s linear infinite;
  white-space: nowrap;

  opacity: 1;
}

@media (max-width: 991.98px) {
  .bg-img-holder {
    background-image: none !important;
  }

  .bg-img-holder img {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .bg-img-holder {
    position: absolute;
    z-index: -1;

    height: 100%;
    min-height: 20rem;

    background-repeat: no-repeat;
  }

  .bg-img-holder img {
    display: none;
  }
}

.img-bg:after {
  position: absolute;
  z-index: 1;
  top: -168px;
  left: 31px;

  width: 783px;
  height: 725px;

  content: "";

  background: none no-repeat 50%;
  background-image: none;
  background-size: auto auto;
  background-size: contain;
}

.img-bg img {
  position: relative;
  z-index: 2;
}

.img-as-bg {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  -o-object-fit: cover;
  object-fit: cover;
}

.blend-mode--multiply {
  mix-blend-mode: multiply;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .blend-mode--multiply:after {
    position: absolute;
    top: 0;
    bottom: 0;

    width: 100%;

    content: "";

    background: rgba(0, 0, 214, 0.5);
  }
}

.blend-mode--color-burn {
  mix-blend-mode: color-burn;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .blend-mode--color-burn:after {
    position: absolute;
    top: 0;
    bottom: 0;

    width: 100%;

    content: "";

    background: rgba(0, 0, 214, 0.5);
  }
}

.border-sm {
  border-width: 2px !important;
}

.border-md {
  border-width: 3px !important;
}

.border-lg {
  border-width: 4px !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-groove {
  border-style: groove !important;
}

.rounded-xl {
  border-radius: 0.75rem !important;
}

.rounded-left-pill {
  border-top-left-radius: 50rem !important;
  border-bottom-left-radius: 50rem !important;
}

.rounded-right-pill {
  border-top-right-radius: 50rem !important;
  border-bottom-right-radius: 50rem !important;
}

.rounded-bottom-left {
  border-bottom-left-radius: 90px;
}

.border-soft-primary {
  border-color: #66b9ff !important;
}

.border-dark-primary {
  border-color: #007ce6 !important;
}

.border-soft-secondary {
  border-color: white !important;
}

.border-dark-secondary {
  border-color: #daddea !important;
}

.border-soft-success {
  border-color: #a9e2d0 !important;
}

.border-dark-success {
  border-color: #49c39d !important;
}

.border-soft-info {
  border-color: #b6e0ff !important;
}

.border-dark-info {
  border-color: #37aaff !important;
}

.border-soft-warning {
  border-color: #ffe0a3 !important;
}

.border-dark-warning {
  border-color: #ffb524 !important;
}

.border-soft-danger {
  border-color: #f9b6bd !important;
}

.border-dark-danger {
  border-color: #f03f52 !important;
}

.border-soft-light {
  border-color: white !important;
}

.border-dark-light {
  border-color: #daddea !important;
}

.border-soft-dark {
  border-color: #312997 !important;
}

.border-dark-dark {
  border-color: #100e33 !important;
}

.border-soft-neutral {
  border-color: white !important;
}

.border-dark-neutral {
  border-color: #f2f2f2 !important;
}

.border-soft-white {
  border-color: white !important;
}

.border-dark-white {
  border-color: #f2f2f2 !important;
}

.img-center {
  display: block;

  margin-right: auto;
  margin-left: auto;
}

.img-grayscale {
  filter: grayscale(100%);
}

.img-saturate {
  filter: saturate(150%);
}

.h-100vh {
  height: 100vh !important;
}

.mw-25 {
  max-width: 25% !important;
}

.mw-50 {
  max-width: 50% !important;
}

.mw-75 {
  max-width: 75% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mw-auto {
  max-width: auto !important;
}

.mw-10 {
  max-width: 10% !important;
}

.mw-15 {
  max-width: 15% !important;
}

.mw-80 {
  max-width: 80% !important;
}

.mw-90 {
  max-width: 90% !important;
}

.mw-110 {
  max-width: 110% !important;
}

.mw-120 {
  max-width: 120% !important;
}

.mw-130 {
  max-width: 130% !important;
}

@media (min-width: 576px) {
  .mw-sm-25 {
    max-width: 25% !important;
  }

  .mw-sm-50 {
    max-width: 50% !important;
  }

  .mw-sm-75 {
    max-width: 75% !important;
  }

  .mw-sm-100 {
    max-width: 100% !important;
  }

  .mw-sm-auto {
    max-width: auto !important;
  }

  .mw-sm-10 {
    max-width: 10% !important;
  }

  .mw-sm-15 {
    max-width: 15% !important;
  }

  .mw-sm-80 {
    max-width: 80% !important;
  }

  .mw-sm-90 {
    max-width: 90% !important;
  }

  .mw-sm-110 {
    max-width: 110% !important;
  }

  .mw-sm-120 {
    max-width: 120% !important;
  }

  .mw-sm-130 {
    max-width: 130% !important;
  }
}

@media (min-width: 768px) {
  .mw-md-25 {
    max-width: 25% !important;
  }

  .mw-md-50 {
    max-width: 50% !important;
  }

  .mw-md-75 {
    max-width: 75% !important;
  }

  .mw-md-100 {
    max-width: 100% !important;
  }

  .mw-md-auto {
    max-width: auto !important;
  }

  .mw-md-10 {
    max-width: 10% !important;
  }

  .mw-md-15 {
    max-width: 15% !important;
  }

  .mw-md-80 {
    max-width: 80% !important;
  }

  .mw-md-90 {
    max-width: 90% !important;
  }

  .mw-md-110 {
    max-width: 110% !important;
  }

  .mw-md-120 {
    max-width: 120% !important;
  }

  .mw-md-130 {
    max-width: 130% !important;
  }
}

@media (min-width: 992px) {
  .mw-lg-25 {
    max-width: 25% !important;
  }

  .mw-lg-50 {
    max-width: 50% !important;
  }

  .mw-lg-75 {
    max-width: 75% !important;
  }

  .mw-lg-100 {
    max-width: 100% !important;
  }

  .mw-lg-auto {
    max-width: auto !important;
  }

  .mw-lg-10 {
    max-width: 10% !important;
  }

  .mw-lg-15 {
    max-width: 15% !important;
  }

  .mw-lg-80 {
    max-width: 80% !important;
  }

  .mw-lg-90 {
    max-width: 90% !important;
  }

  .mw-lg-110 {
    max-width: 110% !important;
  }

  .mw-lg-120 {
    max-width: 120% !important;
  }

  .mw-lg-130 {
    max-width: 130% !important;
  }
}

@media (min-width: 1200px) {
  .mw-xl-25 {
    max-width: 25% !important;
  }

  .mw-xl-50 {
    max-width: 50% !important;
  }

  .mw-xl-75 {
    max-width: 75% !important;
  }

  .mw-xl-100 {
    max-width: 100% !important;
  }

  .mw-xl-auto {
    max-width: auto !important;
  }

  .mw-xl-10 {
    max-width: 10% !important;
  }

  .mw-xl-15 {
    max-width: 15% !important;
  }

  .mw-xl-80 {
    max-width: 80% !important;
  }

  .mw-xl-90 {
    max-width: 90% !important;
  }

  .mw-xl-110 {
    max-width: 110% !important;
  }

  .mw-xl-120 {
    max-width: 120% !important;
  }

  .mw-xl-130 {
    max-width: 130% !important;
  }
}

.row-grid + .row-grid {
  margin-top: 3rem;
}

.row-grid > [class*="col-"] + [class*="col-"] {
  margin-top: 3rem;
}

@media (min-width: 992px) {
  .row.row-grid > [class*="col-lg-"] + [class*="col-lg-"] {
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .row.row-grid > [class*="col-md-"] + [class*="col-md-"] {
    margin-top: 0;
  }
}

@media (min-width: 576px) {
  .row.row-grid > [class*="col-sm-"] + [class*="col-sm-"] {
    margin-top: 0;
  }
}

.top-0 {
  top: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.left-0 {
  left: 0 !important;
}

.top-n0 {
  top: 0 !important;
}

.right-n0 {
  right: 0 !important;
}

.bottom-n0 {
  bottom: 0 !important;
}

.left-n0 {
  left: 0 !important;
}

.top-1 {
  top: 0.25rem !important;
}

.right-1 {
  right: 0.25rem !important;
}

.bottom-1 {
  bottom: 0.25rem !important;
}

.left-1 {
  left: 0.25rem !important;
}

.top-n1 {
  top: -0.25rem !important;
}

.right-n1 {
  right: -0.25rem !important;
}

.bottom-n1 {
  bottom: -0.25rem !important;
}

.left-n1 {
  left: -0.25rem !important;
}

.top-2 {
  top: 0.5rem !important;
}

.right-2 {
  right: 0.5rem !important;
}

.bottom-2 {
  bottom: 0.5rem !important;
}

.left-2 {
  left: 0.5rem !important;
}

.top-n2 {
  top: -0.5rem !important;
}

.right-n2 {
  right: -0.5rem !important;
}

.bottom-n2 {
  bottom: -0.5rem !important;
}

.left-n2 {
  left: -0.5rem !important;
}

.top-3 {
  top: 1rem !important;
}

.right-3 {
  right: 1rem !important;
}

.bottom-3 {
  bottom: 1rem !important;
}

.left-3 {
  left: 1rem !important;
}

.top-n3 {
  top: -1rem !important;
}

.right-n3 {
  right: -1rem !important;
}

.bottom-n3 {
  bottom: -1rem !important;
}

.left-n3 {
  left: -1rem !important;
}

.top-4 {
  top: 1.5rem !important;
}

.right-4 {
  right: 1.5rem !important;
}

.bottom-4 {
  bottom: 1.5rem !important;
}

.left-4 {
  left: 1.5rem !important;
}

.top-n4 {
  top: -1.5rem !important;
}

.right-n4 {
  right: -1.5rem !important;
}

.bottom-n4 {
  bottom: -1.5rem !important;
}

.left-n4 {
  left: -1.5rem !important;
}

.top-5 {
  top: 3rem !important;
}

.right-5 {
  right: 3rem !important;
}

.bottom-5 {
  bottom: 3rem !important;
}

.left-5 {
  left: 3rem !important;
}

.top-n5 {
  top: -3rem !important;
}

.right-n5 {
  right: -3rem !important;
}

.bottom-n5 {
  bottom: -3rem !important;
}

.left-n5 {
  left: -3rem !important;
}

.top-6 {
  top: 4.5rem !important;
}

.right-6 {
  right: 4.5rem !important;
}

.bottom-6 {
  bottom: 4.5rem !important;
}

.left-6 {
  left: 4.5rem !important;
}

.top-n6 {
  top: -4.5rem !important;
}

.right-n6 {
  right: -4.5rem !important;
}

.bottom-n6 {
  bottom: -4.5rem !important;
}

.left-n6 {
  left: -4.5rem !important;
}

.top-7 {
  top: 6rem !important;
}

.right-7 {
  right: 6rem !important;
}

.bottom-7 {
  bottom: 6rem !important;
}

.left-7 {
  left: 6rem !important;
}

.top-n7 {
  top: -6rem !important;
}

.right-n7 {
  right: -6rem !important;
}

.bottom-n7 {
  bottom: -6rem !important;
}

.left-n7 {
  left: -6rem !important;
}

.top-8 {
  top: 7.5rem !important;
}

.right-8 {
  right: 7.5rem !important;
}

.bottom-8 {
  bottom: 7.5rem !important;
}

.left-8 {
  left: 7.5rem !important;
}

.top-n8 {
  top: -7.5rem !important;
}

.right-n8 {
  right: -7.5rem !important;
}

.bottom-n8 {
  bottom: -7.5rem !important;
}

.left-n8 {
  left: -7.5rem !important;
}

.top-9 {
  top: 9rem !important;
}

.right-9 {
  right: 9rem !important;
}

.bottom-9 {
  bottom: 9rem !important;
}

.left-9 {
  left: 9rem !important;
}

.top-n9 {
  top: -9rem !important;
}

.right-n9 {
  right: -9rem !important;
}

.bottom-n9 {
  bottom: -9rem !important;
}

.left-n9 {
  left: -9rem !important;
}

.top-10 {
  top: 10.5rem !important;
}

.right-10 {
  right: 10.5rem !important;
}

.bottom-10 {
  bottom: 10.5rem !important;
}

.left-10 {
  left: 10.5rem !important;
}

.top-n10 {
  top: -10.5rem !important;
}

.right-n10 {
  right: -10.5rem !important;
}

.bottom-n10 {
  bottom: -10.5rem !important;
}

.left-n10 {
  left: -10.5rem !important;
}

.top-17 {
  top: 21rem !important;
}

.right-17 {
  right: 21rem !important;
}

.bottom-17 {
  bottom: 21rem !important;
}

.left-17 {
  left: 21rem !important;
}

.top-n17 {
  top: -21rem !important;
}

.right-n17 {
  right: -21rem !important;
}

.bottom-n17 {
  bottom: -21rem !important;
}

.left-n17 {
  left: -21rem !important;
}

@media (min-width: 576px) {
  .top-sm-0 {
    top: 0 !important;
  }

  .right-sm-0 {
    right: 0 !important;
  }

  .bottom-sm-0 {
    bottom: 0 !important;
  }

  .left-sm-0 {
    left: 0 !important;
  }

  .top-sm-n0 {
    top: 0 !important;
  }

  .right-sm-n0 {
    right: 0 !important;
  }

  .bottom-sm-n0 {
    bottom: 0 !important;
  }

  .left-sm-n0 {
    left: 0 !important;
  }

  .top-sm-1 {
    top: 0.25rem !important;
  }

  .right-sm-1 {
    right: 0.25rem !important;
  }

  .bottom-sm-1 {
    bottom: 0.25rem !important;
  }

  .left-sm-1 {
    left: 0.25rem !important;
  }

  .top-sm-n1 {
    top: -0.25rem !important;
  }

  .right-sm-n1 {
    right: -0.25rem !important;
  }

  .bottom-sm-n1 {
    bottom: -0.25rem !important;
  }

  .left-sm-n1 {
    left: -0.25rem !important;
  }

  .top-sm-2 {
    top: 0.5rem !important;
  }

  .right-sm-2 {
    right: 0.5rem !important;
  }

  .bottom-sm-2 {
    bottom: 0.5rem !important;
  }

  .left-sm-2 {
    left: 0.5rem !important;
  }

  .top-sm-n2 {
    top: -0.5rem !important;
  }

  .right-sm-n2 {
    right: -0.5rem !important;
  }

  .bottom-sm-n2 {
    bottom: -0.5rem !important;
  }

  .left-sm-n2 {
    left: -0.5rem !important;
  }

  .top-sm-3 {
    top: 1rem !important;
  }

  .right-sm-3 {
    right: 1rem !important;
  }

  .bottom-sm-3 {
    bottom: 1rem !important;
  }

  .left-sm-3 {
    left: 1rem !important;
  }

  .top-sm-n3 {
    top: -1rem !important;
  }

  .right-sm-n3 {
    right: -1rem !important;
  }

  .bottom-sm-n3 {
    bottom: -1rem !important;
  }

  .left-sm-n3 {
    left: -1rem !important;
  }

  .top-sm-4 {
    top: 1.5rem !important;
  }

  .right-sm-4 {
    right: 1.5rem !important;
  }

  .bottom-sm-4 {
    bottom: 1.5rem !important;
  }

  .left-sm-4 {
    left: 1.5rem !important;
  }

  .top-sm-n4 {
    top: -1.5rem !important;
  }

  .right-sm-n4 {
    right: -1.5rem !important;
  }

  .bottom-sm-n4 {
    bottom: -1.5rem !important;
  }

  .left-sm-n4 {
    left: -1.5rem !important;
  }

  .top-sm-5 {
    top: 3rem !important;
  }

  .right-sm-5 {
    right: 3rem !important;
  }

  .bottom-sm-5 {
    bottom: 3rem !important;
  }

  .left-sm-5 {
    left: 3rem !important;
  }

  .top-sm-n5 {
    top: -3rem !important;
  }

  .right-sm-n5 {
    right: -3rem !important;
  }

  .bottom-sm-n5 {
    bottom: -3rem !important;
  }

  .left-sm-n5 {
    left: -3rem !important;
  }

  .top-sm-6 {
    top: 4.5rem !important;
  }

  .right-sm-6 {
    right: 4.5rem !important;
  }

  .bottom-sm-6 {
    bottom: 4.5rem !important;
  }

  .left-sm-6 {
    left: 4.5rem !important;
  }

  .top-sm-n6 {
    top: -4.5rem !important;
  }

  .right-sm-n6 {
    right: -4.5rem !important;
  }

  .bottom-sm-n6 {
    bottom: -4.5rem !important;
  }

  .left-sm-n6 {
    left: -4.5rem !important;
  }

  .top-sm-7 {
    top: 6rem !important;
  }

  .right-sm-7 {
    right: 6rem !important;
  }

  .bottom-sm-7 {
    bottom: 6rem !important;
  }

  .left-sm-7 {
    left: 6rem !important;
  }

  .top-sm-n7 {
    top: -6rem !important;
  }

  .right-sm-n7 {
    right: -6rem !important;
  }

  .bottom-sm-n7 {
    bottom: -6rem !important;
  }

  .left-sm-n7 {
    left: -6rem !important;
  }

  .top-sm-8 {
    top: 7.5rem !important;
  }

  .right-sm-8 {
    right: 7.5rem !important;
  }

  .bottom-sm-8 {
    bottom: 7.5rem !important;
  }

  .left-sm-8 {
    left: 7.5rem !important;
  }

  .top-sm-n8 {
    top: -7.5rem !important;
  }

  .right-sm-n8 {
    right: -7.5rem !important;
  }

  .bottom-sm-n8 {
    bottom: -7.5rem !important;
  }

  .left-sm-n8 {
    left: -7.5rem !important;
  }

  .top-sm-9 {
    top: 9rem !important;
  }

  .right-sm-9 {
    right: 9rem !important;
  }

  .bottom-sm-9 {
    bottom: 9rem !important;
  }

  .left-sm-9 {
    left: 9rem !important;
  }

  .top-sm-n9 {
    top: -9rem !important;
  }

  .right-sm-n9 {
    right: -9rem !important;
  }

  .bottom-sm-n9 {
    bottom: -9rem !important;
  }

  .left-sm-n9 {
    left: -9rem !important;
  }

  .top-sm-10 {
    top: 10.5rem !important;
  }

  .right-sm-10 {
    right: 10.5rem !important;
  }

  .bottom-sm-10 {
    bottom: 10.5rem !important;
  }

  .left-sm-10 {
    left: 10.5rem !important;
  }

  .top-sm-n10 {
    top: -10.5rem !important;
  }

  .right-sm-n10 {
    right: -10.5rem !important;
  }

  .bottom-sm-n10 {
    bottom: -10.5rem !important;
  }

  .left-sm-n10 {
    left: -10.5rem !important;
  }

  .top-sm-17 {
    top: 21rem !important;
  }

  .right-sm-17 {
    right: 21rem !important;
  }

  .bottom-sm-17 {
    bottom: 21rem !important;
  }

  .left-sm-17 {
    left: 21rem !important;
  }

  .top-sm-n17 {
    top: -21rem !important;
  }

  .right-sm-n17 {
    right: -21rem !important;
  }

  .bottom-sm-n17 {
    bottom: -21rem !important;
  }

  .left-sm-n17 {
    left: -21rem !important;
  }
}

@media (min-width: 768px) {
  .top-md-0 {
    top: 0 !important;
  }

  .right-md-0 {
    right: 0 !important;
  }

  .bottom-md-0 {
    bottom: 0 !important;
  }

  .left-md-0 {
    left: 0 !important;
  }

  .top-md-n0 {
    top: 0 !important;
  }

  .right-md-n0 {
    right: 0 !important;
  }

  .bottom-md-n0 {
    bottom: 0 !important;
  }

  .left-md-n0 {
    left: 0 !important;
  }

  .top-md-1 {
    top: 0.25rem !important;
  }

  .right-md-1 {
    right: 0.25rem !important;
  }

  .bottom-md-1 {
    bottom: 0.25rem !important;
  }

  .left-md-1 {
    left: 0.25rem !important;
  }

  .top-md-n1 {
    top: -0.25rem !important;
  }

  .right-md-n1 {
    right: -0.25rem !important;
  }

  .bottom-md-n1 {
    bottom: -0.25rem !important;
  }

  .left-md-n1 {
    left: -0.25rem !important;
  }

  .top-md-2 {
    top: 0.5rem !important;
  }

  .right-md-2 {
    right: 0.5rem !important;
  }

  .bottom-md-2 {
    bottom: 0.5rem !important;
  }

  .left-md-2 {
    left: 0.5rem !important;
  }

  .top-md-n2 {
    top: -0.5rem !important;
  }

  .right-md-n2 {
    right: -0.5rem !important;
  }

  .bottom-md-n2 {
    bottom: -0.5rem !important;
  }

  .left-md-n2 {
    left: -0.5rem !important;
  }

  .top-md-3 {
    top: 1rem !important;
  }

  .right-md-3 {
    right: 1rem !important;
  }

  .bottom-md-3 {
    bottom: 1rem !important;
  }

  .left-md-3 {
    left: 1rem !important;
  }

  .top-md-n3 {
    top: -1rem !important;
  }

  .right-md-n3 {
    right: -1rem !important;
  }

  .bottom-md-n3 {
    bottom: -1rem !important;
  }

  .left-md-n3 {
    left: -1rem !important;
  }

  .top-md-4 {
    top: 1.5rem !important;
  }

  .right-md-4 {
    right: 1.5rem !important;
  }

  .bottom-md-4 {
    bottom: 1.5rem !important;
  }

  .left-md-4 {
    left: 1.5rem !important;
  }

  .top-md-n4 {
    top: -1.5rem !important;
  }

  .right-md-n4 {
    right: -1.5rem !important;
  }

  .bottom-md-n4 {
    bottom: -1.5rem !important;
  }

  .left-md-n4 {
    left: -1.5rem !important;
  }

  .top-md-5 {
    top: 3rem !important;
  }

  .right-md-5 {
    right: 3rem !important;
  }

  .bottom-md-5 {
    bottom: 3rem !important;
  }

  .left-md-5 {
    left: 3rem !important;
  }

  .top-md-n5 {
    top: -3rem !important;
  }

  .right-md-n5 {
    right: -3rem !important;
  }

  .bottom-md-n5 {
    bottom: -3rem !important;
  }

  .left-md-n5 {
    left: -3rem !important;
  }

  .top-md-6 {
    top: 4.5rem !important;
  }

  .right-md-6 {
    right: 4.5rem !important;
  }

  .bottom-md-6 {
    bottom: 4.5rem !important;
  }

  .left-md-6 {
    left: 4.5rem !important;
  }

  .top-md-n6 {
    top: -4.5rem !important;
  }

  .right-md-n6 {
    right: -4.5rem !important;
  }

  .bottom-md-n6 {
    bottom: -4.5rem !important;
  }

  .left-md-n6 {
    left: -4.5rem !important;
  }

  .top-md-7 {
    top: 6rem !important;
  }

  .right-md-7 {
    right: 6rem !important;
  }

  .bottom-md-7 {
    bottom: 6rem !important;
  }

  .left-md-7 {
    left: 6rem !important;
  }

  .top-md-n7 {
    top: -6rem !important;
  }

  .right-md-n7 {
    right: -6rem !important;
  }

  .bottom-md-n7 {
    bottom: -6rem !important;
  }

  .left-md-n7 {
    left: -6rem !important;
  }

  .top-md-8 {
    top: 7.5rem !important;
  }

  .right-md-8 {
    right: 7.5rem !important;
  }

  .bottom-md-8 {
    bottom: 7.5rem !important;
  }

  .left-md-8 {
    left: 7.5rem !important;
  }

  .top-md-n8 {
    top: -7.5rem !important;
  }

  .right-md-n8 {
    right: -7.5rem !important;
  }

  .bottom-md-n8 {
    bottom: -7.5rem !important;
  }

  .left-md-n8 {
    left: -7.5rem !important;
  }

  .top-md-9 {
    top: 9rem !important;
  }

  .right-md-9 {
    right: 9rem !important;
  }

  .bottom-md-9 {
    bottom: 9rem !important;
  }

  .left-md-9 {
    left: 9rem !important;
  }

  .top-md-n9 {
    top: -9rem !important;
  }

  .right-md-n9 {
    right: -9rem !important;
  }

  .bottom-md-n9 {
    bottom: -9rem !important;
  }

  .left-md-n9 {
    left: -9rem !important;
  }

  .top-md-10 {
    top: 10.5rem !important;
  }

  .right-md-10 {
    right: 10.5rem !important;
  }

  .bottom-md-10 {
    bottom: 10.5rem !important;
  }

  .left-md-10 {
    left: 10.5rem !important;
  }

  .top-md-n10 {
    top: -10.5rem !important;
  }

  .right-md-n10 {
    right: -10.5rem !important;
  }

  .bottom-md-n10 {
    bottom: -10.5rem !important;
  }

  .left-md-n10 {
    left: -10.5rem !important;
  }

  .top-md-17 {
    top: 21rem !important;
  }

  .right-md-17 {
    right: 21rem !important;
  }

  .bottom-md-17 {
    bottom: 21rem !important;
  }

  .left-md-17 {
    left: 21rem !important;
  }

  .top-md-n17 {
    top: -21rem !important;
  }

  .right-md-n17 {
    right: -21rem !important;
  }

  .bottom-md-n17 {
    bottom: -21rem !important;
  }

  .left-md-n17 {
    left: -21rem !important;
  }
}

@media (min-width: 992px) {
  .top-lg-0 {
    top: 0 !important;
  }

  .right-lg-0 {
    right: 0 !important;
  }

  .bottom-lg-0 {
    bottom: 0 !important;
  }

  .left-lg-0 {
    left: 0 !important;
  }

  .top-lg-n0 {
    top: 0 !important;
  }

  .right-lg-n0 {
    right: 0 !important;
  }

  .bottom-lg-n0 {
    bottom: 0 !important;
  }

  .left-lg-n0 {
    left: 0 !important;
  }

  .top-lg-1 {
    top: 0.25rem !important;
  }

  .right-lg-1 {
    right: 0.25rem !important;
  }

  .bottom-lg-1 {
    bottom: 0.25rem !important;
  }

  .left-lg-1 {
    left: 0.25rem !important;
  }

  .top-lg-n1 {
    top: -0.25rem !important;
  }

  .right-lg-n1 {
    right: -0.25rem !important;
  }

  .bottom-lg-n1 {
    bottom: -0.25rem !important;
  }

  .left-lg-n1 {
    left: -0.25rem !important;
  }

  .top-lg-2 {
    top: 0.5rem !important;
  }

  .right-lg-2 {
    right: 0.5rem !important;
  }

  .bottom-lg-2 {
    bottom: 0.5rem !important;
  }

  .left-lg-2 {
    left: 0.5rem !important;
  }

  .top-lg-n2 {
    top: -0.5rem !important;
  }

  .right-lg-n2 {
    right: -0.5rem !important;
  }

  .bottom-lg-n2 {
    bottom: -0.5rem !important;
  }

  .left-lg-n2 {
    left: -0.5rem !important;
  }

  .top-lg-3 {
    top: 1rem !important;
  }

  .right-lg-3 {
    right: 1rem !important;
  }

  .bottom-lg-3 {
    bottom: 1rem !important;
  }

  .left-lg-3 {
    left: 1rem !important;
  }

  .top-lg-n3 {
    top: -1rem !important;
  }

  .right-lg-n3 {
    right: -1rem !important;
  }

  .bottom-lg-n3 {
    bottom: -1rem !important;
  }

  .left-lg-n3 {
    left: -1rem !important;
  }

  .top-lg-4 {
    top: 1.5rem !important;
  }

  .right-lg-4 {
    right: 1.5rem !important;
  }

  .bottom-lg-4 {
    bottom: 1.5rem !important;
  }

  .left-lg-4 {
    left: 1.5rem !important;
  }

  .top-lg-n4 {
    top: -1.5rem !important;
  }

  .right-lg-n4 {
    right: -1.5rem !important;
  }

  .bottom-lg-n4 {
    bottom: -1.5rem !important;
  }

  .left-lg-n4 {
    left: -1.5rem !important;
  }

  .top-lg-5 {
    top: 3rem !important;
  }

  .right-lg-5 {
    right: 3rem !important;
  }

  .bottom-lg-5 {
    bottom: 3rem !important;
  }

  .left-lg-5 {
    left: 3rem !important;
  }

  .top-lg-n5 {
    top: -3rem !important;
  }

  .right-lg-n5 {
    right: -3rem !important;
  }

  .bottom-lg-n5 {
    bottom: -3rem !important;
  }

  .left-lg-n5 {
    left: -3rem !important;
  }

  .top-lg-6 {
    top: 4.5rem !important;
  }

  .right-lg-6 {
    right: 4.5rem !important;
  }

  .bottom-lg-6 {
    bottom: 4.5rem !important;
  }

  .left-lg-6 {
    left: 4.5rem !important;
  }

  .top-lg-n6 {
    top: -4.5rem !important;
  }

  .right-lg-n6 {
    right: -4.5rem !important;
  }

  .bottom-lg-n6 {
    bottom: -4.5rem !important;
  }

  .left-lg-n6 {
    left: -4.5rem !important;
  }

  .top-lg-7 {
    top: 6rem !important;
  }

  .right-lg-7 {
    right: 6rem !important;
  }

  .bottom-lg-7 {
    bottom: 6rem !important;
  }

  .left-lg-7 {
    left: 6rem !important;
  }

  .top-lg-n7 {
    top: -6rem !important;
  }

  .right-lg-n7 {
    right: -6rem !important;
  }

  .bottom-lg-n7 {
    bottom: -6rem !important;
  }

  .left-lg-n7 {
    left: -6rem !important;
  }

  .top-lg-8 {
    top: 7.5rem !important;
  }

  .right-lg-8 {
    right: 7.5rem !important;
  }

  .bottom-lg-8 {
    bottom: 7.5rem !important;
  }

  .left-lg-8 {
    left: 7.5rem !important;
  }

  .top-lg-n8 {
    top: -7.5rem !important;
  }

  .right-lg-n8 {
    right: -7.5rem !important;
  }

  .bottom-lg-n8 {
    bottom: -7.5rem !important;
  }

  .left-lg-n8 {
    left: -7.5rem !important;
  }

  .top-lg-9 {
    top: 9rem !important;
  }

  .right-lg-9 {
    right: 9rem !important;
  }

  .bottom-lg-9 {
    bottom: 9rem !important;
  }

  .left-lg-9 {
    left: 9rem !important;
  }

  .top-lg-n9 {
    top: -9rem !important;
  }

  .right-lg-n9 {
    right: -9rem !important;
  }

  .bottom-lg-n9 {
    bottom: -9rem !important;
  }

  .left-lg-n9 {
    left: -9rem !important;
  }

  .top-lg-10 {
    top: 10.5rem !important;
  }

  .right-lg-10 {
    right: 10.5rem !important;
  }

  .bottom-lg-10 {
    bottom: 10.5rem !important;
  }

  .left-lg-10 {
    left: 10.5rem !important;
  }

  .top-lg-n10 {
    top: -10.5rem !important;
  }

  .right-lg-n10 {
    right: -10.5rem !important;
  }

  .bottom-lg-n10 {
    bottom: -10.5rem !important;
  }

  .left-lg-n10 {
    left: -10.5rem !important;
  }

  .top-lg-17 {
    top: 21rem !important;
  }

  .right-lg-17 {
    right: 21rem !important;
  }

  .bottom-lg-17 {
    bottom: 21rem !important;
  }

  .left-lg-17 {
    left: 21rem !important;
  }

  .top-lg-n17 {
    top: -21rem !important;
  }

  .right-lg-n17 {
    right: -21rem !important;
  }

  .bottom-lg-n17 {
    bottom: -21rem !important;
  }

  .left-lg-n17 {
    left: -21rem !important;
  }
}

@media (min-width: 1200px) {
  .top-xl-0 {
    top: 0 !important;
  }

  .right-xl-0 {
    right: 0 !important;
  }

  .bottom-xl-0 {
    bottom: 0 !important;
  }

  .left-xl-0 {
    left: 0 !important;
  }

  .top-xl-n0 {
    top: 0 !important;
  }

  .right-xl-n0 {
    right: 0 !important;
  }

  .bottom-xl-n0 {
    bottom: 0 !important;
  }

  .left-xl-n0 {
    left: 0 !important;
  }

  .top-xl-1 {
    top: 0.25rem !important;
  }

  .right-xl-1 {
    right: 0.25rem !important;
  }

  .bottom-xl-1 {
    bottom: 0.25rem !important;
  }

  .left-xl-1 {
    left: 0.25rem !important;
  }

  .top-xl-n1 {
    top: -0.25rem !important;
  }

  .right-xl-n1 {
    right: -0.25rem !important;
  }

  .bottom-xl-n1 {
    bottom: -0.25rem !important;
  }

  .left-xl-n1 {
    left: -0.25rem !important;
  }

  .top-xl-2 {
    top: 0.5rem !important;
  }

  .right-xl-2 {
    right: 0.5rem !important;
  }

  .bottom-xl-2 {
    bottom: 0.5rem !important;
  }

  .left-xl-2 {
    left: 0.5rem !important;
  }

  .top-xl-n2 {
    top: -0.5rem !important;
  }

  .right-xl-n2 {
    right: -0.5rem !important;
  }

  .bottom-xl-n2 {
    bottom: -0.5rem !important;
  }

  .left-xl-n2 {
    left: -0.5rem !important;
  }

  .top-xl-3 {
    top: 1rem !important;
  }

  .right-xl-3 {
    right: 1rem !important;
  }

  .bottom-xl-3 {
    bottom: 1rem !important;
  }

  .left-xl-3 {
    left: 1rem !important;
  }

  .top-xl-n3 {
    top: -1rem !important;
  }

  .right-xl-n3 {
    right: -1rem !important;
  }

  .bottom-xl-n3 {
    bottom: -1rem !important;
  }

  .left-xl-n3 {
    left: -1rem !important;
  }

  .top-xl-4 {
    top: 1.5rem !important;
  }

  .right-xl-4 {
    right: 1.5rem !important;
  }

  .bottom-xl-4 {
    bottom: 1.5rem !important;
  }

  .left-xl-4 {
    left: 1.5rem !important;
  }

  .top-xl-n4 {
    top: -1.5rem !important;
  }

  .right-xl-n4 {
    right: -1.5rem !important;
  }

  .bottom-xl-n4 {
    bottom: -1.5rem !important;
  }

  .left-xl-n4 {
    left: -1.5rem !important;
  }

  .top-xl-5 {
    top: 3rem !important;
  }

  .right-xl-5 {
    right: 3rem !important;
  }

  .bottom-xl-5 {
    bottom: 3rem !important;
  }

  .left-xl-5 {
    left: 3rem !important;
  }

  .top-xl-n5 {
    top: -3rem !important;
  }

  .right-xl-n5 {
    right: -3rem !important;
  }

  .bottom-xl-n5 {
    bottom: -3rem !important;
  }

  .left-xl-n5 {
    left: -3rem !important;
  }

  .top-xl-6 {
    top: 4.5rem !important;
  }

  .right-xl-6 {
    right: 4.5rem !important;
  }

  .bottom-xl-6 {
    bottom: 4.5rem !important;
  }

  .left-xl-6 {
    left: 4.5rem !important;
  }

  .top-xl-n6 {
    top: -4.5rem !important;
  }

  .right-xl-n6 {
    right: -4.5rem !important;
  }

  .bottom-xl-n6 {
    bottom: -4.5rem !important;
  }

  .left-xl-n6 {
    left: -4.5rem !important;
  }

  .top-xl-7 {
    top: 6rem !important;
  }

  .right-xl-7 {
    right: 6rem !important;
  }

  .bottom-xl-7 {
    bottom: 6rem !important;
  }

  .left-xl-7 {
    left: 6rem !important;
  }

  .top-xl-n7 {
    top: -6rem !important;
  }

  .right-xl-n7 {
    right: -6rem !important;
  }

  .bottom-xl-n7 {
    bottom: -6rem !important;
  }

  .left-xl-n7 {
    left: -6rem !important;
  }

  .top-xl-8 {
    top: 7.5rem !important;
  }

  .right-xl-8 {
    right: 7.5rem !important;
  }

  .bottom-xl-8 {
    bottom: 7.5rem !important;
  }

  .left-xl-8 {
    left: 7.5rem !important;
  }

  .top-xl-n8 {
    top: -7.5rem !important;
  }

  .right-xl-n8 {
    right: -7.5rem !important;
  }

  .bottom-xl-n8 {
    bottom: -7.5rem !important;
  }

  .left-xl-n8 {
    left: -7.5rem !important;
  }

  .top-xl-9 {
    top: 9rem !important;
  }

  .right-xl-9 {
    right: 9rem !important;
  }

  .bottom-xl-9 {
    bottom: 9rem !important;
  }

  .left-xl-9 {
    left: 9rem !important;
  }

  .top-xl-n9 {
    top: -9rem !important;
  }

  .right-xl-n9 {
    right: -9rem !important;
  }

  .bottom-xl-n9 {
    bottom: -9rem !important;
  }

  .left-xl-n9 {
    left: -9rem !important;
  }

  .top-xl-10 {
    top: 10.5rem !important;
  }

  .right-xl-10 {
    right: 10.5rem !important;
  }

  .bottom-xl-10 {
    bottom: 10.5rem !important;
  }

  .left-xl-10 {
    left: 10.5rem !important;
  }

  .top-xl-n10 {
    top: -10.5rem !important;
  }

  .right-xl-n10 {
    right: -10.5rem !important;
  }

  .bottom-xl-n10 {
    bottom: -10.5rem !important;
  }

  .left-xl-n10 {
    left: -10.5rem !important;
  }

  .top-xl-17 {
    top: 21rem !important;
  }

  .right-xl-17 {
    right: 21rem !important;
  }

  .bottom-xl-17 {
    bottom: 21rem !important;
  }

  .left-xl-17 {
    left: 21rem !important;
  }

  .top-xl-n17 {
    top: -21rem !important;
  }

  .right-xl-n17 {
    right: -21rem !important;
  }

  .bottom-xl-n17 {
    bottom: -21rem !important;
  }

  .left-xl-n17 {
    left: -21rem !important;
  }
}

.center {
  left: 50%;

  transform: translateX(-50%);
}

.middle {
  top: 50%;

  transform: translateY(-50%);
}

[data-negative-margin].is_stuck {
  margin: 0 !important;
}

[class*="shadow"] {
  transition: all 0.2s ease;
}

.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(31, 45, 61, 0.1),
    0 10px 10px -5px rgba(31, 45, 61, 0.04) !important;
}

.shadow-2xl {
  box-shadow: 0 25px 50px -12px rgba(31, 45, 61, 0.25) !important;
}

.hover-shadow-sm:hover {
  box-shadow: 0 0.125rem 0.25rem rgba(31, 45, 61, 0.08) !important;
}

.hover-shadow:hover {
  box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05) !important;
}

.hover-shadow-lg:hover {
  box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125) !important;
}

.hover-shadow-xl:hover {
  box-shadow: 0 20px 25px -5px rgba(31, 45, 61, 0.1),
    0 10px 10px -5px rgba(31, 45, 61, 0.04) !important;
}

.hover-shadow-2xl:hover {
  box-shadow: 0 25px 50px -12px rgba(31, 45, 61, 0.25) !important;
}

.shadow-none,
.hover-shadow-none:hover {
  box-shadow: none !important;
}

.shadow-primary-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 138, 255, 0.4) !important;
}

.shadow-primary {
  box-shadow: 0 0.5rem 1rem rgba(0, 138, 255, 0.4) !important;
}

.shadow-primary-lg {
  box-shadow: 0 1rem 3rem rgba(0, 138, 255, 0.4) !important;
}

.shadow-secondary-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(234, 236, 243, 0.4) !important;
}

.shadow-secondary {
  box-shadow: 0 0.5rem 1rem rgba(234, 236, 243, 0.4) !important;
}

.shadow-secondary-lg {
  box-shadow: 0 1rem 3rem rgba(234, 236, 243, 0.4) !important;
}

.shadow-success-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(92, 201, 167, 0.4) !important;
}

.shadow-success {
  box-shadow: 0 0.5rem 1rem rgba(92, 201, 167, 0.4) !important;
}

.shadow-success-lg {
  box-shadow: 0 1rem 3rem rgba(92, 201, 167, 0.4) !important;
}

.shadow-info-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(80, 181, 255, 0.4) !important;
}

.shadow-info {
  box-shadow: 0 0.5rem 1rem rgba(80, 181, 255, 0.4) !important;
}

.shadow-info-lg {
  box-shadow: 0 1rem 3rem rgba(80, 181, 255, 0.4) !important;
}

.shadow-warning-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(255, 190, 61, 0.4) !important;
}

.shadow-warning {
  box-shadow: 0 0.5rem 1rem rgba(255, 190, 61, 0.4) !important;
}

.shadow-warning-lg {
  box-shadow: 0 1rem 3rem rgba(255, 190, 61, 0.4) !important;
}

.shadow-danger-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(242, 87, 103, 0.4) !important;
}

.shadow-danger {
  box-shadow: 0 0.5rem 1rem rgba(242, 87, 103, 0.4) !important;
}

.shadow-danger-lg {
  box-shadow: 0 1rem 3rem rgba(242, 87, 103, 0.4) !important;
}

.shadow-light-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(234, 236, 243, 0.4) !important;
}

.shadow-light {
  box-shadow: 0 0.5rem 1rem rgba(234, 236, 243, 0.4) !important;
}

.shadow-light-lg {
  box-shadow: 0 1rem 3rem rgba(234, 236, 243, 0.4) !important;
}

.shadow-dark-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(23, 19, 71, 0.4) !important;
}

.shadow-dark {
  box-shadow: 0 0.5rem 1rem rgba(23, 19, 71, 0.4) !important;
}

.shadow-dark-lg {
  box-shadow: 0 1rem 3rem rgba(23, 19, 71, 0.4) !important;
}

.shadow-neutral-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(255, 255, 255, 0.4) !important;
}

.shadow-neutral {
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.4) !important;
}

.shadow-neutral-lg {
  box-shadow: 0 1rem 3rem rgba(255, 255, 255, 0.4) !important;
}

.shadow-white-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(255, 255, 255, 0.4) !important;
}

.shadow-white {
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.4) !important;
}

.shadow-white-lg {
  box-shadow: 0 1rem 3rem rgba(255, 255, 255, 0.4) !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.font-weight-bolder {
  font-weight: 700 !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-underline--dashed {
  border-bottom: 1px dashed !important;
}

.text-line-through {
  text-decoration: line-through !important;
}

a.text-muted:hover {
  color: #5a677a !important;
}

.text-xs {
  font-size: 0.75rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-lg {
  font-size: 1.25rem !important;
}

.text-xl {
  font-size: 1.5rem !important;
}

.lh-100 {
  line-height: 1 !important;
}

.lh-110 {
  line-height: 1.1 !important;
}

.lh-120 {
  line-height: 1.2 !important;
}

.lh-130 {
  line-height: 1.3 !important;
}

.lh-140 {
  line-height: 1.4 !important;
}

.lh-150 {
  line-height: 1.5 !important;
}

.lh-160 {
  line-height: 1.6 !important;
}

.lh-170 {
  line-height: 1.7 !important;
}

.lh-180 {
  line-height: 1.8 !important;
}

.lh-190 {
  line-height: 1.9 !important;
}

.lh-200 {
  line-height: 2 !important;
}

.ls-1 {
  letter-spacing: 0.0625rem !important;
}

.ls-15 {
  letter-spacing: 0.09375rem !important;
}

.ls-2 {
  letter-spacing: 0.125rem !important;
}

.text-limit {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.zindex-0 {
  z-index: 0 !important;
}

.zindex-100 {
  z-index: 100 !important;
}

.zindex-101 {
  z-index: 101 !important;
}

.zindex-102 {
  z-index: 102 !important;
}

:root {
  --primary-dark: #006ecc;
  --primary-light: #33a1ff;
  --secondary-dark: #cacfe0;
  --secondary-light: white;
  --success-dark: #3cb690;
  --success-light: #82d6bc;
  --info-dark: #1d9fff;
  --info-light: #83cbff;
  --warning-dark: #ffad0a;
  --warning-light: #ffcf70;
  --danger-dark: #ee283c;
  --danger-light: #f68692;
  --light-dark: #cacfe0;
  --light-light: white;
  --dark-dark: #0a081f;
  --dark-light: #241e6f;
  --neutral-dark: #e6e6e6;
  --neutral-light: white;
  --white-dark: #e6e6e6;
  --white-light: white;
}

iframe {
  border: 0;
}

figcaption,
figure,
main {
  display: block;

  margin: 0;
}

figure {
  max-width: 100%;
  min-height: 1px;
}

main {
  position: relative;
}

button:focus {
  outline: none;
}

svg {
  overflow: hidden;

  vertical-align: middle;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .img-fluid {
    width: 100%;
  }
}

.alert {
  font-size: 0.875rem;
}

.alert strong {
  font-weight: 600;
}

.alert hr {
  margin: 0.875rem 0;
}

.alert p:last-child {
  margin-bottom: 0;
}

.alert-heading {
  font-size: 1.5rem;
  font-weight: 600;

  margin-top: 0.15rem;
}

.alert-flush {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.alert-modern {
  display: inline-block;

  padding: 0.275rem 1rem 0.375rem 0.375rem;

  border-radius: 50rem;
}

.alert-modern::after {
  font-family: "Font Awesome 5 Free";
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  font-variant: normal;

  display: inline-block;

  margin-left: 0.5rem;

  content: "\f105";

  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.alert-modern:empty::after {
  margin-left: 0;
}

.alert-modern .badge + .alert-content {
  margin-left: 0.5rem;
}

@media (max-width: 575.98px) {
  .alert-modern {
    text-align: center;
  }

  .alert-modern:after {
    content: "";
  }

  .alert-modern .alert-content {
    display: block;

    margin-top: 0.5rem;
  }
}

.alert-dark {
  color: #fff;
  border: 0;
  background-color: #171347;
}

.alert-dark .alert-link {
  color: #fff;
}

.alert-dark .close > span:not(.sr-only) {
  color: white;
}

.alert-dark .close:hover > span:not(.sr-only),
.alert-dark .close:focus > span:not(.sr-only) {
  color: #fff;
}

.alert-dark hr {
  border-color: rgba(232, 236, 184, 0.1);
}

[class*="alert-outline-"] {
  border-right: 1px solid;
}

[class*="alert-outline-"] .alert-group-prepend {
  padding-right: 0.875rem;
}

.alert-outline-primary {
  color: #0f5da2;
  border-color: #008aff;
  background: transparent;
}

.alert-outline-primary hr {
  border-top-color: #1a96ff;
}

.alert-outline-primary .alert-link {
  color: #0f5da2;
}

.alert-outline-primary .alert-group-prepend {
  border-right: 1px solid #008aff;
}

.alert-outline-secondary {
  color: #89909c;
  border-color: #eaecf3;
  background: transparent;
}

.alert-outline-secondary hr {
  border-top-color: #fafbfc;
}

.alert-outline-secondary .alert-link {
  color: #89909c;
}

.alert-outline-secondary .alert-group-prepend {
  border-right: 1px solid #eaecf3;
}

.alert-outline-success {
  color: #3f7e74;
  border-color: #5cc9a7;
  background: transparent;
}

.alert-outline-success hr {
  border-top-color: #6fcfb1;
}

.alert-outline-success .alert-link {
  color: #3f7e74;
}

.alert-outline-success .alert-group-prepend {
  border-right: 1px solid #5cc9a7;
}

.alert-outline-info {
  color: #3874a2;
  border-color: #50b5ff;
  background: transparent;
}

.alert-outline-info hr {
  border-top-color: #6ac0ff;
}

.alert-outline-info .alert-link {
  color: #3874a2;
}

.alert-outline-info .alert-group-prepend {
  border-right: 1px solid #50b5ff;
}

.alert-outline-warning {
  color: #93783d;
  border-color: #ffbe3d;
  background: transparent;
}

.alert-outline-warning hr {
  border-top-color: #ffc757;
}

.alert-outline-warning .alert-link {
  color: #93783d;
}

.alert-outline-warning .alert-group-prepend {
  border-right: 1px solid #ffbe3d;
}

.alert-outline-danger {
  color: #8d4353;
  border-color: #f25767;
  background: transparent;
}

.alert-outline-danger hr {
  border-top-color: #f46f7c;
}

.alert-outline-danger .alert-link {
  color: #8d4353;
}

.alert-outline-danger .alert-group-prepend {
  border-right: 1px solid #f25767;
}

.alert-outline-light {
  color: #89909c;
  border-color: #eaecf3;
  background: transparent;
}

.alert-outline-light hr {
  border-top-color: #fafbfc;
}

.alert-outline-light .alert-link {
  color: #89909c;
}

.alert-outline-light .alert-group-prepend {
  border-right: 1px solid #eaecf3;
}

.alert-outline-dark {
  color: #1b1f42;
  border-color: #171347;
  background: transparent;
}

.alert-outline-dark hr {
  border-top-color: #1e185b;
}

.alert-outline-dark .alert-link {
  color: #1b1f42;
}

.alert-outline-dark .alert-group-prepend {
  border-right: 1px solid #171347;
}

.alert-outline-neutral {
  color: #939aa2;
  border-color: white;
  background: transparent;
}

.alert-outline-neutral hr {
  border-top-color: white;
}

.alert-outline-neutral .alert-link {
  color: #939aa2;
}

.alert-outline-neutral .alert-group-prepend {
  border-right: 1px solid white;
}

.alert-outline-white {
  color: #939aa2;
  border-color: white;
  background: transparent;
}

.alert-outline-white hr {
  border-top-color: white;
}

.alert-outline-white .alert-link {
  color: #939aa2;
}

.alert-outline-white .alert-group-prepend {
  border-right: 1px solid white;
}

.avatar {
  font-size: 1rem;
  font-weight: 600;

  position: relative;

  display: inline-flex;

  width: 3.125rem;
  height: 3.125rem;

  vertical-align: middle;

  color: #fff;
  border-radius: 0.375rem;

  align-items: center;
  justify-content: center;
}

.avatar img {
  width: 100%;

  border-radius: 0.375rem;
}

.avatar.rounded-circle img {
  border-radius: 50%;
}

.avatar span {
  background-color: #008aff;
}

.avatar + .avatar {
  margin-left: 0.25rem;
}

.avatar + .avatar-content {
  display: inline-block;

  margin-left: 0.75rem;
}

.avatar-2xl {
  font-size: 1.375rem;

  width: 7.5rem;
  height: 7.5rem;
}

.avatar-xl {
  font-size: 1.375rem;

  width: 6rem;
  height: 6rem;
}

.avatar-lg {
  font-size: 1.25rem;

  width: 4rem;
  height: 4rem;
}

.avatar-sm {
  font-size: 0.75rem;

  width: 2.4375rem;
  height: 2.4375rem;

  border-radius: 0.2rem;
}

.avatar-xs {
  font-size: 0.675rem;

  width: 1.5rem;
  height: 1.5rem;

  border-radius: 0.2rem;
}

.badge {
  display: inline;

  text-transform: none;
}

.badge i:not(:last-child),
.badge svg {
  margin-right: 0.5rem;
}

.badge-group .badge {
  display: inline-block;

  margin-top: 0.125rem;
  margin-right: 0.25rem;
  margin-bottom: 0.125rem;
}

.badge-xs {
  font-size: 60%;

  padding: 0.25rem 0.5rem;
}

.badge-md {
  padding: 0.65em 1em;
}

.badge-lg {
  padding: 0.85em 1.375em;
}

.badge-inline {
  margin-right: 0.625rem;
}

.badge-inline + span {
  position: relative;
  top: 2px;
}

.badge-inline + span > a {
  text-decoration: underline;
}

.btn .badge:not(:first-child) {
  margin-left: 0.5rem;
}

.btn .badge:not(:last-child) {
  margin-right: 0.5rem;
}

.badge-soft-primary {
  color: #008aff;
  background-color: #cce8ff;
}

.badge-soft-primary[href]:hover,
.badge-soft-primary[href]:focus {
  text-decoration: none;

  color: #008aff;
  background-color: #b3ddff;
}

.badge-soft-secondary {
  color: #eaecf3;
  background-color: #fbfbfd;
}

.badge-soft-secondary[href]:hover,
.badge-soft-secondary[href]:focus {
  text-decoration: none;

  color: #eaecf3;
  background-color: #eaeaf5;
}

.badge-soft-success {
  color: #5cc9a7;
  background-color: #def4ed;
}

.badge-soft-success[href]:hover,
.badge-soft-success[href]:focus {
  text-decoration: none;

  color: #5cc9a7;
  background-color: #cbeee3;
}

.badge-soft-info {
  color: #50b5ff;
  background-color: #dcf0ff;
}

.badge-soft-info[href]:hover,
.badge-soft-info[href]:focus {
  text-decoration: none;

  color: #50b5ff;
  background-color: #c3e5ff;
}

.badge-soft-warning {
  color: #ffbe3d;
  background-color: #fff2d8;
}

.badge-soft-warning[href]:hover,
.badge-soft-warning[href]:focus {
  text-decoration: none;

  color: #ffbe3d;
  background-color: #ffeabf;
}

.badge-soft-danger {
  color: #f25767;
  background-color: #fcdde1;
}

.badge-soft-danger[href]:hover,
.badge-soft-danger[href]:focus {
  text-decoration: none;

  color: #f25767;
  background-color: #fac6cc;
}

.badge-soft-light {
  color: #eaecf3;
  background-color: #fbfbfd;
}

.badge-soft-light[href]:hover,
.badge-soft-light[href]:focus {
  text-decoration: none;

  color: #eaecf3;
  background-color: #eaeaf5;
}

.badge-soft-dark {
  color: #171347;
  background-color: #d1d0da;
}

.badge-soft-dark[href]:hover,
.badge-soft-dark[href]:focus {
  text-decoration: none;

  color: #171347;
  background-color: #c3c2cf;
}

.badge-soft-neutral {
  color: #fff;
  background-color: white;
}

.badge-soft-neutral[href]:hover,
.badge-soft-neutral[href]:focus {
  text-decoration: none;

  color: #fff;
  background-color: #f2f2f2;
}

.badge-soft-white {
  color: #fff;
  background-color: white;
}

.badge-soft-white[href]:hover,
.badge-soft-white[href]:focus {
  text-decoration: none;

  color: #fff;
  background-color: #f2f2f2;
}

.breadcrumb {
  border: 1px solid #e2e8f0;
}

.breadcrumb-sm {
  font-size: 0.875rem;
}

.breadcrumb-links {
  border: 0;
  border-radius: 0;
  background-color: transparent;
}

.breadcrumb-links .breadcrumb-item + .breadcrumb-item::before {
  width: 0.3rem;
  height: 0.6rem;
  margin-right: 0.5rem;

  content: "";

  background: #cbd5e0;

  mask-size: contain;
}

.breadcrumb-dark .breadcrumb-item a {
  color: rgba(255, 255, 255, 0.8);
}

.breadcrumb-dark .breadcrumb-item a:hover {
  color: white;
}

.breadcrumb-dark .breadcrumb-item.active {
  color: rgba(255, 255, 255, 0.6);
}

.breadcrumb-dark .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.8);
}

.breadcrumb-light .breadcrumb-item a {
  color: rgba(23, 19, 71, 0.8);
}

.breadcrumb-light .breadcrumb-item a:hover {
  color: #171347;
}

.breadcrumb-light .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(23, 19, 71, 0.8);
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn + .btn {
  margin-left: 0.5rem;
}

.btn-group > .btn {
  margin: 0;
}

.btn-neutral {
  color: #273444;
  border: 2px solid #e2e8f0;
  background-color: transparent;
}

.btn-neutral:hover {
  border-color: #4dadff;
  background-color: transparent;
}

.btn-neutral:not(:disabled):not(.disabled):focus,
.btn-neutral:not(:disabled):not(.disabled):active {
  color: #008aff;
  border-color: #4dadff;
  background-color: transparent;
}

.btn-neutral:not(:disabled):not(.disabled).active {
  color: #008aff;
  border-color: #008aff;
  background-color: #fff;
}

.btn-xs {
  font-size: 0.75rem;
  line-height: 1.5;

  padding: 0.375rem 1rem;

  border-radius: 0.375rem;
}

.btn-xl {
  font-size: 1.5rem;
  line-height: 1.5;

  padding: 1.5rem 2rem;

  border-radius: 0.75rem;
}

@media (max-width: 1200px) {
  .btn-xl {
    font-size: calc(1.275rem + 0.3vw);
  }
}

.btn-nobg {
  padding: 0;

  background: transparent !important;
}

.btn-nobg:hover {
  border-color: transparent !important;
  background: transparent !important;
}

.btn-nobg--hover:hover {
  background-color: transparent;
}

.btn-soft-primary {
  color: #008aff;
  border-color: #cce8ff;
  background-color: #cce8ff;
  box-shadow: none;
}

.btn-soft-primary:hover {
  color: #fff;
  border-color: #008aff;
  background-color: #008aff;
  box-shadow: 0 4px 11px rgba(204, 232, 255, 0.35);
}

.btn-soft-primary:focus,
.btn-soft-primary.focus {
  box-shadow: none, 0 0 0 0 rgba(6, 125, 227, 0.5);
}

.btn-soft-primary.disabled,
.btn-soft-primary:disabled {
  color: #008aff;
  border-color: #cce8ff;
  background-color: #cce8ff;
}

.btn-soft-primary:not(:disabled):not(.disabled):active,
.btn-soft-primary:not(:disabled):not(.disabled).active,
.show > .btn-soft-primary.dropdown-toggle {
  color: #fff;
  border-color: #008aff;
  background-color: #008aff;
}

.btn-soft-primary:not(:disabled):not(.disabled):active:focus,
.btn-soft-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-soft-primary.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(6, 125, 227, 0.5);
}

.btn-soft-secondary {
  color: #eaecf3;
  border-color: #fbfbfd;
  background-color: #fbfbfd;
  box-shadow: none;
}

.btn-soft-secondary:hover {
  color: #273444;
  border-color: #eaecf3;
  background-color: #eaecf3;
  box-shadow: 0 4px 11px rgba(251, 251, 253, 0.35);
}

.btn-soft-secondary:focus,
.btn-soft-secondary.focus {
  box-shadow: none, 0 0 0 0 rgba(205, 208, 217, 0.5);
}

.btn-soft-secondary.disabled,
.btn-soft-secondary:disabled {
  color: #eaecf3;
  border-color: #fbfbfd;
  background-color: #fbfbfd;
}

.btn-soft-secondary:not(:disabled):not(.disabled):active,
.btn-soft-secondary:not(:disabled):not(.disabled).active,
.show > .btn-soft-secondary.dropdown-toggle {
  color: #273444;
  border-color: #eaecf3;
  background-color: #eaecf3;
}

.btn-soft-secondary:not(:disabled):not(.disabled):active:focus,
.btn-soft-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-soft-secondary.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(205, 208, 217, 0.5);
}

.btn-soft-success {
  color: #5cc9a7;
  border-color: #def4ed;
  background-color: #def4ed;
  box-shadow: none;
}

.btn-soft-success:hover {
  color: #fff;
  border-color: #5cc9a7;
  background-color: #5cc9a7;
  box-shadow: 0 4px 11px rgba(222, 244, 237, 0.35);
}

.btn-soft-success:focus,
.btn-soft-success.focus {
  box-shadow: none, 0 0 0 0 rgba(84, 179, 152, 0.5);
}

.btn-soft-success.disabled,
.btn-soft-success:disabled {
  color: #5cc9a7;
  border-color: #def4ed;
  background-color: #def4ed;
}

.btn-soft-success:not(:disabled):not(.disabled):active,
.btn-soft-success:not(:disabled):not(.disabled).active,
.show > .btn-soft-success.dropdown-toggle {
  color: #fff;
  border-color: #5cc9a7;
  background-color: #5cc9a7;
}

.btn-soft-success:not(:disabled):not(.disabled):active:focus,
.btn-soft-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-soft-success.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(84, 179, 152, 0.5);
}

.btn-soft-info {
  color: #50b5ff;
  border-color: #dcf0ff;
  background-color: #dcf0ff;
  box-shadow: none;
}

.btn-soft-info:hover {
  color: #fff;
  border-color: #50b5ff;
  background-color: #50b5ff;
  box-shadow: 0 4px 11px rgba(220, 240, 255, 0.35);
}

.btn-soft-info:focus,
.btn-soft-info.focus {
  box-shadow: none, 0 0 0 0 rgba(74, 162, 227, 0.5);
}

.btn-soft-info.disabled,
.btn-soft-info:disabled {
  color: #50b5ff;
  border-color: #dcf0ff;
  background-color: #dcf0ff;
}

.btn-soft-info:not(:disabled):not(.disabled):active,
.btn-soft-info:not(:disabled):not(.disabled).active,
.show > .btn-soft-info.dropdown-toggle {
  color: #fff;
  border-color: #50b5ff;
  background-color: #50b5ff;
}

.btn-soft-info:not(:disabled):not(.disabled):active:focus,
.btn-soft-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-soft-info.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(74, 162, 227, 0.5);
}

.btn-soft-warning {
  color: #ffbe3d;
  border-color: #fff2d8;
  background-color: #fff2d8;
  box-shadow: none;
}

.btn-soft-warning:hover {
  color: #fff;
  border-color: #ffbe3d;
  background-color: #ffbe3d;
  box-shadow: 0 4px 11px rgba(255, 242, 216, 0.35);
}

.btn-soft-warning:focus,
.btn-soft-warning.focus {
  box-shadow: none, 0 0 0 0 rgba(223, 169, 62, 0.5);
}

.btn-soft-warning.disabled,
.btn-soft-warning:disabled {
  color: #ffbe3d;
  border-color: #fff2d8;
  background-color: #fff2d8;
}

.btn-soft-warning:not(:disabled):not(.disabled):active,
.btn-soft-warning:not(:disabled):not(.disabled).active,
.show > .btn-soft-warning.dropdown-toggle {
  color: #fff;
  border-color: #ffbe3d;
  background-color: #ffbe3d;
}

.btn-soft-warning:not(:disabled):not(.disabled):active:focus,
.btn-soft-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-soft-warning.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(223, 169, 62, 0.5);
}

.btn-soft-danger {
  color: #f25767;
  border-color: #fcdde1;
  background-color: #fcdde1;
  box-shadow: none;
}

.btn-soft-danger:hover {
  color: #fff;
  border-color: #f25767;
  background-color: #f25767;
  box-shadow: 0 4px 11px rgba(252, 221, 225, 0.35);
}

.btn-soft-danger:focus,
.btn-soft-danger.focus {
  box-shadow: none, 0 0 0 0 rgba(212, 82, 98, 0.5);
}

.btn-soft-danger.disabled,
.btn-soft-danger:disabled {
  color: #f25767;
  border-color: #fcdde1;
  background-color: #fcdde1;
}

.btn-soft-danger:not(:disabled):not(.disabled):active,
.btn-soft-danger:not(:disabled):not(.disabled).active,
.show > .btn-soft-danger.dropdown-toggle {
  color: #fff;
  border-color: #f25767;
  background-color: #f25767;
}

.btn-soft-danger:not(:disabled):not(.disabled):active:focus,
.btn-soft-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-soft-danger.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(212, 82, 98, 0.5);
}

.btn-soft-light {
  color: #eaecf3;
  border-color: #fbfbfd;
  background-color: #fbfbfd;
  box-shadow: none;
}

.btn-soft-light:hover {
  color: #273444;
  border-color: #eaecf3;
  background-color: #eaecf3;
  box-shadow: 0 4px 11px rgba(251, 251, 253, 0.35);
}

.btn-soft-light:focus,
.btn-soft-light.focus {
  box-shadow: none, 0 0 0 0 rgba(205, 208, 217, 0.5);
}

.btn-soft-light.disabled,
.btn-soft-light:disabled {
  color: #eaecf3;
  border-color: #fbfbfd;
  background-color: #fbfbfd;
}

.btn-soft-light:not(:disabled):not(.disabled):active,
.btn-soft-light:not(:disabled):not(.disabled).active,
.show > .btn-soft-light.dropdown-toggle {
  color: #273444;
  border-color: #eaecf3;
  background-color: #eaecf3;
}

.btn-soft-light:not(:disabled):not(.disabled):active:focus,
.btn-soft-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-soft-light.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(205, 208, 217, 0.5);
}

.btn-soft-dark {
  color: #171347;
  border-color: #d1d0da;
  background-color: #d1d0da;
  box-shadow: none;
}

.btn-soft-dark:hover {
  color: #fff;
  border-color: #171347;
  background-color: #171347;
  box-shadow: 0 4px 11px rgba(209, 208, 218, 0.35);
}

.btn-soft-dark:focus,
.btn-soft-dark.focus {
  box-shadow: none, 0 0 0 0 rgba(25, 24, 71, 0.5);
}

.btn-soft-dark.disabled,
.btn-soft-dark:disabled {
  color: #171347;
  border-color: #d1d0da;
  background-color: #d1d0da;
}

.btn-soft-dark:not(:disabled):not(.disabled):active,
.btn-soft-dark:not(:disabled):not(.disabled).active,
.show > .btn-soft-dark.dropdown-toggle {
  color: #fff;
  border-color: #171347;
  background-color: #171347;
}

.btn-soft-dark:not(:disabled):not(.disabled):active:focus,
.btn-soft-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-soft-dark.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(25, 24, 71, 0.5);
}

.btn-soft-neutral {
  color: #fff;
  border-color: white;
  background-color: white;
  box-shadow: none;
}

.btn-soft-neutral:hover {
  color: #273444;
  border-color: #fff;
  background-color: #fff;
  box-shadow: 0 4px 11px rgba(255, 255, 255, 0.35);
}

.btn-soft-neutral:focus,
.btn-soft-neutral.focus {
  box-shadow: none, 0 0 0 0 rgba(223, 225, 227, 0.5);
}

.btn-soft-neutral.disabled,
.btn-soft-neutral:disabled {
  color: #fff;
  border-color: white;
  background-color: white;
}

.btn-soft-neutral:not(:disabled):not(.disabled):active,
.btn-soft-neutral:not(:disabled):not(.disabled).active,
.show > .btn-soft-neutral.dropdown-toggle {
  color: #273444;
  border-color: #fff;
  background-color: #fff;
}

.btn-soft-neutral:not(:disabled):not(.disabled):active:focus,
.btn-soft-neutral:not(:disabled):not(.disabled).active:focus,
.show > .btn-soft-neutral.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(223, 225, 227, 0.5);
}

.btn-soft-white {
  color: #fff;
  border-color: white;
  background-color: white;
  box-shadow: none;
}

.btn-soft-white:hover {
  color: #273444;
  border-color: #fff;
  background-color: #fff;
  box-shadow: 0 4px 11px rgba(255, 255, 255, 0.35);
}

.btn-soft-white:focus,
.btn-soft-white.focus {
  box-shadow: none, 0 0 0 0 rgba(223, 225, 227, 0.5);
}

.btn-soft-white.disabled,
.btn-soft-white:disabled {
  color: #fff;
  border-color: white;
  background-color: white;
}

.btn-soft-white:not(:disabled):not(.disabled):active,
.btn-soft-white:not(:disabled):not(.disabled).active,
.show > .btn-soft-white.dropdown-toggle {
  color: #273444;
  border-color: #fff;
  background-color: #fff;
}

.btn-soft-white:not(:disabled):not(.disabled):active:focus,
.btn-soft-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-soft-white.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(223, 225, 227, 0.5);
}

.btn-facebook {
  color: #fff;
  border-color: #3b5999;
  background-color: #3b5999;
  box-shadow: none;
}

.btn-facebook:hover {
  color: #fff;
  border-color: #2d4474;
  background-color: #30497d;
}

.btn-facebook:focus,
.btn-facebook.focus {
  box-shadow: none, 0 0 0 rgba(59, 89, 153, 0.35);
}

.btn-facebook.disabled,
.btn-facebook:disabled {
  color: #fff;
  border-color: #3b5999;
  background-color: #3b5999;
}

.btn-facebook:not(:disabled):not(.disabled):active,
.btn-facebook:not(:disabled):not(.disabled).active,
.show > .btn-facebook.dropdown-toggle {
  color: #fff;
  border-color: #293e6b;
  background-color: #2d4474;
}

.btn-facebook:not(:disabled):not(.disabled):active:focus,
.btn-facebook:not(:disabled):not(.disabled).active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(59, 89, 153, 0.35);
}

.btn-twitter {
  color: #fff;
  border-color: #1da1f2;
  background-color: #1da1f2;
  box-shadow: none;
}

.btn-twitter:hover {
  color: #fff;
  border-color: #0c85d0;
  background-color: #0d8ddc;
}

.btn-twitter:focus,
.btn-twitter.focus {
  box-shadow: none, 0 0 0 rgba(29, 161, 242, 0.35);
}

.btn-twitter.disabled,
.btn-twitter:disabled {
  color: #fff;
  border-color: #1da1f2;
  background-color: #1da1f2;
}

.btn-twitter:not(:disabled):not(.disabled):active,
.btn-twitter:not(:disabled):not(.disabled).active,
.show > .btn-twitter.dropdown-toggle {
  color: #fff;
  border-color: #0b7ec4;
  background-color: #0c85d0;
}

.btn-twitter:not(:disabled):not(.disabled):active:focus,
.btn-twitter:not(:disabled):not(.disabled).active:focus,
.show > .btn-twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(29, 161, 242, 0.35);
}

.btn-google-plus {
  color: #fff;
  border-color: #dd4b39;
  background-color: #dd4b39;
  box-shadow: none;
}

.btn-google-plus:hover {
  color: #fff;
  border-color: #c23321;
  background-color: #cd3623;
}

.btn-google-plus:focus,
.btn-google-plus.focus {
  box-shadow: none, 0 0 0 rgba(221, 75, 57, 0.35);
}

.btn-google-plus.disabled,
.btn-google-plus:disabled {
  color: #fff;
  border-color: #dd4b39;
  background-color: #dd4b39;
}

.btn-google-plus:not(:disabled):not(.disabled):active,
.btn-google-plus:not(:disabled):not(.disabled).active,
.show > .btn-google-plus.dropdown-toggle {
  color: #fff;
  border-color: #b7301f;
  background-color: #c23321;
}

.btn-google-plus:not(:disabled):not(.disabled):active:focus,
.btn-google-plus:not(:disabled):not(.disabled).active:focus,
.show > .btn-google-plus.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(221, 75, 57, 0.35);
}

.btn-instagram {
  color: #fff;
  border-color: #e4405f;
  background-color: #e4405f;
  box-shadow: none;
}

.btn-instagram:hover {
  color: #fff;
  border-color: #d31e40;
  background-color: #de1f44;
}

.btn-instagram:focus,
.btn-instagram.focus {
  box-shadow: none, 0 0 0 rgba(228, 64, 95, 0.35);
}

.btn-instagram.disabled,
.btn-instagram:disabled {
  color: #fff;
  border-color: #e4405f;
  background-color: #e4405f;
}

.btn-instagram:not(:disabled):not(.disabled):active,
.btn-instagram:not(:disabled):not(.disabled).active,
.show > .btn-instagram.dropdown-toggle {
  color: #fff;
  border-color: #c81c3d;
  background-color: #d31e40;
}

.btn-instagram:not(:disabled):not(.disabled):active:focus,
.btn-instagram:not(:disabled):not(.disabled).active:focus,
.show > .btn-instagram.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(228, 64, 95, 0.35);
}

.btn-pinterest {
  color: #fff;
  border-color: #bd081c;
  background-color: #bd081c;
  box-shadow: none;
}

.btn-pinterest:hover {
  color: #fff;
  border-color: #8c0615;
  background-color: #980617;
}

.btn-pinterest:focus,
.btn-pinterest.focus {
  box-shadow: none, 0 0 0 rgba(189, 8, 28, 0.35);
}

.btn-pinterest.disabled,
.btn-pinterest:disabled {
  color: #fff;
  border-color: #bd081c;
  background-color: #bd081c;
}

.btn-pinterest:not(:disabled):not(.disabled):active,
.btn-pinterest:not(:disabled):not(.disabled).active,
.show > .btn-pinterest.dropdown-toggle {
  color: #fff;
  border-color: #800513;
  background-color: #8c0615;
}

.btn-pinterest:not(:disabled):not(.disabled):active:focus,
.btn-pinterest:not(:disabled):not(.disabled).active:focus,
.show > .btn-pinterest.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(189, 8, 28, 0.35);
}

.btn-youtube {
  color: #fff;
  border-color: #cd201f;
  background-color: #cd201f;
  box-shadow: none;
}

.btn-youtube:hover {
  color: #fff;
  border-color: #a11918;
  background-color: #ac1b1a;
}

.btn-youtube:focus,
.btn-youtube.focus {
  box-shadow: none, 0 0 0 rgba(205, 32, 31, 0.35);
}

.btn-youtube.disabled,
.btn-youtube:disabled {
  color: #fff;
  border-color: #cd201f;
  background-color: #cd201f;
}

.btn-youtube:not(:disabled):not(.disabled):active,
.btn-youtube:not(:disabled):not(.disabled).active,
.show > .btn-youtube.dropdown-toggle {
  color: #fff;
  border-color: #961717;
  background-color: #a11918;
}

.btn-youtube:not(:disabled):not(.disabled):active:focus,
.btn-youtube:not(:disabled):not(.disabled).active:focus,
.show > .btn-youtube.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(205, 32, 31, 0.35);
}

.btn-slack {
  color: #fff;
  border-color: #3aaf85;
  background-color: #3aaf85;
  box-shadow: none;
}

.btn-slack:hover {
  color: #fff;
  border-color: #2d8968;
  background-color: #30926f;
}

.btn-slack:focus,
.btn-slack.focus {
  box-shadow: none, 0 0 0 rgba(58, 175, 133, 0.35);
}

.btn-slack.disabled,
.btn-slack:disabled {
  color: #fff;
  border-color: #3aaf85;
  background-color: #3aaf85;
}

.btn-slack:not(:disabled):not(.disabled):active,
.btn-slack:not(:disabled):not(.disabled).active,
.show > .btn-slack.dropdown-toggle {
  color: #fff;
  border-color: #2a7f61;
  background-color: #2d8968;
}

.btn-slack:not(:disabled):not(.disabled):active:focus,
.btn-slack:not(:disabled):not(.disabled).active:focus,
.show > .btn-slack.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(58, 175, 133, 0.35);
}

.btn-dribbble {
  color: #fff;
  border-color: #ea4c89;
  background-color: #ea4c89;
  box-shadow: none;
}

.btn-dribbble:hover {
  color: #fff;
  border-color: #e51e6b;
  background-color: #e62a72;
}

.btn-dribbble:focus,
.btn-dribbble.focus {
  box-shadow: none, 0 0 0 rgba(234, 76, 137, 0.35);
}

.btn-dribbble.disabled,
.btn-dribbble:disabled {
  color: #fff;
  border-color: #ea4c89;
  background-color: #ea4c89;
}

.btn-dribbble:not(:disabled):not(.disabled):active,
.btn-dribbble:not(:disabled):not(.disabled).active,
.show > .btn-dribbble.dropdown-toggle {
  color: #fff;
  border-color: #dc1a65;
  background-color: #e51e6b;
}

.btn-dribbble:not(:disabled):not(.disabled):active:focus,
.btn-dribbble:not(:disabled):not(.disabled).active:focus,
.show > .btn-dribbble.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(234, 76, 137, 0.35);
}

.btn-github {
  color: #fff;
  border-color: #222;
  background-color: #222;
  box-shadow: none;
}

.btn-github:hover {
  color: #fff;
  border-color: #090909;
  background-color: #0f0f0f;
}

.btn-github:focus,
.btn-github.focus {
  box-shadow: none, 0 0 0 rgba(34, 34, 34, 0.35);
}

.btn-github.disabled,
.btn-github:disabled {
  color: #fff;
  border-color: #222;
  background-color: #222;
}

.btn-github:not(:disabled):not(.disabled):active,
.btn-github:not(:disabled):not(.disabled).active,
.show > .btn-github.dropdown-toggle {
  color: #fff;
  border-color: #020202;
  background-color: #090909;
}

.btn-github:not(:disabled):not(.disabled):active:focus,
.btn-github:not(:disabled):not(.disabled).active:focus,
.show > .btn-github.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(34, 34, 34, 0.35);
}

.btn-vimeo {
  color: #fff;
  border-color: #04a0f0;
  background-color: #04a0f0;
  box-shadow: none;
}

.btn-vimeo:hover {
  color: #fff;
  border-color: #037fbe;
  background-color: #0387ca;
}

.btn-vimeo:focus,
.btn-vimeo.focus {
  box-shadow: none, 0 0 0 rgba(4, 160, 240, 0.35);
}

.btn-vimeo.disabled,
.btn-vimeo:disabled {
  color: #fff;
  border-color: #04a0f0;
  background-color: #04a0f0;
}

.btn-vimeo:not(:disabled):not(.disabled):active,
.btn-vimeo:not(:disabled):not(.disabled).active,
.show > .btn-vimeo.dropdown-toggle {
  color: #fff;
  border-color: #0376b1;
  background-color: #037fbe;
}

.btn-vimeo:not(:disabled):not(.disabled):active:focus,
.btn-vimeo:not(:disabled):not(.disabled).active:focus,
.show > .btn-vimeo.dropdown-toggle:focus {
  box-shadow: 0 0 0 rgba(4, 160, 240, 0.35);
}

.btn svg:not(:first-child),
.btn i:not(:first-child) {
  margin-left: 0.5rem;
}

.btn svg:not(:last-child),
.btn i:not(:last-child) {
  margin-right: 0.5rem;
}

.btn-icon .btn-inner--text:not(:first-child) {
  margin-left: 0.75em;
}

.btn-icon .btn-inner--text:not(:last-child) {
  margin-right: 0.75em;
}

.btn-icon .btn-inner--icon img {
  width: 20px;
}

.btn-icon-only {
  display: inline-flex;

  width: 3.125rem;
  height: 3.125rem;
  padding: 0;

  align-items: center;
  justify-content: center;
}

.btn-icon-only.btn-xs {
  width: 2rem;
  height: 2rem;
}

.btn-icon-only.btn-sm,
.btn-group-sm > .btn-icon-only.btn {
  width: 2.4375rem;
  height: 2.4375rem;
}

.btn-icon-only.btn-lg,
.btn-group-lg > .btn-icon-only.btn {
  width: 4rem;
  height: 4rem;
}

.card {
  position: relative;
  margin-bottom: 30px;
  border: 1px #fff solid;
}

.card.hover-scale-110:hover {
  z-index: 2;

  border-radius: 0.75rem !important;
}

.card.rounded-top-right {
  border-radius: 0.75rem 5rem 5rem 0.75rem 0.75rem !important;
}

.card.rounded-top-left {
  border-radius: 5rem 0.75rem 0.75rem 0.75rem !important;
}

.card.rounded-bottom-right {
  border-radius: 0.75rem 0.75rem 5rem 0.75rem !important;
}

.card.rounded-bottom-left {
  border-radius: 0.75rem 0.75rem 0.75rem 5rem !important;
}

.card.rounded-diagonal-left {
  border-radius: 5rem 0.75rem 5rem 0.75rem !important;
}

.card.rounded-diagonal-right {
  border-radius: 0.75rem 5rem 0.75rem 5rem !important;
}

.card-fluid {
  height: calc(100% - 30px);
}

.card-header {
  padding-bottom: 0;

  border-bottom: 0;
}

.card-header .action-item {
  padding-top: 0;
  padding-bottom: 0;
}

.card-btn {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.card-deck + .row {
  margin-top: 30px;
}

.card-wrapper .card:last-child {
  margin-bottom: 0 !important;
}

.card-img-bg {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  transition: all 0.2s ease;

  border-radius: 0.75rem;
  background-size: cover;

  -webkit-backface-visibility: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .card-img-bg {
    transition: none;
  }
}

.card-img-bg ~ .card-body {
  position: relative;
}

.card:hover .card-img-bg {
  transform: scale(1.1);
}

.card .card-image,
.card .animate-this,
.card > a {
  min-height: 1px;
}

.accordion .card {
  margin-bottom: 0;
}

.accordion .card-header {
  position: relative;

  cursor: pointer;
}

.accordion .card-header:after {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;

  position: absolute;
  top: 50%;
  right: 1.5rem;

  content: "+";
  transform: translateY(-50%);
}

.accordion .card-header[aria-expanded="false"]:after {
  content: "+";
}

.accordion .card-header[aria-expanded="true"]:after {
  content: "-";
}

.accordion .card-header[aria-expanded="true"] .heading {
  color: #008aff;
}

.accordion-spaced .card {
  margin-bottom: 1.5rem;

  border-radius: 0.75rem !important;
}

.close > span:not(.sr-only) {
  color: rgba(31, 45, 61, 0.5);
}

.close:hover > span:not(.sr-only),
.close:focus > span:not(.sr-only) {
  color: rgba(31, 45, 61, 0.8);
}

.custom-control-label {
  line-height: 1.8;
}

.checklist .custom-control-input:checked ~ .custom-control-label {
  text-decoration: line-through;
}

.checklist .card {
  margin-bottom: 0.5rem;
}

.checklist .card:last-child {
  margin-bottom: 0;
}

.dropdown,
.dropup,
.dropright,
.dropleft {
  display: inline-block;
}

.dropdown-menu {
  font-size: 0.875rem;
}

.dropdown-menu .dropdown-item > i,
.dropdown-menu .dropdown-item > svg {
  font-size: 1rem;

  margin-right: 1rem;
}

.dropdown-menu .dropdown-item img,
.dropdown-menu .dropdown-item svg {
  position: relative;
  top: -1px;

  height: 14px;
  margin-right: 0.5rem;
}

.dropdown-menu .dropdown-item .icon-flag {
  width: 19px;
}

.dropdown-menu .list-group-item .media p {
  line-height: 1.4;

  color: #4a5568;
}

.dropdown-menu .list-group-item .media:hover .heading {
  color: #008aff;
}

.dropdown-menu-dark {
  border: 0;
  background: #282f37;
}

.dropdown-menu-dark .dropdown-item {
  color: rgba(255, 255, 255, 0.9);
}

.dropdown-menu-dark .dropdown-item:active,
.dropdown-menu-dark .dropdown-item:focus,
.dropdown-menu-dark .dropdown-item:hover {
  color: white;
  background: rgba(45, 55, 72, 0.1);
}

.dropdown-menu-dark .dropdown-divider {
  background: rgba(31, 45, 61, 0.2);
}

@media (min-width: 992px) {
  .dropdown-menu-arrow:before {
    position: absolute;
    z-index: -5;
    bottom: 100%;
    left: 1.25rem;

    display: block;

    width: 16px;
    height: 16px;

    content: "";
    transform: rotate(-45deg) translateY(1rem);

    border-radius: 0.2rem;
    background: #fff;
    box-shadow: none;
  }

  .dropdown-menu-arrow[x-placement="bottom-end"]:before {
    bottom: 100%;

    transform: rotate(-45deg) translateY(1rem);
  }

  .dropdown-menu-arrow[x-placement="top-end"]:before {
    bottom: 0;

    transform: rotate(-45deg) translateY(0.5rem);
  }

  .dropdown-menu-arrow.dropdown-menu-right:before {
    right: 2.5rem;
    left: auto;
  }

  .dropdown-menu-sm {
    min-width: 10rem !important;

    border: 0.2rem;
  }

  .dropdown-menu-sm.dropdown-menu-arrow.dropdown-menu-right:before {
    right: 2rem;
    left: auto;
  }

  .dropdown-menu-md {
    min-width: 22rem !important;

    border-radius: 0.375rem;
  }

  .dropdown-menu-lg {
    min-width: 32rem !important;

    border-radius: 0.375rem;
  }

  .dropdown-menu-xl {
    min-width: 46rem !important;

    border-radius: 0.375rem;
  }
}

@media (min-width: 992px) {
  .dropdown-fluid {
    position: static;
  }

  .dropdown-fluid .dropdown-menu {
    width: calc(100% - 30px);
    max-width: 1200px;
  }

  .dropdown-fluid .dropdown-menu {
    right: auto;
    left: 15px;
  }

  .dropdown-fluid .dropdown-menu-right {
    right: 15px;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-animate > .dropdown-menu {
    position: absolute;

    display: block;

    pointer-events: none;

    opacity: 0;
  }

  .dropdown-animate:not([data-toggle="hover"]) .dropdown-menu.show {
    animation: show-dropdown 0.3s ease forwards;
    pointer-events: auto;
  }

  .dropdown-animate:not([data-toggle="hover"]) .dropdown-menu.hide {
    display: block;

    animation: hide-dropdown 0.3s ease backwards;
  }

  .dropdown-animate[data-toggle="hover"] > .dropdown-menu {
    left: 50%;

    display: block;

    margin: 0;

    transition: all 0.2s ease;
    transform: translateX(-50%) translateY(-3px) scale(0.97);
  }
}

@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .dropdown-animate[data-toggle="hover"] > .dropdown-menu {
    transition: none;
  }
}

@media (min-width: 992px) {
  .dropdown-animate[data-toggle="hover"]:hover > .dropdown-menu {
    display: block;
    visibility: visible;

    transform: translateX(-50%) translateY(-3px) scale(1);
    pointer-events: auto;

    opacity: 1;
  }
}

@keyframes show-dropdown {
  0% {
    transition: all 0.3s ease;
    transform: translateX(0) translateY(-3px) scale(0.97);

    opacity: 0;
  }

  100% {
    transform: translateX(0) translateY(-3px) scale(1);

    opacity: 1;
  }
}

@keyframes hide-dropdown {
  from {
    transition: all 0.3s ease;
    transform: translateX(0) translateY(-3px) scale(1);

    opacity: 1;
  }

  to {
    transform: translateX(0) translateY(-3px) scale(0.97);

    opacity: 0;
  }
}

.footer {
  padding-top: 3rem;
  background: var(--voilet);
}

.footer .nav-link {
  font-size: 0.875rem;
}

.footer .list-unstyled li a {
  font-size: 0.875rem;

  display: inline-block;

  padding: 0.125rem 0;
}

.footer-light p {
  color: #718096;
}

.footer-light .heading {
  color: #152c5b;
}

.footer-light .list-unstyled li a {
  color: #a0aec0;
}

.footer-light .list-unstyled li a:hover {
  text-decoration: underline;

  color: #4a5568;
}

.footer-light .copyright a {
  color: #4a5568;
}

.footer-light .copyright a:hover {
  color: #008aff;
}

.footer-dark p {
  color: rgba(255, 255, 255, 0.9);
}

.footer-dark .heading {
  color: #fff;
}

.footer-dark .list-unstyled li a {
  color: rgba(255, 255, 255, 0.8);
}

.footer-dark .list-unstyled li a:hover {
  color: rgba(255, 255, 255, 0.9);
}

.footer-dark .copyright a {
  color: #ccc5fc;
}

.footer-dark .copyright a:hover {
  color: var(--orange);
}

.footer-dark {
  color: #000000;
  background-color: var(--voilet);
}

.footer-dark .heading {
  color: #fff;
}

.footer-dark .nav .nav-item .nav-link,
.footer-dark .list-unstyled li a,
.footer-dark .footer-link {
  color: #1e1f21;
}

.footer-dark .nav .nav-item .nav-link:hover,
.footer-dark .list-unstyled li a:hover,
.footer-dark .footer-link:hover {
  color: #000;
}

.footer-dark hr {
  border-color: rgb(216 203 187 / 74%);
}

.form-control:focus:-ms-input-placeholder {
  color: #cbd5e0;
}

.form-control:focus::-ms-input-placeholder {
  color: #cbd5e0;
}

.form-control:focus::placeholder {
  color: #cbd5e0;
}

.form-control-muted {
  border-color: #edf0f5;
  background-color: #edf0f5;
}

.form-control-muted:focus {
  background-color: #edf0f5;
}

.form-control-emphasized {
  border-color: #fff;
  background-color: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-control-emphasized:focus {
  border-color: transparent;
  background-color: #fff;
  box-shadow: 0 0 0 2px #008aff;
}

.form-control-flush {
  padding: 0;

  border-width: 0;
  background-color: transparent;
  box-shadow: none;
}

.form-control-flush:focus {
  border-width: 0;
  background-color: transparent;
  box-shadow: none;
}

textarea[resize="none"] {
  resize: none !important;
}

textarea[resize="both"] {
  resize: both !important;
}

textarea[resize="vertical"] {
  resize: vertical !important;
}

textarea[resize="horizontal"] {
  resize: horizontal !important;
}

.form-control-xl {
  font-size: 1.5rem;
  line-height: 1.5;

  padding: 1.5rem 2rem;

  border-radius: 0.75rem;
}

.form-primary .form-control {
  color: #fff;
  border: 1px solid #0069c2;
  background-color: #0069c2;
}

.form-primary .form-control:focus {
  border: 1px solid #005ead;
  background-color: #005ead;
}

.form-primary .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.form-primary .form-control::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.form-primary .form-control::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.form-warning .form-control {
  color: #fff;
  border: 1px solid #ffa900;
  background-color: #ffa900;
}

.form-warning .form-control:focus {
  border: 1px solid #ea9c00;
  background-color: #ea9c00;
}

.form-warning .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.form-warning .form-control::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.form-warning .form-control::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.form-danger .form-control {
  color: #fff;
  border: 1px solid #ee1e34;
  background-color: #ee1e34;
}

.form-danger .form-control:focus {
  border: 1px solid #e61228;
  background-color: #e61228;
}

.form-danger .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.form-danger .form-control::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.form-danger .form-control::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.header-transparent + section {
  padding-top: 7rem !important;
}

.header-transparent + .header-1 {
  padding-top: 14rem !important;
}

.header-account-page .btn-group-nav {
  position: relative;
  z-index: 1;

  transform: translateY(50%);
}

@media (min-width: 768px) {
  .header-web-app .row:last-of-type {
    position: relative;
    z-index: 2;

    margin-bottom: -12rem;
  }

  .header-web-app + section {
    padding-top: 12rem;
  }
}

.icon i,
.icon svg {
  font-size: 1.71429rem;
}

.icon:not(.icon-shape) svg {
  width: 3rem;
  height: 3rem;
}

.icon + .icon-text {
  width: calc(100% - 3rem - 1);
  padding-left: 1rem;
}

.icon-xl i,
.icon-xl svg {
  font-size: 2.85714rem;
}

.icon-xl:not(.icon-shape) svg {
  width: 5rem;
  height: 5rem;
}

.icon-xl + .icon-text {
  width: calc(100% - 5rem - 1);
}

.icon-lg i,
.icon-lg svg {
  font-size: 2.28571rem;
}

.icon-lg:not(.icon-shape) svg {
  width: 4rem;
  height: 4rem;
}

.icon-lg + .icon-text {
  width: calc(100% - 4rem - 1);
}

.icon-sm i,
.icon-sm svg {
  font-size: 1.14286rem;
}

.icon-sm:not(.icon-shape) svg {
  width: 2rem;
  height: 2rem;
}

.icon-sm + .icon-text {
  width: calc(100% - 2rem - 1);
}

.icon-xs i,
.icon-xs svg {
  font-size: 0.57143rem;
}

.icon-xs:not(.icon-shape) svg {
  width: 1rem;
  height: 1rem;
}

.icon-xs + .icon-text {
  width: calc(100% - 1rem - 1);
}

.icon-shape {
  display: inline-flex;

  width: 3rem;
  height: 3rem;

  text-align: center;
  vertical-align: middle;

  border-radius: 0.375rem;

  align-items: center;
  justify-content: center;
}

.icon-shape i {
  font-size: 1.125rem;
}

.icon-shape svg {
  width: 1.2rem;
  height: 1.2rem;
}

.icon-shape.icon-xl {
  width: 5rem;
  height: 5rem;
}

.icon-shape.icon-xl i {
  font-size: 2.25rem;
}

.icon-shape.icon-xl svg {
  width: 2rem;
  height: 2rem;
}

.icon-shape.icon-lg {
  width: 4rem;
  height: 4rem;
}

.icon-shape.icon-lg i {
  font-size: 1.75rem;
}

.icon-shape.icon-lg svg {
  width: 1.6rem;
  height: 1.6rem;
}

.icon-shape.icon-sm {
  width: 2rem;
  height: 2rem;
}

.icon-shape.icon-sm i {
  font-size: 0.875rem;
}

.icon-shape.icon-sm svg {
  width: 0.8rem;
  height: 0.8rem;
}

.icon-shape.icon-xs {
  width: 1rem;
  height: 1rem;
}

.icon-shape.icon-xs i {
  font-size: 0.75rem;
}

.icon-shape.icon-xs svg {
  width: 0.4rem;
  height: 0.4rem;
}

.icon-primary {
  color: #008aff;
  background-color: #cce8ff;
}

.icon-outline-primary {
  color: #0063b8;
  border: 3px solid rgba(51, 161, 255, 0.5);
}

.icon-secondary {
  color: #eaecf3;
  background-color: #fbfbfd;
}

.icon-outline-secondary {
  color: #bac1dc;
  border: 3px solid rgba(255, 255, 255, 0.5);
}

.icon-success {
  color: #5cc9a7;
  background-color: #def4ed;
}

.icon-outline-success {
  color: #32ac86;
  border: 3px solid rgba(130, 214, 188, 0.5);
}

.icon-info {
  color: #50b5ff;
  background-color: #dcf0ff;
}

.icon-outline-info {
  color: #0997ff;
  border: 3px solid rgba(131, 203, 255, 0.5);
}

.icon-warning {
  color: #ffbe3d;
  background-color: #fff2d8;
}

.icon-outline-warning {
  color: #f5a300;
  border: 3px solid rgba(255, 207, 112, 0.5);
}

.icon-danger {
  color: #f25767;
  background-color: #fcdde1;
}

.icon-outline-danger {
  color: #f30e26;
  border: 3px solid rgba(246, 134, 146, 0.5);
}

.icon-light {
  color: #eaecf3;
  background-color: #fbfbfd;
}

.icon-outline-light {
  color: #bac1dc;
  border: 3px solid rgba(255, 255, 255, 0.5);
}

.icon-dark {
  color: #171347;
  background-color: #d1d0da;
}

.icon-outline-dark {
  color: #04030f;
  border: 3px solid rgba(36, 30, 111, 0.5);
}

.icon-neutral {
  color: #fff;
  background-color: white;
}

.icon-outline-neutral {
  color: #dddada;
  border: 3px solid rgba(255, 255, 255, 0.5);
}

.icon-white {
  color: #fff;
  background-color: white;
}

.icon-outline-white {
  color: #dddada;
  border: 3px solid rgba(255, 255, 255, 0.5);
}

.input-group-text {
  transition: all 0.2s ease;
}

.input-group-flush .form-control,
.input-group-flush .input-group-text {
  border: 0 !important;
}

.focused .input-group {
  box-shadow: none, 0 0 20px rgba(0, 138, 255, 0.1);
}

.focused .input-group .form-control {
  box-shadow: none !important;
}

.focused .input-group-text {
  color: #008aff;
  border-color: rgba(0, 138, 255, 0.5);
  background-color: #eaecf3;
}

.jumbotron {
  position: relative;
}

.jumbotron:not([class*="rounded-"]) {
  border-radius: 5rem;
}

.jumbotron:not([class*="rounded-"]) > img {
  border-radius: 5rem;
}

.jumbotron.rounded-right {
  border-radius: 0 5rem 5rem 0 !important;
}

.jumbotron.rounded-left {
  border-radius: 5rem 0 0 5rem !important;
}

.jumbotron.rounded-top-right {
  border-radius: 0 5rem 0 0 !important;
}

.jumbotron.rounded-top-left {
  border-radius: 5rem 0 0 0 !important;
}

.jumbotron.rounded-bottom-right {
  border-radius: 0 0 5rem 0 !important;
}

.jumbotron.rounded-bottom-left {
  border-radius: 0 0 0 5rem !important;
}

.jumbotron.rounded-diagonal-left {
  border-radius: 5rem 0 5rem 0 !important;
}

.jumbotron.rounded-diagonal-right {
  border-radius: 0 5rem 0 5rem !important;
}

.docs .header .navbar {
  position: fixed;
  z-index: 1000;
}

@media (min-width: 576px) {
  .docs .container-fluid,
  .docs .container-sm,
  .docs .container-md,
  .docs .container-lg,
  .docs .container-xl {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media (max-width: 767.98px) {
  .docs .container-fluid,
  .docs .container-sm,
  .docs .container-md,
  .docs .container-lg,
  .docs .container-xl {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.docs .container-docs {
  display: flex;

  flex: 1 1;
  align-items: stretch;
}

.docs .main-content {
  display: flex;

  min-width: 0;
  max-width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;

  flex: 1 1 auto;
}

@media (min-width: 992px) {
  .docs .main-content {
    padding-left: 250px;
  }
}

.docs .main-content .container,
.docs .main-content .container-fluid,
.docs .main-content .container-sm,
.docs .main-content .container-md,
.docs .main-content .container-lg,
.docs .main-content .container-xl {
  position: relative;
}

.docs .main-content-title + .row,
.docs .main-content-title + .card {
  margin-top: 30px;
}

.docs .docs-content {
  margin-top: 80px;
}

@media (min-width: 992px) {
  .docs .docs-content {
    padding: 0 70px;
  }
}

@media (min-width: 768px) {
  .docs .docs-content {
    padding: 0 50px;
  }
}

@media (max-width: 991.98px) {
  .docs .docs-content {
    padding: 0 20px;
  }
}

.docs .docs-title {
  margin: 2.5rem 0;
  padding: 2.5rem;

  border: 2px solid #e2e8f0;
  border-radius: 0.5rem;
}

.docs .sidenav {
  position: fixed;
  z-index: 900;
  top: 0;

  overflow: auto;

  height: 100vh;
  margin-bottom: 0;

  transition: all 0.2s ease;

  border: 0;
  border-radius: 0;
}

@media (prefers-reduced-motion: reduce) {
  .docs .sidenav {
    transition: none;
  }
}

@media (min-width: 992px) {
  .docs .sidenav {
    max-width: 250px !important;
    height: 100vh;

    transform: translateX(0);

    border-right: 1px solid #e2e8f0;
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .docs .sidenav {
    position: fixed;
    top: 0;
    left: 0;

    max-width: 0;
    height: 100%;

    transform: translateX(-150%);

    border: 0;
  }
}

.docs .sidenav.show {
  z-index: 900;

  max-width: 250px !important;

  transform: translateX(0);

  border-right: 1px solid #e2e8f0;
  border-radius: 0;
}

.docs .sidenav-header {
  margin-bottom: 1.5rem;
  padding: 1.5rem 0;
}

.docs .sidenav-header .navbar-brand img {
  height: 1.5rem;
}

.docs .main-content-title + .nav-tabs {
  margin-bottom: 30px;
}

.list-group {
  list-style-type: none;
}

.list-group-space .list-group-item {
  margin-bottom: 1.5rem;

  border-radius: 0.375rem;
}

.list-group-item {
  font-size: 0.875rem;
}

.list-group-img {
  width: 3rem;
  height: 3rem;
  margin: -0.1rem 1.2rem 0 -0.2rem;

  vertical-align: top;

  border-radius: 50%;
}

.list-group-content {
  min-width: 0;

  flex: 1 1;
}

.list-group-content p {
  line-height: 1.5;

  margin: 0.2rem 0 0;
}

.list-group-heading {
  color: #2d3748;
}

.list-group-heading > small {
  font-weight: 500;

  float: right;

  color: #a0aec0;
}

.list-group-sm .list-group-item {
  font-size: 0.875rem;

  padding: 0.675rem 1.25rem;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  transition: all 0.2s ease;
}

@media (prefers-reduced-motion: reduce) {
  .mask {
    transition: none;
  }
}

.mask + .container {
  position: relative;
}

.card .mask {
  border-radius: 0.75rem;
}

.card .hover-mask {
  transition-delay: 150ms;

  opacity: 0;
}

.card:hover .hover-mask {
  opacity: 0.4;
}

.modal-content {
  border: 0;
  border-radius: 0.75rem;
}

.modal-fluid .modal-dialog {
  margin-top: 0;
  margin-bottom: 0;
}

.modal-fluid .modal-content {
  border-radius: 0;
}

.modal-open {
  max-height: 100vh;
}

.modal-dialog-aside {
  position: absolute;

  margin: 0;
}

.modal-primary .modal-title {
  color: #fff;
}

.modal-primary .modal-header,
.modal-primary .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}

.modal-primary .modal-content {
  color: #fff;
  background-color: #008aff;
}

.modal-primary .modal-content .heading {
  color: #fff;
}

.modal-primary .close > span:not(.sr-only) {
  color: #fff;
}

.modal-secondary .modal-title {
  color: #273444;
}

.modal-secondary .modal-header,
.modal-secondary .modal-footer {
  border-color: rgba(39, 52, 68, 0.075);
}

.modal-secondary .modal-content {
  color: #273444;
  background-color: #eaecf3;
}

.modal-secondary .modal-content .heading {
  color: #273444;
}

.modal-secondary .close > span:not(.sr-only) {
  color: #fff;
}

.modal-success .modal-title {
  color: #fff;
}

.modal-success .modal-header,
.modal-success .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}

.modal-success .modal-content {
  color: #fff;
  background-color: #5cc9a7;
}

.modal-success .modal-content .heading {
  color: #fff;
}

.modal-success .close > span:not(.sr-only) {
  color: #fff;
}

.modal-info .modal-title {
  color: #fff;
}

.modal-info .modal-header,
.modal-info .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}

.modal-info .modal-content {
  color: #fff;
  background-color: #50b5ff;
}

.modal-info .modal-content .heading {
  color: #fff;
}

.modal-info .close > span:not(.sr-only) {
  color: #fff;
}

.modal-warning .modal-title {
  color: #fff;
}

.modal-warning .modal-header,
.modal-warning .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}

.modal-warning .modal-content {
  color: #fff;
  background-color: #ffbe3d;
}

.modal-warning .modal-content .heading {
  color: #fff;
}

.modal-warning .close > span:not(.sr-only) {
  color: #fff;
}

.modal-danger .modal-title {
  color: #fff;
}

.modal-danger .modal-header,
.modal-danger .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}

.modal-danger .modal-content {
  color: #fff;
  background-color: #f25767;
}

.modal-danger .modal-content .heading {
  color: #fff;
}

.modal-danger .close > span:not(.sr-only) {
  color: #fff;
}

.modal-light .modal-title {
  color: #273444;
}

.modal-light .modal-header,
.modal-light .modal-footer {
  border-color: rgba(39, 52, 68, 0.075);
}

.modal-light .modal-content {
  color: #273444;
  background-color: #eaecf3;
}

.modal-light .modal-content .heading {
  color: #273444;
}

.modal-light .close > span:not(.sr-only) {
  color: #fff;
}

.modal-dark .modal-title {
  color: #fff;
}

.modal-dark .modal-header,
.modal-dark .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}

.modal-dark .modal-content {
  color: #fff;
  background-color: #171347;
}

.modal-dark .modal-content .heading {
  color: #fff;
}

.modal-dark .close > span:not(.sr-only) {
  color: #fff;
}

.modal-neutral .modal-title {
  color: #273444;
}

.modal-neutral .modal-header,
.modal-neutral .modal-footer {
  border-color: rgba(39, 52, 68, 0.075);
}

.modal-neutral .modal-content {
  color: #273444;
  background-color: #fff;
}

.modal-neutral .modal-content .heading {
  color: #273444;
}

.modal-neutral .close > span:not(.sr-only) {
  color: #fff;
}

.modal-white .modal-title {
  color: #273444;
}

.modal-white .modal-header,
.modal-white .modal-footer {
  border-color: rgba(39, 52, 68, 0.075);
}

.modal-white .modal-content {
  color: #273444;
  background-color: #fff;
}

.modal-white .modal-content .heading {
  color: #273444;
}

.modal-white .close > span:not(.sr-only) {
  color: #fff;
}

.nav-link {
  font-size: 0.875rem;

  color: #4a5568;
}

.nav-link:hover {
  color: #273444;
}

.nav-link.active {
  font-weight: 600;

  color: #273444;
}

@media (min-width: 992px) {
  .nav-link-badge {
    position: relative;
  }

  .nav-link-badge .badge {
    position: relative;
    top: -15px;
    left: -5px;
  }

  .nav-link-badge .nav-link-text {
    position: relative;
  }

  .nav-link-badge .nav-link-text:before {
    position: absolute;
    top: 0;
    left: -7px;

    display: block;

    width: 20px;
    height: 20px;
    margin: auto;

    content: "";
    transform: translate(-50%, -50%) rotate(100deg);

    border-left: 1px dashed #fff;
    border-radius: 10px;
  }
}

.nav.overflow-x {
  display: flex;
  overflow-x: auto;

  padding-bottom: 1px;

  flex-wrap: nowrap;
}

.nav.overflow-x .nav-item {
  width: auto;
  max-width: 100%;

  flex: 0 0 auto;
}

.nav-header {
  border-bottom-width: 0;
}

.navbar {
  position: relative;
  z-index: 101;
}

.navbar .container {
  position: relative;

  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-nav .nav-link {
    transition: none;
  }
}

.navbar-nav .nav-link i,
.navbar-nav .nav-link svg,
.navbar-nav .nav-link img {
  margin-right: 0.5rem;
}

.navbar-nav .media-pill .avatar {
  width: 36px;
  height: 36px;
}

.nav-link > img,
.nav-link > svg {
  position: relative;
  top: -1px;

  height: 14px;
  margin-right: 0.5rem;
}

.nav-link .icon-flag {
  width: 19px;
}

.navbar-text {
  font-size: 1rem;
}

.navbar-user {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-around;
}

.navbar-img-brand {
  font-size: initial;
}

.navbar-shadow {
  box-shadow: 0 0 10px rgba(31, 45, 61, 0.03);
}

.header,
.navbar,
.navbar-top {
  transition: all 0.2s ease;
}

@media (prefers-reduced-motion: reduce) {
  .header,
  .navbar,
  .navbar-top {
    transition: none;
  }
}

.navbar-border.navbar-dark {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.navbar-border.navbar-dark .border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.navbar-border.navbar-light {
  border-bottom: 1px solid rgba(31, 45, 61, 0.04) !important;
}

.navbar-border.navbar-light .border-bottom {
  border-bottom: 1px solid rgba(31, 45, 61, 0.1) !important;
}

.navbar-dark .border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.navbar-light .border-bottom {
  border-bottom: 1px solid rgba(31, 45, 61, 0.1) !important;
}

.header-transparent {
  position: absolute;
  z-index: 1030;
  top: 0;
  left: 0;

  width: 100%;
}

.header-transparent .navbar-main .container:not(.border-0)::after {
  position: absolute;
  right: 0;
  bottom: -0.75rem;
  left: 0;

  content: "";

  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.header-transparent .navbar-top {
  padding-right: 1rem;
  padding-left: 1rem;

  border: 0;
  background-color: transparent !important;
}

.header-transparent .navbar:not(.sticky) {
  border: 0;
  background-color: transparent !important;
  box-shadow: none;
}

.header-transparent
  + main
  section:first-child:not([data-spotlight])
  .container {
  padding-top: 5rem;
}

.header-collapse-show {
  z-index: 1000;
  top: 0;

  width: 100%;
  min-height: 100%;
}

.navbar-sticky {
  position: -webkit-sticky;
}

.navbar-sticky.sticky {
  position: fixed;
  z-index: 1030;
  top: -100px;
  left: 0;

  width: 100%;

  transition: all 0.2s ease;
  transform: translateY(100px);

  border-top: 0;
  box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
}

@media (prefers-reduced-motion: reduce) {
  .navbar-sticky.sticky {
    transition: none;
  }
}

.navbar-top {
  position: relative;

  display: flex;

  align-items: center;
}

.navbar-top .navbar-nav {
  flex-direction: row;
}

.navbar-top .navbar-nav .nav-item:last-child {
  margin-right: 0;
}

.navbar-top .navbar-nav .nav-link {
  padding: 0.5rem 0.5rem;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-brand img {
    height: 1.75rem;
  }

  .navbar-expand-sm .navbar-brand.dropdown-toggle:after {
    font-size: 1rem;

    float: none;

    opacity: 0.6;
  }

  .navbar-expand-sm .navbar-brand.dropdown-toggle:hover:after {
    opacity: 1;
  }

  .navbar-expand-sm .navbar-nav:not(.flex-row) .nav-link {
    padding: 0.625rem 0;
  }

  .navbar-expand-sm .navbar-nav:not(.flex-row) .dropdown-menu {
    min-width: auto;

    box-shadow: none;
  }

  .navbar-expand-sm .navbar-nav:not(.flex-row) .dropdown-menu .media svg {
    width: 30px;
  }

  .navbar-expand-sm .navbar-nav.flex-row .nav-link {
    padding-right: 0.675rem;
    padding-left: 0.675rem;
  }

  .navbar-expand-sm .navbar-nav.flex-row .dropdown {
    position: static;
  }

  .navbar-expand-sm .navbar-nav.flex-row .dropdown-menu {
    position: absolute;
    top: 60px;
    right: auto;
    left: 15px;

    width: calc(100% - 30px);
  }

  .navbar-expand-sm
    .navbar-nav
    .dropdown:not(.dropdown-submenu)
    > .dropdown-menu {
    display: block !important;
  }

  .navbar-expand-sm .navbar-collapse {
    background: #fff;
  }

  .navbar-expand-sm .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: rgba(31, 45, 61, 0.6);
  }

  .navbar-expand-sm .navbar-collapse .navbar-nav .nav-item-spaced {
    margin-bottom: 1.5rem;
  }

  .navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-item {
    color: rgba(31, 45, 61, 0.8);
  }

  .navbar-expand-sm .navbar-collapse .navbar-toggler {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0.5rem;

    color: rgba(31, 45, 61, 0.6);
  }

  .navbar-expand-sm .navbar-collapse-fade {
    z-index: 1050;

    height: auto !important;

    opacity: 0;
  }

  .navbar-expand-sm .navbar-collapse-fade.collapsing,
  .navbar-expand-sm .navbar-collapse-fade.show {
    animation: show-navbar-collapse 0.2s ease forwards;
    animation-delay: 0.15s;
  }

  .navbar-expand-sm .navbar-collapse-fade.collapsing-out {
    transition: opacity 0.3s linear;
    animation: none;
    animation-delay: 0;

    opacity: 0;
  }

  .navbar-expand-sm .navbar-collapse-overlay {
    position: fixed;
    z-index: 100;
    top: 1rem;
    right: 1rem;
    left: 1rem;

    overflow-y: scroll;

    max-height: calc(100vh - 2rem) !important;
    padding: 1rem 0;

    transition: all 0.2s ease-in-out;
    transition-property: opacity, transform;
    transform: translateY(-100%);
    transform-origin: top center;

    opacity: 0;
    border-radius: 0.375rem;
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);

    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-sm .navbar-collapse-overlay {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-collapse-overlay.show {
    transition: all 0.2s ease-in-out;
    transform: translateY(0);

    opacity: 1;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-sm .navbar-collapse-overlay.show {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-collapse-overlay .nav-item {
    padding: 0 1.5rem;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-brand img {
    height: 1.75rem;
  }

  .navbar-expand-sm .navbar-brand.dropdown-toggle:after {
    display: none;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;

    border-radius: 0;
  }

  .navbar-expand-sm .navbar-nav .nav-link-icon {
    padding-right: 0.75rem;
    padding-left: 0.75rem;

    border-radius: 0;
  }

  .navbar-expand-sm .navbar-nav .nav-link-icon i {
    margin-right: 0;
  }

  .navbar-expand-sm .navbar-collapse .collapse-header {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-brand img {
    height: 1.75rem;
  }

  .navbar-expand-md .navbar-brand.dropdown-toggle:after {
    font-size: 1rem;

    float: none;

    opacity: 0.6;
  }

  .navbar-expand-md .navbar-brand.dropdown-toggle:hover:after {
    opacity: 1;
  }

  .navbar-expand-md .navbar-nav:not(.flex-row) .nav-link {
    padding: 0.625rem 0;
  }

  .navbar-expand-md .navbar-nav:not(.flex-row) .dropdown-menu {
    min-width: auto;

    box-shadow: none;
  }

  .navbar-expand-md .navbar-nav:not(.flex-row) .dropdown-menu .media svg {
    width: 30px;
  }

  .navbar-expand-md .navbar-nav.flex-row .nav-link {
    padding-right: 0.675rem;
    padding-left: 0.675rem;
  }

  .navbar-expand-md .navbar-nav.flex-row .dropdown {
    position: static;
  }

  .navbar-expand-md .navbar-nav.flex-row .dropdown-menu {
    position: absolute;
    top: 60px;
    right: auto;
    left: 15px;

    width: calc(100% - 30px);
  }

  .navbar-expand-md
    .navbar-nav
    .dropdown:not(.dropdown-submenu)
    > .dropdown-menu {
    display: block !important;
  }

  .navbar-expand-md .navbar-collapse {
    background: #fff;
  }

  .navbar-expand-md .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: rgba(31, 45, 61, 0.6);
  }

  .navbar-expand-md .navbar-collapse .navbar-nav .nav-item-spaced {
    margin-bottom: 1.5rem;
  }

  .navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item {
    color: rgba(31, 45, 61, 0.8);
  }

  .navbar-expand-md .navbar-collapse .navbar-toggler {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0.5rem;

    color: rgba(31, 45, 61, 0.6);
  }

  .navbar-expand-md .navbar-collapse-fade {
    z-index: 1050;

    height: auto !important;

    opacity: 0;
  }

  .navbar-expand-md .navbar-collapse-fade.collapsing,
  .navbar-expand-md .navbar-collapse-fade.show {
    animation: show-navbar-collapse 0.2s ease forwards;
    animation-delay: 0.15s;
  }

  .navbar-expand-md .navbar-collapse-fade.collapsing-out {
    transition: opacity 0.3s linear;
    animation: none;
    animation-delay: 0;

    opacity: 0;
  }

  .navbar-expand-md .navbar-collapse-overlay {
    position: fixed;
    z-index: 100;
    top: 1rem;
    right: 1rem;
    left: 1rem;

    overflow-y: scroll;

    max-height: calc(100vh - 2rem) !important;
    padding: 1rem 0;

    transition: all 0.2s ease-in-out;
    transition-property: opacity, transform;
    transform: translateY(-100%);
    transform-origin: top center;

    opacity: 0;
    border-radius: 0.375rem;
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);

    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-md .navbar-collapse-overlay {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-collapse-overlay.show {
    transition: all 0.2s ease-in-out;
    transform: translateY(0);

    opacity: 1;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-md .navbar-collapse-overlay.show {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-collapse-overlay .nav-item {
    padding: 0 1.5rem;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-brand img {
    height: 1.75rem;
  }

  .navbar-expand-md .navbar-brand.dropdown-toggle:after {
    display: none;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;

    border-radius: 0;
  }

  .navbar-expand-md .navbar-nav .nav-link-icon {
    padding-right: 0.75rem;
    padding-left: 0.75rem;

    border-radius: 0;
  }

  .navbar-expand-md .navbar-nav .nav-link-icon i {
    margin-right: 0;
  }

  .navbar-expand-md .navbar-collapse .collapse-header {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-brand img {
    height: 1.75rem;
  }

  .navbar-expand-lg .navbar-brand.dropdown-toggle:after {
    font-size: 1rem;

    float: none;

    opacity: 0.6;
  }

  .navbar-expand-lg .navbar-brand.dropdown-toggle:hover:after {
    opacity: 1;
  }

  .navbar-expand-lg .navbar-nav:not(.flex-row) .nav-link {
    padding: 0.625rem 0;
  }

  .navbar-expand-lg .navbar-nav:not(.flex-row) .dropdown-menu {
    min-width: auto;

    box-shadow: none;
  }

  .navbar-expand-lg .navbar-nav:not(.flex-row) .dropdown-menu .media svg {
    width: 30px;
  }

  .navbar-expand-lg .navbar-nav.flex-row .nav-link {
    padding-right: 0.675rem;
    padding-left: 0.675rem;
  }

  .navbar-expand-lg .navbar-nav.flex-row .dropdown {
    position: static;
  }

  .navbar-expand-lg .navbar-nav.flex-row .dropdown-menu {
    position: absolute;
    top: 60px;
    right: auto;
    left: 15px;

    width: calc(100% - 30px);
  }

  /*
  .navbar-expand-lg .navbar-nav .dropdown:not(.dropdown-submenu)>.dropdown-menu {
    display: block !important;
  } */

  .navbar-expand-lg .navbar-collapse {
    background: #fff;
  }

  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: rgba(31, 45, 61, 0.6);
  }

  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-item-spaced {
    margin-bottom: 1.5rem;
  }

  .navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-item {
    color: rgba(31, 45, 61, 0.8);
  }

  .navbar-expand-lg .navbar-collapse .navbar-toggler {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0.5rem;

    color: rgba(31, 45, 61, 0.6);
  }

  .navbar-expand-lg .navbar-collapse-fade {
    z-index: 1050;

    height: auto !important;

    opacity: 0;
  }

  .navbar-expand-lg .navbar-collapse-fade.collapsing,
  .navbar-expand-lg .navbar-collapse-fade.show {
    animation: show-navbar-collapse 0.2s ease forwards;
    animation-delay: 0.15s;
  }

  .navbar-expand-lg .navbar-collapse-fade.collapsing-out {
    transition: opacity 0.3s linear;
    animation: none;
    animation-delay: 0;

    opacity: 0;
  }

  .navbar-expand-lg .navbar-collapse-overlay {
    position: fixed;
    z-index: 100;
    top: 1rem;
    right: 1rem;
    left: 1rem;

    overflow-y: scroll;

    max-height: calc(100vh - 2rem) !important;
    padding: 1rem 0;

    transition: all 0.2s ease-in-out;
    transition-property: opacity, transform;
    transform: translateY(-100%);
    transform-origin: top center;

    opacity: 0;
    border-radius: 0.375rem;
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);

    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-lg .navbar-collapse-overlay {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-collapse-overlay.show {
    transition: all 0.2s ease-in-out;
    transform: translateY(0);

    opacity: 1;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-lg .navbar-collapse-overlay.show {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-collapse-overlay .nav-item {
    padding: 0 1.5rem;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-brand img {
    height: 1.75rem;
  }

  .navbar-expand-lg .navbar-brand.dropdown-toggle:after {
    display: none;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;

    border-radius: 0;
  }

  .navbar-expand-lg .navbar-nav .nav-link-icon {
    padding-right: 0.75rem;
    padding-left: 0.75rem;

    border-radius: 0;
  }

  .navbar-expand-lg .navbar-nav .nav-link-icon i {
    margin-right: 0;
  }

  .navbar-expand-lg .navbar-collapse .collapse-header {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-brand img {
    height: 1.75rem;
  }

  .navbar-expand-xl .navbar-brand.dropdown-toggle:after {
    font-size: 1rem;

    float: none;

    opacity: 0.6;
  }

  .navbar-expand-xl .navbar-brand.dropdown-toggle:hover:after {
    opacity: 1;
  }

  .navbar-expand-xl .navbar-nav:not(.flex-row) .nav-link {
    padding: 0.625rem 0;
  }

  .navbar-expand-xl .navbar-nav:not(.flex-row) .dropdown-menu {
    min-width: auto;

    box-shadow: none;
  }

  .navbar-expand-xl .navbar-nav:not(.flex-row) .dropdown-menu .media svg {
    width: 30px;
  }

  .navbar-expand-xl .navbar-nav.flex-row .nav-link {
    padding-right: 0.675rem;
    padding-left: 0.675rem;
  }

  .navbar-expand-xl .navbar-nav.flex-row .dropdown {
    position: static;
  }

  .navbar-expand-xl .navbar-nav.flex-row .dropdown-menu {
    position: absolute;
    top: 60px;
    right: auto;
    left: 15px;

    width: calc(100% - 30px);
  }

  .navbar-expand-xl
    .navbar-nav
    .dropdown:not(.dropdown-submenu)
    > .dropdown-menu {
    display: block !important;
  }

  .navbar-expand-xl .navbar-collapse {
    background: #fff;
  }

  .navbar-expand-xl .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: rgba(31, 45, 61, 0.6);
  }

  .navbar-expand-xl .navbar-collapse .navbar-nav .nav-item-spaced {
    margin-bottom: 1.5rem;
  }

  .navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-item {
    color: rgba(31, 45, 61, 0.8);
  }

  .navbar-expand-xl .navbar-collapse .navbar-toggler {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0.5rem;

    color: rgba(31, 45, 61, 0.6);
  }

  .navbar-expand-xl .navbar-collapse-fade {
    z-index: 1050;

    height: auto !important;

    opacity: 0;
  }

  .navbar-expand-xl .navbar-collapse-fade.collapsing,
  .navbar-expand-xl .navbar-collapse-fade.show {
    animation: show-navbar-collapse 0.2s ease forwards;
    animation-delay: 0.15s;
  }

  .navbar-expand-xl .navbar-collapse-fade.collapsing-out {
    transition: opacity 0.3s linear;
    animation: none;
    animation-delay: 0;

    opacity: 0;
  }

  .navbar-expand-xl .navbar-collapse-overlay {
    position: fixed;
    z-index: 100;
    top: 1rem;
    right: 1rem;
    left: 1rem;

    overflow-y: scroll;

    max-height: calc(100vh - 2rem) !important;
    padding: 1rem 0;

    transition: all 0.2s ease-in-out;
    transition-property: opacity, transform;
    transform: translateY(-100%);
    transform-origin: top center;

    opacity: 0;
    border-radius: 0.375rem;
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);

    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-xl .navbar-collapse-overlay {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-collapse-overlay.show {
    transition: all 0.2s ease-in-out;
    transform: translateY(0);

    opacity: 1;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-xl .navbar-collapse-overlay.show {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-collapse-overlay .nav-item {
    padding: 0 1.5rem;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-brand img {
    height: 1.75rem;
  }

  .navbar-expand-xl .navbar-brand.dropdown-toggle:after {
    display: none;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;

    border-radius: 0;
  }

  .navbar-expand-xl .navbar-nav .nav-link-icon {
    padding-right: 0.75rem;
    padding-left: 0.75rem;

    border-radius: 0;
  }

  .navbar-expand-xl .navbar-nav .nav-link-icon i {
    margin-right: 0;
  }

  .navbar-expand-xl .navbar-collapse .collapse-header {
    display: none;
  }
}

.navbar-expand .navbar-brand img {
  height: 1.75rem;
}

.navbar-expand .navbar-brand.dropdown-toggle:after {
  font-size: 1rem;

  float: none;

  opacity: 0.6;
}

.navbar-expand .navbar-brand.dropdown-toggle:hover:after {
  opacity: 1;
}

.navbar-expand .navbar-nav:not(.flex-row) .nav-link {
  padding: 0.625rem 0;
}

.navbar-expand .navbar-nav:not(.flex-row) .dropdown-menu {
  min-width: auto;

  box-shadow: none;
}

.navbar-expand .navbar-nav:not(.flex-row) .dropdown-menu .media svg {
  width: 30px;
}

.navbar-expand .navbar-nav.flex-row .nav-link {
  padding-right: 0.675rem;
  padding-left: 0.675rem;
}

.navbar-expand .navbar-nav.flex-row .dropdown {
  position: static;
}

.navbar-expand .navbar-nav.flex-row .dropdown-menu {
  position: absolute;
  top: 60px;
  right: auto;
  left: 15px;

  width: calc(100% - 30px);
}

.navbar-expand .navbar-nav .dropdown:not(.dropdown-submenu) > .dropdown-menu {
  display: block !important;
}

.navbar-expand .navbar-collapse {
  background: #fff;
}

.navbar-expand .navbar-collapse .navbar-nav .nav-item .nav-link {
  color: rgba(31, 45, 61, 0.6);
}

.navbar-expand .navbar-collapse .navbar-nav .nav-item-spaced {
  margin-bottom: 1.5rem;
}

.navbar-expand .navbar-collapse .navbar-nav .dropdown-item {
  color: rgba(31, 45, 61, 0.8);
}

.navbar-expand .navbar-collapse .navbar-toggler {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0.5rem;

  color: rgba(31, 45, 61, 0.6);
}

.navbar-expand .navbar-collapse-fade {
  z-index: 1050;

  height: auto !important;

  opacity: 0;
}

.navbar-expand .navbar-collapse-fade.collapsing,
.navbar-expand .navbar-collapse-fade.show {
  animation: show-navbar-collapse 0.2s ease forwards;
  animation-delay: 0.15s;
}

.navbar-expand .navbar-collapse-fade.collapsing-out {
  transition: opacity 0.3s linear;
  animation: none;
  animation-delay: 0;

  opacity: 0;
}

.navbar-expand .navbar-collapse-overlay {
  position: fixed;
  z-index: 100;
  top: 1rem;
  right: 1rem;
  left: 1rem;

  overflow-y: scroll;

  max-height: calc(100vh - 2rem) !important;
  padding: 1rem 0;

  transition: all 0.2s ease-in-out;
  transition-property: opacity, transform;
  transform: translateY(-100%);
  transform-origin: top center;

  opacity: 0;
  border-radius: 0.375rem;
  box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);

  -webkit-overflow-scrolling: touch;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-expand .navbar-collapse-overlay {
    transition: none;
  }
}

.navbar-expand .navbar-collapse-overlay.show {
  transition: all 0.2s ease-in-out;
  transform: translateY(0);

  opacity: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-expand .navbar-collapse-overlay.show {
    transition: none;
  }
}

.navbar-expand .navbar-collapse-overlay .nav-item {
  padding: 0 1.5rem;
}

.navbar-expand .navbar-brand img {
  height: 1.75rem;
}

.navbar-expand .navbar-brand.dropdown-toggle:after {
  display: none;
}

.navbar-expand .navbar-nav .nav-link {
  padding-top: 1rem;
  padding-bottom: 1rem;

  border-radius: 0;
}

.navbar-expand .navbar-nav .nav-link-icon {
  padding-right: 0.75rem;
  padding-left: 0.75rem;

  border-radius: 0;
}

.navbar-expand .navbar-nav .nav-link-icon i {
  margin-right: 0;
}

.navbar-expand .navbar-collapse .collapse-header {
  display: none;
}

.navbar-toggler-icon {
  width: 1.25em;
  height: 1.25em;
}

@keyframes show-navbar-collapse {
  0% {
    transform: translateX(30px);

    opacity: 0;
  }

  100% {
    transform: translateX(0);

    opacity: 1;
  }
}

.pagination {
  margin-bottom: 0;
}

.page-item .page-link,
.page-item > span {
  font-size: 0.875rem;

  margin: 0 3px;

  text-align: center;

  border-radius: 0.2rem;
  box-shadow: 0 0.125rem 0.25rem rgba(31, 45, 61, 0.08);
}

.popover {
  border: 0;
}

.popover-header {
  font-weight: 600;
}

.popover-primary {
  background-color: #008aff;
}

.popover-primary .popover-header {
  color: #fff;
  background-color: #008aff;
}

.popover-primary .popover-body {
  color: #fff;
}

.popover-primary .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}

.popover-primary.bs-popover-top .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #008aff;
}

.popover-primary.bs-popover-right .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #008aff;
}

.popover-primary.bs-popover-bottom .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #008aff;
}

.popover-primary.bs-popover-left .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #008aff;
}

.popover-secondary {
  background-color: #eaecf3;
}

.popover-secondary .popover-header {
  color: #273444;
  background-color: #eaecf3;
}

.popover-secondary .popover-body {
  color: #273444;
}

.popover-secondary .popover-header {
  border-color: rgba(39, 52, 68, 0.2);
}

.popover-secondary.bs-popover-top .arrow::after,
.popover-secondary.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #eaecf3;
}

.popover-secondary.bs-popover-right .arrow::after,
.popover-secondary.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #eaecf3;
}

.popover-secondary.bs-popover-bottom .arrow::after,
.popover-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #eaecf3;
}

.popover-secondary.bs-popover-left .arrow::after,
.popover-secondary.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #eaecf3;
}

.popover-success {
  background-color: #5cc9a7;
}

.popover-success .popover-header {
  color: #fff;
  background-color: #5cc9a7;
}

.popover-success .popover-body {
  color: #fff;
}

.popover-success .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}

.popover-success.bs-popover-top .arrow::after,
.popover-success.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #5cc9a7;
}

.popover-success.bs-popover-right .arrow::after,
.popover-success.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #5cc9a7;
}

.popover-success.bs-popover-bottom .arrow::after,
.popover-success.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #5cc9a7;
}

.popover-success.bs-popover-left .arrow::after,
.popover-success.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #5cc9a7;
}

.popover-info {
  background-color: #50b5ff;
}

.popover-info .popover-header {
  color: #fff;
  background-color: #50b5ff;
}

.popover-info .popover-body {
  color: #fff;
}

.popover-info .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}

.popover-info.bs-popover-top .arrow::after,
.popover-info.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #50b5ff;
}

.popover-info.bs-popover-right .arrow::after,
.popover-info.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #50b5ff;
}

.popover-info.bs-popover-bottom .arrow::after,
.popover-info.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #50b5ff;
}

.popover-info.bs-popover-left .arrow::after,
.popover-info.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #50b5ff;
}

.popover-warning {
  background-color: #ffbe3d;
}

.popover-warning .popover-header {
  color: #fff;
  background-color: #ffbe3d;
}

.popover-warning .popover-body {
  color: #fff;
}

.popover-warning .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}

.popover-warning.bs-popover-top .arrow::after,
.popover-warning.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #ffbe3d;
}

.popover-warning.bs-popover-right .arrow::after,
.popover-warning.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #ffbe3d;
}

.popover-warning.bs-popover-bottom .arrow::after,
.popover-warning.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #ffbe3d;
}

.popover-warning.bs-popover-left .arrow::after,
.popover-warning.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #ffbe3d;
}

.popover-danger {
  background-color: #f25767;
}

.popover-danger .popover-header {
  color: #fff;
  background-color: #f25767;
}

.popover-danger .popover-body {
  color: #fff;
}

.popover-danger .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}

.popover-danger.bs-popover-top .arrow::after,
.popover-danger.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #f25767;
}

.popover-danger.bs-popover-right .arrow::after,
.popover-danger.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #f25767;
}

.popover-danger.bs-popover-bottom .arrow::after,
.popover-danger.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #f25767;
}

.popover-danger.bs-popover-left .arrow::after,
.popover-danger.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #f25767;
}

.popover-light {
  background-color: #eaecf3;
}

.popover-light .popover-header {
  color: #273444;
  background-color: #eaecf3;
}

.popover-light .popover-body {
  color: #273444;
}

.popover-light .popover-header {
  border-color: rgba(39, 52, 68, 0.2);
}

.popover-light.bs-popover-top .arrow::after,
.popover-light.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #eaecf3;
}

.popover-light.bs-popover-right .arrow::after,
.popover-light.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #eaecf3;
}

.popover-light.bs-popover-bottom .arrow::after,
.popover-light.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #eaecf3;
}

.popover-light.bs-popover-left .arrow::after,
.popover-light.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #eaecf3;
}

.popover-dark {
  background-color: #171347;
}

.popover-dark .popover-header {
  color: #fff;
  background-color: #171347;
}

.popover-dark .popover-body {
  color: #fff;
}

.popover-dark .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}

.popover-dark.bs-popover-top .arrow::after,
.popover-dark.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #171347;
}

.popover-dark.bs-popover-right .arrow::after,
.popover-dark.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #171347;
}

.popover-dark.bs-popover-bottom .arrow::after,
.popover-dark.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #171347;
}

.popover-dark.bs-popover-left .arrow::after,
.popover-dark.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #171347;
}

.popover-neutral {
  background-color: #fff;
}

.popover-neutral .popover-header {
  color: #273444;
  background-color: #fff;
}

.popover-neutral .popover-body {
  color: #273444;
}

.popover-neutral .popover-header {
  border-color: rgba(39, 52, 68, 0.2);
}

.popover-neutral.bs-popover-top .arrow::after,
.popover-neutral.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #fff;
}

.popover-neutral.bs-popover-right .arrow::after,
.popover-neutral.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #fff;
}

.popover-neutral.bs-popover-bottom .arrow::after,
.popover-neutral.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #fff;
}

.popover-neutral.bs-popover-left .arrow::after,
.popover-neutral.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #fff;
}

.popover-white {
  background-color: #fff;
}

.popover-white .popover-header {
  color: #273444;
  background-color: #fff;
}

.popover-white .popover-body {
  color: #273444;
}

.popover-white .popover-header {
  border-color: rgba(39, 52, 68, 0.2);
}

.popover-white.bs-popover-top .arrow::after,
.popover-white.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #fff;
}

.popover-white.bs-popover-right .arrow::after,
.popover-white.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #fff;
}

.popover-white.bs-popover-bottom .arrow::after,
.popover-white.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #fff;
}

.popover-white.bs-popover-left .arrow::after,
.popover-white.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #fff;
}

.progress-wrapper {
  position: relative;

  padding-top: 1.5rem;
}

.progress-wrapper .progress {
  margin-bottom: 1rem;
}

.progress-inverse {
  background-color: inverse(#eaecf3);
}

.progress-heading {
  font-size: 0.875rem;
  font-weight: 600;

  margin: 0 0 2px;
  padding: 0;
}

.progress-text {
  margin-bottom: 0;
}

.progress-lg {
  height: 1rem;
}

.progress-md {
  height: 0.75rem;
}

.progress-sm {
  height: 0.375rem;
}

.progress-xs {
  height: 0.125rem;
}

.progress-group {
  position: relative;
}

.progress-prepend-icon {
  font-size: 20px;

  position: absolute;
  top: 50%;

  transform: translateY(-50%);
}

.progress-prepend-icon:not(:first-child) {
  right: -17px;
}

.progress-prepend-icon:not(:last-child) {
  left: -17px;
}

.table thead th {
  font-size: 0.75rem;

  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  letter-spacing: 0;
  text-transform: none;

  border-bottom-width: 1px;
  background-color: #3f3966;
}

.table td .progress {
  width: 120px;
  height: 3px;
  margin: 0;
}

.table td,
.table th {
  font-size: 0.84rem;
  font-weight: 400;
  white-space: nowrap;
}

.table th {
  font-weight: 600;
}

.table.align-items-center td,
.table.align-items-center th {
  vertical-align: middle;
}

.table.table-dark thead th,
.table .thead-dark th {
  color: #4b40cc;
  background-color: #1c1757;
}

.table.table-dark thead th a,
.table .thead-dark th a {
  color: #4b40cc;
}

.table .thead-light th {
  color: #4a5568;
  background-color: transparent;
}

.table .thead-light th a {
  color: #4a5568;
}

.table-hover tr {
  transition: all 0.2s ease;
}

@media (prefers-reduced-motion: reduce) {
  .table-hover tr {
    transition: none;
  }
}

.table-flush td,
.table-flush th {
  border-right: 0;
  border-left: 0;
}

.table-flush tbody tr:first-child td,
.table-flush tbody tr:first-child th {
  border-top: 0;
}

.table-flush tbody tr:last-child td,
.table-flush tbody tr:last-child th {
  border-bottom: 0;
}

.card .table {
  margin-bottom: 0;
}

.card .table td,
.card .table th {
  /* padding-right: 1.75rem; 
    padding-left: 1.75rem; */
}

.table .custom-toggle {
  display: block;
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
  font-weight: 600;
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
  font-weight: 600;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a {
  color: var(--lightvoilet);
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.h1 a:hover,
.h2 a:hover,
.h3 a:hover,
.h4 a:hover,
.h5 a:hover,
.h6 a:hover {
  color: var(--orange);
}

a {
  transition: all 0.2s ease;

  outline: none;
}

@media (prefers-reduced-motion: reduce) {
  a {
    transition: none;
  }
}

.surtitle {
  font-size: 0.675rem;
  font-weight: 400;

  text-transform: uppercase;

  color: #718096;
}

.fluid-paragraph {
  position: relative;

  width: 680px;
  margin: auto;
  padding: 0 1.5rem;
}

.fluid-paragraph-sm {
  width: 580px;
}

@media (max-width: 767.98px) {
  .fluid-paragraph {
    width: 100%;
  }
}

.text-highlight-primary {
  background-image: linear-gradient(
    transparent 66%,
    rgba(0, 138, 255, 0.15) 34%
  );
  background-repeat: repeat-x;
}

.text-highlight-secondary {
  background-image: linear-gradient(
    transparent 66%,
    rgba(234, 236, 243, 0.15) 34%
  );
  background-repeat: repeat-x;
}

.text-highlight-success {
  background-image: linear-gradient(
    transparent 66%,
    rgba(92, 201, 167, 0.15) 34%
  );
  background-repeat: repeat-x;
}

.text-highlight-info {
  background-image: linear-gradient(
    transparent 66%,
    rgba(80, 181, 255, 0.15) 34%
  );
  background-repeat: repeat-x;
}

.text-highlight-warning {
  background-image: linear-gradient(
    transparent 66%,
    rgba(255, 190, 61, 0.15) 34%
  );
  background-repeat: repeat-x;
}

.text-highlight-danger {
  background-image: linear-gradient(
    transparent 66%,
    rgba(242, 87, 103, 0.15) 34%
  );
  background-repeat: repeat-x;
}

.text-highlight-light {
  background-image: linear-gradient(
    transparent 66%,
    rgba(234, 236, 243, 0.15) 34%
  );
  background-repeat: repeat-x;
}

.text-highlight-dark {
  background-image: linear-gradient(
    transparent 66%,
    rgba(23, 19, 71, 0.15) 34%
  );
  background-repeat: repeat-x;
}

.text-highlight-neutral {
  background-image: linear-gradient(
    transparent 66%,
    rgba(255, 255, 255, 0.15) 34%
  );
  background-repeat: repeat-x;
}

.text-highlight-white {
  background-image: linear-gradient(
    transparent 66%,
    rgba(255, 255, 255, 0.15) 34%
  );
  background-repeat: repeat-x;
}

.link {
  transition: all 0.2s ease;
}

@media (prefers-reduced-motion: reduce) {
  .link {
    transition: none;
  }
}

[class*="link-underline-"] {
  position: relative;

  padding-bottom: 8px;
}

[class*="link-underline-"]:after {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 3px;

  content: "";
  transition: all 0.2s cubic-bezier(0.77, 0, 0.2, 2.25);

  border-radius: 50rem;
}

@media (prefers-reduced-motion: reduce) {
  [class*="link-underline-"]:after {
    transition: none;
  }
}

[class*="link-underline-"]:hover:after {
  left: -4px;

  width: calc(100% + 8px);
}

.link-underline-primary {
  color: #008aff;
}

.link-underline-primary:after {
  background-color: #008aff;
}

.link-underline-primary:hover {
  color: #006ecc;
}

.link-underline-secondary {
  color: #eaecf3;
}

.link-underline-secondary:after {
  background-color: #eaecf3;
}

.link-underline-secondary:hover {
  color: #cacfe0;
}

.link-underline-success {
  color: #5cc9a7;
}

.link-underline-success:after {
  background-color: #5cc9a7;
}

.link-underline-success:hover {
  color: #3cb690;
}

.link-underline-info {
  color: #50b5ff;
}

.link-underline-info:after {
  background-color: #50b5ff;
}

.link-underline-info:hover {
  color: #1d9fff;
}

.link-underline-warning {
  color: #3f3966;
  font-weight: 900;
}

.link-underline-warning:after {
  background-color: #3f3966;
}

.link-underline-warning:hover {
  color: #ffad0a;
}

.link-underline-danger {
  color: #f25767;
}

.link-underline-danger:after {
  background-color: #f25767;
}

.link-underline-danger:hover {
  color: #ee283c;
}

.link-underline-light {
  color: #eaecf3;
}

.link-underline-light:after {
  background-color: #eaecf3;
}

.link-underline-light:hover {
  color: #cacfe0;
}

.link-underline-dark {
  color: #171347;
}

.link-underline-dark:after {
  background-color: #171347;
}

.link-underline-dark:hover {
  color: #0a081f;
}

.link-underline-neutral {
  color: #fff;
}

.link-underline-neutral:after {
  background-color: #fff;
}

.link-underline-neutral:hover {
  color: #e6e6e6;
}

.link-underline-white {
  color: #fff;
}

.link-underline-white:after {
  background-color: #fff;
}

.link-underline-white:hover {
  color: #e6e6e6;
}

.blockquote .quote {
  position: absolute;
}

.blockquote .quote:before {
  font-family: Arial;
  font-size: 4em;
  line-height: 1;

  content: "\201C";

  color: #008aff;
}

.blockquote .quote + .quote-text {
  padding-left: 2.25rem;
}

.blockquote footer {
  background: transparent;
}

.blockquote-border-left {
  border-left: 0.5rem solid #008aff;
}

.blockquote-card {
  position: relative;

  padding: 1.2em 30px 1.2em 75px;

  border-left: 0.5rem solid #008aff;
}

.blockquote-card:before {
  font-family: Arial;
  font-size: 4em;

  position: absolute;
  top: -0.875rem;
  left: 0.875rem;

  content: "\201C";

  color: #008aff;
}

.blockquote-card:after {
  content: "";
}

.list-icons li i,
.list-icons li svg {
  font-size: 8px;

  position: relative;
  top: 2px;

  margin-right: 1rem;

  transform: translateY(-50%);
}

article h4:not(:first-child),
article h5:not(:first-child) {
  margin-top: 3rem;
}

article h4,
article h5 {
  margin-bottom: 1.5rem;
}

article figure {
  margin: 3rem 0;
}

article figure + h5 {
  margin-top: 0 !important;
}

article h5 + figure {
  margin-top: 0;
}

article p {
  line-height: 1.9;
}

.feather {
  width: 1em;
  height: 1em;

  fill: none;
  stroke: currentcolor;
  stroke-width: 3px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.svg-text {
  display: inline-flex;

  align-items: center;
}

.svg-text svg {
  position: relative;
}

.navbar {
  position: relative;
  z-index: 101;
}

.navbar .container {
  position: relative;

  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
}

.navbar-nav .nav-link {
  font-size: 0.95rem;
  font-weight: 400;
  transition: all 0.15s linear;
  letter-spacing: 0;
  text-transform: none;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-nav .nav-link {
    transition: none;
  }
}

.navbar-nav .nav-link i,
.navbar-nav .nav-link svg,
.navbar-nav .nav-link img {
  margin-right: 0.5rem;
}

.navbar-nav .media-pill .avatar {
  width: 36px;
  height: 36px;
}

.nav-link > img,
.nav-link > svg {
  position: relative;
  top: -1px;

  height: 14px;
  margin-right: 0.5rem;
}

.nav-link .icon-flag {
  width: 19px;
}

.navbar-text {
  font-size: 1rem;
}

.navbar-user {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-around;
}

.navbar-img-brand {
  font-size: initial;
}

.navbar-shadow {
  box-shadow: 0 0 10px rgba(31, 45, 61, 0.03);
}

.header,
.navbar,
.navbar-top {
  transition: all 0.2s ease;
}

@media (prefers-reduced-motion: reduce) {
  .header,
  .navbar,
  .navbar-top {
    transition: none;
  }
}

.navbar-border.navbar-dark {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.navbar-border.navbar-dark .border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.navbar-border.navbar-light {
  border-bottom: 1px solid rgba(31, 45, 61, 0.04) !important;
}

.navbar-border.navbar-light .border-bottom {
  border-bottom: 1px solid rgba(31, 45, 61, 0.1) !important;
}

.navbar-dark .border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.navbar-light .border-bottom {
  border-bottom: 1px solid rgba(31, 45, 61, 0.1) !important;
}

.header-transparent {
  position: absolute;
  z-index: 1030;
  top: 0;
  left: 0;

  width: 100%;
}

.header-transparent .navbar-main .container:not(.border-0)::after {
  position: absolute;
  right: 0;
  bottom: -0.75rem;
  left: 0;

  content: "";

  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.header-transparent .navbar-top {
  padding-right: 1rem;
  padding-left: 1rem;

  border: 0;
  background-color: transparent !important;
}

.header-transparent .navbar:not(.sticky) {
  border: 0;
  background-color: transparent !important;
  box-shadow: none;
}

.header-transparent
  + main
  section:first-child:not([data-spotlight])
  .container {
  padding-top: 5rem;
}

.header-collapse-show {
  z-index: 1000;
  top: 0;

  width: 100%;
  min-height: 100%;
}

.navbar-sticky {
  position: -webkit-sticky;
}

.navbar-sticky.sticky {
  position: fixed;
  z-index: 1030;
  top: -100px;
  left: 0;

  width: 100%;

  transition: all 0.2s ease;
  transform: translateY(100px);

  border-top: 0;
  box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
}

ul.listnone {
  list-style-type: none;
  margin: 0px 0px;
  padding: 0px 0px;
}

ul.listnone li {
  margin-bottom: 5px;
}

ul.listnone li:hover .rcsicon {
  background: var(--orange);
  outline: 2px var(--orange) solid;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-sticky.sticky {
    transition: none;
  }
}

.navbar-top {
  position: relative;

  display: flex;

  align-items: center;
}

.navbar-top .navbar-nav {
  flex-direction: row;
}

.navbar-top .navbar-nav .nav-item:last-child {
  margin-right: 0;
}

.navbar-top .navbar-nav .nav-link {
  padding: 0.5rem 0.5rem;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-brand img {
    height: 1.75rem;
  }

  .navbar-expand-sm .navbar-brand.dropdown-toggle:after {
    font-size: 1rem;

    float: none;

    opacity: 0.6;
  }

  .navbar-expand-sm .navbar-brand.dropdown-toggle:hover:after {
    opacity: 1;
  }

  .navbar-expand-sm .navbar-nav:not(.flex-row) .nav-link {
    padding: 0.625rem 0;
  }

  .navbar-expand-sm .navbar-nav:not(.flex-row) .dropdown-menu {
    min-width: auto;

    box-shadow: none;
  }

  .navbar-expand-sm .navbar-nav:not(.flex-row) .dropdown-menu .media svg {
    width: 30px;
  }

  .navbar-expand-sm .navbar-nav.flex-row .nav-link {
    padding-right: 0.675rem;
    padding-left: 0.675rem;
  }

  .navbar-expand-sm .navbar-nav.flex-row .dropdown {
    position: static;
  }

  .navbar-expand-sm .navbar-nav.flex-row .dropdown-menu {
    position: absolute;
    top: 60px;
    right: auto;
    left: 15px;

    width: calc(100% - 30px);
  }

  .navbar-expand-sm
    .navbar-nav
    .dropdown:not(.dropdown-submenu)
    > .dropdown-menu {
    display: block !important;
  }

  .navbar-expand-sm .navbar-collapse {
    background: #fff;
  }

  .navbar-expand-sm .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: rgba(31, 45, 61, 0.6);
  }

  .navbar-expand-sm .navbar-collapse .navbar-nav .nav-item-spaced {
    margin-bottom: 1.5rem;
  }

  .navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-item {
    color: rgba(31, 45, 61, 0.8);
  }

  .navbar-expand-sm .navbar-collapse .navbar-toggler {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0.5rem;

    color: rgba(31, 45, 61, 0.6);
  }

  .navbar-expand-sm .navbar-collapse-fade {
    z-index: 1050;

    height: auto !important;

    opacity: 0;
  }

  .navbar-expand-sm .navbar-collapse-fade.collapsing,
  .navbar-expand-sm .navbar-collapse-fade.show {
    animation: show-navbar-collapse 0.2s ease forwards;
    animation-delay: 0.15s;
  }

  .navbar-expand-sm .navbar-collapse-fade.collapsing-out {
    transition: opacity 0.3s linear;
    animation: none;
    animation-delay: 0;

    opacity: 0;
  }

  .navbar-expand-sm .navbar-collapse-overlay {
    position: fixed;
    z-index: 100;
    top: 1rem;
    right: 1rem;
    left: 1rem;

    overflow-y: scroll;

    max-height: calc(100vh - 2rem) !important;
    padding: 1rem 0;

    transition: all 0.2s ease-in-out;
    transition-property: opacity, transform;
    transform: translateY(-100%);
    transform-origin: top center;

    opacity: 0;
    border-radius: 0.375rem;
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);

    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-sm .navbar-collapse-overlay {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-collapse-overlay.show {
    transition: all 0.2s ease-in-out;
    transform: translateY(0);

    opacity: 1;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-sm .navbar-collapse-overlay.show {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-collapse-overlay .nav-item {
    padding: 0 1.5rem;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-brand img {
    height: 1.75rem;
  }

  .navbar-expand-sm .navbar-brand.dropdown-toggle:after {
    display: none;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;

    border-radius: 0;
  }

  .navbar-expand-sm .navbar-nav .nav-link-icon {
    padding-right: 0.75rem;
    padding-left: 0.75rem;

    border-radius: 0;
  }

  .navbar-expand-sm .navbar-nav .nav-link-icon i {
    margin-right: 0;
  }

  .navbar-expand-sm .navbar-collapse .collapse-header {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-brand img {
    height: 1.75rem;
  }

  .navbar-expand-md .navbar-brand.dropdown-toggle:after {
    font-size: 1rem;

    float: none;

    opacity: 0.6;
  }

  .navbar-expand-md .navbar-brand.dropdown-toggle:hover:after {
    opacity: 1;
  }

  .navbar-expand-md .navbar-nav:not(.flex-row) .nav-link {
    padding: 0.625rem 0;
  }

  .navbar-expand-md .navbar-nav:not(.flex-row) .dropdown-menu {
    min-width: auto;

    box-shadow: none;
  }

  .navbar-expand-md .navbar-nav:not(.flex-row) .dropdown-menu .media svg {
    width: 30px;
  }

  .navbar-expand-md .navbar-nav.flex-row .nav-link {
    padding-right: 0.675rem;
    padding-left: 0.675rem;
  }

  .navbar-expand-md .navbar-nav.flex-row .dropdown {
    position: static;
  }

  .navbar-expand-md .navbar-nav.flex-row .dropdown-menu {
    position: absolute;
    top: 60px;
    right: auto;
    left: 15px;

    width: calc(100% - 30px);
  }

  .navbar-expand-md
    .navbar-nav
    .dropdown:not(.dropdown-submenu)
    > .dropdown-menu {
    display: block !important;
  }

  .navbar-expand-md .navbar-collapse {
    background: #fff;
  }

  .navbar-expand-md .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: rgba(31, 45, 61, 0.6);
  }

  .navbar-expand-md .navbar-collapse .navbar-nav .nav-item-spaced {
    margin-bottom: 1.5rem;
  }

  .navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item {
    color: rgba(31, 45, 61, 0.8);
  }

  .navbar-expand-md .navbar-collapse .navbar-toggler {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0.5rem;

    color: rgba(31, 45, 61, 0.6);
  }

  .navbar-expand-md .navbar-collapse-fade {
    z-index: 1050;

    height: auto !important;

    opacity: 0;
  }

  .navbar-expand-md .navbar-collapse-fade.collapsing,
  .navbar-expand-md .navbar-collapse-fade.show {
    animation: show-navbar-collapse 0.2s ease forwards;
    animation-delay: 0.15s;
  }

  .navbar-expand-md .navbar-collapse-fade.collapsing-out {
    transition: opacity 0.3s linear;
    animation: none;
    animation-delay: 0;

    opacity: 0;
  }

  .navbar-expand-md .navbar-collapse-overlay {
    position: fixed;
    z-index: 100;
    top: 1rem;
    right: 1rem;
    left: 1rem;

    overflow-y: scroll;

    max-height: calc(100vh - 2rem) !important;
    padding: 1rem 0;

    transition: all 0.2s ease-in-out;
    transition-property: opacity, transform;
    transform: translateY(-100%);
    transform-origin: top center;

    opacity: 0;
    border-radius: 0.375rem;
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);

    -webkit-overflow-scrolling: touch;
  }

  ul.listnone li a {
    margin-bottom: 0px;
    text-align: left !important;
  }

  .mbnone {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .colsnone {
    padding-left: 0px;
    margin-top: -10px;
  }

  .containfull {
    max-width: 100% !important;
  }

  .graycircle {
    border-radius: 0 !important;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-md .navbar-collapse-overlay {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-collapse-overlay.show {
    transition: all 0.2s ease-in-out;
    transform: translateY(0);

    opacity: 1;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-md .navbar-collapse-overlay.show {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-collapse-overlay .nav-item {
    padding: 0 1.5rem;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-brand img {
    height: 1.75rem;
  }

  .navbar-expand-md .navbar-brand.dropdown-toggle:after {
    display: none;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;

    border-radius: 0;
  }

  .navbar-expand-md .navbar-nav .nav-link-icon {
    padding-right: 0.75rem;
    padding-left: 0.75rem;

    border-radius: 0;
  }

  .navbar-expand-md .navbar-nav .nav-link-icon i {
    margin-right: 0;
  }

  .navbar-expand-md .navbar-collapse .collapse-header {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-brand img {
    height: 1.75rem;
  }

  .navbar-expand-lg .navbar-brand.dropdown-toggle:after {
    font-size: 1rem;

    float: none;

    opacity: 0.6;
  }

  .navbar-expand-lg .navbar-brand.dropdown-toggle:hover:after {
    opacity: 1;
  }

  .navbar-expand-lg .navbar-nav:not(.flex-row) .nav-link {
    padding: 0.625rem 0;
  }

  .navbar-expand-lg .navbar-nav:not(.flex-row) .dropdown-menu {
    min-width: auto;

    box-shadow: none;
  }

  .navbar-expand-lg .navbar-nav:not(.flex-row) .dropdown-menu .media svg {
    width: 30px;
  }

  .navbar-expand-lg .navbar-nav.flex-row .nav-link {
    padding-right: 0.675rem;
    padding-left: 0.675rem;
  }

  .navbar-expand-lg .navbar-nav.flex-row .dropdown {
    position: static;
  }

  .navbar-expand-lg .navbar-nav.flex-row .dropdown-menu {
    position: absolute;
    top: 60px;
    right: auto;
    left: 15px;

    width: calc(100% - 30px);
  }

  /*
  .navbar-expand-lg .navbar-nav .dropdown:not(.dropdown-submenu)>.dropdown-menu {
    display: block !important;
  }
*/
  .navbar-expand-lg .navbar-collapse {
    background: #fff;
  }

  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: rgba(31, 45, 61, 0.6);
  }

  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-item-spaced {
    margin-bottom: 1.5rem;
  }

  .navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-item {
    color: rgba(31, 45, 61, 0.8);
  }

  .navbar-expand-lg .navbar-collapse .navbar-toggler {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0.5rem;

    color: rgba(31, 45, 61, 0.6);
  }

  .navbar-expand-lg .navbar-collapse-fade {
    z-index: 1050;

    height: auto !important;

    opacity: 0;
  }

  .navbar-expand-lg .navbar-collapse-fade.collapsing,
  .navbar-expand-lg .navbar-collapse-fade.show {
    animation: show-navbar-collapse 0.2s ease forwards;
    animation-delay: 0.15s;
  }

  .navbar-expand-lg .navbar-collapse-fade.collapsing-out {
    transition: opacity 0.3s linear;
    animation: none;
    animation-delay: 0;

    opacity: 0;
  }

  .navbar-expand-lg .navbar-collapse-overlay {
    position: fixed;
    z-index: 100;
    top: 1rem;
    right: 1rem;
    left: 1rem;

    overflow-y: scroll;

    max-height: calc(100vh - 2rem) !important;
    padding: 1rem 0;

    transition: all 0.2s ease-in-out;
    transition-property: opacity, transform;
    transform: translateY(-100%);
    transform-origin: top center;

    opacity: 0;
    border-radius: 0.375rem;
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);

    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-lg .navbar-collapse-overlay {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-collapse-overlay.show {
    transition: all 0.2s ease-in-out;
    transform: translateY(0);

    opacity: 1;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-lg .navbar-collapse-overlay.show {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-collapse-overlay .nav-item {
    padding: 0 1.5rem;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-brand img {
    height: 30px;
  }

  .navbar-expand-lg .navbar-brand.dropdown-toggle:after {
    display: none;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;

    border-radius: 0;
  }

  .navbar-expand-lg .navbar-nav .nav-link-icon {
    padding-right: 0.75rem;
    padding-left: 0.75rem;

    border-radius: 0;
  }

  .navbar-expand-lg .navbar-nav .nav-link-icon i {
    margin-right: 0;
  }

  .navbar-expand-lg .navbar-collapse .collapse-header {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-brand img {
    height: 1.75rem;
  }

  .navbar-expand-xl .navbar-brand.dropdown-toggle:after {
    font-size: 1rem;

    float: none;

    opacity: 0.6;
  }

  .navbar-expand-xl .navbar-brand.dropdown-toggle:hover:after {
    opacity: 1;
  }

  .navbar-expand-xl .navbar-nav:not(.flex-row) .nav-link {
    padding: 0.625rem 0;
  }

  .navbar-expand-xl .navbar-nav:not(.flex-row) .dropdown-menu {
    min-width: auto;

    box-shadow: none;
  }

  .navbar-expand-xl .navbar-nav:not(.flex-row) .dropdown-menu .media svg {
    width: 30px;
  }

  .navbar-expand-xl .navbar-nav.flex-row .nav-link {
    padding-right: 0.675rem;
    padding-left: 0.675rem;
  }

  .navbar-expand-xl .navbar-nav.flex-row .dropdown {
    position: static;
  }

  .navbar-expand-xl .navbar-nav.flex-row .dropdown-menu {
    position: absolute;
    top: 60px;
    right: auto;
    left: 15px;

    width: calc(100% - 30px);
  }

  .navbar-expand-xl
    .navbar-nav
    .dropdown:not(.dropdown-submenu)
    > .dropdown-menu {
    display: block !important;
  }

  .navbar-expand-xl .navbar-collapse {
    background: #fff;
  }

  .navbar-expand-xl .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: rgba(31, 45, 61, 0.6);
  }

  .navbar-expand-xl .navbar-collapse .navbar-nav .nav-item-spaced {
    margin-bottom: 1.5rem;
  }

  .navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-item {
    color: rgba(31, 45, 61, 0.8);
  }

  .navbar-expand-xl .navbar-collapse .navbar-toggler {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0.5rem;

    color: rgba(31, 45, 61, 0.6);
  }

  .navbar-expand-xl .navbar-collapse-fade {
    z-index: 1050;

    height: auto !important;

    opacity: 0;
  }

  .navbar-expand-xl .navbar-collapse-fade.collapsing,
  .navbar-expand-xl .navbar-collapse-fade.show {
    animation: show-navbar-collapse 0.2s ease forwards;
    animation-delay: 0.15s;
  }

  .navbar-expand-xl .navbar-collapse-fade.collapsing-out {
    transition: opacity 0.3s linear;
    animation: none;
    animation-delay: 0;

    opacity: 0;
  }

  .navbar-expand-xl .navbar-collapse-overlay {
    position: fixed;
    z-index: 100;
    top: 1rem;
    right: 1rem;
    left: 1rem;

    overflow-y: scroll;

    max-height: calc(100vh - 2rem) !important;
    padding: 1rem 0;

    transition: all 0.2s ease-in-out;
    transition-property: opacity, transform;
    transform: translateY(-100%);
    transform-origin: top center;

    opacity: 0;
    border-radius: 0.375rem;
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);

    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-xl .navbar-collapse-overlay {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-collapse-overlay.show {
    transition: all 0.2s ease-in-out;
    transform: translateY(0);

    opacity: 1;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-xl .navbar-collapse-overlay.show {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-collapse-overlay .nav-item {
    padding: 0 1.5rem;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-brand img {
    height: 1.75rem;
  }

  .navbar-expand-xl .navbar-brand.dropdown-toggle:after {
    display: none;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;

    border-radius: 0;
  }

  .navbar-expand-xl .navbar-nav .nav-link-icon {
    padding-right: 0.75rem;
    padding-left: 0.75rem;

    border-radius: 0;
  }

  .navbar-expand-xl .navbar-nav .nav-link-icon i {
    margin-right: 0;
  }

  .navbar-expand-xl .navbar-collapse .collapse-header {
    display: none;
  }
}

.navbar-expand .navbar-brand img {
  height: 1.75rem;
}

.navbar-expand .navbar-brand.dropdown-toggle:after {
  font-size: 1rem;

  float: none;

  opacity: 0.6;
}

.navbar-expand .navbar-brand.dropdown-toggle:hover:after {
  opacity: 1;
}

.navbar-expand .navbar-nav:not(.flex-row) .nav-link {
  padding: 0.625rem 0;
}

.navbar-expand .navbar-nav:not(.flex-row) .dropdown-menu {
  min-width: auto;

  box-shadow: none;
}

.navbar-expand .navbar-nav:not(.flex-row) .dropdown-menu .media svg {
  width: 30px;
}

.navbar-expand .navbar-nav.flex-row .nav-link {
  padding-right: 0.675rem;
  padding-left: 0.675rem;
}

.navbar-expand .navbar-nav.flex-row .dropdown {
  position: static;
}

.navbar-expand .navbar-nav.flex-row .dropdown-menu {
  position: absolute;
  top: 60px;
  right: auto;
  left: 15px;

  width: calc(100% - 30px);
}

.navbar-expand .navbar-nav .dropdown:not(.dropdown-submenu) > .dropdown-menu {
  display: block !important;
}

.navbar-expand .navbar-collapse {
  background: #fff;
}

.navbar-expand .navbar-collapse .navbar-nav .nav-item .nav-link {
  color: rgba(31, 45, 61, 0.6);
}

.navbar-expand .navbar-collapse .navbar-nav .nav-item-spaced {
  margin-bottom: 1.5rem;
}

.navbar-expand .navbar-collapse .navbar-nav .dropdown-item {
  color: rgba(31, 45, 61, 0.8);
}

.navbar-expand .navbar-collapse .navbar-toggler {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0.5rem;

  color: rgba(31, 45, 61, 0.6);
}

.navbar-expand .navbar-collapse-fade {
  z-index: 1050;

  height: auto !important;

  opacity: 0;
}

.navbar-expand .navbar-collapse-fade.collapsing,
.navbar-expand .navbar-collapse-fade.show {
  animation: show-navbar-collapse 0.2s ease forwards;
  animation-delay: 0.15s;
}

.navbar-expand .navbar-collapse-fade.collapsing-out {
  transition: opacity 0.3s linear;
  animation: none;
  animation-delay: 0;

  opacity: 0;
}

.navbar-expand .navbar-collapse-overlay {
  position: fixed;
  z-index: 100;
  top: 1rem;
  right: 1rem;
  left: 1rem;

  overflow-y: scroll;

  max-height: calc(100vh - 2rem) !important;
  padding: 1rem 0;

  transition: all 0.2s ease-in-out;
  transition-property: opacity, transform;
  transform: translateY(-100%);
  transform-origin: top center;

  opacity: 0;
  border-radius: 0.375rem;
  box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);

  -webkit-overflow-scrolling: touch;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-expand .navbar-collapse-overlay {
    transition: none;
  }
}

.navbar-expand .navbar-collapse-overlay.show {
  transition: all 0.2s ease-in-out;
  transform: translateY(0);

  opacity: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-expand .navbar-collapse-overlay.show {
    transition: none;
  }
}

.navbar-expand .navbar-collapse-overlay .nav-item {
  padding: 0 1.5rem;
}

.navbar-expand .navbar-brand img {
  height: 1.75rem;
}

.navbar-expand .navbar-brand.dropdown-toggle:after {
  display: none;
}

.navbar-expand .navbar-nav .nav-link {
  padding-top: 1rem;
  padding-bottom: 1rem;

  border-radius: 0;
}

.navbar-expand .navbar-nav .nav-link-icon {
  padding-right: 0.75rem;
  padding-left: 0.75rem;

  border-radius: 0;
}

.navbar-expand .navbar-nav .nav-link-icon i {
  margin-right: 0;
}

.navbar-expand .navbar-collapse .collapse-header {
  display: none;
}

.navbar-toggler-icon {
  width: 1.25em;
  height: 1.25em;
}

@keyframes show-navbar-collapse {
  0% {
    transform: translateX(30px);

    opacity: 0;
  }

  100% {
    transform: translateX(0);

    opacity: 1;
  }
}

.navbar .dropdown-menu {
  min-width: 16rem;
  border: 0 solid rgba(31, 45, 61, 0);
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 1.5rem 4rem rgba(31, 45, 61, 0.15);
}

.navbar .dropdown-menu .list-group .list-group-item {
  padding-right: 0;
  padding-left: 0;

  background: transparent;
}

.navbar .dropdown-menu .list-group .list-group-item:hover .heading {
  color: rgba(65, 95, 129, 0.8);
}

.navbar .dropdown-header {
  padding: 0;

  color: #3b485e;
}

.navbar .dropdown-item {
  font-size: 0.9rem;
  font-weight: 400;
  padding: 0.375rem 1rem;
  color: rgba(31, 45, 61, 0.8);
}

.navbar .dropdown-item:first-child {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.navbar .dropdown-item:last-child {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.navbar .dropdown-item:hover,
.navbar .dropdown-item:focus {
  text-decoration: none;
  color: var(--lightvoilet);
  background: #f9f8fe;
}

.navbar .dropdown-item.active,
.navbar .dropdown-item:active {
  text-decoration: none;

  color: rgba(65, 95, 129, 0.8);
  background-color: transparent;
}

.navbar .dropdown-img-left {
  display: flex;
  flex-direction: column;

  height: 100%;
  padding: 1.625rem 1.75rem;

  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  background: no-repeat center center/cover;

  justify-content: center;
  align-items: center;
}

.navbar .dropdown-img-left:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: "";

  border-radius: inherit;
  background-color: rgba(0, 138, 255, 0.8);
}

.navbar .dropdown-menu-xl,
.navbar .dropdown-menu-lg {
  padding: 2rem 2.5rem;
}

.navbar .dropdown-menu-xl .dropdown-header,
.navbar .dropdown-menu-lg .dropdown-header {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;

  color: #008aff;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-collapse .dropdown-menu {
    padding-right: 0;
    padding-left: 0;

    border-radius: 0;
    background: transparent;
  }

  .navbar-expand-sm .navbar-collapse .dropdown-menu.show {
    border-bottom: 1px solid #eaecf3;
  }

  .navbar-expand-sm .navbar-collapse .dropdown-menu-single {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1rem;

    border-left: 3px solid #008aff;
  }

  .navbar-expand-sm .navbar-collapse .dropdown-item {
    font-size: 1rem;
    font-weight: 600;

    position: relative;
  }

  .navbar-expand-sm .navbar-collapse .dropdown-img-left {
    border-radius: 0.5rem;
  }

  .navbar-expand-sm
    .navbar-collapse
    .dropdown-submenu.show
    .dropdown-toggle:after {
    transition: all 0.2s ease;
    transform: rotate(90deg);
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-sm
    .navbar-collapse
    .dropdown-submenu.show
    .dropdown-toggle:after {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-collapse .delimiter {
    padding-top: 2.5rem;

    border-top: 1px solid #e2e8f0;
  }

  .navbar-expand-sm .navbar-collapse .navbar-btn {
    width: 100%;
    margin-top: 1rem;
    padding: 1rem 1.75rem;

    color: #008aff;
    border: 0;
    border-top: 1px solid #e2e8f0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: transparent;
  }

  .navbar-expand-sm
    .navbar-collapse
    .nav-item.dropdown
    .dropdown-toggle.nav-link:after {
    transition: all 0.2s ease;
    transform: rotate(-90deg);
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-sm
    .navbar-collapse
    .nav-item.dropdown
    .dropdown-toggle.nav-link:after {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-collapse .nav-item.dropdown .dropdown-toggle:after {
    font-size: 0.75rem;

    float: right;
  }

  .navbar-expand-sm
    .navbar-collapse
    .nav-item.dropdown.show
    .dropdown-toggle.nav-link:after {
    transform: rotate(0);
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm
    .dropdown:not(.dropdown-submenu):not(.dropdown-fluid)
    > .dropdown-menu:not(.dropdown-menu-right) {
    margin-left: -1.25rem;
  }

  .navbar-expand-sm
    .dropdown:not(.dropdown-submenu):not(.dropdown-fluid)
    > .dropdown-menu-right {
    margin-right: -1.25rem;
  }

  .navbar-expand-sm
    .dropdown-menu
    .list-group-emphasized
    > li
    .list-group-item {
    transition: all 0.2s ease;
    transform: scale(1);
  }
}

@media (min-width: 576px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-sm
    .dropdown-menu
    .list-group-emphasized
    > li
    .list-group-item {
    transition: none;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm .dropdown-menu .list-group-emphasized > li .media-body * {
    transition: all 0.2s ease;
  }
}

@media (min-width: 576px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-sm .dropdown-menu .list-group-emphasized > li .media-body * {
    transition: none;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm
    .dropdown-menu
    .list-group-emphasized
    > li:hover
    .list-group-item {
    z-index: 11;

    transform: scale(1.05);

    border-radius: 0.5rem;
    background-color: #fafbfe;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05);
  }

  .navbar-expand-sm .list-group .list-group-item:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .navbar-expand-sm .list-group .list-group-item:last-child {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .navbar-expand-sm .list-group > li:first-child .list-group-item {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .navbar-expand-sm .list-group > li:last-child .list-group-item {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .navbar-expand-sm .bg-dropdown-secondary {
    background-color: #fafafa;
  }

  .navbar-expand-sm .dropdown-body-left {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .navbar-expand-sm .dropdown-body-right {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .navbar-expand-sm .dropdown-submenu .dropdown-menu {
    min-width: 16rem;
    margin-left: 1rem;
  }

  .navbar-expand-sm .dropdown-submenu .dropdown-menu:before {
    position: absolute;
    top: 0;
    left: -1rem;

    height: 100%;

    content: "";

    border-left: 1.5rem solid transparent;
  }

  .navbar-expand-sm .dropdown-submenu .dropdown-item:after {
    right: 0;
  }

  .navbar-expand-sm .dropdown-body {
    padding: 2rem 2.5rem;
  }

  .navbar-expand-sm .delimiter {
    border-right: 1px solid #e2e8f0;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-collapse .dropdown-menu {
    padding-right: 0;
    padding-left: 0;

    border-radius: 0;
    background: transparent;
  }

  .navbar-expand-md .navbar-collapse .dropdown-menu.show {
    border-bottom: 1px solid #eaecf3;
  }

  .navbar-expand-md .navbar-collapse .dropdown-menu-single {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1rem;

    border-left: 3px solid #008aff;
  }

  .navbar-expand-md .navbar-collapse .dropdown-item {
    font-size: 1rem;
    font-weight: 600;

    position: relative;
  }

  .navbar-expand-md .navbar-collapse .dropdown-img-left {
    border-radius: 0.5rem;
  }

  .navbar-expand-md
    .navbar-collapse
    .dropdown-submenu.show
    .dropdown-toggle:after {
    transition: all 0.2s ease;
    transform: rotate(90deg);
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-md
    .navbar-collapse
    .dropdown-submenu.show
    .dropdown-toggle:after {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-collapse .delimiter {
    padding-top: 2.5rem;

    border-top: 1px solid #e2e8f0;
  }

  .navbar-expand-md .navbar-collapse .navbar-btn {
    width: 100%;
    margin-top: 1rem;
    padding: 1rem 1.75rem;

    color: #008aff;
    border: 0;
    border-top: 1px solid #e2e8f0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: transparent;
  }

  .navbar-expand-md
    .navbar-collapse
    .nav-item.dropdown
    .dropdown-toggle.nav-link:after {
    transition: all 0.2s ease;
    transform: rotate(-90deg);
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-md
    .navbar-collapse
    .nav-item.dropdown
    .dropdown-toggle.nav-link:after {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-collapse .nav-item.dropdown .dropdown-toggle:after {
    font-size: 0.75rem;

    float: right;
  }

  .navbar-expand-md
    .navbar-collapse
    .nav-item.dropdown.show
    .dropdown-toggle.nav-link:after {
    transform: rotate(0);
  }
}

@media (min-width: 768px) {
  .navbar-expand-md
    .dropdown:not(.dropdown-submenu):not(.dropdown-fluid)
    > .dropdown-menu:not(.dropdown-menu-right) {
    margin-left: -1.25rem;
  }

  .navbar-expand-md
    .dropdown:not(.dropdown-submenu):not(.dropdown-fluid)
    > .dropdown-menu-right {
    margin-right: -1.25rem;
  }

  .navbar-expand-md
    .dropdown-menu
    .list-group-emphasized
    > li
    .list-group-item {
    transition: all 0.2s ease;
    transform: scale(1);
  }
}

@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-md
    .dropdown-menu
    .list-group-emphasized
    > li
    .list-group-item {
    transition: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .dropdown-menu .list-group-emphasized > li .media-body * {
    transition: all 0.2s ease;
  }
}

@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-md .dropdown-menu .list-group-emphasized > li .media-body * {
    transition: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md
    .dropdown-menu
    .list-group-emphasized
    > li:hover
    .list-group-item {
    z-index: 11;

    transform: scale(1.05);

    border-radius: 0.5rem;
    background-color: #fafbfe;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05);
  }

  .navbar-expand-md .list-group .list-group-item:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .navbar-expand-md .list-group .list-group-item:last-child {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .navbar-expand-md .list-group > li:first-child .list-group-item {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .navbar-expand-md .list-group > li:last-child .list-group-item {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .navbar-expand-md .bg-dropdown-secondary {
    background-color: #fafafa;
  }

  .navbar-expand-md .dropdown-body-left {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .navbar-expand-md .dropdown-body-right {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .navbar-expand-md .dropdown-submenu .dropdown-menu {
    min-width: 16rem;
    margin-left: 1rem;
  }

  .navbar-expand-md .dropdown-submenu .dropdown-menu:before {
    position: absolute;
    top: 0;
    left: -1rem;

    height: 100%;

    content: "";

    border-left: 1.5rem solid transparent;
  }

  .navbar-expand-md .dropdown-submenu .dropdown-item:after {
    right: 0;
  }

  .navbar-expand-md .dropdown-body {
    padding: 2rem 2.5rem;
  }

  .navbar-expand-md .delimiter {
    border-right: 1px solid #e2e8f0;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-collapse .dropdown-menu {
    padding-right: 0;
    padding-left: 0;

    border-radius: 0;
    background: transparent;
  }

  .navbar-expand-lg .navbar-collapse .dropdown-menu-single {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1rem;

    border-left: 3px solid #008aff;
  }

  .navbar-expand-lg .navbar-collapse .dropdown-item {
    font-size: 0.9rem;
    font-weight: 400;
    position: relative;
  }

  .navbar-expand-lg .navbar-collapse .dropdown-img-left {
    border-radius: 0.5rem;
  }

  .navbar-expand-lg
    .navbar-collapse
    .dropdown-submenu.show
    .dropdown-toggle:after {
    transition: all 0.2s ease;
    transform: rotate(90deg);
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-lg
    .navbar-collapse
    .dropdown-submenu.show
    .dropdown-toggle:after {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-collapse .delimiter {
    padding-top: 2.5rem;

    border-top: 1px solid #e2e8f0;
  }

  .navbar-expand-lg .navbar-collapse .navbar-btn {
    width: 100%;
    margin-top: 1rem;
    padding: 1rem 1.75rem;

    color: #008aff;
    border: 0;
    border-top: 1px solid #e2e8f0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: transparent;
  }

  /*
  .navbar-expand-lg .navbar-collapse .nav-item.dropdown .dropdown-toggle.nav-link:after {
    transition: all 0.2s ease;
    transform: rotate(-90deg);
  } */
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-lg
    .navbar-collapse
    .nav-item.dropdown
    .dropdown-toggle.nav-link:after {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-collapse .nav-item.dropdown .dropdown-toggle:after {
    font-size: 0.75rem;

    float: right;
  }

  .navbar-expand-lg
    .navbar-collapse
    .nav-item.dropdown.show
    .dropdown-toggle.nav-link:after {
    transform: rotate(0);
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg
    .dropdown:not(.dropdown-submenu):not(.dropdown-fluid)
    > .dropdown-menu:not(.dropdown-menu-right) {
    margin-left: -1.25rem;
  }

  .navbar-expand-lg
    .dropdown:not(.dropdown-submenu):not(.dropdown-fluid)
    > .dropdown-menu-right {
    margin-right: -1.25rem;
  }

  .navbar-expand-lg
    .dropdown-menu
    .list-group-emphasized
    > li
    .list-group-item {
    transition: all 0.2s ease;
    transform: scale(1);
  }
}

@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-lg
    .dropdown-menu
    .list-group-emphasized
    > li
    .list-group-item {
    transition: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .dropdown-menu .list-group-emphasized > li .media-body * {
    transition: all 0.2s ease;
  }
}

@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-lg .dropdown-menu .list-group-emphasized > li .media-body * {
    transition: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg
    .dropdown-menu
    .list-group-emphasized
    > li:hover
    .list-group-item {
    z-index: 11;

    transform: scale(1.05);

    border-radius: 0.5rem;
    background-color: #fafbfe;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05);
  }

  .navbar-expand-lg .list-group .list-group-item:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .navbar-expand-lg .list-group .list-group-item:last-child {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .navbar-expand-lg .list-group > li:first-child .list-group-item {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .navbar-expand-lg .list-group > li:last-child .list-group-item {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .navbar-expand-lg .bg-dropdown-secondary {
    background-color: #fafafa;
  }

  .navbar-expand-lg .dropdown-body-left {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .navbar-expand-lg .dropdown-body-right {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .navbar-expand-lg .dropdown-submenu .dropdown-menu {
    min-width: 16rem;
    margin-left: 1rem;
  }

  .navbar-expand-lg .dropdown-submenu .dropdown-menu:before {
    position: absolute;
    top: 0;
    left: -1rem;

    height: 100%;

    content: "";

    border-left: 1.5rem solid transparent;
  }

  .navbar-expand-lg .dropdown-submenu .dropdown-item:after {
    right: 0;
  }

  .navbar-expand-lg .dropdown-body {
    padding: 2rem 2.5rem;
  }

  .navbar-expand-lg .delimiter {
    border-right: 1px solid #e2e8f0;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-collapse .dropdown-menu {
    padding-right: 0;
    padding-left: 0;

    border-radius: 0;
    background: transparent;
  }

  .navbar-expand-xl .navbar-collapse .dropdown-menu.show {
    border-bottom: 1px solid #eaecf3;
  }

  .navbar-expand-xl .navbar-collapse .dropdown-menu-single {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1rem;

    border-left: 3px solid #008aff;
  }

  .navbar-expand-xl .navbar-collapse .dropdown-item {
    font-size: 1rem;
    font-weight: 600;

    position: relative;
  }

  .navbar-expand-xl .navbar-collapse .dropdown-img-left {
    border-radius: 0.5rem;
  }

  .navbar-expand-xl
    .navbar-collapse
    .dropdown-submenu.show
    .dropdown-toggle:after {
    transition: all 0.2s ease;
    transform: rotate(90deg);
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-xl
    .navbar-collapse
    .dropdown-submenu.show
    .dropdown-toggle:after {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-collapse .delimiter {
    padding-top: 2.5rem;

    border-top: 1px solid #e2e8f0;
  }

  .navbar-expand-xl .navbar-collapse .navbar-btn {
    width: 100%;
    margin-top: 1rem;
    padding: 1rem 1.75rem;

    color: #008aff;
    border: 0;
    border-top: 1px solid #e2e8f0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: transparent;
  }

  .navbar-expand-xl
    .navbar-collapse
    .nav-item.dropdown
    .dropdown-toggle.nav-link:after {
    transition: all 0.2s ease;
    transform: rotate(-90deg);
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-xl
    .navbar-collapse
    .nav-item.dropdown
    .dropdown-toggle.nav-link:after {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-collapse .nav-item.dropdown .dropdown-toggle:after {
    font-size: 0.75rem;

    float: right;
  }

  .navbar-expand-xl
    .navbar-collapse
    .nav-item.dropdown.show
    .dropdown-toggle.nav-link:after {
    transform: rotate(0);
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl
    .dropdown:not(.dropdown-submenu):not(.dropdown-fluid)
    > .dropdown-menu:not(.dropdown-menu-right) {
    margin-left: -1.25rem;
  }

  .navbar-expand-xl
    .dropdown:not(.dropdown-submenu):not(.dropdown-fluid)
    > .dropdown-menu-right {
    margin-right: -1.25rem;
  }

  .navbar-expand-xl
    .dropdown-menu
    .list-group-emphasized
    > li
    .list-group-item {
    transition: all 0.2s ease;
    transform: scale(1);
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-xl
    .dropdown-menu
    .list-group-emphasized
    > li
    .list-group-item {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .dropdown-menu .list-group-emphasized > li .media-body * {
    transition: all 0.2s ease;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-xl .dropdown-menu .list-group-emphasized > li .media-body * {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl
    .dropdown-menu
    .list-group-emphasized
    > li:hover
    .list-group-item {
    z-index: 11;

    transform: scale(1.05);

    border-radius: 0.5rem;
    background-color: #fafbfe;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05);
  }

  .navbar-expand-xl .list-group .list-group-item:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .navbar-expand-xl .list-group .list-group-item:last-child {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .navbar-expand-xl .list-group > li:first-child .list-group-item {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .navbar-expand-xl .list-group > li:last-child .list-group-item {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .navbar-expand-xl .bg-dropdown-secondary {
    background-color: #fafafa;
  }

  .navbar-expand-xl .dropdown-body-left {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .navbar-expand-xl .dropdown-body-right {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .navbar-expand-xl .dropdown-submenu .dropdown-menu {
    min-width: 16rem;
    margin-left: 1rem;
  }

  .navbar-expand-xl .dropdown-submenu .dropdown-menu:before {
    position: absolute;
    top: 0;
    left: -1rem;

    height: 100%;

    content: "";

    border-left: 1.5rem solid transparent;
  }

  .navbar-expand-xl .dropdown-submenu .dropdown-item:after {
    right: 0;
  }

  .navbar-expand-xl .dropdown-body {
    padding: 2rem 2.5rem;
  }

  .navbar-expand-xl .delimiter {
    border-right: 1px solid #e2e8f0;
  }
}

.navbar-expand .navbar-collapse .dropdown-menu {
  padding-right: 0;
  padding-left: 0;

  border-radius: 0;
  background: transparent;
}

.navbar-expand .navbar-collapse .dropdown-menu.show {
  border-bottom: 1px solid #eaecf3;
}

.navbar-expand .navbar-collapse .dropdown-menu-single {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1rem;

  border-left: 3px solid #008aff;
}

.navbar-expand .navbar-collapse .dropdown-item {
  font-size: 1rem;
  font-weight: 600;

  position: relative;
}

.navbar-expand .navbar-collapse .dropdown-img-left {
  border-radius: 0.5rem;
}

.navbar-expand .navbar-collapse .dropdown-submenu.show .dropdown-toggle:after {
  transition: all 0.2s ease;
  transform: rotate(90deg);
}

@media (prefers-reduced-motion: reduce) {
  .navbar-expand
    .navbar-collapse
    .dropdown-submenu.show
    .dropdown-toggle:after {
    transition: none;
  }
}

.navbar-expand .navbar-collapse .delimiter {
  padding-top: 2.5rem;

  border-top: 1px solid #e2e8f0;
}

.navbar-expand .navbar-collapse .navbar-btn {
  width: 100%;
  margin-top: 1rem;
  padding: 1rem 1.75rem;

  color: #008aff;
  border: 0;
  border-top: 1px solid #e2e8f0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: transparent;
}

.navbar-expand
  .navbar-collapse
  .nav-item.dropdown
  .dropdown-toggle.nav-link:after {
  transition: all 0.2s ease;
  transform: rotate(-90deg);
}

@media (prefers-reduced-motion: reduce) {
  .navbar-expand
    .navbar-collapse
    .nav-item.dropdown
    .dropdown-toggle.nav-link:after {
    transition: none;
  }
}

.navbar-expand .navbar-collapse .nav-item.dropdown .dropdown-toggle:after {
  font-size: 0.75rem;

  float: right;
}

.navbar-expand
  .navbar-collapse
  .nav-item.dropdown.show
  .dropdown-toggle.nav-link:after {
  transform: rotate(0);
}

.navbar-expand
  .dropdown:not(.dropdown-submenu):not(.dropdown-fluid)
  > .dropdown-menu:not(.dropdown-menu-right) {
  margin-left: -1.25rem;
}

.navbar-expand
  .dropdown:not(.dropdown-submenu):not(.dropdown-fluid)
  > .dropdown-menu-right {
  margin-right: -1.25rem;
}

.navbar-expand .dropdown-menu .list-group-emphasized > li .list-group-item {
  transition: all 0.2s ease;
  transform: scale(1);
}

@media (prefers-reduced-motion: reduce) {
  .navbar-expand .dropdown-menu .list-group-emphasized > li .list-group-item {
    transition: none;
  }
}

.navbar-expand .dropdown-menu .list-group-emphasized > li .media-body * {
  transition: all 0.2s ease;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-expand .dropdown-menu .list-group-emphasized > li .media-body * {
    transition: none;
  }
}

.navbar-expand
  .dropdown-menu
  .list-group-emphasized
  > li:hover
  .list-group-item {
  z-index: 11;

  transform: scale(1.05);

  border-radius: 0.5rem;
  background-color: #fafbfe;
  box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05);
}

.navbar-expand .list-group .list-group-item:first-child {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.navbar-expand .list-group .list-group-item:last-child {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.navbar-expand .list-group > li:first-child .list-group-item {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.navbar-expand .list-group > li:last-child .list-group-item {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.navbar-expand .bg-dropdown-secondary {
  background-color: #fafafa;
}

.navbar-expand .dropdown-body-left {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.navbar-expand .dropdown-body-right {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.navbar-expand .dropdown-submenu .dropdown-menu {
  min-width: 16rem;
  margin-left: 1rem;
}

.navbar-expand .dropdown-submenu .dropdown-menu:before {
  position: absolute;
  top: 0;
  left: -1rem;

  height: 100%;

  content: "";

  border-left: 1.5rem solid transparent;
}

.navbar-expand .dropdown-submenu .dropdown-item:after {
  right: 0;
}

.navbar-expand .dropdown-body {
  padding: 2rem 2.5rem;
}

.navbar-expand .delimiter {
  border-right: 1px solid #e2e8f0;
}

.slice {
  position: relative;

  padding-top: 4rem;
  padding-bottom: 4rem;
}

.slice-xl {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.slice-lg {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.slice-sm {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.slice:not(.border-top):not(.border-bottom):not(.delimiter-bottom):not(
    [class*="bg-"]
  ):not(.section-rotate)
  + .slice:not(.border-top):not(.border-bottom):not(.delimiter-top):not(
    [class*="bg-"]
  ) {
  padding-top: 0;
}

.slice-video {
  min-height: 600px;
}

.shape-container {
  position: absolute;
  z-index: 2;
  right: 0;
  left: 0;

  overflow: hidden;

  width: 100%;

  transform: translateZ(0);
}

.shape-container svg {
  display: block;

  vertical-align: baseline;
  pointer-events: none;

  fill: #fff;
}

.shape-position-top {
  top: 0;

  margin-top: -1px;
}

.shape-position-bottom {
  bottom: 0;

  margin-bottom: -1px;
}

.shape-orientation-inverse {
  transform: rotate(180deg);
}

.shape-line {
  min-height: 19px;
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .ie-shape-line {
    height: 100px;
  }

  .ie-shape-wave-1 {
    height: 504px;
  }

  .ie-shape-wave-3 {
    height: 231px;
  }

  .ie-shape-clouds {
    height: 400px;
  }
}

.highlight {
  margin-bottom: 1rem;
  padding: 1.25rem;

  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  background-color: #13103b;
}

.highlight pre {
  margin-bottom: 0;
  padding: 1.25rem;
}

.hljs {
  display: block;
  overflow-x: auto;

  padding: 0.5em;

  color: #dcdcdc;
}

.hljs pre code {
  color: #dcdcdc;
}

.hljs-keyword,
.hljs-literal,
.hljs-symbol,
.hljs-name {
  color: #569cd6;
}

.hljs-link {
  text-decoration: underline;

  color: #569cd6;
}

.hljs-built_in,
.hljs-type {
  color: #4ec9b0;
}

.hljs-number,
.hljs-class {
  color: #b8d7a3;
}

.hljs-string,
.hljs-meta-string {
  color: #d69d85;
}

.hljs-regexp,
.hljs-template-tag {
  color: #9a5334;
}

.hljs-subst,
.hljs-function,
.hljs-title,
.hljs-params,
.hljs-formula {
  color: #dcdcdc;
}

.hljs-comment,
.hljs-quote {
  font-style: italic;

  color: #57a64a;
}

.hljs-doctag {
  color: #608b4e;
}

.hljs-meta,
.hljs-meta-keyword,
.hljs-tag {
  color: #9b9b9b;
}

.hljs-variable,
.hljs-template-variable {
  color: #bd63c5;
}

.hljs-attr,
.hljs-attribute,
.hljs-builtin-name {
  color: #9cdcfe;
}

.hljs-section {
  color: gold;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-bullet,
.hljs-selector-tag,
.hljs-selector-id,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo {
  color: #d7ba7d;
}

.hljs-addition {
  display: inline-block;

  width: 100%;

  background-color: #144212;
}

.hljs-deletion {
  display: inline-block;

  width: 100%;

  background-color: #600;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .navbar .btn {
    padding: 10px;
  }

  span.homeIcon svg {
    font-size: 21px;
    right: 1px;
  }

  .homeIcon {
    position: absolute;
    right: 7px;
    top: 21px;
  }

  .bottmHome span {
    font-size: 11px;
  }
}

/** Start of new submenu link **/

.new_samplecode li a {
  padding: 7px 10px;
  width: 100%;
  display: block;
  font-size: 15px;
}

.new_samplecode li a.activate {
  background: #3f3966;
  color: #fff !important;
  border-radius: 5px;
}

.table_api {
  border: 1px solid #eaecf3;
}

.table_api td {
  padding: 5px 10px;
  border: 1px solid #eaecf3;
}

.table_api td img {
  margin: 0px auto;
  display: block;
}

.table_api td img:hover {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.bg-darkbg {
  background: rgb(249, 250, 250);
}

.subNavLink li a {
  font-size: 14px;
}

.accordin {
  width: 30px;
  height: 30px;
  position: relative;
  float: right;
}

.accordion-button:after {
  content: "\25b2";
  font-size: 16px;
  color: #fff;
  position: absolute;
  right: 6px;
  top: 0px;
}

.accordion-button:not(.collapse.show):after {
  transform: rotate(180deg);
}

.samplecode_list {
  position: relative;
}

.accordin.collapsed.collapse {
  display: block;
}

.codeblockside {
  position: sticky;
  top: 10px;
  height: 470px;
  overflow: auto;
  margin-bottom: 20px;
}

.sampleincode {
  background: #fff;
}

.sampleincode pre code span {
  white-space: break-spaces;
}

.sampleincode .nav.nav-tabs {
  position: sticky;
  top: 0px;
  background: #fff !important;
}

.sampleincode {
  padding-bottom: 0px;
}

.codeblockfirst {
  min-height: 5540px;
}

.codeblocksecond {
  min-height: 610px;
}

.yellowish {
  color: rgb(253, 194, 54);
}

.graytext {
  color: gray;
  font-size: 14px;
}

h3.main_innerheading {
  font-size: 24px !important;
}

/** start of home style css **/
.orange_bg {
  position: relative;
  color: var(--orange);
}

.orange_bg:before {
  background-color: var(--orange);
  width: 100%;
  content: "";
  position: absolute;
  width: 100%;
  height: 6px;
  bottom: 0px;
}

.yellow_bg {
  color: var(--darkblack);
  padding: 4px;
  background: #fdc236;
}

.text_overhidden {
  overflow: hidden;
  height: 167px;
}

ul.main_list {
  margin: 0px 13px;
  padding: 0px 0px;
}

ul.main_list li {
  font-size: 16px;
  color: var(--lightblack);
  width: 50%;
  float: left;
}

.gray_box {
  border-radius: 10px;
  box-shadow: 5px 5px 36px 0 rgba(67, 94, 224, 0.17);
  border: 1px solid transparent;
  padding: 20px 20px;
  border-radius: 10px 10px;
  margin-bottom: 1.5rem;
  text-align: center;
  background: #fff;
}

.gray_box h4 {
  font-size: 1.2rem;
}

.highsmsimg {
  height: 150px;
}

.background_voilet {
  background: linear-gradient(
    193.19deg,
    rgba(234, 226, 255, 0.35) 3.09%,
    hsla(0, 0%, 100%, 0.35) 83.65%
  );
  border: 1px solid #fff;
  box-shadow: 0 4px 14px #dce3ff;
  border-radius: 15px 15px;
  padding: 30px 20px;
}

.circlebg {
  background: linear-gradient(
    98.69deg,
    #f4f6ee 18.15%,
    #e3f4ff 81.6%,
    #edf8ff 95.79%
  );
  width: 85px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);
  height: 85px;
  border-radius: 50px;
}

h3.main_subtitle {
  font-size: 24px !important;
}

.buletpoint {
  padding-left: 20px;
  padding-right: 20px;
}

.bgfoot {
  background: #282445;
}

.copyright {
  color: #fff;
}

.footer_logo {
  margin-top: 12px;
}

.footerlinks li {
  margin: 8px 0px;
}

.footerlinks li a {
  font-size: 14px;
  color: #e1dcfd !important;
}

.footerlinks li a:hover {
  color: var(--orange) !important;
}

.footer-dark .heading {
  border-bottom: 0px #fff solid;
}

.gray_box:hover {
  border-color: var(--voilet);
}

.homebg {
  background: linear-gradient(190deg, #fff 15.22%, #f6f4fb 67.5%);

  padding-top: 135px;
}

.hero {
  padding-top: 135px;
}

.fixbgColor {
  background: #f4f7fc;
}

.common_box {
  background-repeat: no-repeat;
  background-position: 100% 100%;
  margin-bottom: 30px;
  box-shadow: 5px 5px 36px 0 rgba(67, 94, 224, 0.17);
}

.common_innerbox {
  background: linear-gradient(
    180deg,
    rgba(227, 219, 255, 0.3),
    rgba(255, 238, 230, 0.3)
  );
  padding: 25px 25px;
  border-radius: 10px 10px;
  height: 300px;
}

.common_innerbox:hover {
  background: rgb(95, 90, 127);
  color: #eee;
}

.commonboxbusiness {
  height: 100%;
  min-height: 250px;
}

.commonboxbusiness4 {
  height: 100%;
  min-height: 215px;
}

.commonboxbusiness2 {
  height: 100%;
  min-height: 340px;
}

.commonheight {
  height: 100%;
  min-height: 430px;
}

.common_innerbox:hover a {
  color: var(--orange);
}

.common_innerbox:hover h3.main_subtitle {
  color: #fff;
}

.yellow_txtcolor {
  color: var(--yellow);
}

.voilet_txtcolor {
  color: var(--voilet);
}

.orange_txtcolor {
  color: var(--orange);
}

.orange_bgcolor {
  color: #fff;
  background: #ff8434;
  padding: 4px 13px;
  display: inline-block;
}

.table_left tr td:first-child {
  background: rgb(249, 250, 250);
}

.table_left .thead-dark th {
  color: #fff !important;
}

.btn-login:hover {
  border: 1px solid var(--orange);
  background: var(--orange);
  color: #fff;
}

.whatsapp-innerbox {
  padding: 20px 24px;
}

.price_txt {
  margin: 6px auto;
  font-size: 16px;
  color: var(--voilet);
  font-weight: 700;
}

ul.pricelisting {
  margin: 0px 0px;
  padding: 0px 0px;
}

ul.pricelisting li {
  text-align: left;
  font-size: 16px;
  margin-left: 14px;
}

.hero h6 {
  font-weight: 600;
  color: var(--voilet);
  margin-bottom: 20px;
  margin-left: 10px;
  font-size: 20px;
}

.whatsapp_tableprice {
  border: 1px solid #eaecf3;
}

.whatsapp_tableprice tr td {
  vertical-align: middle;
  padding: 0px 0px;
}

.whatsapp_tableprice tr td:first-child {
  background: rgb(249, 250, 250);
}

.whatsapp_tableprice tr td p {
  margin-bottom: 0px;
}

.combobox {
  padding: 40px 20px;
  max-width: 650px;
  margin: 20px auto;
  text-align: center;
  box-shadow: 5px 5px 36px 0 rgba(67, 94, 224, 0.17);
  border-radius: 10px 10px;
}

.whatsapp_priceindia h2 {
  font-size: 27px;
}

.combobox p {
  font-size: 18px;
  color: var(--voilet);
}

.table_hover {
  background: #f9fafa;
}

.table_hover tr td:nth-child(1) {
  width: 4%;
}

.table_hover tr td:nth-child(2) {
  width: 32%;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.table_hover tr td {
  border: 1px solid #eaecf3;
}

.table_hover tr:hover td {
  background: #fff;
}

.dltlist li {
  margin-bottom: 10px;
  text-align: left;
}

.form-controls {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
  display: block;
  border-radius: 0.375rem;
  border: 1px solid var(--light-dark);
  padding: 0.75rem 1rem;
  color: #6c757d;
}

.input-groupbox {
  border: 1px solid var(--light-dark);
  border-radius: 0.375rem;
  padding: 0.75rem 1rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
}

.input-result {
  border: 1px solid var(--light-dark);
  border-radius: 0.375rem;
  padding: 0.75rem 1rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: 48px;
  color: #6c757d;
  background: rgb(249, 250, 250);
}

.btn_multiply {
  padding: 0.75rem 1rem;
  color: #fff;
  border: 1px solid var(--voilet);
  border-radius: 6px;
  font-size: 0.9rem;
  font-family: "poppins";
  font-weight: 400;
  background: var(--voilet);
}

.btn_multiply:hover {
  background: var(--orange);
  border: 1px solid var(--orange);
  color: #fff;
}

.pricing .nav .nav-link {
  padding: 6px 40px;
}

.resultbg {
  color: var(--voilet);
  font-weight: 700;
  font-size: 17px;
  padding: 0.55rem 1rem;
}

.preinline {
  display: inline;
  background: var(--voilet);
  color: #fff;
  padding: 2px 2px;
}

.accordion-item {
  display: flex;
  flex-direction: column;
}

.accordionBtn {
  width: 100%;
  text-align: left;
  padding: 10px 20px;
  border: 0px;
  border-bottom: 1px #02877a solid;
  outline: none;
  cursor: pointer;
  display: flex;
  font-size: 1.4rem;
  font-weight: 400;
  justify-content: space-between;
  align-items: center;
  color: var(--darkblack);
  background: transparent;
  color: #02877a;
}

.accordionBtn .svg-inline--fa {
  color: #02877a;
}

.last-footer .svg-inline--fa {
  color: #e1dcfd;
}

.accordion-content {
  transition: max-height 0.3s ease-in-out;
  padding: 20px 0px;
}

ul.accordin_list li {
  text-align: left;
  margin-bottom: 10px;
  font-size: 15px;
}

.accordion-image {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0px;
  right: 0px;
}

ul.accordincheck li:before {
  content: "\2713";
  display: inline-block;
  color: #02877a;
  position: absolute;
  left: 30px;
}

ul.accordincheck li {
  list-style-type: none;
  margin-bottom: 10px;
}

ul.whatsappcheck {
  margin: 0px 0px;
  padding: 0px 0px;
}

.minheight {
  min-height: 350px;
}

ul.whatsappcheck li:before {
  content: "\2713";
  display: inline-block;
  color: #02877a;
  position: absolute;
  left: 20px;
}

ul.whatsappcheck li {
  list-style-type: none;
  margin-left: 25px;
  margin-bottom: 15px;
}

.accordion-image img {
  max-width: 100%;
  height: auto;
}

.accordion-item:nth-child(2) .accordion-image {
  top: -58px;
}

.accordion-item:nth-child(3) .accordion-image {
  top: -116px;
}

.header_low {
  font-size: 18px;
}

.herocontainer {
  background: linear-gradient(190deg, #fff 15.22%, #f6f4fb 67.5%);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 50% 50%;
  border-bottom-right-radius: 50% 50%;
  padding-top: 135px;
}

.herocontainer h1 {
  margin-top: 1em;
}

.codeheight {
  max-height: 327px;
  overflow: auto;
}

.commonboxbusiness1 {
  background: rgb(95, 90, 127);
  color: #fff;
}

.commonboxbusiness1 a {
  color: #e1dcfd;
}

.commonboxbusiness1 a:hover {
  color: var(--orange);
}

.commonboxbusiness3 {
  background: rgb(95, 90, 127);
  color: #fff;
}

.commonboxbusiness3 .main_subtitle {
  color: #fff;
}

.commonboxbusiness1 .main_subtitle {
  color: #fff;
}

.contentinbox {
  background: #3f3966;
}

.greenbg {
  background: linear-gradient(190deg, #fff 15.22%, #f6f4fb 67.5%);
}

.order-first {
  order: 6 !important;
}

.order-last {
  order: -1 !important;
}

.featureTable {
  outline: 4px #f4f7fc solid;
}

.featureTable tr td {
  padding: 15px 15px;
  border: 4px #fff solid;
  background: #f4f7fc;
  font-size: 16px;
  font-weight: 400;
  width: 50%;
  text-align: center;
}

.featureTable tr td:hover {
  background: var(--orange);
  color: #fff;
}

.rcsicon {
  background: rgb(95, 90, 127);
  width: 60px;
  height: 60px;
  border-radius: 50% 50%;
  text-align: center;
  margin: 0px auto;
  border: 3px #fff solid;
  outline: 2px var(--voilet) solid;
}

.card .rcsicon:hover {
  background: var(--orange);
  outline: 2px var(--orange) solid;
}

.rcsicon1 {
  background: var(--yellow);
  outline: 2px var(--yellow) solid;
}

.rcsicon2 {
  background: var(--orange);
  outline: 2px var(--orange) solid;
}

.rcsicon svg {
  fill: #fff;
  margin: 15px;
}

.grayheight {
  min-height: 328px;
}

.gray_box:hover .rcsicon {
  background: var(--orange);
  outline: 2px var(--orange) solid;
}

ul.navtablink {
  display: table;
  width: 100%;
  margin: 10px 0px 30px;
  border-bottom: 1px var(--voilet) solid;
}

ul.navtablink li {
  width: 20%;
  text-align: center;
  display: inline-block;
}

ul.navtablink li a {
  font-size: 1rem;
  font-weight: 600;
  padding: 14px 10px;
}

ul.navtablink li a.nav-link.active {
  background: var(--voilet);
  color: #fff;
}

.smtxt {
  font-size: 13px;
}

.css-1wa3eu0-placeholder {
  font-size: 13px !important;
  margin-left: 13px;
}

.css-yk16xz-control {
  background: #f9fafb;
}

.mt0 {
  margin-top: 0px !important;
}

#singupData .form-group {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.samplecode .nav.nav-tabs {
  position: sticky !important;
  width: 100% !important;
  background: #fff !important;
  top: 0 !important;
}

.shadheight {
  min-height: 340px;
}

.shadminhgt {
  min-height: 290px;
}

.shadowhighlight {
  background-image: linear-gradient(
      to left,
      rgb(255, 250, 246) 1%,
      rgb(255, 255, 255) 50%,
      rgb(249, 244, 227) 100%
    ),
    linear-gradient(
      to left,
      rgb(255, 232, 107) 1%,
      rgb(92, 17, 155) 50%,
      rgb(242, 238, 238) 100%
    );
  /*! background-size: 100% 0px; */
  background-position: 0% 0%, 0% 100%;
  background-repeat: no-repeat;
  line-height: 50px;
  background-color: #fff;
  border-radius: 10px;
}

.border-right-email {
  border-width: 3px;
  border-style: solid;
  border-image: linear-gradient(to bottom, #3a3462, rgba(0, 0, 0, 0)) 1 100%;
}

.price-list li {
  margin-left: 10px;
}

hr {
  margin-top: 1.2rem;
  margin-bottom: 0.7rem;
}

.badge-primary {
  background-color: var(--orange);
}

.btn-orange {
  border: 2px var(--voilet) solid;
  background: #fff !important;
  color: var(--voilet) !important;
}

.btn-orange:hover {
  background: var(--voilet) !important;
  color: #fff !important;
}

.shadowsins {
  min-height: 305px;
}

h3.titlesm {
  font-size: 19px !important;
}

.styles_faq-row-wrapper__3vA1D {
  background: transparent !important;
}

.nav-mega {
  width: 100%;
}

.nav-mega .dropdown {
  position: static;
}

.nav-mega .dropdown-menu.mega-menu {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-border-radius-topleft: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
  min-width: auto;
  width: 100%;
  margin-top: 0;
  padding: 0;
  border-color: #ccc;
}

.nav-mega .dropdown-menu.mega-menu > li {
  padding: 20px;
}

.nav-mega .dropdown-menu.mega-menu .media-list .media {
  padding: 10px;
  font-size: 13px;
}

.nav-mega .dropdown-menu.mega-menu .media-list .media-heading {
  font-size: 16px;
}

.whatsapp_profile_img {
  height: 300px;
  display: block;
}

h3.headingthird {
  font-size: 1.75rem !important;
}

.shadowlg {
  min-height: 330px;
}

.shadowlg p {
  min-height: 120px;
}

.graycircle {
  border-radius: 50% 50%;
}

.mega-dropdown {
  position: static !important;
  width: 100%;
}

.mega-dropdown-menu {
  padding: 20px 0px;
  width: 100%;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.mega-dropdown-menu > li > ul {
  padding: 0;
  margin: 0;
}

.mega-dropdown-menu > li > ul > li {
  list-style: none;
}

.mega-dropdown-menu > li > ul > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #999;
  white-space: normal;
}

.mega-dropdown-menu > li ul > li > a:hover,
.mega-dropdown-menu > li ul > li > a:focus {
  text-decoration: none;
  color: #444;
  background-color: #f5f5f5;
}

ul.pricepolicy {
  margin: 0px 0px;
  padding: 0px 0px;
}

ul.pricepolicy li {
  margin-bottom: 10px;
  font-size: 15px;
}

.orangecolor {
  background: #fff8f4;
}

.whitebgColor {
  background: #fff;
  border-radius: 30px;
}

.whatsappIntbox {
  padding-top: 40px;
  min-height: 360px;
}

.whatsappborderin {
  border-bottom: 2px #f4f7fc solid;
}

.whatsappborderlefin {
  border-left: 2px #f4f7fc solid;
}

.whatsappIntbox h2 {
  font-size: 1.5em;
  margin-bottom: 0px;
}

.whatsapp-innerbox h6 {
  font-size: 1.2rem;
  margin-bottom: 0px !important;
  color: var(--voilet);
}

.whatsappIntStar {
  background: var(--orange);
}

.whatsappIntStar h2,
.whatsappIntStar p,
.whatsappIntStar h6 {
  color: #fff;
}

.orange_pricetxt {
  color: yellow;
  font-size: 24px;
  font-weight: 700;
}

.whatsapplogo {
  position: fixed;
  right: 22px;
  top: 63px;
  z-index: 1111;
}

.headerfixed {
  position: fixed;
  width: 100%;
  left: 0px;
  right: 0px;
  z-index: 99;
  box-shadow: 0px 1px 2px 1px #f4f7fc;
  top: 0px;
}

.whatsappIntbox .whatsapp-innerbox {
  padding-top: 2px;
}

@media screen and (max-width: 1500px) {
  .smsgatewaywork img {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .whatsapplogo {
    top: 135px;
  }
}

@media screen and (max-width: 1280px) {
  .smsgatewaywork img {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 1200px) {
  .hero h1 {
    font-size: 28px;
  }

  .hero p {
    font-size: 16px;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.4rem;
    padding-left: 0.4rem;
  }

  .navbar-nav .nav-link {
    font-size: 0.85rem;
  }

  .navbar-expand-lg .navbar-brand img {
    margin-top: 10px;
  }
}

@media screen and (max-width: 1100px) {
  .smsgatewaywork img {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media (max-width: 991px) {
  .rightBr {
    background: #fff;
  }

  code {
    white-space: initial !important;
  }

  .imgmbnone {
    display: none;
  }

  blockquote {
    font-size: 1.4rem;
  }

  blockquote:before,
  blockquote:after {
    font-size: 4rem;
  }

  blockquote:before {
    left: 1rem;
    top: -2rem;
  }

  blockquote::after {
    right: 1rem;
    bottom: -1rem;
  }

  .accordion-image img,
  .imgfull {
    width: 100%;
  }

  .btncenter {
    text-align: center;
  }

  .mobile-signup a {
    text-align: center;
  }

  .herocontainer h1 {
    margin-top: 2em;
  }

  .navbar-expand-lg .navbar-collapse .dropdown-menu.show {
    background: #f9f8fe;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  .navbar-nav .nav-link {
    font-size: 0.9rem;
  }

  .background_voilet {
    margin-bottom: 40px;
  }

  nav.navbar.navbar-expand-lg.navbar-light.bg-white {
    padding: 14px 0px;
  }

  .smsgatewaywork img {
    float: right;
    max-width: 93%;
    width: 100%;
  }

  .smsgatewaywork .wrap {
    position: relative;
  }

  .mt_mob0 {
    margin-top: 0px !important;
  }

  table.table_left {
    border: 0;
  }

  table.table_left caption {
    font-size: 1.3em;
  }

  table.table_left thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table.table_left tr {
    border: 1px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table.table_left td {
    display: block;
    font-size: 0.8em;
    text-align: left;
  }

  table.table_left td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label) ":";
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 5px;
  }

  table.table_left td:last-child {
    border-bottom: 0;
  }

  .hero {
    background: none !important;
    padding-top: 136px !important;
  }

  .accordionBtn {
    font-size: 1.2rem;
    padding: 20px 20px;
  }

  table.table_left td,
  table.table_left th {
    white-space: normal !important;
  }

  .mobile-signup a {
    text-align: center;
    display: inline-block;
    padding: 6px 10px;
  }

  .navbar-toggler {
    float: right;
  }

  .headerfixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 3px 5px -6px #000 !important;
  }

  .navbar-collapse {
    position: absolute;
    top: 0px;
    margin-top: 53px;
    width: 100%;
    box-shadow: 0 3px 5px -6px #000;
    left: 0px;
    right: 0px;
    padding: 0px 20px;
  }

  .navbarcollapse_none {
    position: relative;
    top: 0px;
    margin-top: 0px;
    width: 100%;
    box-shadow: 0 0px 0px 0px #000;
    left: 0px;
    right: 0px;
  }

  .widthBlock {
    width: 300px;
    text-align: right;
  }

  .navbar .container_mobile {
    max-width: 100%;
  }

  .navbar-expand-lg .navbar-nav:not(.flex-row) .nav-link:hover {
    color: var(--lightvoilet);
  }

  .mobile-signup .btn_hidden {
    display: none;
  }

  .common_innerbox {
    height: 100%;
  }

  .table_hover tr td:nth-child(1),
  .table_hover tr td:nth-child(2) {
    width: 100%;
  }

  .accordion-image {
    position: relative;
  }

  .accordion-item:nth-child(2) .accordion-image,
  .accordion-item:nth-child(3) .accordion-image {
    top: 0px;
  }

  .accordion-image img {
    text-align: center;
    margin: 0px auto;
  }

  .order-first {
    order: -1 !important;
  }

  .order-last {
    order: 6 !important;
  }

  .pmt {
    padding-top: 0px !important;
  }

  .pmb {
    padding-bottom: 40px !important;
  }

  .pmb0 {
    padding-bottom: 0px !important;
  }

  .mmb0 {
    margin-bottom: 0px !important;
  }

  .pmtt {
    margin-top: 10px;
  }

  .mtt3 {
    margin-top: 20px !important;
  }

  .mtt2 {
    margin-top: 30px !important;
  }

  .commonheight {
    min-height: 100%;
  }

  .txtcenter {
    text-align: center;
  }

  ul.navtablink li a {
    padding: 14px 20px;
  }

  ul.navtablink li {
    width: auto;
  }

  .mt0 {
    margin-top: 30px !important;
  }

  .whatsapplogo {
    top: 111px;
  }
}

@media (max-width: 767px) {
  .text_overhidden,
  .highsmsimg {
    height: 100%;
  }

  .rightBr {
    background: #fff;
  }

  ul.main_list li {
    width: 100%;
    float: none;
  }

  .lead {
    font-size: 15px;
  }

  .footerlinks li {
    margin: 5px 0px;
    width: 33%;
    float: left;
  }

  .mbfooter {
    margin-bottom: 20px;
  }

  .footer-dark {
    padding-left: 15px;
    padding-right: 15px;
  }

  .hero {
    background-image: none;
    margin-top: 50px;
  }

  .navbar-expand-lg .navbar-collapse .nav-item.dropdown .dropdown-toggle::after,
  .navbar-expand-lg
    .navbar-collapse
    .nav-item.mega-dropdown
    .dropdown-toggle::after {
    font-size: 1.2rem;
    float: right;
    margin-right: 20px;
  }

  .listnone .rcsicon {
    display: none;
  }

  .navbar-brand {
    margin-bottom: 7px;
  }

  .common_box_gap {
    margin-bottom: 0px;
  }

  .common_innerbox {
    height: 100%;
  }

  .container_mobile {
    width: 100%;
    max-width: 100%;
  }

  .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    padding: 0px;
    transform: rotate(0deg);
  }

  .btn-login {
    background: #fff;
  }

  .dropdown-toggle::after {
    display: inline-block !important;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    padding: 0px;
    transform: rotate(0deg);
  }

  h2 {
    font-size: 27px;
  }

  .whatsapp-pricing-box {
    margin-left: 10px;
    margin-right: 10px;
  }

  .btn_multiply {
    width: 100%;
    margin: 0px 0px 20px;
  }

  .apinbtns .btn {
    width: 100%;
    margin-bottom: 10px;
  }

  .apinbtns .btn + .btn {
    margin-bottom: 0px;
    margin-left: 0px;
  }

  ul.navtablink li {
    width: 100%;
  }

  ul.navtablink {
    border-bottom: 0px #fff solid;
  }

  .whatsapplogo {
    height: 45px;
    top: 118px;
  }
}

@media (max-width: 600px) {
  .hero {
    margin-top: 50px;
  }

  .whatsapplogo {
    height: 45px;
    top: 142px;
  }

  .herocontainer h1 {
    margin-top: 1.4em !important;
  }

  ul.navtablink li {
    width: 100%;
    text-align: center;
    display: block;
  }

  .shadheight,
  .shadminhgt,
  .shadowsins {
    min-height: 100%;
  }
}

@media (max-width: 480px) {
  .footerlinks li {
    width: 50%;
  }

  .mobile-signup .home-button {
    font-size: 13px;
  }

  .api-buttons .btn + .btn {
    margin-left: 0px;
  }
}

@media (max-width: 430px) {
  .navbar-expand-lg .navbar-brand img {
    height: 24px;
  }

  .whatsapplogo {
    height: 35px;
    top: 150px;
  }

  .navbar-brand {
    margin-right: 0px;
  }
}

@media (max-width: 380px) {
  .last-footer a {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
}

.cardinboxslg {
  background-color: #eef2fc;

}
.cardinboxlayout {
  padding: 20px 50px;
}

.panel_listbox {
  margin: 0px 0px;
  padding: 0px 0px;
}

.slider-container {
  overflow: hidden;
}

.slider-container ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.slider-container ul li {
  margin-right: 10px;
  margin-left: 10px;
}

.slider-container ul li:last-child {
  margin-right: 0;
}

/* Image styles */
.sliders img {
  width: 140px;
}
.panel_listbox {
  margin: 0px 0px;
  padding: 0px 0px;
}
.panel_listbox li {
  margin-left: 25px;
}
.sliders li img {
  width: 130px;
 
}
.slider-container img {
  border: 2px #eee solid;
  border-radius: 6px 6px;
}
.cardinboxlayout h2 {
  font-size: 42px;
  margin-left: 12px;
  font-weight: 700;
  line-height: 46px;
}
.cardinPara {
  font-weight: 600;
  color: var(--darkblack);
  margin: 0px 12px 14px;
  font-size: 16px;
}
.cardinboxlayout h2, .cardinboxlayout h4 {
  margin-left: 12px;
}
.ReactTelFullbox .react-tel-input .flag-dropdown {
  background: transparent !important;
  border:0px #fff solid !important;
}
.ReactTelFullbox .react-tel-input .selected-flag {
  height: 43px !important;
}
.ReactTelFullbox .react-tel-input .country-list {
  top: 44px !important;
}
.ReactTelFullbox .react-tel-input {
  width:90px !important;
  padding: 6px 0px !important;
}
.ReactTelFullbox .form-control {
  padding: 0.75rem 0.25rem 0.75rem 2.6rem !important
}
.ReactTelFullbox .react-tel-input button {
  background: #fff !important;
  border: 0px #fff solid !important;
}
.ReactTelFullbox  {
  border: 1px solid #cccccc;
  border-radius: 4px 0px 0px 4px;
}
.ReactTelFullbox .react-tel-input .selected-flag {
  border-radius: 10px;
}
.css-2b097c-container {
  z-index: 15 !important;
}


@media (max-width: 1500px) {
  .cardinboxlayout h2 {
    font-size:38px;
  }
}
@media (max-width: 1400px) {
  .slider-container ul li {
    margin-right: 5px;
    margin-left: 5px;
  }
}
@media (max-width: 1280px) {
  .cardinboxlayout h2 {
    font-size:32px;
  }
}

@media (max-width: 991px) {
.borderMobs {
  border-bottom: 2px #eee solid;
}
.slider-container ul li {
  margin-left: 3px;
  margin-right: 3px;
}
.cardinboxlayout {
  padding: 0px 30px;
}
.heightVh {
  min-height: 100% !important;
}
.cardinboxslg {
  margin-top: 30px;
}
.cardinboxlayout h2 {
  line-height: 40px;
}
  .navbar-collapse {
      margin-top: 0px !important;
}
.content {
  margin-top: 30px;
}
.login-new-box {
  justify-content: center;
}
}
@media (max-width: 767px) {
  .cardinboxlayout {
    padding: 30px 10px;
  }
.panel_listbox {
  margin-bottom: 30px !important;
}
.authkeyjoindate {
  display: none;
}
.login-new-box {
  height: 100%;
}

}

