.sandbox_icon svg{
    width:20px;
    font-size:18px;
  }
  .sandbox_icon2 svg{
    width: 24px;
  }
  .sandbox_icon3 svg{
    font-size:21px;
  }
  .sandbox_icon3, .sandbox_icon2 {
    margin-right: 2px;
  }
  .voilet svg {
    color: #3c3664;
  }
  .orange svg {
    color: #ff8434;
  }
  .green svg {
    color: #2a7841;
  }
  
  /* toggle in label designing */
  
  .switchboard {
    position: absolute;
    top: -10px;
    left: 0px;
    right: 0px;
    text-align: center;
    width:100%;
  }
  .switchName {
    position:relative;
    top: -19px;
    display: inline-block;
    margin-left: 10px;
  }
  
  .switchboard input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .toggle {
    position : relative ;
    display : inline-block;
    width: 60px;
    height: 34px;
    background-color: #ccc;
    border-radius: 30px;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 10px;
  }
       
  /* After slide changes */
  .toggle:after {
    content: '';
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #fff;
    top: 3px; 
    left: 3px;
    transition:  all 0.5s;
  }
       
  /* Checkbox checked effect */
  .switchboard .checkbox:checked + .toggle::after {
    left : 28px; 
  }
       
  /* Checkbox checked toggle label bg color */
  .switchboard .checkbox:checked + .toggle {
    background-color: #6cbc6c;
  }
       
  /* Checkbox vanished */
  .switchboard .checkbox { 
    display : none;
  }
  .sandcardinbox {
    border: 2px #eee solid;
    border-radius: 10px 10px;
  }
  .sandcardSelect {
    background: #3c3664;
    color: #fff;
  }
  .sandcardSelect .font-weight-bold {
    color: #fff;
  }