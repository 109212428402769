.dashboardContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem;
    height: 100%;

}

@media (minWidth:990px) {
    .dashboardContainer{
        margin-top: 35px;
    }
    
}

main {
    /* max-height: 90vh; */
    overflow-y: auto;
    width: 100%;
}

.chatDetailCard {
    position: fixed;
    z-index: 1001;
    /* Ensure it's above the overlay */
    right: 25%;
    top: 50%;
    transform: translate(0, -50%);
    /* Center the card vertically */
    width: 400px;
    /* Set a fixed width for the card */
    background-color: white;
    /* Add background color */
}

.details{
    height: 50vh;
    overflow-y: auto;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Dimmed background */
    z-index: 2;
    /* Overlay should be below the card */
}

.activeChartBtn{
    background-color:#d1f0d3 ;
}


.closeIcon {
    font-size: 2rem;
    cursor: pointer;
    color: #555555;
}

.overviewSection {
    width: 100%;
    display: grid;
    gap: 1.5rem;
    /* Space between grid items */
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    /* Responsive columns */
    margin-bottom: 1.5rem;
}

.chartSection {
    display: grid;
    gap: 1.5rem;
    /* Space between grid items */
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    /* Responsive columns */
    margin-bottom: 1.5rem;
    width: 100%;
}

.agentSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
}

.ChatSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 3rem;
}

.userManagement {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

}