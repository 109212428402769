/* The switch container */
.switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
}

/* Hide default checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* Slider */

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 24px;
}

/* The slider's handle */
.slider::before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;

}

/* Checked state */
input:checked+.slider {
    background-color: #2196f3;

}

input:checked+.slider::before {
    transform: translateX(22px);
}

.conditionPills {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    /* Space between pills */
}

.pill {
    background-color: grey;
    /* Blue background */
    color: white;
    padding: .3rem .5rem;
    border-radius: .3rem;
    /* Rounded corners */
    font-size: .8rem;
    font-weight: 500;
    display: inline-block;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
    cursor: default;
    white-space: nowrap;
    /* Prevent text from wrapping */
}

@media (min-width: 576px) {
    .wMd30 {
        width: 30% !important;
        max-width: 300px;
    }
}

.spinnerContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    /* Dark background */
    backdrop-filter: blur(2px);
    /* Apply blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.spinnerWrapper {
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 5px solid #f3f3f3;
    border-top: 5px solid black;
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

.percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: bold;
    color: black;
    z-index: 1;
}

.uploadText {
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: bold;
    color: black;
    z-index: 1;
    text-align: center;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.animatedText {
    animation: pulse 1s infinite;
    color: black;
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.inputGroup {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.inputGroupText {
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 14px;
    width: 25%;
    padding: 8px ;
    padding-left: 20px;
    color: #a0aec0;
    background-color: #f8f9fa;
    /* Optional background */
    border: 1px solid #ced4da;
    /* Optional border */
    border-radius: 6px 0 0 6px;
    
}

.selectContainer {
    flex-grow: 1;
    margin-bottom: 0;
    display: flex;
    align-items: center;
}

.reactSelect {
    width: 100%;
    margin-bottom: 0;
}

.reactSelect__control {
    border: 1px solid #ced4da !important;
    box-shadow: none !important;
    height: 40px;
    border-radius: 0 6px 6px 0 !important;
    /* Adjust height to match other form controls */
}

