.spinnerContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    /* Dark background */
    backdrop-filter: blur(2px);
    /* Apply blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.spinnerWrapper {
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 5px solid #f3f3f3;
    border-top: 5px solid black;
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: bold;
    color: black;
    z-index: 1;
}

.uploadText {
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: bold;
    color: black;
    z-index: 1;
    text-align: center;
}