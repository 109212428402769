.authkey-message-container {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 400px;
    margin-top: 30px;
    overflow: hidden; /* Prevents scrolling outside the text box */
  }
  
  /* Message input area styles */
  #authkey-message-input {
    width: 100%;
    height: 50px; /* Fixed height */
    padding: 12px;
    padding-right: 50px; /* Ensure space for the send button */
    border: 1px solid #e1e1e1;
    font-size: 15px;
    background-color: #f7f7f7;
    outline: none;
    color: #333;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    border-radius: 15px; /* Apply border-radius on both left and right sides */
    overflow: hidden; /* Prevents both horizontal and vertical scrolling inside */
    resize: none; /* Prevents resizing the text box */
    white-space: nowrap; /* Prevents the text from wrapping */
    text-overflow: ellipsis; /* Adds an ellipsis if the text overflows */
  }
  
  /* Send button styles */
  .authkey-send-button {
    background-color: #25d366;
    border: none;
    border-radius: 50%; /* Keep the send button round */
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 10px; /* Adjusted slightly to avoid covering the right border */
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 0.3s ease;
    z-index: 1; /* Ensure the button is above the text box */
  }
  
  .authkey-send-button span {
    font-size: 20px;
    color: white;
    font-weight: bold;
    line-height: 0;
  }
  
         
.authkey-dh {
    /* visibility:hidden; */
    display: none;
    opacity: 0;
    margin-bottom: 0;
  }
  .authkey-ds {
    /* visibility:visible; */
    display: block;
    opacity: 1;
    margin-bottom: 20px;
  }
  .authkey-whatsappBtn {
    border-radius: 100%;
    line-height: 1.32;
    color: rgb(255, 255, 255);
    font-size: 0px;
    background-color: #25d366;
    border-width: 0px;
    padding: 0px;
    height: fit-content;
    width: fit-content;
    cursor: pointer;
    position: relative;
    /* float:${ndn.wig_position}; */
  }
  .authkey-whatsappIcon {
    fill: white;
    width: 3.125rem;
    height: 3.125rem;
    padding: 0.4375rem;
  }
  .authkey-whatsappLive {
    background-color: rgb(255, 0, 0);
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    display: block !important;
    height: 0.6rem;
    width: 0.6rem;
    font-size: 0.687rem;
    top: 7px;
    right: 2px;
  }
  .authkey-whatsappHeader {
    color: rgb(17, 17, 17);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 20px;
    /* background: ${ndn.back_color}; */
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .authkey-whatsappAvataarContainer {
    position: relative;
    width: 52px;
    height: 52px;
    box-shadow: rgba(17, 17, 17, 0.1) 0px 0px 2px inset;
    border-radius: 50%;
    display: block;
    flex-shrink: 0;
    overflow: inherit;
    cursor: pointer;
  }
  .authkey-whatsappAvataar {
    width: 52px;
    height: 52px;
    background-color: rgb(210, 210, 210);
    opacity: 1;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
  
  .authkey-whatsappAvataarImg {
    object-fit: cover;
    display: inline-block !important;
    position: static !important;
    margin: 0px !important;
    padding: 0px !important;
    max-width: none !important;
    height: inherit !important;
    width: inherit !important;
    visibility: visible !important;
  }
  .authkey-whatsappAvataarContainer:before {
    content: "";
    bottom: 0px;
    right: 0px;
    width: 12px;
    height: 12px;
    box-sizing: border-box;
    position: absolute;
    z-index: 2;
    border-radius: 50%;
    background-color: rgb(74, 213, 4);
    display: block;
    border: 2px solid rgb(0, 128, 105);
  }
  .authkey-whatsappClientImg:before {
    content: "";
    bottom: 0px;
    right: 0px;
    width: 12px;
    height: 12px;
    box-sizing: border-box;
    position: absolute;
    z-index: 2;
    border-radius: 50%;
    background-color: rgb(74, 213, 4);
    display: block;
    border: 2px solid rgb(0, 128, 105);
  }
  .authkey-whatsappWindow {
    z-index: 2147483647;
    width: 360px;
    pointer-events: all;
    touch-action: auto;
  
    transition: opacity 0.3s, margin 0.3s, visibility 0.3s;
  
    inset: auto 20px 76px auto;
  }
  .authkey-whatsappWindowShadow {
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 30px 0px;
  }
  .authkey-whatsappCloseIcon {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 20px;
    height: 20px;
    opacity: 0.4;
    cursor: pointer;
    transition: 0.3s;
    outline: transparent;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    display: flex !important;
  }
  .authkey-whatsappCloseIcon:before,
  .authkey-whatsappCloseIcon:after {
    content: "";
    position: absolute;
    width: 12px;
    height: 2px;
    background-color: rgb(255, 255, 255);
    display: block;
    border-radius: 2px;
  }
  .authkey-whatsappCloseIcon:before {
    transform: rotate(45deg);
  }
  .authkey-whatsappCloseIcon:after {
    transform: rotate(-45deg);
  }
  .authkey-whatsappHeaderInfo {
    margin-left: 16px;
    margin-right: 16px;
    width: 100%;
    overflow: hidden;
  }
  .authkey-whatsappAvataarName {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    max-height: 60px;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: rgb(255, 255, 255);
    font-family: Arial, Helvetica, sans-serif;
  }
  .authkey-whatsappOnline {
    font-size: 13px;
    line-height: 18px;
    margin-top: 4px;
    color: rgb(255, 255, 255);
    font-family: Arial, Helvetica, sans-serif;
  }
  .authkey-whatsappBottomLayout {
    background: url(https://static.elfsight.com/apps/all-in-one-chat/patterns/background-whatsapp.jpg)
      center center / cover no-repeat;
  }
  .authkey-whatsappChatbox {
    position: relative;
    padding: 20px 20px 12px;
    overflow: auto;
    max-height: 382px;
  }
  .authkey-whatsappChatLayout {
    padding: 6px 14px;
    position: relative;
    transform-origin: center top;
    z-index: 2;
    color: rgb(255, 255, 255);
    font-size: 15px;
    line-height: 1.39;
    max-width: calc(100% - 80px);
    border-radius: 0px 16px 16px;
    background-color: rgb(255, 255, 255);
    opacity: 1;
    hyphens: auto;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 0px 0px;
  }
  .authkey-whatsappChatMessage {
    display: flex;
    align-items: flex-end;
    color: #000;
    font-family: Arial, Helvetica, sans-serif;
  }
  .authkey-whatsappChatSvg {
    position: absolute;
    top: 0px;
    left: -9px;
  }
  .authkey-whatsappChatTime {
    text-align: right;
    margin-left: 12px;
    font-size: 12px;
    line-height: 14px;
    opacity: 0.5;
    color: #000;
    font-family: Arial, Helvetica, sans-serif;
  }
  .authkey-whatsappBtnInline {
    border-radius: 24px;
    border-color: rgba(255, 255, 255, 0.1);
    width: auto;
    line-height: 1.32;
    color: rgb(255, 255, 255);
    font-family: inherit;
    font-weight: bold;
    font-size: 16px;
    background-color: rgb(37, 211, 102);
    border-width: 0px;
    padding: 0px;
    margin: 20px;
    max-width: 100%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 0px 0px;
    padding: 12px 27px;
    cursor: pointer;
  }
  .authkey-whatsappBottomnext {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .authkey-m-d {
    position: fixed;
    /* bottom: 20px; */
    /* right: 12px; */
    z-index: 10000;
  }
  .authkey-branding {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    margin: 0;
    p{
      font-size: 10px;
      margin-top: 4px;
      margin-bottom: 4px;
      margin-left: 1%;
      margin-right: 1%;
    }
    img{
      width: 0.8em;
    }
    a{
      font-size: 10px;
    }
  }
  