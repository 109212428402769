.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupInner {
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  padding-left: 3%;
}

@media (max-width: 768px) {
  .popupInner {
    max-width: 90%;
    max-height: 90%;
  }
}

.cancelButton {
  border: none;
  background: none;
  font-size: xx-large;
  opacity: 50%;
  float: right;
}

.popupcontent {
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
}
