.container {
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 2rem;
    padding: 12px;
    border: 2px solid black;
    border-radius: 20px;
    background-color: lightgray;
}

.deviceFrame {
    position: relative;
    background-color: #f5f5f5;
    border-radius: .8rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 20px; */
}

/* .container::before {
    content: '';
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 5px;
    background-color: black;
    border-radius: 10px;
    z-index: 1;
} */

.deviceContent {
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    color: #333;
    height: 45vh;
    overflow-y: auto;
    padding: .5rem;
    background-color: whitesmoke;
    width: 100%;
}

.description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    font-size: .8rem;
    word-break: break-all;
    padding: 1rem 0;
    border-bottom: .1rem solid gray;
    border-radius: .1rem;
}

.detailLogo {
    width: 35px !important;
    height: 35px;
    border-radius: 50%;
}

.message {
    margin: 10px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content:center ;
    align-items: flex-end;
}

.message.user {
    justify-content: flex-end;
}

.message.agent {
    justify-content: flex-start;
}

.message .bubble {
    padding: 10px;
    border-radius: 15px;
    background-color: #e3f0e7;
    width:100%;
}

.message.user .bubble {
    background-color: #e3f0e7;
}

.rcsHeader {
    display: flex;
    justify-content: space-between;
    background-color: #09988d;
    color: white;
    padding: .5rem;
    width: 100%;
}

.brandImage {
    border-radius: 50%;
    width: 22px;
    height: 22px;
}

.textBold {
    font-weight: bold;
}

.imagePreview {
    width: 100%;
    max-width: 100%;
    margin-top: 10px;
    border-radius: 10px;
}

.emojiPreview {
    font-size: 24px;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: .2rem;
    height: 6vh;
    color: black;
}

.addOptions {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 20%;
}

.input {
    width: 80%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #add7ba;
    border-radius: 10px;
    padding: .5rem;

}


.suggestions {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
}

.button {
    padding:.5rem ;
    /* font-size: .4rem; */
    /* border: 1px solid black; */
    border-radius: 4px;
    background-color: #a0ceae;
    color: white;
    cursor: default;
    text-align: center;
    width: 100%;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #e7e7e7;
}

.button span {
    /* font-size: 14px; */
    font-weight: bold;
    color: #555;
}
.richCardContainer{
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:#e3f0e7 ;
    padding: .2rem;
    border-radius: 4px;
}
 img,  video{
    width: 100%;
    border-radius: 4px;
    
}






