.whats-app-header{
  display: flex !important;
  align-content: center;
  align-items: center;
  padding: 12px 0px;
  margin-top: 9px;
  font-size: 24px;
  position: relative;
  z-index: 1;
}
.whats-app-header p {
  color: #fff !important;
  margin: 0px;
  margin-left: 10px;
}
.whats-app-header img {
  width: 40px;
  height: 40px;
  border: 2px solid #fff;
  border-radius: 50%;
  /* padding: px; */
  background-color: #fff;
}
.call-action-area {
  border: 1px solid #e5e1e1;
  padding: 10px;
  border-radius: 5px;
  margin-top: 12px;
}
.whatsapp-review {
  height: 700px;
  margin: 0px auto;
  display: block;
}

.whatsapp-bg{
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100%;
  margin: 0 auto;
  max-width: 290px;
  height: 584px;
  padding: 13px 0px 0px 30px;
}
.body-message {
  padding: 10px;
  width: 90%;
  white-space: pre-line;
  overflow-y: scroll;
  max-height: 400px;
}

/* .inner-body-message {
  background: #fff;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 18px;
  min-height: 52px;
} */

.carousel-body-message {
  padding: 10px;
  width: 90%;
  white-space: pre-line;
  overflow-y: scroll;
  max-height: 400px;
}

.inner-carousel-body-message {
  background: #fff;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 18px;
  min-height: 52px;
}


.doc-type {
  min-height: 100px;
  border: dotted 2px gray;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
}

.button-text {
  background: #fff;
  border-radius: 5px;
  text-align: center;
  margin-top: 10px;
  padding: 5px;
  width: 90%;
}
.button-text p{
  margin: 0px;
  font-size: 13px !important;
  color: #00a7ff !important;
}

.button-carousel-text {
  background: #fff;
  border-radius: 5px;
  text-align: center;
  margin-top: 10px;
  padding: 5px;
  width: 100%;
}

.button-carousel-text p{
  margin: 0px;
  font-size: 13px !important;
  color: #00a7ff !important;
}

.body-message p {
  font-size: 12px !important;
  color: gray !important;
}
.body-message strong {
  font-size: 14px !important;
  color: #000 !important;
}
.whatsapp ul{
  list-style: none;
  padding: 0px;
  margin-top: -17px;
}

.whatsapp li {
  font-size: 13px;
  border-bottom: 1px solid #f1e7e7;
  padding: 5px 0px;
  color: #065f54;
  font-weight: 600;
}

.whatsapp strong {
  font-size: 13px;
  font-weight: 500;
  color: gray;
  text-transform: capitalize;
 }

 .whatsapp-box {
  width: 100%;
  border: 1px solid #cfc9c9;
  padding: 10px;
  margin-bottom: 18px;
  border-radius: 5px;
  padding-bottom: 60px;
}
.whatapp-app .form-control {
  color: #000;
  font-weight: 500;
}

.whatsapp-options {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.whatsapp-box h4{
  margin: 0px;
  font-size: 14px;
}
.whatsapp-box button {
  padding: 5px 10px;
  font-size: 10px;
}
.whatsapp-options p{
  margin: 0px;
  font-size: 13px !important;
  color: gray!important;
}

.choose-options {
  font-size: 13px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: start;
  margin: 10px 0px;
}
.whatsappImg img {
  width: 30px;
  height: 30px;
}
.whatsappImg {
   padding: 1px 0px;
}
.bodyMessage {
  width:100%;
  background: transparent !Important;
}