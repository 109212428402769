/* Overlay for modal */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Box */
.modal {
    background: white;
    width: 400px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-in-out;
}

/* Header */
.header {
    padding: 16px;
    background: #f5f5f5;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header h4 {
    margin: 0;
}

.closeButton {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

/* Body */
.body {
    padding: 16px;
}

/* Footer */
.footer {
    padding: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

/* Buttons */
.btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.secondary {
    background: #ddd;
    color: #333;
}

.danger {
    background: #e74c3c;
    color: white;
}

/* Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}