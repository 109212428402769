.app-one {
  background-color: #f7f7f7;
  height: calc(100vh - 150px);
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.app-one ::-webkit-scrollbar {
  width: 3px;
  border-radius: 5px;
}

/* Track */
.app-one ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.app-one ::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.app-one ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.side {
  padding: 0;
  margin: 0;
  height: 100%;
}

.unread-message-notification {
  background: #4f5c64;
  color: #fff;
  border-radius: 13px;
  height: 26px;
  padding: 3px 6px;
  font-size: 12px;
}

.side-one {
  padding: 0;
  margin: 0;
  height: 100%;
  z-index: 1;
  position: relative;
  display: block;
  top: 0;
}

.sideBar-body:visited {
  background-color: #f2f2f2;
}

.side-two {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  position: relative;
  top: -100%;
  left: -100%;
  -webkit-transition: left 0.3s ease;
  transition: left 0.3s ease;
}

#conversation:after {
  content: url(../../../Dashboard/assets/img/whatapp-bg.jpg);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
  opacity: 0.4;
  background-image: url(../../../Dashboard/assets/img/whatapp-bg.jpg);
}

.heading {
  padding: 10px 16px 10px 15px;
  margin: 0;
  width: 100%;
  background-color: #f0f2f5;
  z-index: 1000;
  border-bottom: 1px solid #f7f7f7;
}

.heading-avatar {
  padding: 0;
  cursor: pointer;
}

.heading-avatar-icon img {
  height: 30px;
  width: 30px;
  padding: 4px;
}

.heading-name {
  padding: 0 !important;
  cursor: pointer;
}

.heading-name-meta {
  font-weight: 700;
  font-size: 100%;
  padding: 5px;
  padding-bottom: 0;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
  display: block;
}

.heading-online {
  display: none;
  padding: 0 5px;
  font-size: 12px;
  color: #93918f;
}

.heading-compose {
  padding: 0;
}

.heading-compose i {
  text-align: center;
  padding: 5px;
  color: #93918f;
  cursor: pointer;
}

.heading-dot {
  padding: 0;
  margin-left: 10px;
}

.heading-dot i {
  text-align: right;
  padding: 5px;
  color: #93918f;
  cursor: pointer;
}

.searchBox {
  padding: 0 !important;
  margin: 0 !important;
  height: 60px;
  width: 100%;
}

.searchBox-inner {
  height: 100%;
  width: 100%;
  padding: 10px !important;
  background-color: #fbfbfb;
}

/*#searchBox-inner input {
  box-shadow: none;
}*/

.searchBox-inner input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.sideBar {
  padding: 0 !important;
  margin: 0 !important;
  background-color: #fff;
  overflow-y: auto;
  /* height: calc(100% - 54px); */
}

.sideBar-body.active {
  background: #f0f2f5;
}

.sideBar-body {
  position: relative;
  padding: 16px 0px 13px 0px !important;
  /* border-bottom: 1px solid #f7f7f7; */
  margin: 0 !important;
  cursor: pointer;
  width: 90%;
}
.sideBar-outerBody {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items:center ;
  /* overflow-x: hidden; */
  
}
.drop{
  /* padding: 7px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:center ;
  margin-top: 0px;
  height: 100%;
  cursor: pointer;
 
}
.sideBar-body :hover {
  background-color: #f2f2f2;
}
.sideBar-outerBody:hover{
  background-color: #f2f2f2;
}
.sideBar-avatar {
  text-align: center;
  padding: 0 !important;
}

.avatar-icon img {
  border-radius: 50%;
  height: 49px;
  width: 49px;
}

.sideBar-main {
  padding: 0 !important;
}

.sideBar-main .row {
  padding: 0 !important;
  margin: 0 !important;
}

.sideBar-name {
  padding: 0px 10px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  width: 150px;
}

.sideBar-name small {
  position: absolute;
  left: 11px;
  bottom: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  width: 150px;
  font-size: 11px;
}

.sideBar-name small::after {
  content: "...";
}

.name-meta {
  font-size: 12px;
  text-align: left;
  font-weight: 500;
  color: #000;
}

.sideBar-time {
  padding: 0px 10px !important;
}

.time-meta {
  text-align: right;
  font-size: 12px;
  padding: 1% !important;
  color: rgba(0, 0, 0, 0.4);
  vertical-align: baseline;
}

/*New Message*/

.newMessage {
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  position: relative;
  left: -100%;
}

.newMessage-heading {
  padding: 10px 16px 10px 15px !important;
  margin: 0 !important;
  height: 100px;
  width: 100%;
  background-color: #00bfa5;
  z-index: 1001;
}

.newMessage-main {
  padding: 10px 16px 0 15px !important;
  margin: 0 !important;
  height: 60px;
  margin-top: 30px !important;
  width: 100%;
  z-index: 1001;
  color: #fff;
}

.newMessage-title {
  font-size: 18px;
  font-weight: 700;
  padding: 10px 5px !important;
}

.newMessage-back {
  text-align: center;
  vertical-align: baseline;
  padding: 12px 5px !important;
  display: block;
  cursor: pointer;
}

.newMessage-back i {
  margin: auto !important;
}

.composeBox {
  padding: 0 !important;
  margin: 0 !important;
  height: 60px;
  width: 100%;
}

.composeBox-inner {
  height: 100%;
  width: 100%;
  padding: 10px !important;
  background-color: #fbfbfb;
}

.composeBox-inner input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.compose-sideBar {
  padding: 0 !important;
  margin: 0 !important;
  background-color: #fff;
  overflow-y: auto;
  border: 1px solid #f7f7f7;
  height: calc(100% - 160px);
}

/*Conversation*/

.conversation {
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  /*width: 100%;*/
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  /*overflow-y: auto;*/
}

.message {
  overflow-y: auto;
}

.message-previous {
  margin: 0 !important;
  padding: 0 !important;
  height: auto;
  width: 100%;
}

.previous {
  font-size: 15px;
  text-align: center;
  padding: 10px !important;
  cursor: pointer;
}

.previous a {
  text-decoration: none;
  font-weight: 700;
}

.message-body {
  margin: 0 !important;
  padding: 0 !important;
  width: auto;
  height: auto;
}

.message-main-receiver {
  /*padding: 10px 20px;*/
  max-width: 60%;
}

.message-main-sender {
  padding: 3px 20px !important;
  margin-left: 0% !important;
  max-width: 60%;
}

.message-text {
  margin: 0 !important;
  padding: 5px !important;
  word-wrap: break-word;
  font-weight: 500;
  color: #000;
  font-size: 14px;
  padding-bottom: 0 !important;
  text-shadow: none;
}

.message-body svg.svg-inline--fa.fa-check.fa-w-16 {
  font-size: 8px;
}

.message-time {
  margin: 0 !important;
  margin-left: 50px !important;
  font-size: 12px;
  text-align: right;
  color: #9a9a9a;
}

.col-sm-3.side {
  padding: 0px;
}

.receiver {
  width: auto !important;
  padding: 4px 10px 7px !important;
  border-radius: 10px 10px 10px 0;
  background: #ffffff;
  font-size: 12px;
  margin-bottom: 15px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  word-wrap: break-word;
  position: relative;
  z-index: 1;
  display: inline-block;
}

.sender {
  float: right;
  width: auto !important;
  background: #dcf8c6;
  border-radius: 10px 10px 0 10px;
  padding: 4px 10px 7px !important;
  font-size: 12px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  display: inline-block;
  position: relative;
  z-index: 1;
  word-wrap: break-word;
}

.whatsapp-image {
  width: 200px;
}

/*Reply*/
.attachment {
  position: absolute;
  top: -126px;
  background: #374e3b;
  left: 10px;
  padding: 11px;
  display: flex;
  color: #fff;
  justify-content: space-between;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  z-index: 99;
  flex-direction: column;
  gap: 17px;
}

.attachment svg {
  font-size: 20px;
}

.reply {
  height: auto;
  background: #c2c2c3 !important;
  padding: 9px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.whatsapp-alert {
  position: absolute !important;
  bottom: 25%;
  left: -2px;
  bottom: 15%;
  z-index: 9;
  margin: 0px auto;
  display: block;
}

.reply-emojis {
  padding: 5px !important;
}

p.empty-message {
  position: relative;
  z-index: 99;
}

.reply-emojis i {
  text-align: center;
  padding: 5px 5px 5px 5px !important;
  color: #93918f;
  cursor: pointer;
}

.unread-m-count {
  background: #3b7ddd;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: block;
  height: 18px;
  width: 18px;
  padding: 1px;
  position: absolute;
  top: 40px;
  right: -15px;
  text-align: center;
  transition: top 0.1s ease-out;
  font-size: 0.675rem;
  color: #fff;
  display: none;
  z-index: 1,
}

.unread-message span,
.unread-message small {
  font-weight: 700;
}

.unread-message .unread-m-count {
  display: block !important;
}

.reply-recording {
  padding: 5px !important;
}

.reply-recording i {
  text-align: center;
  padding: 5px !important;
  color: #93918f;
  cursor: pointer;
}

.whatsapp-image {
  border-radius: 10px;
}

.reply-send {
  padding: 5px !important;
}

.receiver small {
  float: right;
}

.image-caption {
  padding-top: 10px;
  font-weight: 300;
}

.reply-send i {
  text-align: center;
  padding: 5px !important;
  color: #93918f;
  cursor: pointer;
}

.reply-main {
  padding: 2px 5px !important;
}

.reply-send svg {
  margin-top: 11px;
  color: #fff;
}

.reply-send {
  background: #26dd43;
  border-radius: 10px;
  cursor: pointer;
  height: 44px;
}

.reply-main textarea {
  width: 100%;
  resize: none;
  overflow: hidden;
  padding: 10px !important;
  outline: none;
  border: none;
  text-indent: 12px;
  box-shadow: none;
  height: 100%;
  font-size: 16px;
}

.reply-main textarea:focus {
  outline: none;
  border: none;
  text-indent: 5px;
  box-shadow: none;
}

@media screen and (max-width: 700px) {
  .app {
    top: 0;
    height: 100%;
  }

  .fa-2x {
    font-size: 2.3em !important;
  }

  .heading-avatar {
    padding: 0 !important;
  }

  .heading-avatar-icon img {
    height: 50px;
    width: 50px;
  }

  .heading-compose {
    padding: 5px !important;
  }

  .heading-compose i {
    color: #fff;
    cursor: pointer;
  }

  .heading-dot {
    padding: 5px !important;
    margin-left: 10px !important;
  }

  .heading-dot i {
    color: #fff;
    cursor: pointer;
  }

  .sideBar {
    height: calc(100% - 130px);
  }

  .sideBar-body {
    height: 80px;
  }

  .sideBar-avatar {
    text-align: left;
    padding: 0 8px !important;
  }

  .avatar-icon img {
    height: 55px;
    width: 55px;
  }

  .sideBar-main {
    padding: 0 !important;
  }

  .sideBar-main .row {
    padding: 0 !important;
    margin: 0 !important;
  }

  .sideBar-name {
    padding: 10px 5px !important;
  }

  .name-meta {
    font-size: 16px;
    padding: 5% !important;
  }

  .sideBar-time {
    padding: 10px !important;
  }

  .time-meta {
    text-align: right;
    font-size: 14px;
    padding: 4% !important;
    color: rgba(0, 0, 0, 0.4);
    vertical-align: baseline;
  }

  /*Conversation*/
  .conversation {
    padding: 0 !important;
    margin: 0 !important;
    height: 100%;
    /*width: 100%;*/
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    /*overflow-y: auto;*/
  }

  .message {
    height: calc(100% - 140px);
  }

  .reply-emojis {
    padding: 5px 0 !important;
  }

  .reply-emojis i {
    padding: 5px 2px !important;
    font-size: 1.8em !important;
  }

  .reply-main {
    padding: 2px 8px !important;
  }

  .reply-main textarea {
    padding: 8px !important;
    font-size: 18px;
  }

  .reply-recording {
    padding: 5px 0 !important;
  }

  .reply-recording i {
    padding: 5px 0 !important;
    font-size: 1.8em !important;
  }

  .reply-send {
    padding: 5px 0 !important;
  }

  .reply-send i {
    padding: 5px 2px 5px 0 !important;
    font-size: 1.8em !important;
  }
}

.removenumbericon {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.number-input::-webkit-inner-spin-button,
.number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.dropdownn {
  position: relative;
  display: inline-block;
  background-color:"white";
}

.drop-butn {
  color: #7c7e81;
  padding: 5px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-top: 2px;
  background-color: white;
  border-radius: 3px;

}
.active-drop-butn{
  background-color: #f1f1f1;
  color: #7c7e81;
  padding: 5px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  margin-top: 2px;
  border-radius: 3px;
}

.dropdownn-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1000;
  /* top:4.5rem; */
  right:5px;
}

.addstarbtn {
  color: black;
  padding: 5px 9px;
  text-decoration: none;
  display: block;
  border: none;
  cursor: pointer;
  background-color: rgb(123, 167, 127);
  font-size: small;
  width: 100%;
}
.removestarbtn {
  color: rgb(228, 220, 220);
  padding: 5px 9px;
  text-decoration: none;
  display: block;
  border: none;
  cursor: pointer;
  background-color: rgb(149, 109, 64);
  font-size: small;
  width: 100%;
}

.addstarbtn:hover {background-color: rgb(159, 222, 164);}
.removestarbtn:hover {background-color: rgb(182, 138, 87);;}

.dropdownn .dropdownn-content {
  display: block;
}

.dropdownn:hover .drop-butn {
  background-color: #a8a9b1;
  color:white;
}
.whatsapp-chat-compname{
  display: inline-block;
  white-space: nowrap; 
  width: 140px; 
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  top: 7px;
  font-size: 14px;
}

.conv-search-icon{
  position: relative;
  top: 4px;
  border: 0;
}
.ChatPreviewFrame {
  max-width: 300px;
    border: 1px #d9dbe4 solid;
    height: calc(100vh - 360px);
    border-radius: 20px 20px;
    overflow: hidden;
    margin: 0px auto;
    position: sticky;
    top: 0px;
}
.borderBox {
  border: 1px #eee solid !important;
}
.alert-secondary {
  background: #f8f8f8 !Important;
}
.ChatFlexLeft {
  position: sticky;
    top: 0px;
}
ul.ChatUserList, ul.ChatUserList li {
  margin: 0px 0px;
  padding: 0px 0px;
  list-style-type: none;
}
ul.ChatUserList li {
  padding: 10px 10px;
  border-bottom: 1px #eee solid;
}
ul.ChatUserList li:hover{
  background: #f5f6f6;
}
.ChatLive_card {
  position: relative;
}
.userchatoverlay {
  background: #efeae2;
  background-image: url(../../../Dashboard/assets/img/chatBg.png);
  bottom: 0;

  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.chatLiveHeader {
  background: #fff;
  z-index: 99;
}
.ChatUserLeftScoll {
  height: calc(100vh - 2em);
  overflow-y: auto;
}

.chat-window {
  overflow-y: auto;
  padding: 15px;
  width:100%;
  height: calc(100vh - 400px);
  background:hsla(0, 0%, 100%, 0.75);
}
.chat-bubble {
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 10px;
  max-width: 100%;
}
.sender {
  background-color: rgba(3, 168, 78, .1);
  color: #000;
  align-self: flex-start;
}
.receiver {
  background-color: #eee;
  align-self: flex-end;
}

.borderbox {
  border: 1px #000 solid;
}
.ChatPreviewFrame  h6 {
  color: #fff;
}
.dropdown .dropdown-menu.show {
  top: auto !important;
}
.rmove {
  margin-right: 0px !important;
}
label {
  font-size: 14px;
}
.chatBorderBlock h6 {
  font-size: 15px;
}
.dropdown-menu {
  top: 100% !important;
}