.rcs-tabs {
    margin-bottom: 1rem;
}

.rcs-tab-button.active::after {
    content: '';
    border: solid #007bff;
    border-width: 0 18px 15px 0;
    display: inline-block;
    transform: rotate(132deg);
    position: absolute;
    left: 37px;
    bottom: -6px;
}

.rcs-tab-button {
    padding: 0.5rem 1rem;
    margin-right: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f8f9fa;
    cursor: pointer;
    font-size: 12px;
    position: relative;
}

.rcs-tab-button.active {
    background-color: #007bff;
    color: white;
}

.rcs-tab-content .rcs-tab-pane {
    display: none;
}

.rcs-tab-content .tab-pane.active {
    display: block;
}

.suggested-button {
    background-color: #f8f9fa;
    padding: 10px;
}

.rcs-title-preview {
    font-size: 12px;
}

.suggested-title {
    font-size: 14px;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 10px;
}

.preView {
    cursor: pointer;
}

.hide-current {
    display: none;
}