/* rcsTemplateDetail.module.css */

/* .textSuggestions {
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    margin: 16px 0;
} */

.templateBody {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.textBold {
    font-weight: bold;
    margin-right: 8px;
}

.suggestions {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.suggestionButton {
    background-color: #007bff;
    color: white;
}

.suggestionButton:hover {
    background-color: #0056b3;
}

.noSuggestions {
    color: #888;
}

.invalidTemplate {
    color: red;
    font-weight: bold;
}

.carousal {
    display: flex;
    gap: 16px;
    overflow-x: auto;
    padding: 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
}

.richCard {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    text-align: center;
}

.media img,
.media video {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 16px;
}

.cardContent h4 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
}

.cardContent p {
    font-size: 14px;
    color: #555;
}


.row {
    display: flex;
    align-items: center;
    /* gap: 16px; */
    margin-bottom: .5rem;
    font-size: .8rem;
}

.label {
    font-weight: bold;
    width: 20%;
    /* Adjust width as needed */
    text-align: left;
    margin-bottom: 0 !important;
    margin-right: 1rem !important;
    font-size: .8rem;
    text-wrap: balance;
}

.contentWithCopy {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
    justify-content: space-between;
}

.copyButton {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #555;
    transition: color 0.3s ease;
}

.copyButton:hover {
    color: #007bff;
}

.buttonsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    flex: 1;
}

.buttonCard {
    background: #f8f9fa;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 280px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .badge {
    background-color: #007bff;
    color: white;
    padding: 4px 10px;
    font-size: 12px;
    border-radius: 12px;
    font-weight: bold;
  }
  
  .property {
    margin-bottom: 10px;
  }
  
  .property label {
    font-weight: bold;
    display: block;
    margin-bottom: 4px;
  }
  
  .valueContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    padding: 6px 8px;
    border-radius: 6px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }

.carousal {
    display: flex;
    flex-direction: row; /* Arrange cards horizontally */
    gap: 16px; /* Space between cards */
    overflow-x: auto; /* Enable horizontal scrolling */
    padding: 16px; /* Padding around the carousal container */
    border: 1px solid #ddd; /* Add a border to distinguish the carousal */
    border-radius: 8px; /* Rounded corners for a modern look */
    background-color: #fff; /* White background for cards */
    scrollbar-width: thin; /* Thin scrollbar for better aesthetics */
    scrollbar-color: #ccc #f9f9f9; /* Custom scrollbar color */
}

/* Styling for the cards inside the carousal */
.carousal .richCardCarouselItem {
    flex: 0 0 calc(100% / 2 - 16px); /* Adjust card width, e.g., 3 cards per view */
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for cards */
    padding: 16px;
    text-align: center;
    min-width: 280px; /* Minimum width to ensure proper display */
}

.richCardStandalone{
    background-color: whitesmoke;
    padding: .5rem;
    border-radius: .3rem;
}



/* Styling for the media inside the card */
.carousal .media img,
.carousal .media video {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 16px;
}

/* Styling for the text inside the card */
.carousal .cardContent h4 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
}

.carousal .cardContent p {
    font-size: 14px;
    color: #555;
}

/* Styling for suggestions/buttons inside the card */
.carousal .buttonsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
}

.carousal .button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.carousal .suggestionButton {
    background-color: #007bff;
    color: white;
}

.carousal .suggestionButton:hover {
    background-color: #0056b3;
}

/* Styling for the custom scrollbar */
.carousal::-webkit-scrollbar {
    height: 8px; /* Height of the horizontal scrollbar */
}

.carousal::-webkit-scrollbar-thumb {
    background-color: #ccc; /* Color of the scrollbar thumb */
    border-radius: 8px; /* Rounded scrollbar thumb */
}

.carousal::-webkit-scrollbar-track {
    background-color: #f9f9f9; /* Track background color */
}
@media (max-width: 768px) {
    .carousal .richCardCarouselItem {
        flex: 0 0 calc(100% / 2 - 16px); 
    }
}

@media (max-width: 480px) {
    .carousal .richCardCarouselItem {
        flex: 0 0 100%; 
    }
}


